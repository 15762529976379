import React    from "react";
import Layout   from "@components/formLayout";
import Form     from '@cBehaviour/form';
import T        from "@cBehaviour/i18n";
import Contact  from "@root/entities/contacts";
import Observer from "@common/lib/observer";
import Comment      from "@root/entities/comments";
import Application  from "@uBehaviour/application";

class ContactForm extends React.Component{
  constructor(props){
    super(props);
    this._observer = new Observer();
  }
  render(){
    const props = this.props;
    const comment = this.props.id === "new" ? null : (
      <Layout.Comment>
        <Comment.View subject={ this.props.id } query={{ "tenants": { $elemMatch: { "tenant" : this.props.currentTenant.currentId, roles: "admin" }}, _id: { $ne: this.props.session.userId }}} id={ this.props.id } resource="Contact" />
      </Layout.Comment>
    );
    // const logs = this.props.id === "new" ? null : (
    //   <Layout.Log>
    //     <Contact.Log id={ this.props.id } />
    //   </Layout.Log>
    // );

    const id = props.id === "new" ? null : props.id;
    
    return (
      <Layout close={ props.close }>
        <Layout.Title>
          <Form.Observer observer={ this._observer }>
          {contact => (
            <div>
              <T>contact</T>
              <span> --- </span>
              <span>{ contact.name }</span>
            </div>
          )}
          </Form.Observer>
        </Layout.Title>
        <Layout.Form>
          <Contact.Form id={ id } observer={ this._observer }/>
        </Layout.Form>
        {/* comment */}
      </Layout>
    )
  }
};

export default Application.forward(["currentTenant", "session"], [], ContactForm);
import { FunctionComponent, PropsWithChildren } from "react";

import './responsive.css';

const Mobile: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className="bs-responsive-mobile">
    { children }
  </div>
);

const Web: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className="bs-responsive-web">
    { children }
  </div>
);

export default {
  Mobile,
  Web
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-forgotPassword {
  margin-bottom: 15px;
}
.bs-forgotPassword .bs-nudge {
  font-size: small;
  margin-bottom: var(--std-size-inter-element);
}
.bs-forgotPassword .bs-old-input-text-like{
  padding: 15px;
  border-radius: 5px;
}
.bs-forgotPassword-actions {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--std-large-size-inter-element);
}
.bs-forgotPassword-actions > span{
  margin-bottom: 30px;
  color: #d94c0b;
}
.bs-forgotPassword-actions > span:hover{
  cursor: pointer;
}

.bs-forgotPassword button{
  background-color: #D94C0B;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: medium;
  font-weight: lighter;
}
.bs-forgotPassword>button:nth-child(3){
  margin-top: 14px;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/applications/unauthenticated/views/login/forgotPassword.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,4CAA4C;AAC9C;AACA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,wCAAwC;AAC1C;AACA;EACE,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".bs-forgotPassword {\n  margin-bottom: 15px;\n}\n.bs-forgotPassword .bs-nudge {\n  font-size: small;\n  margin-bottom: var(--std-size-inter-element);\n}\n.bs-forgotPassword .bs-old-input-text-like{\n  padding: 15px;\n  border-radius: 5px;\n}\n.bs-forgotPassword-actions {\n  margin-top: 15px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: var(--std-large-size-inter-element);\n}\n.bs-forgotPassword-actions > span{\n  margin-bottom: 30px;\n  color: #d94c0b;\n}\n.bs-forgotPassword-actions > span:hover{\n  cursor: pointer;\n}\n\n.bs-forgotPassword button{\n  background-color: #D94C0B;\n  border-radius: 5px;\n  padding: 8px 20px;\n  font-size: medium;\n  font-weight: lighter;\n}\n.bs-forgotPassword>button:nth-child(3){\n  margin-top: 14px;\n  margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React                    from "react";
import _                        from "lodash";
import moment                   from "moment";
import { Grid }                 from "semantic-ui-react";
import Form                     from "@uBehaviour/form";
import Field                    from "@cComponents/field";
import Input                    from "@cComponents/input";
import Application              from "@uBehaviour/application";
import T                        from "@cBehaviour/i18n";
import Display                  from "@uComponents/displayIf";
import Tool                     from "@uLib/tool";
import Demo                     from "./demo";
import Button                   from "@cComponents/button";
import SelectTeamsAgents        from "@root/entities/teamsAndAgents/select";
import Assignment               from '@entities/assignments';
import fetch                    from "@wLib/fetch";
import { Section }              from "@cComponents/section";
import IssueFormContent         from "@root/entities/issues/components/form/content";
import NewCError                from '@cComponents/error_new';
import FormError                from "@cComponents/formError";

import "./form.css";
import Acl from "@universal/behaviour/acl";

const getTeamsAndAgents = (values) => {
  const team = values.filter(t => t.type === "team").map(t => t._id);
  if(team && team.length){
    const query = { _id: team[0] };
    return fetch(`/teams?q=${JSON.stringify(query)}`).then(response => response.json()).then(teams => teams.map(t => { t.type = "team"; return t; }));
  }
  const agents = values.filter(a => a.type === "agent").map(a => a._id);
  if(agents && agents.length){
    const query = { _id: { $in: agents } };
    return fetch(`/users?q=${JSON.stringify(query)}`).then(response => response.json()).then(agents => agents.map(a => { a.type = "agent"; return a; }));
  }
};

const getEquipements = (equipments) => {
  const query = { _id: { $in: equipments } };
  return fetch(`/equipments?q=${JSON.stringify(query)}`).then(response => response.json());
}

const IssueTemplate = Application.Service.forward(["currentTenant"], ({ currentTenant }) => {
    return (
      <Form.Simple.Adapter name="template">
      {(  errors, value, set ) => (
        <Form.Simple onChange={ (form, object) => { set(object); return object; }} errors={ Form.Simple.toRelayError(errors, "template") } value={ value } hasDependency>
        {(ctx, value, errors) => (
          <>
            <IssueFormContent value={ value }/>
            <Grid columns={16} stackable>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Field.Short name="plannedType">
                    <Input.Radio.Btn inline>
                      <Input.Radio.Value value="deadline"><T>recurrence_form_type_deadline</T></Input.Radio.Value>
                      <Input.Radio.Value value="assignment"><T>recurrence_form_type_assignment</T></Input.Radio.Value>
                    </Input.Radio.Btn>
                  </Field.Short>
                </Grid.Column>
              </Grid.Row>
              <Display.If condition={ value.plannedType === "assignment" }>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Field.Short name="assignment.allDay" required>
                      <Input.Radio.Btn inline>
                        <Input.Radio.Value value={true}><T>recurrence_form_assignment_allDay_yes</T></Input.Radio.Value>
                        <Input.Radio.Value value={false}><T>recurrence_form_assignment_allDay_no</T></Input.Radio.Value>
                      </Input.Radio.Btn>
                    </Field.Short>
                  </Grid.Column>
                </Grid.Row>
                <Display.If condition={ !value.assignment?.allDay }>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Field.Short name="assignment.startHour" required>
                        <Input.Hour />
                      </Field.Short>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Field.Short name="assignment.endHour" required>
                        <Input.Hour />
                      </Field.Short>
                    </Grid.Column>
                  </Grid.Row>
                </Display.If>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Field.Base  name="assignment.assignedTo">
                      <Field.Label><T>recurrence_label_assignment_assignedTo</T></Field.Label>
                      <Field.Input>
                        {(value, set, clear)=> (
                          <Input.SimpleSelectable 
                            textify={assignment => assignment.type === "agent" ? <span><span className="fa fa-user" />&nbsp;{ assignment.fullname }</span> : <span><span className="fa fa-users" />&nbsp;{ assignment.name }</span> }
                            getDatas={ getTeamsAndAgents }
                            onChange={ (val) => set(val.team.map(t => ({type: "team", _id: t._id})).concat(val.agents.map(a => ({type: "agent", _id: a._id}))))}
                            value={ value ? value : [] }
                            label={(<T>assignment_add_assignment</T>)}
                          >
                            <Input.SimpleSelectable.Means>
                              {(onChange, close) => (
                                <SelectTeamsAgents
                                  stopSelection={close}
                                  onChange ={onChange}
                                  selected={{ team: value?.filter(v => v.type === "team").map(t => ({ _id: t._id })) || [], agents: value?.filter(v => v.type === "agent").map(a => ({ _id: a._id })) || [] }}
                                />
                              )}
                            </Input.SimpleSelectable.Means>
                          </Input.SimpleSelectable>
                        )}
                      </Field.Input>
                    </Field.Base>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Field.Base  name="assignment.necessariesEquipments">
                      <Field.Label><T>recurrence_label_assignment_necessariesEquipments</T></Field.Label>
                      <Field.Input>
                        {(value, set, clear)=> (
                          <Input.SimpleSelectable 
                            textify={equipment => <span><span className="fa fa-wrench" />&nbsp;{ equipment.name }</span> }
                            getDatas={ getEquipements }
                            onChange={ set }
                            value={  value ? value.map(e => e._id) : [] }
                            label={(<T>assignment_add_equipment</T>)}
                          >
                            <Input.SimpleSelectable.Means>
                              <Assignment.SelectEquipments 
                                tenant={ currentTenant.currentId }
                                selected={ value ? value : []  }
                              />
                            </Input.SimpleSelectable.Means>
                          </Input.SimpleSelectable>
                        )}
                      </Field.Input>
                    </Field.Base>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Field.Base  name="assignment.necessariesSupplies">
                      <Field.Label><T>recurrence_label_assignment_necessariesSupplies</T></Field.Label>
                      <Field.Input>
                        {(value, set, clear)=> (
                          <Assignment.SelectSupply
                            tenant={ currentTenant.currentId } 
                            onChange={ set }
                            value={ value ? value : [] }
                          />
                        )}
                      </Field.Input>
                    </Field.Base>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Field.Short  name="assignment.workInstructions">
                      <Input.Textarea />
                    </Field.Short>
                  </Grid.Column>
                </Grid.Row>
              </Display.If>
            </Grid>
          </>
        )}
        </Form.Simple>
      )}
      </Form.Simple.Adapter>
    );
  }
);


const DaySelector = ({ value, onChange, Component, children, ...rest }) => (
  <Component { ...rest } value={ value } onChange={ onChange }>
  {
    Tool.range(1, 8).map(day => (
      <Component.Value key={day} value={day % 7}>
      {selected => (
        <div className={ `bs-recurence-daySelector ${selected ? "bs-selected" : "bs-notSelected"}`}>
          <div className="bs-recurence-daySelector-header"/>
          <div className="bs-recurence-daySelector-content">
            { moment().day(day % 7).format("dddd") }
          </div>
        </div>
      )}
      </Component.Value>
    ))
  }
  </Component>
);

class InputSchedulerRule extends React.Component {
  _onRuleTypeChange = (discriminator) => {
    const value = this._dataRuleToFormRule(this.props.value);
    switch(discriminator){
      case "weekly":
        value.rule = {
          discriminator: "weekly",
          dayOfWeek: value.rule.dayOfWeek !== undefined ? value.rule.dayOfWeek : []
        };
        break;
      case "monthlyFixedDay":
        value.rule = {
          discriminator: "monthlyFixedDay",
          dayOfMonth: value.rule.dayOfMonth !== undefined ? value.rule.dayOfMonth : 1
        };
        break;
      case "monthlyRelativeDay":
        value.rule = {
          discriminator: "monthlyRelativeDay",
          nth: value.rule.nth !== undefined ? value.rule.nth : 1,
          dayOfWeek: value.rule.dayOfWeek !== undefined ? value.rule.dayOfWeek : 1
        };
        break;
      case "yearlyFixedDay":
        value.rule = {
          discriminator: "yearlyFixedDay",
          dayOfMonth: value.rule.dayOfMonth !== undefined ? value.rule.dayOfMonth : 1,
          month: value.rule.month !== undefined ? value.rule.month : 1
        };
        break;
      case "yearlyRelativeDay":
        value.rule = {
          discriminator: "yearlyRelativeDay",
          nth: value.rule.nth !== undefined ? value.rule.nth : 1,
          dayOfWeek: value.rule.dayOfWeek !== undefined ? value.rule.dayOfWeek : 1,
          month: value.rule.month !== undefined ? value.rule.month : 1
        };
        break;
      default: throw new Error(`InputSchedulerRule._onRuleTypeChange : unknown discriminator "${discriminator}"`);
    }
    this._onChange(value);
  }
  _onJumpChange = (jump) => {
    const value = this._dataRuleToFormRule(this.props.value);
    value.jump = jump - 1;
    this._onChange(value);
  }
  _onNotWorkingDayRuleChange = (onNotWorkingDay) => {
    const value = this._dataRuleToFormRule(this.props.value);
    value.onNotWorkingDay = onNotWorkingDay;
    this._onChange(value);
  }
  _onDayOfWeekChange = (values) => {
    const value = this._dataRuleToFormRule(this.props.value);
    value.rule.dayOfWeek = values;
    this._onChange(value);
  }
  _onDayOfMonthChange = (dayOfMonth) => {
    const value = this._dataRuleToFormRule(this.props.value);
    value.rule.dayOfMonth = dayOfMonth;
    this._onChange(value);
  }
  _onNthChange = (nth) => {
    const value = this._dataRuleToFormRule(this.props.value);
    value.rule.nth = nth;
    this._onChange(value);
  }
  _onMonthChange = (month) => {
    const value = this._dataRuleToFormRule(this.props.value);
    value.rule.month = month;
    this._onChange(value);
  }
  _getDefaultFormRule(){
    return {
      jump: 0,
      onNotWorkingDay: null,
      rule: {
        discriminator: "weekly",
        dayOfWeek: []
      }
    };
  }
  _dataRuleToFormRule(dataRule){
    return this._recursiveDataRuleToFormRule(this._getDefaultFormRule(), dataRule);
  }
  _recursiveDataRuleToFormRule(formRule, dataRule){
    switch(dataRule.discriminator){
      case "jump":
        formRule.jump = dataRule.jump;
        this._recursiveDataRuleToFormRule(formRule, dataRule.rule);
        break;
      case "notWorkingDayJumpNext":
        formRule.onNotWorkingDay = "jumpNext";
        this._recursiveDataRuleToFormRule(formRule, dataRule.rule);
        break;
      case "notWorkingDayScheduleOnNextWorkingDay":
        formRule.onNotWorkingDay = "scheduleOnNextWorkingDay";
        this._recursiveDataRuleToFormRule(formRule, dataRule.rule);
        break;
      case "notWorkingDayScheduleOnPreviousWorkingDay":
        formRule.onNotWorkingDay = "scheduleOnPreviousWorkingDay";
        this._recursiveDataRuleToFormRule(formRule, dataRule.rule);
        break;
      case "weekly":
        formRule.rule = {
          discriminator: "weekly",
          dayOfWeek: dataRule.dayOfWeek
        };
        break;
      case "monthlyFixedDay":
        formRule.rule = {
          discriminator: "monthlyFixedDay",
          dayOfMonth: dataRule.dayOfMonth
        };
        break;
      case "monthlyRelativeDay":
        formRule.rule = {
          discriminator: "monthlyRelativeDay",
          dayOfWeek: dataRule.dayOfWeek,
          nth: dataRule.nth
        };
        break;
      case "yearlyFixedDay":
        formRule.rule = {
          discriminator: "yearlyFixedDay",
          dayOfMonth: dataRule.dayOfMonth,
          month: dataRule.month
        };
        break;
      case "yearlyRelativeDay":
        formRule.rule = {
          discriminator: "yearlyRelativeDay",
          dayOfWeek: dataRule.dayOfWeek,
          nth: dataRule.nth,
          month: dataRule.month
        };
        break;
      default: throw new Error(`InputSchedulerRule._recursiveDataRuleToFormRule : unknown discriminator "${dataRule.discriminator}"`);
    }
    return formRule;
  }
  _formRuleToDataRule(formRule){
    let rule = formRule.rule;

    if(formRule.jump){
      rule = {
        discriminator: "jump",
        jump: formRule.jump,
        rule
      };
    }

    switch(formRule.onNotWorkingDay){
      case "jumpNext":
        rule = {
          discriminator: "notWorkingDayJumpNext",
          rule
        };
        break;
      case "scheduleOnNextWorkingDay":
        rule = {
          discriminator: "notWorkingDayScheduleOnNextWorkingDay",
          rule
        };
        break;
      case "scheduleOnPreviousWorkingDay":
        rule = {
          discriminator: "notWorkingDayScheduleOnPreviousWorkingDay",
          rule
        };
        break;
      default:
    }

    return rule;
  }
  _onChange(value){
    if(this.props.onChange){
      this.props.onChange(this._formRuleToDataRule(value));
    }
  }
  _getDayOfWeek(discriminator){
    const dayOfWeek = this._dataRuleToFormRule(this.props.value).rule?.dayOfWeek;
    if(discriminator === 'weekly'){
      if(Array.isArray(dayOfWeek)){
        return dayOfWeek;
      }
      if(_.isInteger(dayOfWeek)){
        return [dayOfWeek];
      }
      return [];
    }
    if(_.isInteger(dayOfWeek)){
      return dayOfWeek;
    }
    if(Array.isArray(dayOfWeek) && dayOfWeek.length){
      return dayOfWeek[0];
    }
    return 1;
  }
  render(){
    const value         = this._dataRuleToFormRule(this.props.value);
    const jump          = _.isInteger(value.jump) ? value.jump + 1 : 1;
    const notWorkingDay = value.onNotWorkingDay === undefined ? null : value.onNotWorkingDay;
    const discriminator = value?.rule?.discriminator ? value?.rule?.discriminator : "weekly";
    const nth           = value.rule?.nth ? value.rule?.nth : 1;
    const dayOfWeek     = this._getDayOfWeek(discriminator);
    const month         = value.rule?.month ? value.rule?.month : 0;
    const dayOfMonth    = value.rule?.dayOfMonth ? value.rule?.dayOfMonth : 1;
    return (
      <div className="bs-recurrence-form-schedulerRule">
        <Input.Select onChange={ this._onRuleTypeChange } value={ discriminator } inline fluid>
          <Input.Select.Value value="weekly"><T>recurrence_form_weekly</T></Input.Select.Value>
          <Input.Select.Value value="monthlyFixedDay"><T>recurrence_form_monthlyFixedDay</T></Input.Select.Value>
          <Input.Select.Value value="monthlyRelativeDay"><T>recurrence_form_monthlyRelativeDay</T></Input.Select.Value>
          <Input.Select.Value value="yearlyFixedDay"><T>recurrence_form_yearlyFixedDay</T></Input.Select.Value>
          <Input.Select.Value value="yearlyRelativeDay"><T>recurrence_form_yearlyRelativeDay</T></Input.Select.Value>
        </Input.Select>
        <div className="bs-recurrence-form-schedulerRule-rule">
          <Display.Switch>
            <Display.Case condition={ discriminator === "weekly" }>
              <div className="bs-recurrence-form-schedulerRule-rule-weekly">
                <Field.Display required>
                  <Field.Label><T>recurrence_schedulerRule_weekly_daySelect</T></Field.Label>
                  <Field.Input><DaySelector onChange={ this._onDayOfWeekChange } value={ dayOfWeek } Component={ Input.Checkbox } noFrame inline /></Field.Input>
                </Field.Display>
                <Form.Simple.ErrorAdapter name="schedulerRule.dayOfWeek">
                  {errors => (
                    <NewCError errors={errors} />
                  )}
                </Form.Simple.ErrorAdapter>
                <Field.Display>
                  <Field.Label><T>recurrence_schedulerRule_weekly_weekSelect</T></Field.Label>
                  <Field.Input>
                    <div className="bs-recurrence-form-schedulerRule-rule-weekly-jump">
                      <T bind={{ jump: ( <Input.Numeric nullable={ false } numericType="integer" onChange={ this._onJumpChange } value={jump} min={1} />) }}>recurrence_schedulerRule_weekly_weekSelect_value</T>
                    </div>
                  </Field.Input>
                </Field.Display>
              </div>
            </Display.Case>
            <Display.Case condition={ discriminator === "monthlyFixedDay" }>
              <T bind={{
                jump: (<Input.Numeric nullable={ false } numericType="integer" onChange={ this._onJumpChange } value={jump} min={1}/>),
                dayOfMonth: (<Input.Numeric nullable={ false } numericType="integer" onChange={ this._onDayOfMonthChange } value={dayOfMonth} min={1} max={31}/>),
              }}>recurrence_schedulerRule_monthlyFixedDay</T>
            </Display.Case>
            <Display.Case condition={ discriminator === "monthlyRelativeDay" }>
              <T bind={{
                adNum: <sup>{ nth === 1 ? <T>add_num_1</T> : nth === 2 ? <T>add_num_2</T> : <T>add_num_rest</T> }</sup>,
                jump: (<Input.Numeric nullable={ false } numericType="integer" onChange={ this._onJumpChange } value={jump} min={1}/>),
                nth: (<Input.Numeric nullable={ false } numericType="integer" onChange={ this._onNthChange } value={nth} min={1} max={5}/>),
                dayOfWeek: (<Input.Select onChange={ this._onDayOfWeekChange } value={dayOfWeek}>{ Tool.range(1, 8).map(day => (<Input.Select.Value key={day} value={day % 7}>{ moment().day(day%7).format("dddd")}</Input.Select.Value>))}</Input.Select>)
              }}>recurrence_schedulerRule_monthlyRelativeDay</T>
            </Display.Case>
            <Display.Case condition={ discriminator === "yearlyFixedDay" }>
              <T bind={{
                jump: (<Input.Numeric nullable={ false } numericType="integer" onChange={ this._onJumpChange } value={jump} min={1}/>),
                dayOfMonth: (<Input.Numeric nullable={ false } numericType="integer" onChange={ this._onDayOfMonthChange } value={dayOfMonth} min={1} max={31}/>),
                month: (<Input.Select onChange={ this._onMonthChange } value={month}>{ Tool.range(0, 12).map(month => (<Input.Select.Value key={month} value={month}>{ moment().month(month).format("MMMM")}</Input.Select.Value>))}</Input.Select>)
              }}>recurrence_schedulerRule_yearlyFixedDay</T>
            </Display.Case>
            <Display.Case condition={ discriminator === "yearlyRelativeDay" }>
              <T bind={{
                jump: (<Input.Numeric nullable={ false } numericType="integer" onChange={ this._onJumpChange } value={jump} min={1}/>),
                adNum: <sup>{ nth === 1 ? <T>add_num_1</T> : nth === 2 ? <T>add_num_2</T> : <T>add_num_rest</T> }</sup>,
                nth: (<Input.Numeric nullable={ false } numericType="integer" onChange={ this._onNthChange } value={nth} min={1} max={5}/>),
                dayOfWeek: (<Input.Select onChange={ this._onDayOfWeekChange } value={dayOfWeek}>{ Tool.range(1, 8).map(day => (<Input.Select.Value key={day} value={day % 7}>{ moment().day(day%7).format("dddd")}</Input.Select.Value>))}</Input.Select>),
                month: (<Input.Select onChange={ this._onMonthChange } value={month}>{ Tool.range(0, 12).map(month => (<Input.Select.Value key={month} value={month}>{ moment().month(month).format("MMMM")}</Input.Select.Value>))}</Input.Select>)
              }}>recurrence_schedulerRule_yearlyRelativeDay</T>
            </Display.Case>
          </Display.Switch>
        </div>
        <div className="bs-recurrence-form-schedulerRule-notWorkingDay">
          <Field.Display>
            <Field.Label><T>recurrence_schedulerRule_notWorkingDay</T></Field.Label>
            <Field.Input>
              <Input.Select value={ notWorkingDay } onChange={ this._onNotWorkingDayRuleChange } fluid>
                <Input.Select.Value value={ null }><T>recurrence_schedulerRule_notWorkingDay_schedule</T></Input.Select.Value>
                <Input.Select.Value value={ "jumpNext" }><T>recurrence_schedulerRule_notWorkingDay_jumpNext</T></Input.Select.Value>
                <Input.Select.Value value={ "scheduleOnNextWorkingDay" }><T>recurrence_schedulerRule_notWorkingDay_scheduleNextWorkingDay</T></Input.Select.Value>
                <Input.Select.Value value={ "scheduleOnPreviousWorkingDay" }><T>recurrence_schedulerRule_notWorkingDay_schedulePreviousWorkingDay</T></Input.Select.Value>
              </Input.Select>
            </Field.Input>
          </Field.Display>
        </div>
      </div>
    );
  }
}
class EndRule extends React.Component{
  constructor(props){
    super(props);
    this._onRuleChange    = this._onRuleChange.bind(this);
    this._onNumberChange  = this._onNumberChange.bind(this);
    this._onDateChange    = this._onDateChange.bind(this);
  }
  _onRuleChange(discriminator){
    switch(discriminator){
      case "never":
        this._onChange(null);
        break;
      case "occurenceNumbers":
        this._onChange({
          discriminator: "occurenceNumbers",
          number: 1
        });
        break;
      case "date":
        this._onChange({
          discriminator: "date",
          date: moment().toISOString()
        });
        break;
      default: throw new Error(`EndRule._onRuleChange : unknown discriminator "${discriminator}"`)
    }
  }
  _onNumberChange(number){
    if(this.props.value && this.props.value.discriminator === "occurenceNumbers"){
      const value = Tool.clone(this.props.value);
      value.number = number;
      this._onChange(value);
    }
  }
  _onDateChange(date){
    if(this.props.value && this.props.value.discriminator === "date"){
      const value = Tool.clone(this.props.value);
      value.date = moment(date).startOf("day").toISOString();
      this._onChange(value);
    }
  }
  _onChange(value){
    if(this.props.onChange){
      this.props.onChange(value);
    }
  }
  render(){
    const discriminator = this.props.value ? this.props.value.discriminator : "never";
    const date          = moment(this.props.value?.date ? this.props.value.date : moment().toISOString()).toDate();
    const number        = this.props.value?.number ? this.props.value.number : 1;
    return (
      <div className="bs-recurrence-form-endRule">
        <Input.Radio.Btn onChange={ this._onRuleChange } value={ discriminator } inline>
          <Input.Radio.Value value="never">
            <T>recurrence_form_endRule_radio_never</T>
          </Input.Radio.Value>
          <Input.Radio.Value value="occurenceNumbers">
            <T>recurrence_form_endRule_radio_occurenceNumbers</T>
          </Input.Radio.Value>
          <Input.Radio.Value value="date">
            <T>recurrence_form_endRule_radio_date</T>
          </Input.Radio.Value>
        </Input.Radio.Btn>
        <Display.Switch>
          <Display.Case condition={ discriminator === "occurenceNumbers" }>
            <div className="bs-recurrence-form-endRule-input">
              <T bind={{
                number: (<Input.Numeric nullable={ false } numericType="integer" onChange={ this._onNumberChange } value={number} min={1}/>)
              }}>recurrence_form_endRule_occurenceNumbers</T>
              <Display.If condition={this.props.history.nbrAlreadyPlanned}>
                &nbsp;<T bind={{ nbrAlreadyPlanned: this.props.history.nbrAlreadyPlanned}}>recurrence_form_endRule_alreadyPlanned</T>
              </Display.If>
            </div>
          </Display.Case>
          <Display.Case condition={ discriminator === "date" }>
            <div className="bs-recurrence-form-endRule-input">
              <T bind={{
                date: <Input.Date value={ date } onChange={ this._onDateChange } />
              }}>recurrence_form_endRule_date</T>
            </div>
          </Display.Case>
        </Display.Switch>     
      </div>
    );
  } 
}
const RecurrenceRule = ({ schedulerRule, history }) => (
  <Grid stackable>
    <Grid.Row>
      <Grid.Column width={ 8 }>
        <Field.Short name="schedulerRule" noError>
          <InputSchedulerRule />
        </Field.Short>
      </Grid.Column>
      <Grid.Column width={ 8 }>
        <Field.Short name="endRule" noError>
          <EndRule history={ history }/>
        </Field.Short>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <Form.Simple.InputAdapter name="eventHorizonInDay">
        {(value, set, clear) => (
          <div className="bs-recurrence-eventHorizonInDay-input">
            <T bind={{
                eventHorizonInDay: <Input.Numeric nullable={ false } numericType="integer" onChange={ set } value={ value } min={1}/>
              }}
            >recurrence_schedulerRule_eventHorizonInDay</T>
          </div>
        )}
        </Form.Simple.InputAdapter>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <Form.Simple.InputAdapter name="start">
        {(value, set, clear) => (
          <div className="bs-recurrence-start-input">
            <Display.If condition={ history.lastPlannedDate }>
              <Display.Then>
                <T bind={{ lastPlannedDate: moment(history.lastPlannedDate).format("DD/MM/YYYY")}}>recurrence_form_schedulerRule_next_occurence_lastPlannedDate</T>
              </Display.Then>
              <Display.Else>
                <T bind={{ 
                  start: <Input.Date value={ moment(value).toDate() } onChange={ set } />
                }}>recurrence_form_schedulerRule_next_occurence_start</T>
              </Display.Else>
            </Display.If>
          </div>
        )}
        </Form.Simple.InputAdapter>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

let schedulerRule = {};
schedulerRule = Object.assign(schedulerRule, { type: "discriminator", rules: [
  { test: (value) => value.discriminator === "jump",
    rule: { type: "object", props: {
      jump: { type: "number", integer: true, min: 0 },
      rule: schedulerRule
    }},
  },
  { test: (value) => value.discriminator === "notWorkingDayJumpNext",
    rule: { type: "object", props: {
      rule: schedulerRule
    }},
  },
  { test: (value) => value.discriminator === "notWorkingDayScheduleOnNextWorkingDay",
    rule: { type: "object", props: {
      rule: schedulerRule
    }},
  },
  { test: (value) => value.discriminator === "notWorkingDayScheduleOnPreviousWorkingDay",
    rule: { type: "object", props: {
      rule: schedulerRule
    }},
  },
  { test: (value) => value.discriminator === "weekly",
    rule: { type: "object", props: {
      dayOfWeek: { type: "array", items: { type: "number", integer: true, min: 0, max: 6 }, min: 1, max: 7 }
    }},
  },
  { test: (value) => value.discriminator === "monthlyFixedDay",
    rule: { type: "object", props: {
      dayOfMonth: { type: "number", integer: true, min: 1, max: 31 }
    }},
  },
  { test: (value) => value.discriminator === "monthlyRelativeDay",
    rule: { type: "object", props: {
      dayOfWeek: { type: "number", integer: true, min: 0, max: 6 },
      nth: { type: "number", integer: true, min: 1, max: 5 }
    }},
  },
  { test: (value) => value.discriminator === "yearlyFixedDay",
    rule: { type: "object", props: {
      dayOfMonth: { type: "number", integer: true, min: 1, max: 31 },
      month: { type: "number", integer: true, min: 0, max: 11 }
    }},
  },
  { test: (value) => value.discriminator === "yearlyRelativeDay",
    rule: { type: "object", props: {
      dayOfWeek: { type: "number", integer: true, min: 0, max: 6 },
      nth: { type: "number", integer: true, min: 1, max: 5 },
      month: { type: "number", integer: true, min: 0, max: 11 }
    }},
  }
]});

const recurrenceSchema = {
  start: { type: "date", convert: true },
  eventHorizonInDay: { type: "number", integer: true, min: 1 },
  schedulerRule,
  endRule: { type: "discriminator", rules: [
    { test: (value) => value.discriminator === "occurenceNumbers",
      rule: { type: "object", props: {
        number: { type: "number", integer: true, min: 1 }
      }}
    },
    { test: (value) => value.discriminator === "date",
      rule: { type: "object", props: {
        date: { type: "date", convert: true }
      }}
    },
  ], optional: true },
  template: { type: "discriminator", rules: [
    { test: (value) => value.discriminator === "publicSpace",
      rule: { type: "object", props: {
        location: { type: "object", props: {
          address: { type: "object" },
          position: { type: "object" }
        }},
        files: { type: "array", items: "string", optional: true },
        category: { type: "string" },
        description: { type: "string", min: 1 }
      }}
    },
    { test: (value) => value.discriminator === "building",
      rule: { type: "object", props: {
        location: { type: "object", props: {
          building: { type: "string" },
          place: { type: "string", optional: true },
          locationInfo: { type: "string", optional: true },
        }},
        files: { type: "array", items: "string", optional: true },
        category: { type: "string" },
        description: { type: "string", min: 1 }
      }}
    },
    { test: (value) => value.discriminator === "equipment",
      rule: { type: "object", props: {
        equipment: { type: "string" },
        files: { type: "array", items: "string", optional: true },
        category: { type: "string" },
        description: { type: "string", min: 1 }
      }}
    }
  ]}
};

const endSchedulerRule = (rule) => rule.rule ? endSchedulerRule(rule.rule) : rule;

class RecurrenceForm extends React.Component {
  state = {
    confirmDelete: false
  }
  constructor(props){
    super(props);
    this._form               = React.createRef();
    this._preload            = this._preload.bind(this);
    this._presubmit          = this._presubmit.bind(this);
    this._submit             = this._submit.bind(this);
    this._onChange           = this._onChange.bind(this);
    this._onAddressChange    = this._onAddressChange.bind(this);
    this._onMapChange        = this._onMapChange.bind(this);
    this._close              = this._close.bind(this);
    this._confirmDelete      = this._confirmDelete.bind(this);
    this._cancelDelete       = this._cancelDelete.bind(this);
    this._delete             = this._delete.bind(this);
  }
  _preload(form, recurrence){
    //issue template

    if(recurrence?.template?.location?.building){
      recurrence.template.discriminator = "building";
    }else if(recurrence?.template?.equipment){
      recurrence.template.discriminator = "equipment";
    }else{
      recurrence.template.discriminator = "publicSpace";
    }

    recurrence.template.plannedType = recurrence.type;

    recurrence.start = recurrence.history.start;
    
    if(recurrence.type === "assignment") {
      const agents = recurrence.template.assignment.agents;
      const team = recurrence.template.assignment.team;
      recurrence.template.assignment.assignedTo = team.map(t => { 
        return {
          _id: t,
          type: "team"
        };
      }).concat(agents.map(a => {
        return {
          _id: a,
          type: "agent"
        };
      }));
      const equipments = recurrence.template.assignment.necessariesEquipments;
      recurrence.template.assignment.necessariesEquipments = equipments.map(e => {
        return {
          _id: e
        };
      })
    }

    return recurrence;
  }
  _onChange(form, recurrence, diff, type){
    diff.forEach(diff => {
      const path = diff.path.join(".");
      switch(path){
        case 'template.discriminator':
          recurrence.template.category = null;
          break;
        case 'template.plannedType':
          if(diff.rhs === "assignment"){
            recurrence.template.assignment = {
              allDay : true
            };
          }else{
            delete recurrence.template.assignment;
          }
          break;
        default:
      }
    });

    const rule = endSchedulerRule(recurrence.schedulerRule);
    if(rule.discriminator === 'weekly'){
      if(!Array.isArray(rule.dayOfWeek)){
        if(_.isInteger(rule.dayOfWeek)){
          rule.dayOfWeek =  [rule.dayOfWeek];
        } else {
          rule.dayOfWeek =  [];
        }
      }
    } else if(Array.isArray(rule.dayOfWeek)){
      if(rule.dayOfWeek.length){
        rule.dayOfWeek = rule.dayOfWeek[0];
      } else {
        rule.dayOfWeek = 1;
      }
    }

    return recurrence;
  }
  _presubmit(form, recurrence){
    const newRecurrence = {
      _id: recurrence._id,
      type: recurrence.template.plannedType,
      start: recurrence.start,
      eventHorizonInDay: recurrence.eventHorizonInDay,
      template: {
        description: recurrence.template.description,
        files: recurrence.template.files ? recurrence.template.files : [],
        category: recurrence.template.category,
        tags: recurrence.template.tags,
        requestor: recurrence.template.requestor
      },
      schedulerRule: recurrence.schedulerRule,
      endRule: recurrence.endRule
    };
    switch(recurrence.template.discriminator){
      case "publicSpace":
        newRecurrence.template.location = {
          address: recurrence.template.location.address,
          position: recurrence.template.location.position
        };
      break;
      case "building":
        newRecurrence.template.location = {
          building: recurrence.template.location.building,
          place: recurrence.template.location.place,
          locationInfo: recurrence.template.location.locationInfo
        };
      break;
      case "equipment":
        newRecurrence.template.equipment = recurrence.template.equipment;
      break;
      default: throw new Error(`RecurrenceForm._presubmit : unknwon recurrence.template.type "${recurrence.template.type}"`);
    }
    
    if(newRecurrence.type === "assignment"){
      let assignment = null;
      if(!recurrence.template.assignment){
        assignment = {
          assignedTo: [],
          necessariesEquipments: [],
          necessariesSupplies: [],
          workInstructions: null
        }
      }else{
        assignment = JSON.parse(JSON.stringify(recurrence.template.assignment));
        if(!assignment.assignedTo){
          assignment.assignedTo = [];
        }
        if(!assignment.necessariesEquipments){
          assignment.necessariesEquipments = [];
        }
      }
      newRecurrence.template.assignment = {
        allDay: assignment.allDay,
        team: assignment.assignedTo.filter(t => t.type === "team").map(t => t._id),
        agents: assignment.assignedTo.filter(t => t.type === "agent").map(a => a._id),
        necessariesEquipments: assignment.necessariesEquipments.map(e => e._id),
        necessariesSupplies: assignment.necessariesSupplies,
        workInstructions: assignment.workInstructions
      };
      if(!assignment.allDay){
        newRecurrence.template.assignment.startHour = assignment.startHour;
        newRecurrence.template.assignment.endHour = assignment.endHour;
      }
    }else{
      newRecurrence.template.assignment = {
        team: [],
        agents: [],
        necessariesEquipments: [],
        necessariesSupplies: [],
        workInstructions: null
      };
    }
    return newRecurrence;
  }
  _submit(form, recurrence){
    let p = Promise.reject();
    if(recurrence._id){
      p = this.props.api.service("recurrences", "put").execute(recurrence._id, recurrence);
    }else{
      p = this.props.api.service("recurrences", "post").execute(recurrence);
    }
    return p.then(() => this._close());
  }

  _close(){
    if(this.props.close){
      this.props.close();
    }
  }

  _onAddressChange(coordinate){
    this._form.current.set("template.location.position", coordinate);

  }

  _onMapChange(value){
    if(value){
      this.props.google.getAddressFromPosition(value).then(address => {
        this._form.current.set("template.location.address", address);
      })
    }else{
      this._form.current.set("template.location.address", "");
    }
  }

  _confirmDelete(){
    this.setState({ confirmDelete: true });
  }

  _cancelDelete(){
    this.setState({ confirmDelete: false });
  }

  _delete(){
    this.props.api.service("recurrences", "delete").execute(this.props.recurrence._id)
      .then(() => this._close());
  }

  _execute = () => {
    this.props.api.service("recurrences", "execute").execute(this.props.recurrence._id)
  }

  getDefaultRecurrence = () => this.props.repository.get("Recurrence").default;


  render(){
    return (
      <Input.File.DropArea>
        <Form.Simple 
          ref={ this._form }
          value={ this.props.recurrence }
          preload={ this._preload }
          presubmit={ this._presubmit }
          submit={ this._submit }
          onChange={ this._onChange }
          default={ this.getDefaultRecurrence() }
          validator={{ schema: recurrenceSchema }}
        >
        {(ctx, recurrence, errors, form) => (
          <div className="bs-recurrence-form">
            <div className="bs-recurrence-form-content">
            <FormError errors={errors.global}>
                <div class="bs-recurrence-form-content-viewport">
                  <Field.ShortLabelContext prefix="recurrence">
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Section>
                            <Section.Title><T>recurrence_form_scheduler_rule</T></Section.Title>
                            <RecurrenceRule schedulerRule={ recurrence.schedulerRule } history={ recurrence.history }/>
                          </Section>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Section>
                            <Section.Title><T>recurrence_form_next_occurence</T></Section.Title>
                            <Demo recurrence={ recurrence } />
                          </Section>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Section>
                            <Section.Title><T>recurrence_form_issue_template</T></Section.Title>
                            <IssueTemplate />
                          </Section>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Field.ShortLabelContext>
                </div>
              </FormError> 
            </div>
            <div className="bs-recurrence-form-footer">
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div className="bs-recurrence-form-action">
                      <Display.If condition={ recurrence._id }>
                        <div className="bs-recurrence-form-action-delete-execute">
                          <Button.Text onClick={ this._confirmDelete }><T>recurrence_form_delete</T></Button.Text>
                          <Acl.If resource="recurrences" action="execute">
                            <Button.Text onClick={ this._execute }><T>recurrence_form_execute</T></Button.Text>
                          </Acl.If>
                        </div>
                      </Display.If>
                      <div className="bs-recurrence-form-action-save-cancel">
                        <Display.If condition={ this.state.confirmDelete }>
                          <Display.Then>
                            <>
                              <T>recurrence_form_delete_confirm_label</T>
                              <Button.Text onClick={ this._cancelDelete }><T>recurrence_form_delete_confirm_cancel</T></Button.Text>
                              <Button.Text onClick={ this._delete }><T>recurrence_form_delete_confirm_confirm</T></Button.Text>
                            </>
                          </Display.Then>
                          <Display.Else>
                            <>
                              <Button.Text onClick={ this._close }><T>recurrence_form_cancel</T></Button.Text>
                              <Button.Text onClick={ () => form.submit(true) }><T>recurrence_form_submit</T></Button.Text>
                            </>
                          </Display.Else>
                        </Display.If>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
        </Form.Simple>
      </Input.File.DropArea>
    );
  }
}

export default Application.Service.forward([["google-map", "google"], "geolocation", "api", "acl", "repository"], RecurrenceForm);
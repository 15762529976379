import React, { FunctionComponent } from "react";
import T from "@uBehaviour/i18n";
import useHelper from "@universal/behaviour/hooks/useHelper";
import AssistanceHelper from "@universal/helpers/assistance";

import "./button.css";
import Button from "@common/components/button";

export const AssistanceButton: FunctionComponent = () => {
  const assistance = useHelper<AssistanceHelper>("assistance");
  const openAssistance = React.useCallback(async () => await assistance.open(), [assistance]);  
  return (
    <Button onClick={ openAssistance }>
      <span className="fa fa-envelope"/>
      <span className="bs-assistance-button-text">
        <T>send_message</T>
      </span>
    </Button>
  );
};

export default AssistanceButton;
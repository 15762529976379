import React        from "react";
import Release      from "@cFeatures/release";
import Modal        from "@cComponents/modal";

import './list.css';


const ReleaseList = (props) => (
  <Modal.Show key="release" userCanClose={false}>
  {() => (
    <div className="bs-release-list-modal-container">
      <Release.List application={props.application} close={ props.close }/>
    </div>
  )}
  </Modal.Show>
);

export default ReleaseList;
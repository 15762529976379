// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-workInstructions-write textarea{
  width: 100%;
  resize: none;
}
.bs-workInstructions-write div{
  display: flex;
  justify-content: flex-end;
}
.bs-workInstructions-write .bs-button:hover,
.bs-workInstructions-read:hover{
  cursor: pointer;
}
.bs-workInstructions-read.readOnly:hover{
  cursor: text;
}
.bs-workInstructions-read span{
  color: #b3b3b3;
  font-weight: 400;
}
.bs-workInstructions-read.valued:hover,
.bs-workInstructions-read span:hover{
  text-decoration: underline;
}
.bs-workInstructions-write .bs-button{
  padding: 2px 5px;
  border-radius: 6px;
  margin-left: 6px;
  margin-top: 6px;
  color: white;
}
.bs-workInstructions-write .bs-button-cancel{
  background-color: #aaa;
}
.bs-workInstructions-write .bs-button-save{
  background-color: #E84C24;
}`, "",{"version":3,"sources":["webpack://./src/entities/assignments/components/workInstructions.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;;EAEE,eAAe;AACjB;AACA;EACE,YAAY;AACd;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;;EAEE,0BAA0B;AAC5B;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd;AACA;EACE,sBAAsB;AACxB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".bs-workInstructions-write textarea{\n  width: 100%;\n  resize: none;\n}\n.bs-workInstructions-write div{\n  display: flex;\n  justify-content: flex-end;\n}\n.bs-workInstructions-write .bs-button:hover,\n.bs-workInstructions-read:hover{\n  cursor: pointer;\n}\n.bs-workInstructions-read.readOnly:hover{\n  cursor: text;\n}\n.bs-workInstructions-read span{\n  color: #b3b3b3;\n  font-weight: 400;\n}\n.bs-workInstructions-read.valued:hover,\n.bs-workInstructions-read span:hover{\n  text-decoration: underline;\n}\n.bs-workInstructions-write .bs-button{\n  padding: 2px 5px;\n  border-radius: 6px;\n  margin-left: 6px;\n  margin-top: 6px;\n  color: white;\n}\n.bs-workInstructions-write .bs-button-cancel{\n  background-color: #aaa;\n}\n.bs-workInstructions-write .bs-button-save{\n  background-color: #E84C24;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import Query from "@universal/types/technic/Query"
import SortRule from "@universal/types/technic/Sort"
import PushService from "../push";
import NetworkingService from "../types/networking";
import Key from "@universal/lib/key";
import ICollection from "@universal/lib/collection/iCollection";
import md5 from 'md5'
import { Listener } from "@universal/lib/event";
import ICacheContext, { Api, ApiFindHandler, ApiGetHandler, ApiCountHandler } from "./iCacheContext";
import AsyncCollection from "@universal/lib/collection/asyncCollection";
import DelayCache from "@universal/lib/cache/delayCache";
import Collection from "@universal/lib/collection/collection";
import { FindHandler } from "@universal/lib/collection/iAsyncCollection";
import Sorter from "@universal/lib/sorter";
import logger from "@universal/lib/logger";

const isInIdQuery = <Type>(query: Query<Type>): boolean => {
  return Object.keys(query).length === 1 && !!query._id?.$in;
}

class ContextOnDemand<Type> implements ICacheContext<Type> {
  private findCache: DelayCache<AsyncCollection<Type>>;

  private countCache: DelayCache<AsyncCollection<Type>>;

  private type: string;

  private pushService: PushService;

  private networkService: NetworkingService;

  private apiFind: Api<Type, ApiFindHandler<Type>>;

  private apiGet: Api<Type, ApiGetHandler<Type>>;

  private apiCount: Api<Type, ApiCountHandler<Type>>;

  private collection: ICollection<Type>;

  private key: Key<Type>;

  private networkListener: Listener;

  private pushListener: Listener;

  private validateMode: boolean = false;

  constructor(
    type: string,
    pushService: PushService,
    networkService: NetworkingService,
    apiFind: Api<Type, ApiFindHandler<Type>>,
    apiGet: Api<Type, ApiGetHandler<Type>>,
    apiCount: Api<Type, ApiCountHandler<Type>>,
    key: Key<Type>
  ) {
    this.type = type;
    this.pushService = pushService;
    this.networkService = networkService;
    this.apiFind = apiFind;
    this.apiGet = apiGet;
    this.apiCount = apiCount;
    this.key = key;
    this.collection = new Collection<Type>(key);
    this.findCache = new DelayCache<AsyncCollection<Type>>(1800000);
    this.countCache = new DelayCache<AsyncCollection<Type>>(1800000);

    this.networkListener = this.networkService.onStateChange.addListener(new Listener(this.onNewtorkStateChange, this));
    this.pushListener = this.pushService.onMessageReceived.addListener(new Listener(this.onMessageReceived, this));
  }

  setValidateMode(validateMode: boolean): void {
    this.validateMode = validateMode;
  }

  private onNewtorkStateChange = () => {
    
  }

  private onMessageReceived = (type: string, action: "create" | "update" | "delete", datas: Type[]) => {
    if(this.type === type){
      if(action === 'create' || action === 'update'){
        this.collection.addMany(datas);
        for(const unit of this.findCache){
          unit.data.addMany(datas);
          unit.data.clearCount();
        }
      } else if(action === 'delete'){
        this.collection.dropMany(datas);
        for(const unit of this.findCache){
          unit.data.dropMany(datas);
          unit.data.clearCount();
        }
      }
    }
  }

  private processIsInIdQuery = (query: Query<Type>): [Type[], Query<Type>] => {
    if(!isInIdQuery(query)){
      return [[], query];
    }
    const ids = [...query._id.$in];  
    const objects = ids.map(id => {
      if(!this.collection.has(this.key.valuesToQuery([id]) as Partial<Type>)){
        return { id, v: null };
      }
      return { id, v: this.collection.get(this.key.valuesToQuery([id]) as Partial<Type>) };
    });

    const newQuery = { _id: { $in: objects.filter(o => !o.v).map(o => o.id) } };
    const results = objects.filter(o => o.v).map(o => o.v as Type);

    return [results, newQuery];
  }

  private findHandler: FindHandler<Type> = async (query, sort, offset, limit) => {
    if(sort instanceof Sorter) {
      sort = sort.sortRule;
    }
    if(Object.keys(sort).length === 0){
      sort = this.key.sortRule;
    }

    const sorter = Sorter.create<Type>(sort);

    const [localResults, newQuery] = this.processIsInIdQuery(query);
    if(isInIdQuery(newQuery) && newQuery._id.$in.length === 0){
      return localResults.sort(sorter.compare);
    }

    const results = await this.apiFind.execute(newQuery, sort, offset, limit);
    this.collection.addMany(results);
    
    if(!localResults.length){
      return results;
    }

    return localResults.concat(results).sort(sorter.compare);
  }

  createContext(query: Query<Type>): void {
  }

  dispose(): void {
    this.networkService.onStateChange.removeListener(this.networkListener);
    this.pushService.onMessageReceived.removeListener(this.pushListener);
  }

  find = async (query: Query<Type>, sortRule: SortRule<Type>, offset: number = 0, limit: number = 30): Promise<Type[]> => {
    if(!query){
      query = {};
    }
    if(!sortRule || Object.keys(sortRule).length === 0){
      sortRule = this.key.sortRule;
    }
    const hash = md5(JSON.stringify(query) + JSON.stringify(sortRule));
    const countHash = md5(JSON.stringify(query));
    if(!this.findCache.has(hash)){
      const collection = new AsyncCollection<Type>(
        this.key,
        query,
        sortRule,
        this.findHandler,
        this._get,
        this._count
      );
      this.findCache.create(hash, collection);
      this.countCache.create(countHash, collection);
    }
    const collection = this.findCache.get(hash);
    const results = await collection.slice(offset, limit);

    if(this.validateMode){
      this.validateFind(
        results,
        query,
        Sorter.create<Type>(sortRule).join(collection.sorter).sortRule,
        offset,
        limit
      );
    }

    return results;
  }

  private async validateFind(results: Type[], query: Query<Type>, sort: SortRule<Type>, offset: number, limit: number): Promise<void> {
    const apiResults = await this.apiFind.execute(query, sort, offset, limit);
    let valid = apiResults.length === results.length;
    for(let i = 0; i < results.length && valid; i++){
      valid = (results[i] as { _id: string })._id === (apiResults[i] as { _id: string })._id;
    }

    if(!valid){
      logger.error("Data inconsistency with '", this.type, "'\nquery: ", query, "\nsort : ", sort, "\ncache: ", results, "\napi: ", apiResults);
    }else {
      logger.info("Data consistency with '", this.type, "'\nquery: ", query, "\nsort : ", sort);
    }
  }

  private _get = async (object: Partial<Type>): Promise<Type> => {
    const key = this.key.extract(object) as Partial<Type>;
    let item: Type;
    if(this.collection.has(key)){
      item = await this.collection.get(key);
    } else {
      item = await this.apiGet.execute(this.key.queryToValues(object)[0]);
      this.collection.add(item);
    }
    return item;
  }

  get = async (id: string): Promise<Type> => {
    return this._get(this.key.valuesToQuery([id]));
  }

  private _count = (query: Query<Type>): Promise<number> => {
    return this.apiCount.execute(query);
  }
  count = async (query: Query<Type> | null): Promise<number> => {
    if(!query){
      query = {};
    }
    
    const countHash = md5(JSON.stringify(query));
    if(!this.countCache.has(countHash)){
      const hash = md5(JSON.stringify(query) + JSON.stringify(this.key.sortRule));
      const collection = new AsyncCollection<Type>(
        this.key,
        query,
        this.key.sortRule,
        this.findHandler,
        this._get,
        this._count
      );
      this.findCache.create(hash, collection);
      this.countCache.create(countHash, collection);
    }

    return this.countCache.get(countHash).length;
  }

}

export default ContextOnDemand;
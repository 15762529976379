import React        from "react";
import md5          from "md5";
import Text         from "./text";
import Select       from "./select";

const unsensitiveCaseBolder = (splitted, splitter) => {
    const ucSplitted    = splitted.toLowerCase();
    const ucSplitter    = splitter.toLowerCase();
    const bolded        = [];
    const sl            = splitter.length;
    let idx             = 0;
    
    if(ucSplitted.indexOf(ucSplitter) === 0){
        const text = splitted.substr(0, sl);
        bolded.push(React.createElement("b", { key: md5(text) }, text));
        idx += sl;
    }
    return bolded.concat(ucSplitted.split(ucSplitter).reduce((acc, val, index) => {
        if(acc.length){
            const text = splitted.substr(idx, sl);
            acc.push(React.createElement('b', { key: md5(`text_${index}`) }, text));
            idx += sl;
        }
        if(val){
            acc.push(splitted.substr(idx, val.length));
            idx += val.length;
        }
        return acc;
    }, []));
};
let id = 1;
export default class Autocomplete extends React.Component{
    constructor(props){
        super(props);
        this._id        = md5(++id + "");
        this._inputText = React.createRef();
        this._select    = React.createRef();
        this._searched  = null;
        this._values    = [];
        this._search(null);
    }
    get value(){
        return this._select.current.value;
    }
    set value(value){
        this._inputText.current.value = value;
        this._select.current.value = value;
    }
    clear(){
        this._inputText.current.clear();
        this._select.current.clear();
        this._search(null);
    }
    componentDidMount(){
        this._inputText.current.onChange.addListener(this);
        this._select.current.onChange.addListener(this);
    }
    handleEvent(ev, component){
        if(component === this._inputText.current){
            const value = this._inputText.current.value;
            if(value){
                this._search(this._inputText.current.value);
                this._select.current.open();
            }else{
                this._select.current.close();
            }
            this._select.current.value = null;
        }else if(component === this._select.current){
            this._inputText.current.value = this._select.current.label;
        }
    }

    _initialize(id) {
        if (!id) this._values = [];
        this.props.initialize(id)
        .then(value => {
            this._values = [value];
            this.forceUpdate();
        })
    }

    _search(value){
        if(value && value.trim() === ""){
            value = null;
        }
        this._searched = value;
        this.props.search(value)
            .then(values => {
                this._values = values;
                this.forceUpdate();
            });
    }
    render(){
        const children =  
            [React.createElement(Select.Display, { key: `${this._id}_display`}, (value, isOpen) => {
                return React.createElement(Text, { ref: this._inputText, noError: this.props.noError, value: value ? value.label : "" }, this.props.children)
            })].concat(this._values.map(value => (value => 
                !this._searched
                    ? React.createElement(Select.Value, value)
                    : React.createElement(Select.Value, value,
                        React.createElement('div', null, unsensitiveCaseBolder(value.label, this._searched))
                    )
            )({ ...value, key: `${this._id}_value_${md5(JSON.stringify(value.value))}` })
        ));
        return React.createElement(Select, {
            noError: this.props.noError,
            name: this.props.name,
            required:this.props.required,
            value: this.props.value,
            onChange: this.props.onChange,
            disabled:this.props.disabled,
            postSelect: this.props.postSelect ? this.props.postSelect : null,
            comparableValue: this.props.comparableValue ? this.props.comparableValue : null,
            withTooltip: true,
            initialize:this._initialize.bind(this),
            ref: this._select 
        }, children);
    }
}
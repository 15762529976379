import { TestBuilder } from "../library/test";
import Application from "@universal/lib/application";
import generatePassword from "@universal/lib/password";
import ApiService from "@universal/services/api";
import Tenant from "@universal/types/business/Tenant";


type StepCreation = { appPP: Application, appAdmin: Application };

export default (title: string, createApplication: () => Promise<StepCreation>, commerialOffer: null | "starter" | "expert", toCommercialOffer: "starter" | "expert") => {
  return TestBuilder.createTest("PanneauPocket", title)
    .addStep("Préparation de l'application", createApplication)
    .addStep("Récupération d'une structure avec Siren", async ({ appPP, appAdmin }: StepCreation) => {
      const size = 20;
      let offset = 0;
      let tenant = null;
      
      while(!tenant){
        const tenants = await appAdmin.getService<ApiService>("api").service("tenants", "get").execute({ 
          "informations.siren": { $ne: null }, 
          "settings.commercialOffer": commerialOffer 
        }, {}, offset, size) as Tenant[];

        if(!tenants.length){
          throw "Aucune structure trouvée";
        }

        for await(const t of tenants){
          const result = await appPP.getService<ApiService>("api").service("tenants", "panneaupocketInfo").execute(t.informations.siren);
          if(!result.technicalUser){
            tenant = t;
            break;
          }
        }

        offset += size;
      }

      return {
        tenant,
        appAdmin,
        appPP
      };
    })
    .addStep("Activation de la structure", async ({ appPP, appAdmin, tenant }: { appPP: Application, appAdmin: Application, tenant: Tenant }) => {
      const result = await appPP.getService<ApiService>("api").service("tenants", "panneaupocketActivate").execute({
        name: tenant.name.fr,
        siren: tenant.informations.siren,
        country: tenant.country,
        position: tenant.settings.defaultMap.position,
        zoomLevel: tenant.settings.defaultMap.zoomLevel,
        password: generatePassword(32)
      });

      const updatedTenant = await appAdmin.getService<ApiService>("api").service("tenants", "get").execute({ _id: tenant._id });

      if(updatedTenant[0].settings.commercialOffer !== toCommercialOffer){
        throw "Erreur lors de l'activation de la structure";
      }
    })
    .build();
};



import React from "react";
import GeneralTooltip from "./infoTooltipContent";
import Data from "@uBehaviour/data";
import AssignmentTooltip from "@entities/assignments/components/tooltip";

import "./tooltipContent.css";

const IssueTooltip = ({ id }) => (
  <Data.One model="Issue" id={ id } load={Object.assign({}, GeneralTooltip.load, AssignmentTooltip.load("assignments"))}>
  {issue => (
    <div className="bs-issue-tooltip">
      <GeneralTooltip data={ issue } />
      {
        issue.assignments.map(assignment => (
          <AssignmentTooltip assignment={ assignment } />
        ))
      }
    </div>
  )}
  </Data.One>
);

export default IssueTooltip;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issue-list-bulkUpdate-form-manager {
  height: inherit;
}

.bs-issue-list-bulkUpdate-form-manager-content {
  height: 60vh;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/managerForm.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".bs-issue-list-bulkUpdate-form-manager {\n  height: inherit;\n}\n\n.bs-issue-list-bulkUpdate-form-manager-content {\n  height: 60vh;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React          from "react";
import moment         from "moment";
import Application    from "@uBehaviour/application";
import data           from "@uBusiness/data";
import T              from "@cBehaviour/i18n";
import Filter         from "@cComponents/filter";
import Input          from "@cComponents/input";
import Collapsable    from "@cComponents/collapsable";
import Display        from '@uComponents/displayIf';      
import InputPeriod    from "@cComponents/input/period";
import User           from "@entities/users";
import Contact        from "@root/entities/contacts";
import toDic          from "@cLib/toDic";
import Filters        from "@entities/filters";
import PriorityItem   from '@root/components/priority/item';
import "./filters.css";
import Acl from "@universal/behaviour/acl";
import useAcl from "@universal/behaviour/hooks/useAcl";
import { combinate } from "@universal/lib/query";

const states = data.states.slice();

const priorities = [{
  label: "issue_filter_priority_1",
  value: 1,
  type: "normal"
}, {
  label: "issue_filter_priority_3",
  value: 3,
  type: "moyen"
}, {
  label: "issue_filter_priority_5",
  value: 5,
  type: "urgent"
}];

const buildQueryPeriod = (value) => {
  let period;
  
  switch(value.type){
    case "today":
      period = { $gte: moment().startOf("day").toISOString(), $lt: moment().startOf("day").add(1, "day").toISOString() };
      break;
    case "tomorrow":
      period = { $gte: moment().startOf("day").add(1, "day").toISOString(), $lt: moment().startOf("day").add(2, "day").toISOString() };
      break;
    case "last_7_days":
      period = { $gte: moment().startOf("day").subtract(7, "day").toISOString(), $lt: moment().startOf("day").add(1, "day").toISOString() };
      break;
    case "last_30_days":
      period = { $gte: moment().startOf("day").subtract(29, "day").toISOString(), $lt: moment().startOf("day").add(1, "day").toISOString() };
      break;
    case "next_7_days":
      period = { $gte: moment().startOf("day").toISOString(), $lt: moment().startOf("day").add(7, "day").toISOString() };
      break;
    case "custom_date":
      if(value.start && value.end){
        period = { $gte: moment(value.start).toISOString(), $lt: moment(value.end).toISOString() };
      }else{
        period = null;
      }
      break;
    default: throw new Error("Unknown type for buildQueryPeriod : " + value.type)
  }
  return period;
}


const buildScheduledQueryPeriod = (value) => {
  const type = value.type;
  if (type !== "custom_date") {
    const nbrDaysToEnd = type === "today" ? 1 : type === "tomorrow" ? 2 : 7;
    return { 
      "assignments.scheduledFrom": { $lt: moment().startOf("day").add(nbrDaysToEnd, "days").toISOString() },
      "assignments.scheduledTo": { $gte: moment().startOf("day").add(type === "tomorrow" ? 1 : 0).toISOString() }
    };
  }  
  if(!value.start || !value.end){
    return { "assignments.scheduledFrom": null, "assignments.scheduledTo": null };
  }
  return {
    "assignments.scheduledFrom": { $lt: moment(value.end).toISOString() },
    "assignments.scheduledTo": { $gte: moment(value.start).toISOString() }
  };
};

const buildCreatedByQuery = (values) => {
  let query = { $or:[{"createdBy": { $in: values.filter(v => v !== "citizen").map(v => v._id) }}]};
  if (values.includes("citizen")) {
    query.$or.push({"requestor.type": "bsCitizen"});
  }  
  return query;
}

const stringifyPeriod = (value) => {
  if(value.type !== "custom_date"){
    return (<T>{`issue_filter_${value.type}`}</T>)
  }else{
    if(value.start && value.end){
      return (<T bind={{ start: moment(value.start).format("DD/MM/YYYY"), end: moment(value.end).subtract(1, "day").format("DD/MM/YYYY")}}>statistics_custom_date_stringify</T>)
    }else{
      return (<T>statistics_custom_date</T>)
    }
  }
}

export default Application.Service.forward(["i18n", "repository", "session", "currentTenant", "api"], props => {
  const allowToReadBuilding = useAcl("categories", "readBuilding");
  const allowToReadEquipment = useAcl("categories", "readEquipment");

  let categoryQuery = null;
  if(!allowToReadBuilding){
    categoryQuery = combinate("$and", categoryQuery, { type: { $ne: "building" }});
  }
  if(!allowToReadEquipment){
    categoryQuery = combinate("$and", categoryQuery, { type: { $ne: "equipment" }});
  }

  return (
    <div className="bs-issues-filters-containers">
      {/* Filtre sur type */}
      <Filters.Type />
      {/* Filtre sur statuts */}
      <Filters.State states={ states } />

      <Acl.If resource="issues" action="viewFilterBuilding">
        {/* Filtre sur bâtiments */}
        <Filters.Building />
        {/* Filtre sur les sous-bâtiments*/}
        <Filters.SubBuilding />
      </Acl.If>
      {/* Filtre sur les catégories */}
      <Filters.Category query={ categoryQuery }/>
      <Acl.If resource="issues" action="viewFilterEquipment">
        {/* Filtre sur les équipements */}
        <Filters.Equipment />
      </Acl.If>
      {/* Filtre sur les managers */}
      <Filters.Manager />
      {/* Filtre sur les créateurs */}          
      <Filter.Generic
        deshydrate={value => value === "citizen" ?  value : value._id}
        hydrate={values => {
          let p = Promise.resolve({});
          const filteredValues = values.filter(v => v !== "citizen");
          if (filteredValues.length) {
            p = props.api.service("users", "get").execute({_id: {$in: filteredValues }}).then(creators => toDic(values, v => v, (v) => creators.find(c => c._id === v)))
          }
          return p.then(dic => {
            if (values.includes("citizen")) {
              dic = {...dic, "citizen": "citizen"}
            }
            return dic;
          });
        }}
        multiple
        name="creators"
        buildQuery={buildCreatedByQuery}
        stringify={value => { return (<><T>issue_filter_creators</T> : {value !== "citizen" ? value.fullname : <T>issue_filter_creators_by_citizen</T>}</>)}}
      >
        {(values, add, drop, clear) => {
          let query = { "tenants": { $elemMatch: { tenant: props.currentTenant.currentId, disabled: false }}, discriminator: {$ne: "citizen" }};
          if(values.length){
            query = { $and: [query, { _id: { $nin: values.filter(v => v !== "citizen").map(v => v._id) }}]};
          }
          return (
            <Collapsable>
              <Collapsable.Title><T>issue_filter_creators</T></Collapsable.Title>
              <Collapsable.Content>
                <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
                  <Input.Checkbox.Value key="citizen" value={"citizen"}>
                    <T>issue_filter_creators_by_citizen</T>
                  </Input.Checkbox.Value>
                </Input.Checkbox.BtnFilter>
                <div className="bs-filter-creators-users-pro-label"><T>issue_filter_users_pro_label</T></div>
                <Input.Selectable 
                  value={ values.filter(v => v !== "citizen").map(v => props.repository.get("User").key.extract(v))}
                  onChange={(vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }}
                  model={ props.repository.get("User") }
                  load={{ avatar:true }}
                  query={ query }
                  sort={{ fullname: 1 }}
                  textify={ user => user.fullname }
                  filterQuery={ value => ({ fullname: { '$regex': value, '$options': 'i' } }) }
                >
                  <User.Item />
                </Input.Selectable>
              </Collapsable.Content>
            </Collapsable>
          )
        }}
      </Filter.Generic>
      {/* Filtre sur les étiquettes */}
      <Filters.Tag />
      {/* Filtre sur les localités */}
      <Filters.Localities />
      {/* Filtre sur la priorité */}
      <Filter.Generic 
        deshydrate={value => value.value}
        hydrate={values => priorities.reduce((map, v) => { map.set(v.value, v); return map; }, new Map())}
        multiple
        name="priorities"
        buildQuery={values => ({ "priority": { $in: values.map(v => v.value) } })}
        stringify={value => (<><T>issue_filter_priority</T> : <T>{ value.label }</T></>)}
      >
        {(values, add, drop, clear) => (
          <Collapsable>
            <Collapsable.Title><T>issue_filter_priority</T></Collapsable.Title>
            <Collapsable.Content>
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
              {
                priorities.map(priority => (
                  <Input.Checkbox.Value key={ priority.label } value={ priority }>
                    <PriorityItem type={priority.type} />
                  </Input.Checkbox.Value>
                ))
              }
              </Input.Checkbox.BtnFilter>
            </Collapsable.Content>
          </Collapsable>
        )}
      </Filter.Generic>
      {/* Filtre sur la visibilité*/}
      <Filter.Generic
        multiple
        name="visibility"
        buildQuery={value => value.length === 1 ? ({ "public": value[0] }) : {} }
        stringify={value => (<><T>issue_filter_visibility</T> : <T>{ value ? "issue_filter_visibility_public" : "issue_filter_visibility_private" }</T></>)}
      >
        {(values, add, drop, clear) => (
          <Collapsable>
            <Collapsable.Title><T>issue_filter_visibility</T></Collapsable.Title>
            <Collapsable.Content>
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
                <Input.Checkbox.Value value={false}><T>issue_filter_visibility_private</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value={true}><T>issue_filter_visibility_public</T></Input.Checkbox.Value>
              </Input.Checkbox.BtnFilter>
            </Collapsable.Content>
          </Collapsable>
        )}
      </Filter.Generic>
      {/* Filtre sur la présence de valorisation*/}
      <Filter.Generic 
        multiple
        name="valorization"
        buildQuery={value => value.length === 1 ? { withValorization: value[0] } : {}}
        stringify={value => (<T>{ value ? "issue_filter_valorization_withValorization" : "issue_filter_valorization_withoutValorization" }</T>)}
      >
        {(values, add, drop, clear) => (
          <Collapsable>
            <Collapsable.Title><T>issue_filter_valorization</T></Collapsable.Title>
            <Collapsable.Content>
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
                <Input.Checkbox.Value value={true}><T>issue_filter_valorization_with</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value={false}><T>issue_filter_valorization_without</T></Input.Checkbox.Value>
              </Input.Checkbox.BtnFilter>
            </Collapsable.Content>
          </Collapsable>  
        )}
      </Filter.Generic>
      {/* Filtre sur le fait de suivre un signalement*/}
      <Filters.Followed />
      {/* Filtre sur la présence de récurence*/}
      <Filter.Generic
        multiple
        name="recurrence"
        buildQuery={value => (value.length === 1 ? { "recurrence": (value[0] ? { $ne: null } : null )} : {})}
        stringify={value => (<T>{ value ? "issue_filter_recurrence_withRecurrence" : "issue_filter_recurrence_withoutRecurrence" }</T>)}
      >
        {(values, add, drop, clear) => (
          <Collapsable>
            <Collapsable.Title><T>issue_filter_recurrence</T></Collapsable.Title>
            <Collapsable.Content>
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
                <Input.Checkbox.Value value={true}><T>issue_filter_recurrence_with</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value={false}><T>issue_filter_recurrence_without</T></Input.Checkbox.Value>
              </Input.Checkbox.BtnFilter>
            </Collapsable.Content>
          </Collapsable>
        )}
      </Filter.Generic>
      {/* Filtre transféré à */}
      <Acl.If resource="contacts" action="manage"> 
        <Filter.Generic 
          deshydrate={value => value._id}
          hydrate={values => props.api.service("contacts", "get").execute({_id: {$in: values }}).then(contacts => toDic(values, v => v, (v) => contacts.find(c => c._id === v)))}
          multiple
          name="transfers"
          buildQuery={values => ({ "transferredTo._id": { $in: values.map(v => v._id) } })}
          stringify={value => { return (<><T>issue_filter_transferred</T> : {value.name}</>)}}
        >
          {(values, add, drop, clear) => {
            let query = { tenant: props.currentTenant.currentId, disabled : false };
            if(values.length){
              query = { $and: [query, { _id: { $nin: values.map(v => v._id) }}]};
            }
            return (
              <Collapsable>
                <Collapsable.Title><T>issue_filter_transferred</T></Collapsable.Title>
                <Collapsable.Content>
                  <Input.Selectable 
                    value={ values.map(v => props.repository.get("Contact").key.extract(v))}
                    onChange={(vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }}
                    model={ props.repository.get("Contact") }
                    query={ query }
                    sort={{ name: 1 }}
                    textify={ contact => contact.name }
                    filterQuery={ value => ({ name: { '$regex': value, '$options': 'i' } }) }
                  >
                    <Contact.Item />
                  </Input.Selectable>
                </Collapsable.Content>
              </Collapsable>
            )
          }}
        </Filter.Generic>
      </Acl.If>
      {/* Filtre équipes */}
      <Filters.Team />
      {/* Filtre agents */}
      <Filters.Agent />
      {/* Filtre sur la date de résolution */}
      <Filter.Generic
        deshydrate={({ type, start, end }) => ({ type, start: start ? moment(start).toISOString(): null, end: end ? moment(end).toISOString() : null }) }
        hydrate={ 
          values => values.reduce((map, value) => { 
            map.set(value, { type: value.type, start: value.start ? moment(value.start).toDate() : null, end: value.end ? moment(value.end).toDate() : null });
            return map
          }, new Map())
        }
        name="resolvedAt"
        buildQuery={value => ({resolvedAt: buildQueryPeriod(value)})}
        stringify={value => (<><T>issue_filter_resolved_at_resolved</T> : { stringifyPeriod(value) }</>)}
      >
        {(value, set, clear) => (
          <Collapsable>
            <Collapsable.Title><T>issue_filter_resolved_at</T></Collapsable.Title>
            <Collapsable.Content>
              <Input.Radio.BtnFilter onChange={(value) => { set({ type: value }); }} value={value && value.type}>
                <Input.Radio.Value value={"today"}><T>issue_filter_today</T></Input.Radio.Value>
                <Input.Radio.Value value={"last_7_days"}><T>issue_filter_last_7_days</T></Input.Radio.Value>
                <Input.Radio.Value value={"last_30_days"}><T>issue_filter_last_30_days</T></Input.Radio.Value>
                <Input.Radio.Value value={"custom_date"}><T>issue_filter_custom_date</T></Input.Radio.Value>
              </Input.Radio.BtnFilter>
              <Display.If condition={ value && value.type === "custom_date"}>
                <div className="bs-filter-period">
                  <InputPeriod
                    allDay
                    start={value && value.start }
                    end={value && value.end }
                    onChange={ (start, end) =>  set({ type: "custom_date", start, end }) }
                  >
                    <InputPeriod.StartLabel>
                      <T>issue_filter_resolvedAt_input_period_start_label</T>
                    </InputPeriod.StartLabel>
                    <InputPeriod.EndLabel>
                      <T>issue_filter_resolvedAt_input_period_end_label</T>
                    </InputPeriod.EndLabel>
                  </InputPeriod>
                </div>
              </Display.If>
            </Collapsable.Content>
          </Collapsable>
        )}
      </Filter.Generic>
      {/* Filtre sur la date de création */}

      <div className="bs-filter-period">
        <Filters.CreatedAt />
      </div>
      {/* Filtre sur la période planifiée */}
      <Filter.Generic 
        deshydrate={({ type, start, end }) => ({ type, start: start ? moment(start).toISOString(): null, end: end ? moment(end).toISOString() : null }) }
        hydrate={ 
          values => values.reduce((map, value) => { 
            map.set(value, { type: value.type, start: value.start ? moment(value.start).toDate() : null, end: value.end ? moment(value.end).toDate() : null });
            return map
          }, new Map())
        }
        name="assignmentScheduled"
        buildQuery={buildScheduledQueryPeriod}
        stringify={value => (<><T>issue_filter_assignmentScheduled_scheduled</T> : { stringifyPeriod(value) }</>)}
      >
        {(value, set, clear) => (
          <Collapsable>
            <Collapsable.Title><T>issue_filter_assignmentScheduled</T></Collapsable.Title>
            <Collapsable.Content>
              <Input.Radio.BtnFilter onChange={(value) => { set({ type: value }); }} value={value && value.type}>
                <Input.Radio.Value value={"today"}><T>issue_filter_today</T></Input.Radio.Value>
                <Input.Radio.Value value={"tomorrow"}><T>issue_filter_tomorrow</T></Input.Radio.Value>
                <Input.Radio.Value value={"next_7_days"}><T>issue_filter_next_7_days</T></Input.Radio.Value>
                <Input.Radio.Value value={"custom_date"}><T>issue_filter_custom_date</T></Input.Radio.Value>
              </Input.Radio.BtnFilter>
              <Display.If condition={ value && value.type === "custom_date"}>
                <div className="bs-filter-period">
                  <InputPeriod
                    allDay
                    start={value && value.start }
                    end={value && value.end }
                    onChange={ (start, end) =>  set({ type: "custom_date", start, end }) }
                  >
                    <InputPeriod.StartLabel>
                      <T>issue_filter_assignment_input_period_start_label</T>
                    </InputPeriod.StartLabel>
                    <InputPeriod.EndLabel>
                      <T>issue_filter_assignment_input_period_end_label</T>
                    </InputPeriod.EndLabel>
                  </InputPeriod>
                </div>
              </Display.If>
            </Collapsable.Content>
          </Collapsable>
        )}
      </Filter.Generic>
      {/* Filtre sur la date d'échéance */}
      <div className="bs-filter-period">
        <Filters.Deadline />
      </div>
    </div>
  )
});
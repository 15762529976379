// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-campaign-manager-askAgain{
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.bs-campaign-manager-askAgain-label{
  flex-grow: 1;
  text-align: justify;
}
.bs-campaign-manager-askAgain-actions{
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/entities/campaigns/components/manager.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".bs-campaign-manager-askAgain{\n  height: 100%;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n}\n.bs-campaign-manager-askAgain-label{\n  flex-grow: 1;\n  text-align: justify;\n}\n.bs-campaign-manager-askAgain-actions{\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import ApiService from "@universal/services/api";
import { TestBuilder } from "../library/test";
import Application from "@universal/lib/application";
import RepositoryService from "@universal/services/repository";
import SessionService from "@universal/services/session";
import Issue from "@universal/types/business/Issue";


type StepCreation = { appAc: Application, appAdmin: Application };
type StepValidation = StepCreation & { adminIssues: Issue[], acIssues: Issue[] };

const getAllIssues = async (app: Application, query?: object): Promise<Issue[]> => {
  if(!query){
    query = {};
  }
  const repository = app.getService<RepositoryService>("repository").get("Issue").repository;
  let issues: Issue[] = [];
  let finished = false;
  let index = 0;
  const toLoad = 100;

  while(!finished) {
    const tmpIssues = await repository.find(query, {}, index, toLoad) as Issue[];
    issues = issues.concat(tmpIssues);
    finished = tmpIssues.length !== toLoad;
    index += toLoad;
  }

  return issues;
};

export default (createApplication: () => Promise<StepCreation>) => {
  return TestBuilder.createTest("AttractiveCity", "Récupération des signalements et des commentaires")
    .addStep("Préparation de l'application", createApplication)
    .addStep("Récupération des signalements qui devrait être visible par l'utilisateur AC avec un utilisateur SuperAdmin", async ({ appAc, appAdmin }: StepCreation) => {
      const adminQuery = {
        createdBy: appAc.getService<SessionService>("session").userBusiness._id,
        tenant: appAc.getService<SessionService>("session").userBusiness.tenant 
      };

      const adminIssues = await getAllIssues(appAdmin, adminQuery);
      if(adminIssues.length === 0){
        throw "Aucun signalement trouvé pour l'utilisateur SuperAdmin";
      }
      return { adminIssues, appAc, appAdmin };
    })
    .addStep("Récupération des signalements visibles par l'utilisateur AC", async ({ appAc, adminIssues, appAdmin }: StepCreation & { adminIssues: Issue[] }) => {
      const acIssues = await getAllIssues(appAc);
      return { adminIssues, acIssues, appAc, appAdmin };
    })
    .addStep("Comparaison des deux listes de signalements", async ({ adminIssues, acIssues, appAc, appAdmin }: StepValidation) => {
      if(adminIssues.length !== acIssues.length){
        throw "L'utilisateur AC n'a pas accès à toutes les signalements";
      }
      return { appAc, acIssues, appAdmin };
    })
    .addStep("Création d'un commentaire publique et d'un privé puis récupération des commentaires pour un signalement", async ({ appAc, acIssues, appAdmin }: StepValidation) => {
      const issue = acIssues[0];

      const commentBeforeCreations = await appAc.getService<ApiService>("api").service("issues", "getComments").execute(issue._id) as Comment[];

      await appAdmin.getService<ApiService>("api").service("issues", "createPublicComment").execute(issue._id, "Test AC commentaire publique");

      await appAdmin.getService<ApiService>("api").service("issues", "createPrivateComment").execute(issue._id, "Test AC commentaire privé");

      const commentAfterCreations = await appAc.getService<ApiService>("api").service("issues", "getComments").execute(issue._id) as Comment[];
      
      if(commentAfterCreations.length !== commentBeforeCreations.length + 1){
        if(commentAfterCreations.length === commentBeforeCreations.length) {
          throw "L'utilisateurs AC n'a pas accès à tous les commentaires publiques";
        }
        throw "L'utilisateurs AC a accès aux commentaires privés";
      }
      
    })
    .build();
};
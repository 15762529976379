// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issue-group{
  white-space: nowrap;
  margin: 0 5px;
}

.bs-issue-group-issue>a {
  color: var(--state-display-color);
}

.bs-issue-group>.bs-issue-group-issue:nth-child(n + 2) {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/group.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".bs-issue-group{\n  white-space: nowrap;\n  margin: 0 5px;\n}\n\n.bs-issue-group-issue>a {\n  color: var(--state-display-color);\n}\n\n.bs-issue-group>.bs-issue-group-issue:nth-child(n + 2) {\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { FunctionComponent }                  from 'react'; 
import createLogView          from "@root/entities/logs";
import Change from '@root/entities/logs/components/change';
import T                      from "@uBehaviour/i18n";
import { BusinessEntity } from '@universal/types/technic/Entityable';
import Tenant, { CommercialOfferType } from '@universal/types/business/Tenant';
import Text, { Style } from '@common/components/text';
import { LogV3Creation } from '@universal/types/business/Log';

const Log = createLogView('Tenant');

type TenantLogProps = {
  tenant: BusinessEntity<Tenant>;
}

const getCommercialOffer = (data: Tenant) => {
  return data.settings.commercialOffer
    ? data.settings.commercialOffer
    : <T>tenant_logs_commercialOffer_not_client</T>;
}
const getCitizenAllowed = (data: Tenant) => {
  return data.settings.citizen.allowed 
    ? <T>tenant_logs_citizen_allowed_yes</T>
    : <T>tenant_logs_citizen_allowed_no</T>
}
const getIsAdminPublicByDefault = (data: Tenant) => {
  return data.settings.citizen.adminIssuesPublicByDefault
    ? <T>tenant_logs_admin_issues_default_public_yes</T>
    : <T>tenant_logs_admin_issues_default_public_no</T>
}

const getClientIdJvs = (data: Tenant) => {
  return data.clientIdJvs
    ? data.clientIdJvs
    : <T>tenant_logs_clientIdJvs_undefined</T>
};
  
const getLogCreation = (data: Tenant): LogV3Creation => {
  return {
    _id: data._id,
    version: 3,
    type: "creation",
    subject: {
      resource: "Tenant",
      id: data._id
    },
    createdAt: data.createdAt,
    createdBy: "000000000000000000000001"
  };
}
const TenantLog: FunctionComponent<TenantLogProps> = ({ tenant }) => (
  <Log data={ tenant } getLogCreation={ getLogCreation }>
    <Log.Rule 
      version={2}
      type={"name"}
      title={() => (<T>tenant_logs_name</T>)}
      textify={ data => { return data.name.fr }}
    />
    <Log.Rule 
      version={2}
      type={"settings.commercialOffer"}
      title={() => (<T>tenant_logs_commercialOffer</T>)}
      textify={( before, after, diff) => (
        <Change>
          <Change.Before>{ getCommercialOffer(before) }</Change.Before>
          <Change.After>{ getCommercialOffer(after) }</Change.After>
        </Change>
      )}
    />
    <Log.Rule 
      version={2}
      type={"settings.citizen.allowed"}
      title={() => (<T>tenant_logs_citizen_allowed</T>)}
      textify={( before, after, diff) => (
        <Change>
          <Change.Before>{ getCitizenAllowed(before) }</Change.Before>
          <Change.After>{ getCitizenAllowed(after) }</Change.After>
        </Change>
      )}
    />
    <Log.Rule 
      version={2}
      type={"settings.citizen.adminIssuesPublicByDefault"}
      title={() => (<T>tenant_logs_admin_issues_default_public</T>)}
      textify={( before, after, diff) => (
        <Change>
          <Change.Before>{ getIsAdminPublicByDefault(before) }</Change.Before>
          <Change.After>{ getIsAdminPublicByDefault(after) }</Change.After>
        </Change>
      )}
    />
    <Log.Rule 
      version={2}
      type={"clientIdJvs"}
      title={() => (<T>tenant_logs_clientIdJvs</T>)}
      textify={( before, after, diff) => (
        <Change>
          <Change.Before>{ getClientIdJvs(before) }</Change.Before>
          <Change.After>{ getClientIdJvs(after) }</Change.After>
        </Change>
      )}
    />
    <Log.Rule 
      version={3}
      type={"invite"}
      title={() => <T>tenant_logs_invite_not_client</T>}
    />
    <Log.Rule 
      version={3}
      type={"creation"}
      title={() => <T>tenant_logs_creation</T>}
    />
  </Log>
);

export default TenantLog;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-teamForm {
  display: flex;
}

.bs-teamForm-content {
  flex-grow: 1;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bs-teamForm .bs-teamForm-picture {
  padding: 0 15px;
}

.bs-teamForm .bs-teamMembers-table {
  width: 100%;
}

.bs-teamForm .bs-teamMembers-table th{
  text-align: left;
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/entities/teams/components/form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".bs-teamForm {\n  display: flex;\n}\n\n.bs-teamForm-content {\n  flex-grow: 1;\n  padding: 0 15px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.bs-teamForm .bs-teamForm-picture {\n  padding: 0 15px;\n}\n\n.bs-teamForm .bs-teamMembers-table {\n  width: 100%;\n}\n\n.bs-teamForm .bs-teamMembers-table th{\n  text-align: left;\n  font-size: 0.9em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

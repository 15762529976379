// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-userWithoutTenants-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("/images/authentification/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.bs-userWithoutTenants-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 700px;
  width: 380px;
  background-color: #FFFFFF;
  gap: 40px;
  padding: 25px;
  border-radius: 15px;
}
.bs-userWithoutTenants-content > img {
  padding: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/userWithoutTenants.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,gEAAgE;EAChE,sBAAsB;EACtB,4BAA4B;AAC9B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,SAAS;EACT,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;AACf","sourcesContent":[".bs-userWithoutTenants-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  background-image: url(\"/images/authentification/background.jpg\");\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n.bs-userWithoutTenants-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 700px;\n  width: 380px;\n  background-color: #FFFFFF;\n  gap: 40px;\n  padding: 25px;\n  border-radius: 15px;\n}\n.bs-userWithoutTenants-content > img {\n  padding: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

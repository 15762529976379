import { Service } from "@universal/lib/application";
import ApiService from "./api";
import PushService from "./push";
import StorageService from "./types/storage";
import NetworkingService from "./types/networking";
import SessionService from "./session";
import CurrentTenantService from "./currentTenant";
import ICacheContext, { Api, ApiFindHandler, ApiGetHandler, ApiCountHandler } from "./cache/iCacheContext";
import Query from "@universal/types/technic/Query";
import ContextAllLoaded from "./cache/contextAllLoaded";
import ContextOnDemand from "./cache/contextOnDemand";
import Key from "@universal/lib/key";
import { Listener } from "@universal/lib/event";
import logger from "@universal/lib/logger";

type CacheContext<Type> = {
  cacheDescriptor: CacheDescriptor;
  oldFindHandler: Api<any, ApiFindHandler<any>>;
  oldGetHandler: Api<any, ApiGetHandler<any>>;
  oldCountHandler: Api<any, ApiCountHandler<any>>;
  cacheContext: ICacheContext<Type>;
}

type CacheDescriptor = {
  model: string;
  api: string;
  key: Key<any>;
  getQuery?: (currentTenantService: CurrentTenantService) => Query<any>;
}

const cacheFactory = (cacheDescriptor: CacheDescriptor, api: ApiService, push: PushService, storage: StorageService, network: NetworkingService, currentTenant: CurrentTenantService, oldFindHandler: Api<any, ApiFindHandler<any>>, oldGetHandler: Api<any, ApiGetHandler<any>>, oldCountHandler: Api<any, ApiCountHandler<any>>): CacheContext<any> => {
  const { model, api: apiName, getQuery, key } = cacheDescriptor;

  let cacheContext: ICacheContext<any>;

  if(getQuery && currentTenant.isSelected()){
    cacheContext = new ContextAllLoaded<any>(
      model,
      push,
      network,
      storage,
      oldFindHandler,
      key,
      getQuery(currentTenant)
    );
  } else {
    cacheContext = new ContextOnDemand<any>(
      model,
      push,
      network,
      oldFindHandler,
      oldGetHandler,
      oldCountHandler,
      key
    );
  }

  api.set(apiName, "get", { execute: cacheContext.find });
  api.set(apiName, "getOne", { execute: cacheContext.get });
  api.set(apiName, "count", { execute: cacheContext.count });


  return {
    cacheDescriptor,
    cacheContext,
    oldFindHandler,
    oldGetHandler,
    oldCountHandler,
  };
}

class CacheService extends Service {
  private api: Record<string, CacheContext<any>> = {};

  private toInitialize: CacheDescriptor[];
  
  private _enable: boolean;

  private sessionListener: Listener;
  
  private currentTenantListener: Listener;
  
  private currentUserId: string | null;

  private _validateMode: boolean;

  constructor(toInitialize: CacheDescriptor[]) {
    super("cache", ["api", "push", "persistentStorage", "session", "currentTenant", "networking"]);

    this.toInitialize = toInitialize;
    this._enable = false;
    this.sessionListener = new Listener(this.onSessionUpdated, this);
    this.currentTenantListener = new Listener(this.onTenantUpdated, this);
    this.currentUserId = null;
    this._validateMode = false;
  }

  get validateMode(){
    return this._validateMode;
  }

  set validateMode(value: boolean){
    this._validateMode = value;
    for(const { cacheContext } of Object.values(this.api)){
      cacheContext.setValidateMode(value);
    }
    logger.log("CacheService validation mode set to", value);
  }

  async start(){
    const [
      session, 
      currentTenant
    ] = await this.waitReady(["session", "currentTenant", "api", "push", "persistentStorage", "networking"]) as [SessionService, CurrentTenantService];

    session.onServiceUpdated.addListener(this.sessionListener);
    currentTenant.onServiceUpdated.addListener(this.currentTenantListener);

    if(session.isLogged()){
      this.currentUserId = session.userId;
      this.activate();
    }
  }

  async stop(){
    const currentTenant = this.application.getService<CurrentTenantService>("currentTenant");
    const session = this.application.getService<SessionService>("session");

    await this.disable();

    session.onServiceUpdated.removeListener(this.sessionListener);
    currentTenant.onServiceUpdated.removeListener(this.currentTenantListener);
  }


  private onSessionUpdated = async () => {
    const session = this.application.getService<SessionService>("session");
    if(session.isLogged()){
      if(!this.currentUserId){
        await this.activate();
      } else {
        await this.update();
      }
      this.currentUserId = session.userId;
    } else {
      await this.disable();
      this.currentUserId = null;
    }
  }

  private onTenantUpdated = async () => {
    this.update();
  }

  async activate() {
    if(this._enable){
      return;
    }
    const api = this.application.getService<ApiService>("api");
    const pushService = this.application.getService<PushService>("push");
    const storageService = this.application.getService<StorageService>("persistentStorage");
    const networkService = this.application.getService<NetworkingService>("networking");
    const currentTenant = this.application.getService<CurrentTenantService>("currentTenant");

    for (const toInitialize of this.toInitialize) {
      const oldFindHandler = api.service(toInitialize.api, "get");
      const oldGetHandler = api.service(toInitialize.api, "getOne");
      const oldCountHandler = api.service(toInitialize.api, "count");

      this.api[toInitialize.model] = cacheFactory(
        toInitialize,
        api,
        pushService,
        storageService,
        networkService,
        currentTenant,
        oldFindHandler,
        oldGetHandler,
        oldCountHandler
      );
    }
    this._enable = true;
    logger.info("CacheService activated");
  }

  async update() {
    if(!this._enable){
      return;
    }
    await this.disable();
    await this.activate();
    logger.info("CacheService updated");
  }

  async disable(){
    if(!this._enable){
      return;
    }
    const api = this.application.getService<ApiService>("api");
    for (const { model, api: apiName } of this.toInitialize) {
      const { oldFindHandler, oldGetHandler, oldCountHandler, cacheContext } = this.api[model];
      api.set(apiName, "get", oldFindHandler);
      api.set(apiName, "getOne", oldGetHandler);
      api.set(apiName, "count", oldCountHandler);
      cacheContext.dispose();
    }
    this.api ={};

    this._enable = false;
    logger.info("CacheService disabled");
  }
}

export default CacheService;
import ScrollBar from '@common/components/scrollBar/scrollBar';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import './responsiveView.css';

const ResponsiveView: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <ScrollBar>
    <div className="bs-featuresDiscovery-responsiveView-container">
      { children }
    </div>
  </ScrollBar>
);

export default ResponsiveView;
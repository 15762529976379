import Form    from './components/form';
import Filters from "./components/filters";
import Item from "./components/item";
import SelectCurrentTenant from './components/selectCurrentTenant';
import Log from "./components/log";

export default {
  Filters,
  Form,
  Item,
  SelectCurrentTenant,
  Log
};
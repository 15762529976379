import Item    from './components/item';
import Form    from './components/form';
import Filters from './components/filters';
import Import from "./components/import";
import Select from './components/select';

export default {
    Item,
    Form,
    Filters,
    Import,
    Select
}
import React from "react";
import PeriodCache from "@uLib/cache/periodCache";
import { Listener } from "@uLib/event";
import _            from "lodash";
import moment       from "moment";
import Application  from "@uBehaviour/application";
import Query, { stackOrCombinate, combinate } from '@uLib/query';
import extendEntities from '@uLib/extendEntities';

class Datas extends React.Component {
  constructor(props) {
    super(props);
    this._dependencies = { category: true, "location.building": true, equipment: true };
    this._repositoryListener = new Listener(this._onMessageReceived, this);
    this._currentTenantListener = new Listener(this._onTenantChange, this);
    this._cacheListener = new Listener(this._loadAssignments, this);
    this._datas = {
      teamsAndAgents: [],
      equipments: []
    };
    this._debounceLoadAssignment = _.debounce(this._loadAssignments.bind(this), 0);
    this._initializeCache();

  }

  _onTenantChange = async () => {
    this._initializeCache();
    await this._loadMetas()
    await this._loadAssignments();
    this.forceUpdate();
  }



  _initializeCache = () => {
    if(this._cache){
      this._cache.onUpdate.removeListener(this._cacheListener);
    }
    this._cache = new PeriodCache((periods) => {
      let query = combinate("$and",
        periods.map(period => ({
          "assignment.scheduledFrom": { $lt: period.end.toISOString() },
          "assignment.scheduledTo": { $gt: period.start.toISOString() }
        })).reduce((query, pQuery) => stackOrCombinate("$or", query, pQuery), null),
        { "tenant": this.props.currentTenant.currentId }
      );
      return this.props.repository.get("Assignment").repository.find(Query.joinWithOptimizer(query, { tenant: this.props.currentTenant.currentId }), undefined, undefined, 10000, this._dependencies);
    },
      assignment => assignment._id,
      assignment => moment(assignment.assignment.scheduledFrom).toDate(),
      assignment => moment(assignment.assignment.scheduledTo).toDate(),
    );
    this._cache.onUpdate.addListener(this._cacheListener);


    if(this._absencesCache){
      this._absencesCache.onUpdate.removeListener(this._cacheListener);
    }
    this._absencesCache = new PeriodCache((periods) => {
      return this.props.repository.get("Absence").repository.join(
        this.props.api.service("absences", "searchByPeriods").execute(periods, null, this.props.currentTenant.currentId),
        {}
      );
    },
      absence => absence._id,
      absence => moment(absence.start).toDate(),
      absence => moment(absence.end).toDate(),
    );
    this._absencesCache.onUpdate.addListener(this._cacheListener);
  }

  componentDidMount() {
    this.props.repository.onEntityUpdated.addListener(this._repositoryListener);
    this.props.currentTenant.onServiceUpdated.addListener(this._currentTenantListener);
    this._loadMetas().then(() => this._loadAssignments());
  }

  componentDidUpdate({ start, end, hash }) {
    if (start.getTime() !== this.props.start.getTime() || end.getTime() !== this.props.end.getTime() || hash !== this.props.hash) {
      this._loadAssignments();
    }
  }

  componentWillUnmount() {
    this.props.currentTenant.onServiceUpdated.removeListener(this._currentTenantListener);
    this.props.repository.onEntityUpdated.removeListener(this._pushListener);
  }

  _onMessageReceived(model, entity, action) {
    if (model.name === "Assignment") {
      switch (action) {
        case "create":
          entity.register();
        case "update":
          this._cache.store(entity);
          break;
        case "remove":
          this._cache.delete(entity._id);
          break;
      }
      this._debounceLoadAssignment();
    } else if (model.name === "Absence") {
      switch (action) {
        case "create":
          entity.register();
        case "update":
          this._absencesCache.store(entity);
          break;
        case "remove":
          this._absencesCache.delete(entity._id);
          break;
      }
      this._debounceLoadAssignment();
    } else if (model.name === "Team" || model.name === "User") {
      this._loadMetas().then(() => this._debounceLoadAssignment());
    }
  }
  _loadMetas(){
    const currentTenant = this.props.currentTenant.currentId;
    const userCanReadEquipment = this.props.acl.connectedUserIsAllow('equipments', 'read');
    return Promise.all([
      this.props.repository.get("Team").repository.find({ tenant: currentTenant, disabled: false }, {}, null, 10000, {}),
      this.props.repository.get("User").repository.find({
        tenants: { $elemMatch: { tenant: currentTenant, roles: { $in: ["agent"]}, disabled: false }}
      }, {}, null, 10000, { avatar: true }),
      userCanReadEquipment ? this.props.repository.get("Equipment").repository.find({ tenant: currentTenant, disabled: false }, { name: 1 }, null, 10000, {}) : Promise.resolve([])
    ]).then(([teams, agents, equipments]) => {
      
      this._teams = teams.reduce((acc, team) => {
    
        team = extendEntities({
          isClosable: function () {
            return true;
          },
          ICalUrlCopyButton: function () {
            return true;
          },
          getAssignementsAndAbsences: function (teamAssignments) {
            return [{ assignments: teamAssignments, absences: [] }]
          },
          isDisplay: () => {
            return this.props.teamFilter(team);
          }
        },team);

        acc[team._id] = {
          team,
          agents: [],
          isAllowed() {
            return !!this.agents.length
          }
        };
        return acc;
      }, {});


      const withoutTeamId = 'withoutTeam';
      const withoutTeam = {
        team: {
          _id: withoutTeamId,
          name: "Agents sans Équipes",
          members: [],
          isClosable : function (){
            return false ;
          },
          ICalUrlCopyButton: function () {
            return false;
          },
          getAssignementsAndAbsences(teamAssignments) {
            return [];
          },
          isDisplay: () => {
            return this.props.teamFilter(withoutTeam.team);
          } 
        },
        agents: [],
        isAllowed() {
          return !!this.agents.length
        }
      };

      this._teams[withoutTeamId] = withoutTeam;

      this._agentsTeam = agents.reduce((acc, agent) => {

        agent = extendEntities({
          getICalQuery: function () {
            const teamQuery = this.team ? [{ "assignment.team": this.team._id }] : [];
            const agentQuery = { "assignment.agents": this._id };
            return { $or: [...teamQuery, agentQuery] };
          }
        }, agent);

      
        if(agent.team){
          acc[agent._id] = agent.team;
          this._teams[agent.team._id].agents.push(agent);
        }else{
          acc[agent._id] = withoutTeamId;
          this._teams[withoutTeamId].agents.push(agent);
          this._teams[withoutTeamId].team.members.push(agent._id);
        }
        return acc;
      }, {});
      this._equipments = equipments.reduce((dic, equipment) => {
        dic[equipment._id] = equipment;
        return dic;
      }, {});
    });
  }

  async _loadAssignments() {
    Promise.all([
      this._cache.get(moment(this.props.start).toDate(), moment(this.props.end).toDate()),
      this._absencesCache.get(moment(this.props.start).toDate(), moment(this.props.end).toDate())
    ]).then(([assignments, absences]) => {
      if (this.props.assignmentFilter) {
        assignments = assignments.filter(this.props.assignmentFilter);
      }
  
      const teams = Object.values(this._teams).filter(t => t.isAllowed());
      const dicTeamsAndAgents = teams.reduce((dic, t) => {
        dic[t.team._id] = {
          team: t.team,
          agents: t.agents.reduce((acc, agent) => {
            acc[agent._id] = { agent, assignments: [], absences: [] };
            return acc;
          }, {}),
          teamAssignments: [],
          assignments: []
        };
        return dic;
      }, {});
  
      let equipments = Object.values(this._equipments);
      if (this.props.equipmentFilter) {
        equipments = equipments.filter(this.props.equipmentFilter);
      }
      const dicEquipments = equipments.reduce((dic, equipment) => {
        dic[equipment._id] = { equipment, assignments: [] };
        return dic;
      }, {});
  
      const assignmentsByTeamsAndEquipments = assignments.reduce((acc, assignment) => {
        assignment.assignment.team.forEach(team => {
          if (acc.teamsAndAgents[team._id]) {
            acc.teamsAndAgents[team._id].assignments.push(assignment);
            acc.teamsAndAgents[team._id].teamAssignments.push(assignment);
          }
        });
  
        assignment.assignment.agents.forEach(agent => {
          const teamId = this._agentsTeam[agent._id]?._id || "withoutTeam";
          const team = acc.teamsAndAgents[teamId];
          if (team) {
            team.assignments.push(assignment);
            team.agents[agent._id]?.assignments.push(assignment);
          }
        });
  
        assignment.assignment.necessariesEquipments.forEach(equipment => {
          if (acc.equipments[equipment._id]) {
            acc.equipments[equipment._id].assignments.push(assignment);
          }
        });
  
        return acc;
      }, {
        teamsAndAgents: dicTeamsAndAgents,
        equipments: dicEquipments,
        start: this.props.start,
        end: this.props.end
      });
  
      absences.forEach(absence => {
        const teamId = this._agentsTeam[absence.user._id]?._id || "withoutTeam";
        const agent = assignmentsByTeamsAndEquipments.teamsAndAgents[teamId]?.agents[absence.user._id];
        if (agent) {
          agent.absences.push(absence);
        }
      });
      assignmentsByTeamsAndEquipments.teamsAndAgents = Object.values(assignmentsByTeamsAndEquipments.teamsAndAgents)
        .map(data => {
          data.agents = Object.values(data.agents).sort((a1, a2) => a1.agent.fullname.localeCompare(a2.agent.fullname));
          data.assignments = _.uniq(data.assignments);
          return data;
        }).sort((a, b) => {
          if (a.team._id === "withoutTeam") {
              return 1;
          }
          if (b.team._id === "withoutTeam") {
              return -1;
          }
          return a.team.name.localeCompare(b.team.name);
        });



      assignmentsByTeamsAndEquipments.equipments = Object.values(assignmentsByTeamsAndEquipments.equipments)
        .map(data => {
          data.assignments = _.uniq(data.assignments);
          return data;
        }).sort((d1, d2) => {
          if (!d1.equipment) return -1;
          if (!d2.equipment) return 1;
          return d1.equipment.name.localeCompare(d2.equipment.name);
        });
  
      this._datas = assignmentsByTeamsAndEquipments;
      this.forceUpdate();
    });
  }
  
  render() {
    return this.props.children({ ...this._datas });
  }
  
};

export default Application.forward(["repository", "currentTenant", "api", "acl"], ["users"], Datas);
import React        from "react";
import _            from "lodash";
import Data         from "@uBehaviour/data";
import Button       from '@cComponents/button';
import Building     from "@root/entities/buildings";
import T            from "@cBehaviour/i18n";
import Filter       from "@cComponents/filter";
import FilteredList from "@cFeatures/filter/list";
import Input        from "@cComponents/input";
import Acl          from "@cBehaviour/acl";
import Display      from "@uComponents/displayIf";
import CurrentTenantService from "@uServices/currentTenant";
import useService from "@uBehaviour/hooks/useService";
import BuildingForm from './form';
import { withLink, withModalLinked } from "@cHoc/withLinkAndModalLinked";
import { Link } from "@cFeatures/router";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar from "@cComponents/searchbar";
import { escapeRegExp } from "@uLib/tool";

const query = (currentTenant: CurrentTenantService) => {
  let query = currentTenant.currentId ? { tenant: currentTenant.currentId }: undefined;
  return query;
}

const textSearchBuildQuery = (value: string) => {
  return { name: { '$regex': escapeRegExp(value), '$options': 'i' } };
}

const defaultQuery = () => {
  return [{ name: "disabled", value: false }];
}

const LinkedBuildingItem = withLink(Building.Item);
const ModalizedBuildingForm = withModalLinked(BuildingForm);

const BuildingList = () => {
  const [state, setState] = React.useState({
    showImportWizard: false
  });
  const currentTenant = useService<CurrentTenantService>('currentTenant');
  
  return (
    <>
      <FilteredList default={defaultQuery()}>
        <FilteredList.Filter>
          <Building.Filters />
        </FilteredList.Filter>
        <FilteredList.Header>
          <ListHeaderLayout>
            <ListHeaderLayout.Center>
              <Filter.Generic name="text" buildQuery={value => textSearchBuildQuery(value)} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
              {(value, set, clear) => (
                <Searchbar>
                  <Input.Text focusOnMount className="bs-filter-text" value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                    <T>freesearch</T>
                  </Input.Text>
                </Searchbar>
              )}
              </Filter.Generic>
            </ListHeaderLayout.Center>
            <ListHeaderLayout.Right>
              <Acl.If resource="buildings" action="manage">
                <Acl.If resource="buildings" action="import">
                  <Button.Text onClick={() => setState({showImportWizard: true})}><T>import</T></Button.Text>
                  <Display.If condition={state.showImportWizard}>
                    <Building.Import onClose = {() => setState({showImportWizard: false})} />
                  </Display.If>
                </Acl.If>
                <Link to="/new">
                  <Button.Stylized.Text><T>add</T></Button.Stylized.Text>
                </Link>
              </Acl.If>
            </ListHeaderLayout.Right>
          </ListHeaderLayout>
        </FilteredList.Header>
        <FilteredList.List>
        {composeQuery => (
          <Data.List model={"Building"} query={composeQuery(query(currentTenant))} load={{files:true}} sort={{ 'name': 1}}>
            <LinkedBuildingItem />
          </Data.List>
        )}
        </FilteredList.List>
      </FilteredList>
      <ModalizedBuildingForm style={{ width: "80vw", height: "90vh" }}/>
    </>
  );
}


export default BuildingList;
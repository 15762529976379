import React, { FunctionComponent }        from "react";
import Campaign     from "@root/entities/campaigns";
import Data         from "@uBehaviour/data";
import useService from "@universal/behaviour/hooks/useService";
import RepositoryService from "@universal/services/repository";
import ObjectId from "@universal/types/technic/ObjectId";

type FormProps = {
  id: ObjectId,
  close: () => void;
}

const Form: FunctionComponent<FormProps> = ({ id, close }) => {
  const repositoryService = useService<RepositoryService>("repository");

  if(id === "new"){
    return  (
      <Campaign.Form data={ repositoryService.get("Campaign").default } close={ close } />
    );
  }

  return (
    <Data.One id={ id } model="Campaign">
      <Campaign.Form close={ close } />
    </Data.One>
  );
}

export default Form;

import React from "react";

import File from "@cComponents/file";
import { Subject } from '@entities/issuesAndAssignments';
import State from "../state";
import { Title } from "@root/entities/issuesAndAssignments";
import Tooltip from "@cComponents/tooltip";
import IssueTooltip from "../tooltipContent";
import { Highlighted } from "@cComponents/highlight";

import Issue from "@universal/types/business/Issue";
import Category from "@universal/types/business/Category";
import { BusinessEntity } from "@universal/types/technic/Entityable";

import "./short.css";
import Group from "@universal/types/business/issue/Group";
import Equipment from "@universal/types/business/Equipment";

import DataTestId, { useDataTestId } from "@universal/features/dataTestId";

interface IssueFullData extends Issue {
  $followed: boolean,
  category: BusinessEntity<Category>,
  priority: number,
  group: BusinessEntity<Group>,
  equipment: BusinessEntity<Equipment>
}
interface ItemShortProps {
  data: IssueFullData;
  noTooltip?: boolean;
};

const getFile = (files = []) => {
  for(let i = 0; i < files.length; ++i){
    if(files[i].mimeType !== undefined) return files[i];
  }
  return null;
}

const Item: React.FunctionComponent<ItemShortProps> = ({ data, noTooltip }) => {
    const issue = data;
    const issueDescriptionTestId = `info-${useDataTestId("issue-description")}`;
    const issueItemTestId   = `inter-${useDataTestId("issue")}`

    const item = (
    <DataTestId.Prefix value="issue">
      <div className="bs-issue-itemShort" data-testid={ issueItemTestId }>
        <div className="bs-issue-itemShort-illustration">
          <File file={ getFile(issue.files) } fit="cover" height="96" width="96"/>
        </div>
        <div className="bs-issue-itemShort-content">
          <div className="bs-issue-itemShort-header">
            <Title
              title={ issue.bsIdNumber + ""}
              category={ issue.category }
              $followed={ issue.$followed }
              priority={ issue.priority }
              group={ issue.group }
              issueId={ issue._id }
            />
            <div className="bs-issue-itemShort-header-state"><State.Short state={issue.state} /></div>
          </div>
          <Subject equipment={issue.equipment} location={ issue.location || issue.equipment?.location } />
          <div className="bs-issue-itemShort-description">
            <div data-testid={ issueDescriptionTestId }>
              <Highlighted>{ issue.description }</Highlighted>
            </div>
          </div>
        </div>
      </div>
    </DataTestId.Prefix>
    );
    if(noTooltip){
      return item;
    }
    return (
      <Tooltip placement="right">
        <Tooltip.Subject>
          {item}
        </Tooltip.Subject>
        <Tooltip.Info>
          <IssueTooltip id={ issue._id } />
        </Tooltip.Info>
      </Tooltip>
    );
}

Item.load = {
  updatedBy: 1,
  createdBy: 1,
  category: 1,
  files: 1,
  "location.building": 1,
  equipment: {
    "location.building": 1
  },
}

export default Item;
import React        from "react";
import Application  from "@uBehaviour/application";
import MapLayer     from "@root/entities/mapLayers";
import Data         from "@uBehaviour/data";


export default Application.forward(["repository"], [], (props) => {
  if(props.id === "new"){
    return  (
      <MapLayer.Form data={props.repository.get("MapLayer").default} close={props.close}/>
    );
  } else {
    return (
      <Data.One id={props.id} model="MapLayer">
        <MapLayer.Form close={props.close} />
      </Data.One> 
    )
  }
});
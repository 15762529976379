// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-unsubscribe-container {
  background-color: white;
  height: 100%;
}

.bs-unsubscribe-container > .bs-scrollBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--std-size-inter-element);
}

.bs-unsubscribe-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 10px 10px 10px;
  width:100%;
}

.bs-unsubscribe-logo-container {
  width: 250px;
}

.bs-unsubscribe-step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bs-unsubscribe-validation-image-container {
  width: 200px;
  margin: 50px 0px;
}

.bs-unsubscribe-step-buttons-container {
  margin-top: 50px;
  display: flex;
  gap: 15px;
}

.bs-unsubscribe-reason-checkboxValue-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.bs-unsubscribe-reason-checkboxValue-container > * {
  margin-top: 20px;
}

.bs-unsubscribe-step-title-container {
  margin: 35px 0;
}

.bs-unsubscribe-noCitizenEmail-info {
  background-color: #f5f5f5;
  border: 1px solid #e3dbdc;
  border-radius: 5px;
  padding: 15px;
  text-align: start;
  margin-top: 35px;
}

.bs-unsubscribe-noCitizenEmail {
  display: flex;
  align-items: center;
  gap: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/starter/unsubscribe.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,4BAA4B;EAC5B,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".bs-unsubscribe-container {\n  background-color: white;\n  height: 100%;\n}\n\n.bs-unsubscribe-container > .bs-scrollBar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding: var(--std-size-inter-element);\n}\n\n.bs-unsubscribe-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 60px 10px 10px 10px;\n  width:100%;\n}\n\n.bs-unsubscribe-logo-container {\n  width: 250px;\n}\n\n.bs-unsubscribe-step-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.bs-unsubscribe-validation-image-container {\n  width: 200px;\n  margin: 50px 0px;\n}\n\n.bs-unsubscribe-step-buttons-container {\n  margin-top: 50px;\n  display: flex;\n  gap: 15px;\n}\n\n.bs-unsubscribe-reason-checkboxValue-container {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n}\n\n.bs-unsubscribe-reason-checkboxValue-container > * {\n  margin-top: 20px;\n}\n\n.bs-unsubscribe-step-title-container {\n  margin: 35px 0;\n}\n\n.bs-unsubscribe-noCitizenEmail-info {\n  background-color: #f5f5f5;\n  border: 1px solid #e3dbdc;\n  border-radius: 5px;\n  padding: 15px;\n  text-align: start;\n  margin-top: 35px;\n}\n\n.bs-unsubscribe-noCitizenEmail {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { FunctionComponent } from 'react';
import useService from '@uBehaviour/hooks/useService';
import SessionService from '@uServices/session';
import CurrentTenant from '@uServices/currentTenant';

import StdExp from './stdExp';
import Settings from './settings';
import Starter from './starter';
import External from './external';
import CitizenExternal from "./citizenExternal";
import Unauthenticated from './unauthenticated';
import StarterActivation from './starterActivation';
import { CommercialOffer } from '@universal/types/business/Tenant';
import AclService from '@universal/services/acl';
import UserWithoutTenants from '@components/userWithoutTenants';

interface ViewProps {

}

/**
 * Composant qui choisi quelle application démarrer
 * @returns 
 */
const View: FunctionComponent<ViewProps> = ({}) => {
  const session = useService<SessionService>("session");
  const currentTenant = useService<CurrentTenant>("currentTenant", ["onServiceUpdated"]);
  const acl = useService<AclService>("acl");
  if(!session.isLogged() || session.mustResetPassword() || (session.user.discriminator === "requestorValidation")){
    return (<Unauthenticated />);
  }

  if(acl.connectedUserIsAllow("issues", "accessExternalPage")){
    return (<External />);
  }
  
  if(acl.connectedUserIsAllow("issues", "accessCitizenExternalPage")){
    return (<CitizenExternal />);
  }

  if(session.isUserProWithoutTenant()){
    return (<UserWithoutTenants />);
  }
  
  if(!currentTenant.isLoaded()){
    return null;
  }

  if(acl.connectedUserIsAllow("tenants", "activateStarter")){
    return (<StarterActivation />);
  }

  if (acl.connectedUserIsAllow("tenants", "onlyConfigure")) {
    return (<Settings />)
  }
  
  if(currentTenant.current?.settings.commercialOffer === CommercialOffer.starter && !acl.connectedUserIsAllow("application", "administrate")){
    return (<Starter />);
  }

  return (<StdExp />);
};

export default View;




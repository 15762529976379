import React from 'react';
import _ from 'lodash';


const useResponsiveObserver = (screenSize: number = 1280) => {
  const [isScreenSizeReach, setScreenSizeReach] = React.useState(window.innerWidth > screenSize);
  
  const updateScreenSize = React.useCallback(_.debounce(() => {
    if ((isScreenSizeReach && window.innerWidth < screenSize) || (!isScreenSizeReach && window.innerWidth > screenSize)) {
      setScreenSizeReach(window.innerWidth > screenSize);
    } 
  }, 200), [ isScreenSizeReach ]);
  
  React.useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, [ updateScreenSize ]);

  return isScreenSizeReach;
}

export default useResponsiveObserver;
import { Service } from "@uLib/application";
import { combinate } from "@universal/lib/query";

import { orCombineRule, getHolidayDayRule, isDayAndMonth, RuleBuilder, CorrepondingDayRule } from "@uLib/holidayDay";
import { isDayOfWeek } from "../lib/holidayDay";
import Observable from '@uLib/observable';
import Tenant, { WeekDayNumber } from "@universal/types/business/Tenant";
import { AsyncListener, Listener } from "@universal/lib/event";
import SessionService from "./session";
import StorageService from "./types/storage";
import NetworkingService from "./types/networking";
import ApiService from "./api";
import AclService from "./acl";
import ObjectId from "@universal/types/technic/ObjectId";
import BsDate from "@universal/types/technic/Date";

export default class CurrentTenantService extends Service{
  private _current: Observable<Tenant | null>;
  private _allTenants: Map<ObjectId, Tenant> | null;
  private _dayNotWorked: CorrepondingDayRule | null;
  private _holidayDayRule: CorrepondingDayRule | null;
  private _loaded: boolean;
  constructor(){
    super("currentTenant", ["api", "userStorage", "session", "acl", "i18n", "networking"]);
    this._current             = new Observable<Tenant | null>(null, (v1, v2) => !!(v1 === v2 || (v1 && v2 && v1._id === v2._id)));
    this._allTenants          = null;
    this._dayNotWorked        = null;
    this._holidayDayRule      = null;
    this._loaded              = false;
  }
  start(){
    return this.waitReady<[SessionService, StorageService, ApiService, AclService, NetworkingService]>(["session", "userStorage", "api", "acl", "networking"])
      .then(async ([session, storage, api, acl, networking]) => {
        let userId = session.userId;
        session.onServiceUpdated.addListener(new AsyncListener(async () => {
          if(session.userId !== userId){
            userId = session.userId;
            await this.updateUserTenantContext(session, storage, networking, api);
          }
        }));

        await this.updateUserTenantContext(session, storage, networking, api);
        this._loaded = true;

        this._current.onUpdate.addListener(new Listener(async (oldTenant: Tenant, newTenant: Tenant) => {
          if(session.hasUser()){
            if(newTenant){
              await storage.set(`tenant`, newTenant._id);
            } else if(session.isLogged()){
              await storage.remove(`tenant`);
            }
          }

          this._buildNotWorkedDayRuleFromCurrentTenant();
          this._loaded = true;
          await this.triggerUpdate();
        }, this));
      });
  }

  private getDefaultTenantId(session: SessionService): null | ObjectId {
    if(session.isLogged()){
      if (session.user.discriminator === "tablet") {
        return session.user.tenant; 
      } else if (session.user.tenants && session.user.tenants.length) {
        return session.user.tenants[0].tenant;
      } else if(session.userToken.discriminator === "starterActivator"){
        return session.userToken.tenant;
      }
      return null;
    } else {
      return null;
    }
  }

  private async loadAllTenants(session: SessionService, storage: StorageService, networking: NetworkingService, api: ApiService) {
    let tenantIds: ObjectId[] = [];
    if(session.isLogged()){
      if (session.user.discriminator === "tablet") {
        tenantIds = [session.user.tenant];
      } else if (session.user.discriminator === "pro") {
        tenantIds = session.user.tenants.map((t: any) => t.tenant);
      } else if(session.userToken.discriminator === "starterActivator"){
        tenantIds = [session.userToken.tenant];
      }
    }

    if(!tenantIds.length){
      if(await storage.has("user_tenants")){
        await storage.remove("user_tenants");
      }
      this._allTenants = null;
      return;
    }


    let tenants: Tenant[] = [];
    if(networking.isConnected()){
      tenants = await api.service("tenants", "get").execute({
        _id: { $in: tenantIds }
      });
      await storage.set("user_tenants", tenants, true);
    } else {
      tenants = (await storage.get("user_tenants", true)) as Tenant[];
    }

    this._allTenants = tenants.reduce<Map<ObjectId, Tenant>>((allTenants, tenant: Tenant) => {
      allTenants.set(tenant._id, tenant);
      return allTenants;
    }, new Map<ObjectId, Tenant>());
  }

  private async useDefaultTenant(session: SessionService, storage: StorageService){
    if(session.isLogged()){
      if(await storage.has(`tenant`)){
        await this.setCurrentId(await storage.get(`tenant`));
      } else {
        await this.setCurrentId(this.getDefaultTenantId(session));
      }

    } else {
      await this.setCurrentId(null);
    }
  }

  private async updateUserTenantContext(session: SessionService, storage: StorageService, networking: NetworkingService, api: ApiService){
    this._loaded = false;
    await this.loadAllTenants(session, storage, networking, api);
    await this.useDefaultTenant(session, storage);
    this._loaded = true;
    await this.triggerUpdate();
  }

  get changeMode() : "none" | "limited" | "unlimited" {
    const session = this.application.getService("session");
    const networking = this.application.getService("networking");
    if(!session.isLogged() || (session.user.discriminator === "pro" && session.user.tenants.length < 2) || !networking.isConnected()){
      return "none";
    }
    if(session.user.discriminator === "pro"){
      return "limited";
    }
    return "unlimited";
  }

  isLoaded(): boolean {
    return this._loaded;
  }

  isClient() : boolean {
    const tenant = this._current.get();
    if(!tenant){
      return false;
    }
    return !!tenant.settings.commercialOffer;
  }

  isAllowToAccess(tenantId: ObjectId): boolean {
    if(this.changeMode === "unlimited"){
      return true;
    }
    return this._allTenants ? this._allTenants.has(tenantId) : false;
  }

  get allTenants(): Tenant[] {
    return this._allTenants ? [...this._allTenants.values()] : [];
  }

  get configurableTenants(): Tenant[]{
    const session: SessionService = this.application.getService("session");
    const tenants = this._allTenants ? [...this._allTenants.values()] : [];

    if(!session.isLogged || !tenants.length) {
      return [];
    }

    const acl: AclService = this.application.getService("acl");
    return tenants.filter((tenant) => acl.isAllow("tenants", "configure", session.user, tenant._id));
  }

  isSelected(): boolean{
    return !!this.current;
  }
  
  get current(): Tenant | null {
    return this._current.get();
  }

  set current(tenant: Tenant | null){
    this._current.set(tenant);
  }

  get currentId(): ObjectId | null{
    if(!this.isSelected()){
      return null;
    }
    return (this.current as Tenant)._id
  }

  /**
   * @deprecated utiliser la méthode setCurrentId
   */
  set currentId(id: ObjectId | null){
    this.setCurrentId(id);
  }

  async setCurrentId(id: ObjectId | null){
    if(!id){
      id = this.getDefaultTenantId(this.application.getService("session"));
    }

    if(!id){
      this.current = null;
      return;
    }

    if(this._allTenants && this._allTenants.has(id)){
      this.current = this._allTenants.get(id) as Tenant;
      return;
    }
    
    const networking: NetworkingService = this.application.getService("networking");
    if(networking.isConnected()){
      const api: ApiService = this.application.getService("api");
      const tenant: Tenant = await api.service("tenants", "getOne").execute(id);
      this.current = tenant;
    } else {
      throw new Error("Network is not enable, can't get Tenant");
    }
  }

  useInQuery(query?: Object, allowNotSelected = false, property = 'tenant') {
    if(!allowNotSelected && !this.isSelected()){
      throw new Error('No current tenant selected');
    }
    const tenantQuery = this.isSelected() ? { [property]: this.currentId } : null;
    return combinate('$and', query, tenantQuery);
  }

  hasGPConnector(): boolean {
    return !!this.getGPConnectorType();
  }

  getGPConnectorType(): string | null{
    if(!this.isSelected() || !this.current?.connector?.gp){
      return null;
    }
    return this.current.connector.gp.type;
  }

  allowAbsenceCreation(): boolean {
    if(!this.isSelected()){
      return false;
    }
    if(this.current?.connector?.gp){
      return !["jvs_horizon"].includes(this.current.connector.gp.type);
    } 
    return true;
  }
  
  _buildNotWorkedDayRuleFromCurrentTenant(){
    if(!this.isSelected()){
      this._dayNotWorked = null;
      return;
    }
    const tenant: Tenant = this.current as Tenant;

    let rule = null;
    if(tenant.settings.holidayDays.standarts.length){
      rule = getHolidayDayRule(...tenant.settings.holidayDays.standarts);
    }
    if(tenant.settings.holidayDays.generics.length){
      let genericRules: CorrepondingDayRule[] = tenant.settings.holidayDays.generics.map(rule => {
        switch(rule.discriminator){
          case "dayAndMonth":
            return isDayAndMonth(rule.params.day, rule.params.month);
          default:
            throw new Error(`Unknown discriminator for holidayDays generic rule: "${rule.discriminator}"`);
        }
      });
      if(rule){
        genericRules = genericRules.concat([rule]);
      }
      rule = orCombineRule(...genericRules);
    }

    this._holidayDayRule = rule;
    
    let weeklyNotWorkedDay: CorrepondingDayRule[] = ([0, 1, 2, 3, 4, 5 , 6] as WeekDayNumber[])
      .filter(day => !tenant.settings.workingDays.includes(day))
      .map(day => isDayOfWeek(day));
    
    if(weeklyNotWorkedDay.length){      
      if(rule){
        weeklyNotWorkedDay = weeklyNotWorkedDay.concat([rule]);
      }
      rule = orCombineRule(...weeklyNotWorkedDay);
    }

    this._dayNotWorked = rule;
  }

  isAnHolidayDay(date: BsDate) {
    if(!this._holidayDayRule){
      return false;
    }
    return this._holidayDayRule(date);
  }

  isNotAWorkingDay(date: BsDate){
    if(!this._dayNotWorked){
      return false;
    }
    return this._dayNotWorked(date);
  }
}
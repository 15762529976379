// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-release-list-modal-container {
  width: 40vw;
  height: 90vh;
}

@media screen and (max-width: 1280px) {
  .bs-release-list-modal-container {
    width: 90vw;
  }
}`, "",{"version":3,"sources":["webpack://./../common/entities/release/list.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".bs-release-list-modal-container {\n  width: 40vw;\n  height: 90vh;\n}\n\n@media screen and (max-width: 1280px) {\n  .bs-release-list-modal-container {\n    width: 90vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

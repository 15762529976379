import React from "react";
import { Subject } from "@entities/issuesAndAssignments";
import T from "@uBehaviour/i18n";
import File from "@cComponents/file";
import State from "@entities/issues/components/state";

import "./infoTooltipContent.css";

const Title = ({ bsIdNumber, category }) => (
  <div className="bs-tooltip-generalData-title">
    <span className="bs-tooltip-generalData-title-bsIdNumber">{bsIdNumber}</span>
    <span className="bs-tooltip-generalData-title-category"><T>{category.label}</T></span>
  </div>
);
const Description = ({ description }) => (
  <div className="bs-tooltip-generalData-description">{description}</div>
);
const Manager = ({ manager }) => (
  <div className="bs-tooltip-generalData-manager">
    <span className="bs-tooltip-generalData-manager-label"><T>tooltip_generalData_manager_label</T></span>
    <span className="bs-tooltip-generalData-manager-value">{manager.fullname}</span>
  </div>
);
const Illustration = ({ files }) => (
  files.length
    ? <File file={files[0]} width={448} height={308} />
    : null
);
const LState = ({ state }) => {
  return (
    <div className="bs-tooltip-generalData-state">
      <State.Standart state={ state } type="issue" />
    </div>
  );
}

const Tooltip = ({ data }) => (
  <div className="bs-tooltip-generalData">
    <div className="bs-tooltip-generalData-header">
      <Title bsIdNumber={data.bsIdNumber} category={data.category} />
      <LState state={ data.state } />
    </div>
    <Subject location={data.location || data.equipment?.location} equipment={ data.equipment }/>
    <Description description={data.description} />
    { data.manager ? <Manager manager={data.manager} /> : null }
    <Illustration files={data.files} />
  </div>
);

Tooltip.load = {
  category: 1,
  manager: 1,
  files: 1,
  "location.building": 1,
  equipment: 1,
  "equipment.location.building": 1,
};

export default Tooltip;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-valorization-modal-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bs-valorization-modal-form>.bs-valorization-modal-form-container {
  flex-grow: 1;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/entities/valorizations/components/valorizations.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".bs-valorization-modal-form {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.bs-valorization-modal-form>.bs-valorization-modal-form-container {\n  flex-grow: 1;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

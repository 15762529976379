// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-valorization-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.bs-valorization-form-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}
.bs-valorization-form-body,
.bs-valorization-form-footer{
  padding: 20px;
}

.bs-valorization-form-statements{
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.bs-valorization-form-fixed{
  margin-top: 20px;
  flex: auto 0 0;
}

.bs-valorization-form-control{
  display: flex !important;
  justify-content: space-between;
}

.bs-valorization-form-separator{
  border-bottom: 1px solid #eeeeee;
}

.bs-valorization-form-buttonSelect>.fa{
  margin-right: 5px;
}
.bs-valorization-form-buttonSelect:hover {
  cursor: pointer;
  color: #6f787d;
}`, "",{"version":3,"sources":["webpack://./src/entities/valorizations/components/form.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB;AACA;;EAEE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".bs-valorization-form {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n.bs-valorization-form-body {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-shrink: 1;\n  overflow: hidden;\n}\n.bs-valorization-form-body,\n.bs-valorization-form-footer{\n  padding: 20px;\n}\n\n.bs-valorization-form-statements{\n  flex-grow: 1;\n  flex-shrink: 1;\n  overflow: hidden;\n}\n\n.bs-valorization-form-fixed{\n  margin-top: 20px;\n  flex: auto 0 0;\n}\n\n.bs-valorization-form-control{\n  display: flex !important;\n  justify-content: space-between;\n}\n\n.bs-valorization-form-separator{\n  border-bottom: 1px solid #eeeeee;\n}\n\n.bs-valorization-form-buttonSelect>.fa{\n  margin-right: 5px;\n}\n.bs-valorization-form-buttonSelect:hover {\n  cursor: pointer;\n  color: #6f787d;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

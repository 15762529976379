import React from "react";
import File from "@cComponents/file";
import State from "../state";
import T from "@uBehaviour/i18n";
import WhoWhenWhat from '@cFeatures/whoWhenWhat';
import { Title, Deadline } from "@root/entities/issuesAndAssignments";
import Display from "@uComponents/displayIf";
import Recurrence from "./recurrence";
import Acl from "@uBehaviour/acl"; 
import Period from "@cComponents/period";
import UserAvatar from "@cFeatures/userAvatar";
import { Subject, Tag } from '@entities/issuesAndAssignments';
import { Highlighted } from "@cComponents/highlight";
import Money from "@cComponents/money";



import "./standart.css";

const getFile = (files = []) => {
  for(let i = 0; i < files.length; ++i){
    if(files[i].mimeType !== undefined) return files[i];
  }
  return null;
}


const Assignments = ({ assignments }) => {
  const a = assignments.reduce(( acc, assignment) => {
    if(!acc.scheduledFrom || (assignment.scheduledFrom && acc.scheduledFrom > assignment.scheduledFrom)){
      acc.scheduledFrom = assignment.scheduledFrom;
    }
    if(!acc.scheduledTo || (assignment.scheduledTo && acc.scheduledTo < assignment.scheduledTo)){
      acc.scheduledTo = assignment.scheduledTo;
    }
    assignment.agents.forEach(agent => {
      if(!acc.agents.find(a => a._id === agent._id)){
        acc.agents.push(agent);
      }
    });
    assignment.team.forEach(team => {
      if(!acc.teams.find(t => t._id === team._id)){
        acc.teams.push(team);
      }
    });
    return acc;
  }, { scheduledFrom: null, scheduledTo: null, teams: [], agents: []});
  let assignedToLabel = null;
  if(a.agents.length && a.teams.length){
    assignedToLabel = (<T>issue_assignment_assignedTo_teamAndAgent</T>);
  }else if(a.agents.length){
    if(a.agents.length === 1){
      assignedToLabel = (<span>{a.agents[0].fullname}</span>);
    }else{
      assignedToLabel = (<T bind={{ nbr: a.agents.length}}>issue_assignment_assignedTo_agents</T>);
    }
  }else if(a.teams.length){
    if(a.teams.length === 1){
      assignedToLabel = (<span>{a.teams[0].name}</span>);
    }else{
      assignedToLabel = (<T bind={{ nbr: a.teams.length}}>issue_assignment_assignedTo_teams</T>);
    }
  }
  return (
    <div className="bs-issue-item-meta-assignment">
      <Display.If condition={ assignedToLabel }>
        <div><span className="fa fa-users" />{ assignedToLabel }</div>
      </Display.If>
      <Display.If condition={ a.scheduledFrom && a.scheduledTo}>
        <div><Period start={ a.scheduledFrom } end={ a.scheduledTo } /></div>
      </Display.If>
    </div>
  );
};

const Item = ({ data }) => (
  <div className="bs-issue-item">
    <div className="bs-issue-item-illustration">
      <File file={ getFile(data.files) } fit="cover" height="160" width="160"/>
    </div>
    <div className="bs-issue-item-content">
      <div className="bs-issue-item-content-header">
        <div>
          <Title
            title={ data.bsIdNumber + ""}
            category={ data.category }
            $followed={ data.$followed }
            priority={ data.priority }
            group={ data.group }
            issueId={ data._id }
          />
          <div className="bs-issue-item-tags-container">
            {data.tags.map(tag => (<Tag tag={ tag } />))}
          </div>
          <Subject location={ data.location || data.equipment?.location } equipment={ data.equipment } />
        </div>               
        <div className="bs-issue-item-content-header-divers">
          <Display.If condition={ !!data.deadline }>
            <Deadline value={ data.deadline } />
          </Display.If>
          <Display.If condition={ !!data.recurrence }>
            <Recurrence recurrence={ data.recurrence }/>
          </Display.If>
          <Display.If condition={ data.withValorization }>
            <Acl.If resource="issues" action="manage">
              <Money value={data.$valorizationsTotal} />
            </Acl.If>
          </Display.If>
        </div>
      </div>
      <div className="bs-issue-item-content-description-container">
        <div className="bs-issue-item-content-description-text">
          <Highlighted>{ data.description }</Highlighted>
        </div>
      </div>
      <div className="bs-issue-item-content-footer">
        <div><WhoWhenWhat who={ data.createdBy } when={ data.createdAt } what="issue_item_created_at_by" /></div>
        <div><WhoWhenWhat who={ data.updatedBy } when={ data.updatedAt } what="issue_item_updated_at_by" /></div>
      </div>
    </div>  
    <div className="bs-issue-item-meta">
      <div className="bs-issue-item-meta-state">
        <Acl.If resource='issues' action='manage'>
          <Acl.Then>
            <State.SelectAndComment issue={ data } />
          </Acl.Then>
          <Acl.Else>
            <State.Standart state={ data.state } />
          </Acl.Else>
        </Acl.If>
      </div>
      <div>
        <Assignments assignments={ data.assignments } />
      </div>
      { data.manager ? <UserAvatar user={ data.manager } /> : null }
    </div>
  </div>
);

Item.load = {
  manager: {
    avatar: 1,
  },
  updatedBy: 1,
  createdBy: 1,
  category: 1,
  files: 1,
  recurrence: 1,
  "assignments.team": 1,
  "assignments.agents": 1,
  "location.building": 1,
  equipment: {
    "location.building": 1
  },
  "group": {
    issues: 1
  }
};

export default Item;
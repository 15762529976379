import Button from "@cComponents/button";
import Text from "@cComponents/text";
import useService from "@uBehaviour/hooks/useService";
import useOpenCloseToggle from "@universal/behaviour/hooks/useOpenCloseToggle";
import T from "@uBehaviour/i18n";
import SessionService from "@uServices/session";
import React, { FunctionComponent } from "react";
import Modal from "@cComponents/modal";
import UnsubscribeWizard, {
  StepValidation,
  StepReason,
  StepConfirmation
} from "@components/starter/unsubscribe";
import Flow from "@universal/components/flow";

type UnsubscribeWizardModalProps = {
  close: () => void;
};

export const UnsubscribeWizardModal: FunctionComponent<UnsubscribeWizardModalProps> = ({ close }) => {
  const session = useService<SessionService>("session");
  const logout = React.useCallback(() => {
    session.logout();
  }, [session]);
  return (
    <Modal.Show close={ close } style={{ width: "60vw", height: "80vh"  }} userCanClose={ false }>
      <UnsubscribeWizard>
        <Flow>
          <Flow.Step>
            {(context, flow) => (
              <StepValidation next={ () => flow.next() } cancel={ close } />
            )}
            </Flow.Step>
            <Flow.Step>
            {(context, flow) => (
              <StepReason next={ () => flow.next() } cancel={ close } />
            )}
            </Flow.Step>
            <Flow.Step>
            {(context, flow) => (
              <StepConfirmation confirm={ logout }/>
            )}
            </Flow.Step>
        </Flow>
      </UnsubscribeWizard>
    </Modal.Show>
  );
}

const Unsubscribe: FunctionComponent = () => {
  const [displayUnsubscribeModal, openUnsubscribeModal, closeUnsubscribeModal] = useOpenCloseToggle();
  return (
    <>
      <Button.Display onClick={ openUnsubscribeModal }>
        <Text><T>starter_f_session_userConnected_unsubscribe</T></Text>
      </Button.Display>
      { displayUnsubscribeModal && (<UnsubscribeWizardModal close={ closeUnsubscribeModal } />) }
    </>
  );
}

export default Unsubscribe;

import React, { FunctionComponent } from 'react';
import CurrentTenant from '@root/services/currentTenant';
import useService from '@universal/behaviour/hooks/useService';
import { CommercialOffer } from '@universal/types/business/Tenant';

import Activation from './views/activation';
import UnsubscribeWizard, { 
  StepConfirmation, 
  StepReason, 
  StepValidation 
} from '@components/starter/unsubscribe';
import AlreadyActivated from './views/alreadyActivated';
import { Route, Switch } from '@universal/features/router';
import Flow from '@universal/components/flow';

const Unsubscribe = () => {
  const cancel = React.useCallback(() => window.close(), []);
  return (
    <UnsubscribeWizard>
      <Flow>
        <Flow.Step>
          {(context, flow) => (
            <StepValidation next={ () => flow.next() } cancel={ cancel } />
          )}
          </Flow.Step>
          <Flow.Step>
          {(context, flow) => (
            <StepReason next={() => flow.next()} cancel={ cancel } />
          )}
          </Flow.Step>
          <Flow.Step>
          {(context, flow) => (
            <StepConfirmation />
          )}
        </Flow.Step>
      </Flow>
    </UnsubscribeWizard>
  )
}


const StarterActivation: FunctionComponent = () => {
  const currentTenant = useService<CurrentTenant>("currentTenant");
  
  if(currentTenant.current?.settings.commercialOffer === CommercialOffer.none){
    return (
      <Switch>
        <Route path="/unsubscribe" component={ Unsubscribe } />
        <Route path="" component={ Activation } />
      </Switch>);
  } else {
    return (<AlreadyActivated />);
  }
};

export default StarterActivation;
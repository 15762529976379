// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issue-item-title{
  color: rgb(94, 103, 108);
  display: flex;
  align-items: center;
}
.bs-issue-item-title>*:nth-child(n + 2){
  margin-left: 5px;
}

.bs-issue-item-title-number{
  font-weight: bold;
}
.bs-issue-item-title-category{
  text-transform: uppercase;
  flex-shrink: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bs-issue-item-title>.fa-star{
  color: #aaa;
}

.bs-issue-item-title>.fa{
  flex-shrink: 0;
}
.bs-issue-item-title-number{
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/entities/issuesAndAssignments/components/title.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,cAAc;;EAEd,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB","sourcesContent":[".bs-issue-item-title{\n  color: rgb(94, 103, 108);\n  display: flex;\n  align-items: center;\n}\n.bs-issue-item-title>*:nth-child(n + 2){\n  margin-left: 5px;\n}\n\n.bs-issue-item-title-number{\n  font-weight: bold;\n}\n.bs-issue-item-title-category{\n  text-transform: uppercase;\n  flex-shrink: 1;\n\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.bs-issue-item-title>.fa-star{\n  color: #aaa;\n}\n\n.bs-issue-item-title>.fa{\n  flex-shrink: 0;\n}\n.bs-issue-item-title-number{\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-recurrence-state{
    display: flex;
    color: white;
}
.bs-recurrence-state-triangle {
	width: 0;
	height: 0px;
	border-right: 14px solid;
	border-bottom: 28px solid transparent;
}
.bs-recurrence-state-rectangle{
    padding-right: 10px;
    padding-top: 2px;
    width: 130px;
    display: flex;
    flex-flow: row-reverse;
}
.bs-recurrence-state-deleted>.bs-recurrence-state-triangle{
    border-right-color: #a09696;
}
.bs-recurrence-state-deleted>.bs-recurrence-state-rectangle{
    background-color: #a09696;
}
.bs-recurrence-state-ended>.bs-recurrence-state-triangle{
    border-right-color: #89cc1f;
}
.bs-recurrence-state-ended>.bs-recurrence-state-rectangle{
    background-color: #89cc1f;
}
.bs-recurrence-state-inProgress>.bs-recurrence-state-triangle{
    border-right-color: #f77448;
}
.bs-recurrence-state-inProgress>.bs-recurrence-state-rectangle{
    background-color: #f77448;
}`, "",{"version":3,"sources":["webpack://./src/entities/recurrences/components/state.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;AACA;CACC,QAAQ;CACR,WAAW;CACX,wBAAwB;CACxB,qCAAqC;AACtC;AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".bs-recurrence-state{\n    display: flex;\n    color: white;\n}\n.bs-recurrence-state-triangle {\n\twidth: 0;\n\theight: 0px;\n\tborder-right: 14px solid;\n\tborder-bottom: 28px solid transparent;\n}\n.bs-recurrence-state-rectangle{\n    padding-right: 10px;\n    padding-top: 2px;\n    width: 130px;\n    display: flex;\n    flex-flow: row-reverse;\n}\n.bs-recurrence-state-deleted>.bs-recurrence-state-triangle{\n    border-right-color: #a09696;\n}\n.bs-recurrence-state-deleted>.bs-recurrence-state-rectangle{\n    background-color: #a09696;\n}\n.bs-recurrence-state-ended>.bs-recurrence-state-triangle{\n    border-right-color: #89cc1f;\n}\n.bs-recurrence-state-ended>.bs-recurrence-state-rectangle{\n    background-color: #89cc1f;\n}\n.bs-recurrence-state-inProgress>.bs-recurrence-state-triangle{\n    border-right-color: #f77448;\n}\n.bs-recurrence-state-inProgress>.bs-recurrence-state-rectangle{\n    background-color: #f77448;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import Form from "@uBehaviour/form";
import IssueFormContent from "@root/entities/issues/components/form/content";
import Input from "@cComponents/input";
import Scrollbar from "@cComponents/scrollBar";
import { Section } from "@cComponents/section";
import Application from "@uBehaviour/application";
import T from "@uBehaviour/i18n";
import Button from "@cComponents/button";
import FormError from "@common/components/formError";

import "./form.css";

const standartValidator = {
  description: { type: "string" },
  files: { type: "array", items: { type: "string" } },
  requestor: { type: "object", props: {
    type: { type: "string", enum:["userPro", "internal", "citizen"] },
    firstname: { type: "string" },
    lastname: { type: "string" },
    email: { type: "email", optional: true },
    phone: { type: "string", optional: true }
  }},
  assignment: { type: "object", props: {
    team: { type: "array", items: { type: "string" }},
    agents: { type: "array", items: { type: "string" }},
    scheduledFrom: { type: "date", convert: true },
    scheduledTo: { type: "date", convert: true },
    necessariesEquipments: { type: "array", items: { type: "string" }},
    necessariesSupplies:{ type: "array", items: { type: "string" }},
    workInstructions: { type: "string" }
  }}
};
const validationSchema = { schema: {
  $$strict: true,
  $$root: true,
  type: "multi",
  rules: [
    { type: "object", props: Object.assign({
      discriminator: { type: "string", enum:["publicSpace"] },
      location: { type: "object", props: {
        address: { type: "object" },
        position: { type: "object" }
      }}}, standartValidator)},
    { type: "object", props: Object.assign({
      discriminator: { type: "string", enum:["building"] },
      location: { type: "object", props: {
        address: { type: "object" },
        building: { type: "string" },
        place: { type: "string", optional: true },
        locationInfo: { type: "string", optional: true },
        position: { type: "object" }
      }}}, standartValidator)},
    { type: "object", props: Object.assign({
      discriminator: { type: "string", enum:["equipment"] },
      equipment: { type: "string" },
      location: { type: "object", optional: true, props: {
        address: { type: "object" },
        position: { type: "object" }
      }}}, standartValidator)}
  ]
}};

const AssignmentForm = Application.Service.forward(["session", "api", "i18n"], ({ session, api, close, date, agent, team, i18n }) => {
  const defaultValue = {
    discriminator: "publicSpace",
    description: "",
    device: "web",
    location: null,
    files: [],
    requestor: {
      type: "userPro",
      firstname: session.user.firstname,
      lastname: session.user.lastname,
      email: session.user.email,
      phone: ""
    },
    assignment: {
      team: team && !agent ? [team._id] : [],
      agents: agent ? [agent._id] : [],
      scheduledFrom: date.toISOString(),
      scheduledTo: date.clone().add(1, "day").toISOString(),
      necessariesEquipments: [],
      necessariesSupplies: [],
      workInstructions: ""
    }
  };
  const submitHandler = React.useCallback((form, value) => {
    return api.service("issues", "createIssueAndAssignment").execute(value)
      .then(() => {
        close();
      });
  }, [api, close]);

  return (
    <Form.Simple default={ defaultValue } validator={ validationSchema } submit={ submitHandler } onChange={ IssueFormContent.onChange }>
    {(ctx, value, errors, form) => (
      <Input.File.DropArea>
        <div className="bs-assignment-form">
          <FormError errors={errors.global}>
            <Scrollbar>
              <IssueFormContent value={ value }/>
              <Section>
                <Section.Title><T bind={{ date: date.format("DD/MM/YYYY"), actor: agent ? agent.fullname : team.name }}>assignment_formContent_global</T></Section.Title>
                <Form.Simple.InputAdapter name="assignment.workInstructions">
                {(value, set, clear) => (
                  <Input.TextArea onChange={ set } value={ value }>{i18n.translate("assignment_formContent_global_workInstructions")}</Input.TextArea>
                )}
                </Form.Simple.InputAdapter>
              </Section>
            </Scrollbar>
          </FormError>
          <div className="bs-assignment-form-footer">
            <Button.Text onClick={ close }><T>assignment_formContent_action_cancel</T></Button.Text>
            <Button.Text onClick={ () => form.submit() }><T>assignment_formContent_action_submit</T></Button.Text>
          </div>
        </div>
      </Input.File.DropArea>
    )}
    </Form.Simple>
  );
});

export default AssignmentForm;
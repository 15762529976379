import Icon             from "./components/icon";
import Avatar           from '@cFeatures/user/avatar';
import Item             from './components/item';
import Form             from './components/form';
import Filters          from "./components/filters";
import CitizenItem      from './components/citizenItem';
import Details          from './components/details';
import Actions          from './components/actions';

export {
  Icon,
  Avatar,
  Item,
  Form,
  Filters,
  CitizenItem,
  Details,
  Actions
};

export default {
  Icon,
  Avatar,
  Item,
  Form,
  Filters,
  CitizenItem,
  Details,
  Actions
}
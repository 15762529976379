// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-equipments-import-title {
  height: 43px;
  border-bottom: 1px solid #dddddd;
  color: #2c3e50;
  background-color: #f5f5f5;
  font-size: 1.2em;
  padding: 10px 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
}


.bs-equipments-import-label {
  padding-bottom: 10px;
}

.bs-equipments-encoding-label {
  padding-top: 25px;
  padding-bottom: 10px;
}

.bs-equipments-import-body {
  padding: 10px 10px;
  color: #2c3e50;
}

.bs-equipments-import-body-extracted {
  padding-top: 25px;
  color:#27ae60;
}

.bs-equipments-import-body-error {
  padding-top: 25px;
  color: #E84C24;
}

.bs-equipments-import-abstract-container {
  padding-top: 25px;
}


.bs-equipments-import-actions {
  background-color: #f5f5f5;
  padding: 10px 10px;
  border-top: 1px solid #dddddd;
  display: flex;
  justify-content: flex-end;
}

.bs-equipments-import-actions > button:not(:last-child) {
  margin-right: 10px;
}

.bs-equipments-display-title {
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/entities/equipments/components/import.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gCAAgC;EAChC,cAAc;EACd,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;;AAGA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;;AAGA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,6BAA6B;EAC7B,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".bs-equipments-import-title {\n  height: 43px;\n  border-bottom: 1px solid #dddddd;\n  color: #2c3e50;\n  background-color: #f5f5f5;\n  font-size: 1.2em;\n  padding: 10px 10px;\n  font-weight: 600;\n  display: flex;\n  align-items: center;\n}\n\n\n.bs-equipments-import-label {\n  padding-bottom: 10px;\n}\n\n.bs-equipments-encoding-label {\n  padding-top: 25px;\n  padding-bottom: 10px;\n}\n\n.bs-equipments-import-body {\n  padding: 10px 10px;\n  color: #2c3e50;\n}\n\n.bs-equipments-import-body-extracted {\n  padding-top: 25px;\n  color:#27ae60;\n}\n\n.bs-equipments-import-body-error {\n  padding-top: 25px;\n  color: #E84C24;\n}\n\n.bs-equipments-import-abstract-container {\n  padding-top: 25px;\n}\n\n\n.bs-equipments-import-actions {\n  background-color: #f5f5f5;\n  padding: 10px 10px;\n  border-top: 1px solid #dddddd;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.bs-equipments-import-actions > button:not(:last-child) {\n  margin-right: 10px;\n}\n\n.bs-equipments-display-title {\n  margin: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

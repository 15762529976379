export default {
  "fr": "FR",
  "nl": "NL",
  "en": "EN",
  "welcome": "Welkom",
  "logout": "Afmelden",
  "login": "Inloggen",
  "day": "Dag",
  "days": "Dagen",
  "five_day": "5 dagen",
  "month": "Maand",
  "password": "Wachtwoord",
  "login_welcome": "Wachtwoord vergeten: neem contact op",
  "login_error_password": "Het ingevoerde e-mailadres of wachtwoord is ongeldig",
  "login_error_readOnlyOnApp": "Uw alleen-lezen-profiel geeft u momenteel geen toegang tot deze applicatie.",
  "language": "Taal",
  "home": "Home",
  "by_anonymous": "Anoniem",
  "submit": "Ingediend",
  "signs": "Signalering",
  "festivity": "Festiviteit",
  "water": "Wateraansluiting",
  "tagged_as": "Gelabeld als",
  "tree": "Boom - Groene zone",
  "playground": "Speelplaats",
  "dripping": "Riool - Afvoerputje",
  "waste": "Afvalinzameling",
  "flood": "Overstroming",
  "deposit": "Illegale storting",
  "lighting": "Straatverlichting",
  "furniture": "Straatmeubilair",
  "pothole": "Gat in de weg",
  "pollution": "Vervuiling",
  "road": "Weg - Trottoir",
  "grafitti": "Graffiti - Vandalisme",
  "suggestion": "Suggestie",
  "congratulation": "Gefeliciteerd!",
  "all_states": "Alle",
  "test": "test",
  "customers": "Klanten",
  "archived": "Gearchiveerd",
  "issueArchived": "Gearchiveerd",
  "issueUnArchived": "Gedearchiveerd",
  "open": "Open",
  "resolved": "Opgelost",
  "closed": "Gesloten",
  "in_progress": "In behandeling",
  "planned": "Gepland",
  "bsId_placeholder": "Zoek id...",
  "deleted": "Verwijderd",
  "rejected": "Afgewezen",
  "transferred": "Overgedragen",
  "nav_new_issue": "Nieuwe inzending",
  "nav_dashboard": "Home",
  "nav_all_issues": "Alle inzendingen",
  "nav_admin_communes": "Admin",
  "select_all": "Alles",
  "unselect_all": "Niets",
  "unselect_all_f": "Niets",
  "sort_by": "Sorteren op",
  "states": "Status",
  "reset_filters": "Alles selecteren",
  "load_filters": "Mijn standaardfilters",
  "save_filters": "Huidige filters opslaan als standaard",
  "categorys": "Categorieën",
  "request_details": "Aanvraagdetails",
  "filter_no_result": "Geen inzending gevonden...",
  "form_validation_name": "Minimaal twee tekens vereist",
  "form_validation_select_tag": "Kies een categorie",
  "form_validation_select_image": "Kies een foto",
  "form_validation_accepted_validimage": "Geef een bestand in .jpg, .jpeg of .png formaat",
  "form_validation_size_validsizeimage": "De foto mag maximaal 5 MB zijn",
  "form_validation_add_comment": "Beschrijf uw suggestie kort",
  "form_validation_add_location": "Geef de locatie van uw foto op",
  "form_location": "Lokaliseer uw foto",
  "form_location_legend": "Geef het adres hieronder aan OF verplaats de marker op de kaart",
  "form_photo": "Upload uw foto",
  "add_picture": "Voeg een foto toe",
  "form_photo_legend": "Eén foto, maximaal 5 MB",
  "form_tag": "Kies een categorie",
  "form_tag_title": "Categorie",
  "form_comment": "Uw opmerking",
  "form_comment_legend_optional": "Maximaal 160 tekens",
  "sub_building_max": "Maximaal 60 tekens",
  "form_comment_legend_mandatory": "Maximaal 160 tekens",
  "form_anonymous_legend": "Uw naam verschijnt niet op de publieke site maar is zichtbaar voor uw gemeentelijke administratie",
  "form_submit": "Verzenden",
  "alert_cant_be_public": "Een inzending zonder foto kan niet openbaar zijn",
  "where": "Waar?",
  "updateIssuePlanification": "Toegewezen aan",
  "updateIssueOrderAndAssign": "Toegewezen aan",
  "newCommentFromCitizen": "Nieuw bericht van een burger",
  "updateIssueOrderAndAssignAndScheduled": "Toegewezen aan",
  "updateIssueAssignAndScheduled": "Toegewezen aan",
  "updateIssueOrder": "Instructies gewijzigd",
  "updateIssueState": "Status gewijzigd",
  "memo_added": "Notitie/Bericht",
  "addIssueInternalNote": "Interne notitie",
  "update_issue_picture": "Foto toegevoegd",
  "updateIssuePicture": "Foto toegevoegd",
  "createIssue": "Nieuwe invoer",
  "updated_issue_to": "Status gewijzigd",
  "updateIssueStateMergin": "Status gewijzigd",
  "createFacebookUser": "Gebruiker aangemaakt via Facebook",
  "createUserPro": "Professionele gebruiker aangemaakt",
  "updateIssueRequestor": "Aanvrager gewijzigd",
  "updateIssueDisplayState": "Zichtbaarheid gewijzigd",
  "emailSent": "E-mail naar aanvrager",
  "phone": "Telefoon",
  "email": "E-mail",
  "letter": "Brief",
  "citizen": "Burger",
  "internal": "Intern",
  "local_stuff": "Intern",
  "localitys": "Gemeenten",
  "update_status": "update_status",
  "other": "Anders",
  "steward": "Beheerder",
  "administrative": "Administratief",
  "wasteCollectionTruck": "Reinigingsdienst",
  "worker": "Gemeentewerker",
  "police": "Politie",
  "request_description": "Beschrijving...",
  "requestor_details": "Informatie aanvrager",
  "form_validation_location_unauthorizedzip": "Gelieve een adres in uw gebied in te voeren.",
  "form_validation_add_email": "Gelieve een geldig e-mailadres in te vullen.",
  "form_validation_location_unselectedzip": "Gelieve een postcode in het adres in te voeren",
  "form_validation_location_unauthorizedAddress": "Het adres moet minimaal een postcode of gemeente bevatten, evenals een straatnaam",
  "form_validation_location_requiredAddress": "Gelieve een adres in te voeren",
  "all_town": "all_town",
  "issue_resolved_email_management_template": "De volgende e-mail wordt naar de aanvrager gestuurd",
  "issue_deleted_email_management_template": "issue_deleted_email_management_template",
  "issue_resolved_no_email": "De burger heeft aangegeven geen e-mails te willen ontvangen",
  "confirmation_email_send": "Uw e-mail is succesvol verzonden",
  "created_at": "Gemaakt op",
  "audience": "Publiek",
  "private": "Privé",
  "public": "Openbaar",
  "memo": "Notitie / Bericht",
  "cancel": "Annuleren",
  "firstname": "Voornaam",
  "tel": "Tel.",
  "lastname": "Achternaam",
  "service": "Profiel",
  "device": "Bron",
  "comment": "opmerking",
  "internal_comment": "Interne opmerking",
  "issue_source": "Aanvrager",
  "citizen_internal": "Burger / Intern",
  "issue_details": "issue_details",
  "type": "Type",
  "location": "locatie",
  "sender": "afzender",
  "creation": "aanmaak",
  "updated": "bijgewerkt",
  "status": "Statussen",
  "status_sing": "Status",
  "creation_date": "Aanmaakdatum",
  "creation_date_ascending": "Aanmaakdatum oplopend",
  "creation_date_descending": "Aanmaakdatum aflopend",
  "update_date_ascending": "Wijzigingsdatum oplopend",
  "update_date_descending": "Wijzigingsdatum aflopend",
  "issues": "Invoeren",
  "from": "Van",
  "to": "Naar",
  "print": "Afdrukken",
  "print_export": "Afdrukken/Exporteren",
  "title_activity_logs": "Activiteiten",
  "meta_desc": "meta_desc",
  "image": "afbeelding",
  "login_error_unAuthorized": "De ingevoerde e-mail of wachtwoord is ongeldig",
  "last_updated": "Laatst bijgewerkt",
  "edit_picture": "edit_picture",
  "click_here_to_edit": "Bewerken",
  "click_here_to_cancel": "Klik hier om te annuleren",
  "informations": "Informatie",
  "requestor": "Aanvrager",
  "via": "via",
  "clear": "Annuleren",
  "source": "Bron",
  "contact": "Contact",
  "name": "Naam",
  "confirm_update": "Opslaan",
  "save_changes": "Opslaan",
  "address": "Adres",
  "form_validation_leavepage": "Sommige wijzigingen zijn niet opgeslagen.",
  "choose": "Kiezen",
  "locality": "gemeente",
  "bsId": "bsId",
  "visibility": "Zichtbaarheid",
  "function": "Functie",
  "communicate_via": "Melden via",
  "communicate_by": "Gemeld via",
  "mail_object": "Onderwerp",
  "mail_message": "Bericht",
  "mail_your_comment": "Optionele opmerking",
  "password_verification": "Wachtwoordverificatie",
  "form_validation_password": "Verkeerd verificatiewachtwoord",
  "password_validation": "Gelieve een wachtwoord in te stellen (twee keer invoeren)",
  "form_validation_passwordregexp": "Ongeldig wachtwoord (minimaal 6 alfanumerieke tekens)",
  "updateIssueTag": "Categorie bijgewerkt",
  "updateIssueETag": "Categorie bijgewerkt",
  "updated_issue_tag_to": "Categorie bijgewerkt",
  "send": "Interne boodschap",
  "send_issue_by_email": "Interne boodschap",
  "choose_a_colleague": "Kies een ontvanger",
  "add": "Toevoegen",
  "transfer": "Overdragen",
  "transfer_label": "Overgedragen aan",
  "issueTransfered": "Overgedragen aan",
  "choose_a_transfer": "Kies een ontvanger",
  "transfer_issue_by_email": "Externe overdracht",
  "issue_sent": "Uw interne bericht is verzonden.",
  "issue_transfered": "Uw bericht is verzonden.",
  "issue_sent_to": "Interne boodschap aan",
  "issueSent": "Interne boodschap aan",
  "issue_transfered_to": "Overgedragen aan",
  "form_validation_requiredValue": "Verplicht veld",
  "issue_update_message": "issue_update_message",
  "unselect": "Geen",
  "transfer_none": "Niet overgedragen",
  "assign_none": "Niet toegewezen",
  "nav_all_issues_list": "Lijst",
  "nav_all_issues_map": "Kaart",
  "nav_all_issues_little_list": "Compacte lijst",
  "owner": "Beheerder",
  "priority": "Prioriteit",
  "updated_issue_new_priority": "Prioriteit bijgewerkt",
  "updateIssuePriority": "Prioriteit bijgewerkt",
  "owners": "Beheerders",
  "priorities": "Prioriteiten",
  "priority_ascending": "Oplopende prioriteit",
  "priority_descending": "Aflopende prioriteit",
  "a_low": "Normaal",
  "j_middle": "Gemiddeld",
  "z_high": "Dringend",
  "stats_states_by_tags": "Statusstatistieken per categorie",
  "numbers": "Getallen",
  "update_owner": "Beheerder gewijzigd",
  "updateIssueOwner": "Beheerder gewijzigd",
  "change_owner_to_me": "U wordt de nieuwe beheerder",
  "you_are_not_owner": "Oeps. U beheert dit dossier niet.",
  "you_are_not_ower_message": "Weet u zeker dat u wilt doorgaan?",
  "updated_issue_new_address": "Adres gewijzigd",
  "updateIssueAddress": "Adres gewijzigd",
  "stats_states_pie": "Statusverdeling",
  "scheduled_to": "op",
  "remove_issue_transfer": "Bevestigt u de wijziging van de overdracht?",
  "issue_transfer_deleted": "Overdracht verwijderd",
  "removeTransfer": "Overdracht verwijderd",
  "assign": "Toegewezen aan",
  "updated_issue_scheduled": "Startdatum gewijzigd",
  "updateIssueScheduled": "Startdatum gewijzigd",
  "deleteIssueScheduled": "Geplande datum verwijderd",
  "transfers": "Overgedragen aan",
  "without": "Geen",
  "issue_assign": "Toewijzing",
  "issue_assigned_to": "Team & Agenten",
  "issueAssigned": "Toegewezen aan",
  "issue_assigned_deleted": "Toewijzing verwijderd",
  "removeAssign": "Toewijzing verwijderd",
  "remove_issue_assign": "Bevestigt u de wijziging van de toewijzing?",
  "assigns": "Team & Agenten",
  "update_issue_address": "Locatie wijzigen",
  "Ok": "Ok",
  "reset_password": "Uw wachtwoord opnieuw instellen",
  "reset_password_unknow_email": "Onbekend e-mailadres of gebruikersnaam. Neem contact op met uw beheerder.",
  "reset_password_email_sent": "Er is een e-mail verzonden om uw wachtwoord opnieuw in te stellen.",
  "reset_password_incorrect_token": "Deze link is niet meer geldig... Vraag een nieuwe aan en probeer opnieuw.",
  "new_email_validation_sent": "Er is een nieuwe e-mail gestuurd om uw account te valideren.",
  "new_email_validation_unknow_email": "Onbekend e-mailadres",
  "new_email_validation_user_already_validated": "Uw gebruikersaccount is al eerder gevalideerd.",
  "reset_password_no_email": "Ontbrekend e-mailadres",
  "password_forgotten": "Wachtwoord vergeten?",
  "assign_to": "Team",
  "reset_password_ok": "Wachtwoord succesvol gewijzigd.",
  "stats_issue_in_time": "Nieuwe binnenkomende meldingen per dag",
  "public_space": "Openbare ruimte",
  "building": "Gebouw",
  "select_issue_type": "Type melding",
  "form_building": "Kies een gebouw",
  "electricity": "Elektriciteit / Verlichting",
  "plumbing": "Loodgieterij / Sanitair",
  "heating": "Verwarming",
  "roof": "Dak",
  "painting": "Schilderwerk / Muren",
  "furnitures": "Meubilair",
  "miscellaneous": "Diversen",
  "doors": "Deuren / Ramen",
  "external_infrastructure": "Externe infrastructuur",
  "buildings": "Gebouwen",
  "buildings_tags": "Categorieën gebouwen",
  "contact_issue_owner": "Schrijf naar de beheerder",
  "owner_contacted": "Bericht van de aanvrager",
  "contactIssueOwner": "Bericht aan de beheerder",
  "requestor_contacted": "E-mail naar de aanvrager",
  "contactIssueRequestor": "E-mail naar de aanvrager",
  "contact_issue_requestor": "E-mail verzenden naar de aanvrager",
  "internal_building_profile": "Interne gebouwen",
  "issue_update_state_required_comment": "Gelieve de reden voor de afwijzing of verwijdering op te geven",
  "form_validation_requiredBuilding": "Gelieve een gebouw te selecteren",
  "alert_building_cant_be_public": "Gebouwaanvragen zijn altijd privé",
  "update_issue_building": "Kies een ander gebouw",
  "updated_issue_new_building": "Gebouw gewijzigd",
  "updateIssueBuilding": "Gebouw gewijzigd",
  "contact_requestor": "E-mail naar aanvrager",
  "download_pdf": "download_pdf",
  "description_updated": "Beschrijving gewijzigd",
  "updateIssueDescription": "Beschrijving gewijzigd",
  "comment_updated": "Publieke bericht gewijzigd",
  "updateIssueComment": "Publieke bericht gewijzigd",
  "new_comment": "Publieke bericht",
  "addIssueComment": "Publieke bericht toegevoegd",
  "description": "Beschrijving",
  "describe_issue": "Beschrijf de aanvraag",
  "add_desc": "Voeg een beschrijving toe",
  "add_order": "Voeg instructies toe",
  "show_more": "Meer weergeven",
  "show_less": "Minder weergeven",
  "scheduled": "Geplande datum",
  "scheduled_ascending": "Geplande datum oplopend",
  "scheduled_descending": "Geplande datum aflopend",
  "scheduledTo_ascending": "Vervaldatum oplopend",
  "scheduledTo_descending": "Vervaldatum aflopend",
  "work_order": "Werkorder",
  "instructions": "Instructies",
  "ended": "Geëindigd op",
  "remarks": "Opmerkingen",
  "signature": "Handtekening",
  "print_all": "Alles afdrukken",
  "by": "Door",
  "create_at": "Gemaakt op",
  "direct_contact": "Direct contact",
  "contact_support": "Probleem? Neem contact met ons op",
  "nav_stats": "Statistieken",
  "no_email_will_be_sent": "Bevestiging van statuswijziging",
  "save_filter_title": "Opslaan van filters",
  "save_filter_explain": "<p>De momenteel geselecteerde filters worden opgeslagen als uw nieuwe standaardfilters.</p><p> Om deze filters toe te passen op de lijst of kaart, hoeft u alleen maar op het pictogram <i class='icon-filter' style='font-size: 15px;'></i> te klikken.</p><p>U kunt op elk moment nieuwe filters opslaan om ze te wijzigen.</p>",
  "delete_user_filters": "verwijder_gebruikersfilters",
  "change_issue_state_and_make_it_public": "Verstuur en maak het rapport openbaar",
  "save_and_make_it_public": "Opslaan en het rapport openbaar maken",
  "save_filter_error_no_empty_filter": "Om uw filters op te slaan, selecteer alstublieft ten minste één item voor de volgende filters",
  "load_no_filter_title": "Ooops",
  "load_no_filter_explain": "U heeft nog geen standaardfilters ingesteld",
  "refresh_filters": "Vernieuwen",
  "Ooops": "Ooops",
  "filters": "Filters",
  "country": "Land",
  "belgium": "België",
  "france": "Frankrijk",
  "communes": "Gemeenten",
  "email_reports_title": "Activiteitenrapport",
  "email_reports_count_title": "email_reports_count_title",
  "email_reports_open_issues": "open rapport(en) van meer dan 15 dagen",
  "email_reports_in_progress_issues": "rapport(en) in behandeling van meer dan 15 dagen",
  "email_reports_planned_issues": "geplande rapport(en) waarvan de verwachte datum is verstreken",
  "connect_to_betterstreet": "Verbind met BetterStreet Admin",
  "date_from_title": "Samenvatting van de rapporten van",
  "date_from": "Van",
  "date_to": "Tot",
  "last_week_created": "Nieuw(e)",
  "last_week_updated": "Bijgewerkt",
  "last_week_resolved": "Opgelost(e)",
  "week": "Week",
  "reports_unsubscribe": "Als u deze rapporten niet meer wilt ontvangen, geef het dan aan",
  "search": "Zoeken",
  "publicSpace": "Openbare ruimte",
  "tag": "Categorie",
  "zip_code": "Postcode",
  "me": "Ik",
  "unauthorized_locality": "Uw geolocatie is buiten het gebied van uw gemeente. U kunt geen rapport maken op deze locatie...",
  "login_error_validatePassword": "Gelieve een nieuw wachtwoord te kiezen en te bevestigen...",
  "requestor_updated": "Aanvrager gewijzigd",
  "export": "Exporteren",
  "filtered_list": "Gefilterde lijst",
  "export_all": "Alles",
  "other_local_stuff": "Andere interne",
  "no_result": "Geen resultaat",
  "initial_comment_updated": "Oorspronkelijke beschrijving van de aanvrager gewijzigd",
  "updateIssueInitialComment": "Oorspronkelijke beschrijving van de aanvrager gewijzigd",
  "delete_issue_comment": "Publieke bericht verwijderd",
  "deleteIssueComment": "Publieke bericht verwijderd",
  "warning_comment_is_public": "Deze beschrijving zal zichtbaar zijn voor een openbaar rapport.",
  "comment_is_public": "Maak deze opmerking openbaar en zichtbaar voor alle burgers",
  "public_comment": "Deze opmerking zal openbaar zijn en zichtbaar voor alle burgers.",
  "public_comment_title": "Publieke berichten",
  "add_public_comment": "Voeg een openbaar bericht toe",
  "cant_be_public": "Dit bericht kan alleen openbaar zijn als het rapport ook openbaar is. Deselectioneer of klik op",
  "remove_comment": "Verwijder het bericht",
  "confirmation_remove_comment": "U gaat het volgende publieke bericht verwijderen",
  "remove": "Verwijderen",
  "issue_update_state_required_display": "Alleen beschikbaar als het rapport openbaar is.",
  "log_comment_public": "Nieuw openbaar bericht",
  "issue_update_state_required_public_comment": "Gelieve een bericht te schrijven (of hieronder deselecteren).",
  "dashboard": "Algemene instellingen",
  "subscription_type": "Abonnementstype",
  "default_visibility": "Standaard zichtbaarheid",
  "default_display_on": "Openbare rapporten",
  "default_display_off": "Privé rapporten",
  "default_display_explanation": "Standaard zichtbaarheid van burger rapporten",
  "public_site_url": "URLs van de gemeentepagina's",
  "add_user": "Voeg gebruiker toe",
  "reply_email": "E-mail van melding / communicatie",
  "fullname": "Volledige naam",
  "display_name": "Handtekening voor e-mails buiten de gemeente",
  "email_notification": "E-mail bij ontvangst van nieuw rapport",
  "reports": "Ontvang wekelijkse rapporten",
  "email_notification_table": "E-mail melding",
  "reports_table": "Wekelijks rapport",
  "edit": "Bewerken",
  "profile": "Gebruikersprofiel",
  "building_profile": "Gebouw",
  "public_space_profile": "Openbare ruimte",
  "full_profile": "Volledig",
  "read_only_profile": "Beperkt (toevoegen/bekijken)",
  "form_title_user_creation": "Nieuwe gebruiker",
  "form_title_user_edit": "Bewerking gebruiker",
  "form_title_user_edit_password": "Wachtwoord opnieuw instellen",
  "select_a_profile": "Kies een profiel",
  "user_pro_email_notifications_explanation": "Alleen voor profiel 'Volledig' categoriebeheerder",
  "user_pro_reports_explanation": "Alleen voor profiel 'Volledig'",
  "add_transfer": "Voeg overdracht toe",
  "add_assign": "Voeg team & agenten toe",
  "form_title_transfer_creation": "Nieuwe overdracht",
  "form_title_transfer_edit": "Bewerking overdracht",
  "form_title_assign_creation": "Nieuwe toewijzing",
  "form_title_assign_edit": "Bewerking toewijzing",
  "add_building": "Voeg gebouw toe",
  "form_title_building_creation": "Nieuw gebouw",
  "form_title_building_edit": "Bewerking gebouw",
  "admin_nav_options": "Instellingen",
  "admin_nav_owners": "Beheerders",
  "admin_nav_users": "Gebruikers",
  "admin_nav_assignees": "Toegewezenen",
  "admin_nav_transfers": "Overdrachten",
  "admin_nav_buildings": "Gebouwen",
  "admin_nav_emails": "E-mails",
  "admin": "Beheerdersconsole",
  "admin_array_title_transfers": "Overdrachten",
  "admin_array_title_assignees": "Toegewezenen",
  "admin_array_title_buildings": "Gebouwen",
  "admin_array_title_emails": "E-mails",
  "admin_array_title_owners": "Beheerders",
  "admin_array_title_users": "Gebruikers",
  "error": "Dit e-mail wordt al gebruikt door een bestaande gebruiker",
  "please_try_again": "Gelieve een ander e-mailadres in te vullen voor deze gebruiker",
  "email_already_in_use": "Het opgegeven e-mailadres kan niet worden gebruikt omdat het al aan een andere gebruiker is gekoppeld",
  "first_name": "Voornaam",
  "last_name": "Achternaam",
  "options": "Opties",
  "user": "Gebruiker",
  "form_title_owner_edit": "Bewerking beheerder",
  "modification_success": "Wijziging(en) opgeslagen",
  "form_validation_userIsOwner": "Deze gebruiker is momenteel beheerder van categorieën. Om zijn profiel te wijzigen, selecteer eerst een andere gebruiker als beheerder.",
  "add_pictures": "Voeg foto('s) of PDF('s) toe",
  "or": "of",
  "drag_your_file": "Sleep foto('s) of PDF('s) hier",
  "issue_cover": "Hoofd foto",
  "confirm_update_cover": "Gebruik als hoofd foto?",
  "confirm_delete_picture": "Verwijder deze foto?",
  "update_cover_modal_title": "Gebruik als hoofd foto?",
  "issue_picture_added": "Foto('s) toegevoegd",
  "addIssuePicture": "Foto('s) toegevoegd",
  "issue_picture_deleted": "Foto verwijderd",
  "deleteIssuePicture": "Foto verwijderd",
  "deleteIssuePDF": "PDF verwijderd",
  "app_loading": "Laden...",
  "charts_loading": "Laden...",
  "without_filters": "geen resultaten gevonden zonder rekening te houden met uw filters. Klik hier om ze te bekijken.",
  "existing_building_name": "Deze naam voor een gebouw is al in gebruik. Gelieve een andere naam in te vullen.",
  "admin_nav_widget": "Widgets",
  "width": "Breedte",
  "add_widget": "Voeg widget toe",
  "form_title_widget_creation": "Widgets",
  "widget_issues_name": "Widget naam",
  "widget_issues_title": "Titel op de widget",
  "widget_display_issues": "Toon rapporten",
  "widget_display_only_resolved": "Alleen de opgeloste",
  "widget_display_add_issue_button": "Toon knop",
  "widget_button_label": "Knop label",
  "widget_display_banner": "Toon banner",
  "widget_width": "Breedte van de widget (pixels)",
  "widget_issues_number": "Aantal rapporten",
  "404_info": "De gevraagde pagina bestaat niet of er is een onverwachte fout opgetreden...",
  "choose_receiver": "Selecteer een ontvanger",
  "user_didnt_accept_notification": "Geen e-mail wordt verzonden omdat de gebruiker geen meldingen per e-mail wenst te ontvangen",
  "citizen_email_unknown": "Geen e-mail wordt verzonden omdat het e-mailadres van de aanvrager onbekend is",
  "internal_issue": "Geen e-mail wordt verzonden omdat het een interne aanvraag betreft",
  "alert_entity_issue_cant_be_public": "Dit type aanvraag is altijd privé",
  "error_ie_title": "Gelieve uw browser bij te werken om BetterStreet te gebruiken",
  "error_ie_contact_us": "Heeft u problemen? Neem contact met ons op via",
  "error_ie_explain_1": "We hebben BetterStreet gebouwd met de nieuwste technologieën zodat de service snel, efficiënt en gemakkelijk te gebruiken is. Helaas ondersteunt uw browser deze technologieën niet.",
  "error_ie_explain_2": "Geen zorgen, u hoeft alleen maar een van deze moderne browsers gratis te downloaden om ervan te genieten.",
  "404_back": "Terug naar de lijst met rapporten",
  "instructions_list": "Werkorders - Meerdere per pagina",
  "print_work_order": "Werkorder afdrukken",
  "print_work_order_one": "Werkorder afdrukken - Eén per pagina",
  "print_work_order_multi": "Werkorder afdrukken - Meerdere per pagina",
  "create_recurrence": "Maak een herhaling",
  "goTo_recurrence": "Ga naar herhaling",
  "delete_date": "verwijder datum",
  "owner_notified": "De beheerder is op de hoogte gesteld",
  "admin_nav_tags": "Categorieën",
  "admin_array_title_tags": "Categorieën",
  "add_tag": "Categorie toevoegen",
  "bs_tag": "Standaardcategorie",
  "tag_public_explanation": "Categorie zichtbaar voor burgers",
  "tag_active_explanation": "Actieve categorie",
  "last_connection": "Laatste verbinding",
  "reason_no_email_will_be_sent": "Er zal geen e-mail worden verzonden voor deze statuswijziging",
  "new_nears_building": "Al gemeld in dit gebouw...",
  "nears_building": "In dit gebouw...",
  "new_nears_public_space": "Al gemeld in de buurt...",
  "nears_public_space": "In de buurt...",
  "today": "Vandaag",
  "seven_last_days": "Laatste 7 dagen",
  "thirteen_last_days": "Laatste 30 dagen",
  "custom_dates": "Aangepast",
  "tomorrow": "Morgen",
  "seven_next_days": "Volgende 7 dagen",
  "following_email_will_be_sent": "De volgende e-mail wordt verzonden naar de aanvrager en eventuele volgers",
  "deleteIssueScheduledTo": "Geplande datum verwijderd",
  "updateIssueScheduledTo": "Einddatum bijgewerkt",
  "deadline": "Deadline",
  "no_email": "Geen e-mail",
  "scheduled_from_validation": "Oeps. Om te plannen moet er een team toegewezen worden!",
  "reload_alert": "De Betterstreet applicatie is sinds je laatste gebruik geüpdatet. Een pagina-herlading is nodig om de wijzigingen door te voeren.",
  "plz_select_team": "Selecteer alstublieft een team of een medewerker",
  "filter_only_planned": "Zonder geplande data",
  "show_list": "Toon lijst",
  "show_filters": "Toon filters",
  "list": "Lijst",
  "calendar_loading": "Bezig met laden van de agenda...",
  "calendar": "Agenda",
  "warning": "Waarschuwing",
  "issue_over_territory": "Het ingevoerde adres ligt buiten je territorium...",
  "issue_over_territory_update": "Waarschuwing: het ingevoerde adres ligt buiten je territorium...",
  "update_state_title_1": "De status van het probleem",
  "update_state_title_2": "is bijgewerkt.",
  "update_state_explain": "Afhankelijk van de geselecteerde filters verschijnt het probleem mogelijk niet meer in deze lijst.",
  "internal_user": "Interne gebruiker",
  "admin_array_title_sub_buildings": "Ondergebouwen",
  "existing_place_name": "Deze naam voor een ondergebouw bestaat al.",
  "form_sub_building": "Precieze locatie...",
  "form_sub_building_validation": "Geef alstublieft de locatie op",
  "add_sub_building": "Ondergebouw toevoegen",
  "edit_sub_building": "Ondergebouw bewerken",
  "reload_user_alert": "Je gebruikersprofiel is gewijzigd door een beheerder. Je browservenster wordt vernieuwd om deze wijzigingen weer te geven...",
  "recurrence_settings": "Herhalingsinstellingen",
  "recurrence": "Herhaling",
  "weekly": "Wekelijks",
  "monthly": "Maandelijks",
  "annual": "Jaarlijks",
  "repeat_every": "Herhalen elke",
  "repeat_every_f": "Herhalen elke",
  "year_the": "jaar op",
  "month_the": "maand op",
  "day_the": "dag(en) op",
  "recurrence_end": "Einde",
  "no_end_date": "Geen einddatum",
  "end_after": "Eindigt na",
  "ocurrences": "herhaling(en)",
  "create_next_issue": "Volgend probleem aanmaken",
  "day_before_recurrence": "dagen voor de berekende vervaldatum",
  "based_on_parameters": "Op basis van deze parameters",
  "first_occurence_at": "De eerste herhaling heeft als vervaldatum",
  "next_intervention_will_be": "De volgende interventie in de reeks wordt automatisch aangemaakt op",
  "delete_recurrence": "Herhaling verwijderen",
  "modify_recurrence": "Herhaling wijzigen",
  "weekly_recurrence": "Wekelijkse herhaling",
  "monthly_recurrence": "Maandelijkse herhaling",
  "annual_recurrence": "Jaarlijkse herhaling",
  "recurrenceCreated": "Herhaling toegevoegd",
  "updated_recurrence": "Herhaling bijgewerkt",
  "updateRecurrence": "Herhaling bijgewerkt",
  "deleteIssueRecurrence": "Herhaling verwijderd",
  "adapt_update_to_recurrence": "Wil je deze wijziging ook toepassen op toekomstige herhalingen die automatisch aangemaakt worden?",
  "week_the": "week(en) op",
  "pdf_documents": "Document(en) PDF",
  "show": "Bekijk",
  "new_recurrence": "Nieuwe herhaling toegevoegd",
  "print_work_order_without_pdf": "Werkorder alleen",
  "print_work_order_with_pdf": "Werkorder en document(en) PDF",
  "addIssuePDF": "PDF toegevoegd",
  "addIssuePictureAndPDF": "Foto('s) en PDF('s) toegevoegd",
  "confirmation": "Bevestiging",
  "currentIssue.description": "currentIssue.beschrijving",
  "ail_object": "ail_object",
  "new_issue_submit_error": "Corrigeer de bovengenoemde fouten...",
  "delete_pdf_title": "Verwijder PDF",
  "delete_pdf_confirmation": "Weet je zeker dat je deze PDF wilt verwijderen?",
  "of_date": "van",
  "create_the": "Aangemaakt op",
  "end_by": "Eindigt op",
  "confirmation_delete_recurrence": "Weet je zeker dat je deze herhaling wilt verwijderen?",
  "this_issue": "Dit probleem",
  "localisation": "Locatie",
  "all_the": "Alle",
  "all_the_f": "Alle",
  "weeks_the": "weken op",
  "update_recurrence_template": "Dit probleem is herhalend. Wil je deze wijziging toepassen op toekomstige meldingen die automatisch aangemaakt worden via deze herhaling?",
  "yes": "Ja",
  "no": "Nee",
  "update_recurrence_title": "Herhaling aanpassen?",
  "createIssueByRecurrence": "Nieuwe melding (via herhaling)",
  "recurrence_issues": "Meldingen van deze herhaling",
  "recurrence_finished": "Reeks beëindigd",
  "recurrence_error_create_interval": "Aantal dagen onjuist",
  "recurrence_error_limit": "Aantal herhalingen onvoldoende",
  "disable_user_pro": "Deactiveren",
  "disable_user_pro_modal_title": "Deactiveren gebruiker",
  "reactive_user_pro": "Heractiveren",
  "disable_user_pro_confirmation": "De gebruiker <%= name %> zal gedeactiveerd worden. Hij/zij heeft geen toegang meer tot de applicatie en zal niet meer zichtbaar zijn.",
  "disable_user_pro_error_owner": "Deze gebruiker kan niet gedeactiveerd worden zolang hij/zij beheerder van een categorie is.",
  "disable_user_pro_error_owner_link": "Selecteer alstublieft een andere beheerder voor de betrokken categorieën door hier te klikken.",
  "show_owner_tags": "Beheerders bewerken",
  "for_example": "bijvoorbeeld",
  "by_email": "Per e-mail",
  "save_and_notif_user": "Opslaan en gebruiker per e-mail uitnodigen",
  "new_account_notification": "Uitnodiging nieuwe gebruiker",
  "send_user_activation": "Nodig gebruiker uit per e-mail",
  "help_me": "Neem contact op met de support",
  "help": "Hulp",
  "choose_password": "Kies je wachtwoord",
  "set_new_password": "Kies een nieuw wachtwoord",
  "active_account_email_title": "Welkom bij BetterStreet Admin",
  "active_account_email_intro_1": "Je ontvangt deze e-mail omdat er toegang tot BetterStreet aan jou is toegewezen",
  "active_account_email_intro_2": "BetterStreet is het nieuwe hulpmiddel voor het beheer van openbare ruimtes en gemeentelijke gebouwen. Het heeft als hoofddoel om alle taken, aanvragen en interventies van de dienst werken op één plaats te centraliseren, evenals een nieuwe manier van communiceren met de burgers.",
  "active_account_email_call_to_action": "Activeer je BetterStreet account door hieronder te klikken (je zult gevraagd worden een wachtwoord te kiezen om je toegang te beveiligen)",
  "active_account_email_btn": "Activeer je account nu door hier te klikken",
  "active_account_email_contact": "Heb je een vraag? Neem contact op met",
  "session_expired": "Je gebruikerssessie is verlopen. Gelieve opnieuw in te loggen.",
  "stats_created_issues": "Nieuw",
  "stats_resolved_issues": "Opgelost",
  "stats_time_to_resolve": "Tijd om op te lossen",
  "stats_not_resolved_since_50_days": "Niet opgelost > 2 maanden",
  "stats_issues_by_date": "Nieuwe interventies",
  "stats_status_pie": "Statusverdeling",
  "stats_espace_public_vs_building": "Openbare ruimte / Gebouw / Voorzieningen",
  "stats_espace_public_vs_building_short": "Openbare ruimte vs Gebouw",
  "stats_citizen_vs_internal": "Burgers vs Internen",
  "stats_tags_bar": "Openbare ruimte - Meest gebruikte categorieën",
  "stats_building_tags_bar": "Gebouwen - Meest gebruikte categorieën",
  "stats_citizens_bar": "Meest actieve burgers",
  "stats_buildings_bar": "Meest actieve gebouwen",
  "stats_web_vs_mobile": "Web vs Smartphone",
  "this_week": "Heden week",
  "last_week": "Vorige week",
  "this_month": "Heden maand",
  "last_month": "Vorige maand",
  "last_three_months": "Laatste 3 maanden",
  "last_six_months": "Laatste 6 maanden",
  "stats-dates": "Analyseperiode",
  "stats_no_data": "Geen gegevens",
  "pie_show_less": "Bekijk alle meldingen",
  "pie_show_more": "Bekijk alleen relevante meldingen",
  "all_time": "Gedurende de gehele periode",
  "monthly_reports": "Ontvang maandelijkse statistieken",
  "stats_email_call_actions": "Bekijk het volledige dashboard",
  "stats_email_subject": "Dashboard <%= maand %> <%= jaar %>: <%= aantal %> uitgevoerde interventies",
  "stats_email_title": "Dashboard <%= maand %> <%= jaar %>",
  "monthly_reports_table": "Maandstatistieken",
  "confirm_picture_rotation": "Wilt u de rotatie van de afbeelding opslaan?",
  "set_issue_cover": "Instellen als hoofdfoto",
  "rotate_picture": "Afbeelding roteren",
  "link_to_citizen_app": "Inloggen voor burgers",
  "incorrect_geolocation_datas": "De vereiste locatie-instellingen ontbreken.",
  "admin_resource_client": "Klant?",
  "admin_resource_building_management": "Gebouwen?",
  "user_pro_monthly_reports_explanation": "Ontvang maandrapporten per e-mail",
  "admin_resource_default_display": "Standaard publieke meldingen?",
  "admin_resource_private": "BetterStreet PRIVATE",
  "admin_resource_entity_public_issues": "Publieke meldingen",
  "admin_resource_default_display_explanation": "Publieke meldingen zichtbaar voor burgers standaard",
  "admin_resource_private_explanation": "Als JA, zijn burger meldingen niet toegestaan",
  "admin_resource_entity_public_issues_explanation": "Bepaalt het weergeven van meldingen, zowel eigen als geërfd van de gekoppelde gemeenten",
  "admin_resource_entity_public_issues_this": "Zelf",
  "admin_resource_entity_public_issues_slaves": "Slave",
  "admin_resource_entity_code": "Nummeringscode voor dossiers",
  "admin_resource_entity_code_explanation": "Code gebruikt voor nummering van meldingen",
  "admin_resource_entity_geolocation": "Geolocatie",
  "latitude": "Breedtegraad",
  "longitude": "Lengtegraad",
  "admin_resource_betterstreet_url": "Betterstreet URL",
  "admin_resource_betterstreet_url_explanation": "URL-extensie voor de specifieke pagina op Betterstreet",
  "admin_resource_web_url": "Externe web-URL",
  "admin_resource_web_url_explanation": "Externe URL voor link naar pagina van gekoppelde gemeenten",
  "admin_resource_entity_description_label": "E-mail prefix - Officiële naam van de entiteit of gemeente",
  "admin_resource_entity_description_label_explanation": "Prefix gebruikt in e-mails naar burgers, voor \"naam\". Ook gebruikt in bevestigingsberichten op mobiele apps, bijv. de stad of de gemeente",
  "admin_resource_entity_colors": "Kleuren",
  "admin_resource_entity_colors_explanation": "Kleuren voor het custom thema van de entiteit homepage",
  "admin_resource_entity_colors_background": "Achtergrondkleur",
  "admin_resource_entity_colors_border": "Randkleur",
  "admin_resource_entity_tag_line": "Tagline",
  "admin_resource_email_signature": "E-mail handtekening",
  "admin_resource_email_footer": "E-mail voettekst",
  "admin_resource_emails_options": "E-mailinhoud",
  "admin_resource_email_signature_explanation": "Specifieke handtekening voor e-mails naar buiten toe",
  "add_entity": "Entiteit toevoegen",
  "admin_resource_commune_entity_active": "Actieve gemeente binnen de entiteit",
  "admin_resource_commune_entity_active_explanation": "Actieve gemeente binnen de entiteit waaraan deze is gekoppeld (indien 'Slave')",
  "admin_resource_commune_description_label": "E-mailvoorvoegsel",
  "admin_resource_commune_description_label_explanation": "Voorvoegsel dat wordt gebruikt in e-mails naar burgers, voor \"naam\". Stad van ... of Gemeente van ... wordt ook gebruikt in berichten op de burgerapp",
  "admin_resource_email_footer_explanation": "Specifieke footer toegevoegd onder externe e-mails",
  "linked": "Gekoppeld",
  "active": "Actief",
  "form_title_tag_edit": "Bewerk categorie",
  "none": "Geen",
  "admin_resource_tag_link_show": "Toon gekoppelde categorie op gemeentelijk niveau",
  "admin_resource_private_message": "Aangepast bericht voor geblokkeerde burgers",
  "confirmation_issue_created_to_entity": "Melding toegevoegd aan de verantwoordelijke entiteit voor deze categorie",
  "confirmation_issue_created_to_entity_explanation": "De melding is automatisch verzonden naar de externe verantwoordelijke voor deze categorie en is daarom niet zichtbaar in de lijst van uw gemeente.",
  "admin_resource_commune_no_client_notification": "Niet-klantgemeente wordt op de hoogte gesteld bij nieuwe melding",
  "admin_resource_commune_no_client_notification_explanation": "Indien niet, ontvangt de gemeente geen melding bij een nieuwe melding.",
  "admin_resource_commune_freemium": "Freemium?",
  "admin_resource_commune_freemium_explanation": "Freemium klant met beperkte functionaliteiten",
  "no_nearby_issues": "Geen meldingen in de buurt...",
  "freemium_modal_calendar_title": "Teamplanningstool",
  "freemium_feature_not_available": "Deze functie is niet beschikbaar in de gratis versie van BetterStreet.",
  "freemium_modal_stats_title": "Statistische analysetool",
  "freemium_modal_building_title": "Beheer van gemeentelijke gebouwinterventies",
  "freemium_modal_map_title": "Geografische visualisatie van interventies",
  "freemium_modal_admin_title": "Beheer en personalisatie van de app-instellingen (gebruikers, categorieën, teams,...)",
  "freemium_modal_recurrence_title": "Beheer van terugkerende interventies",
  "freemium_modal_issues_list_print_title": "Afdrukken van de meldingenlijst",
  "freemium_modal_csv_title": "Exporteer de meldingenlijst naar CSV (Excel)",
  "freemium_modal_issue_print_title": "Afdrukken van de melding en werkorder",
  "freemium_modal_work_order_title": "Planning en afdrukken van werkorder",
  "freemium_modal_internal_message_title": "Versturen van interne berichten",
  "freemium_modal_transfer_title": "Doorsturen van melding naar derde partij",
  "email_reports_is_owner": "Alleen voor meldingen waarvoor u verantwoordelijke bent",
  "for": "voor",
  "create": "Maken",
  "deleteIssueDeadline": "Vervaldatum verwijderd",
  "updateIssueDeadline": "Vervaldatum gewijzigd",
  "issues_sort_created": "Nieuwste",
  "issues_sort_updated": "Bijgewerkt",
  "issues_sort_scheduled": "Op basis van vervaldatum",
  "smart_home_new_issues": "Nieuwste meldingen",
  "smart_home_deadline_this_week": "Vervalt deze week",
  "smart_home_planned_today": "Gepland voor vandaag",
  "smart_home_deadline_outdated": "Vervaldatums overschreden",
  "smart_home_last_activities": "Laatste waargenomen activiteiten (behalve uw eigen activiteiten)",
  "smart_home_settings": "Pas mijn startpagina aan",
  "smart_home_settings_my_issues": "Bekijk alleen de meldingen waarvoor ik verantwoordelijk ben",
  "smart_home_settings_owners_issues": "Bekijk de interventies van de volgende verantwoordelijken",
  "apply": "Toepassen",
  "print_work_orders": "Werkorders - Eén per pagina",
  "work_order_done": "Gereed",
  "work_order_started": "Gestart",
  "work_order_resolved_date": "Uitvoeringsdatum",
  "work_order_performer": "Uitgevoerd door",
  "work_order_time": "Tijd besteed",
  "hours": "Uren",
  "work_order_note": "Opmerkingen",
  "yesterday": "Gisteren",
  "public_space_and_building": "Publieke ruimte & Gebouwen",
  "freemium_modal_priority_title": "freemium_modal_priority_title",
  "freemium_modal_public_comment_title": "freemium_modal_public_comment_title",
  "printed_on": "Afgedrukt op",
  "printWorkOrder": "Werkorder afgedrukt",
  "smart_home_weather": "Weer",
  "scheduled_from": "Gepland vanaf",
  "creator": "Gemaakt door",
  "printGroupedWorkOrder": "Werkorder afgedrukt (gegroepeerd)",
  "add_quick_entry": "Snelle invoer toevoegen",
  "citizen_by_bs": "Burger via BetterStreet",
  "calendar_print_error_no_issues": "Oeps. Er zijn geen interventies om af te drukken.",
  "see_calendar": "Bekijk kalender",
  "updatePlanification": "Planning gewijzigd",
  "contactIssueRequestorAndFollowers": "Bericht verzonden naar",
  "contact_by_email": "Contact via e-mail",
  "followers": "Volgers",
  "follower": "Volger",
  "issue_contact_missing": "Oeps. U moet minstens één contactpersoon selecteren",
  "issueFollowed": "Nieuwe volger burger",
  "issueUnfollowed": "Volgen opgeheven door een burger",
  "nav_smart_home": "Startpagina",
  "print_work_order_select_user": "voor de volgende teams",
  "delete_issue_picture": "Verwijderen",
  "code": "Code",
  "addIssuePictureFromCitizen": "Foto toegevoegd door een burger",
  "entities": "Entiteiten",
  "district": "District",
  "admin_resource_entity_bounds": "admin_resource_entity_bounds",
  "bounds": "Grenzen",
  "add_company": "Voeg een bedrijf toe",
  "is_admin": "Is beheerder",
  "new_issue_slaves_attribution_failed_no_entity_found": "We konden de melding niet toewijzen aan een entiteit.",
  "new_issue_slaves_attribution_failed_multiple_entities_found": "We konden de melding niet toewijzen aan een entiteit.",
  "issue": "Melding",
  "admin_resource_commune_bounds": "admin_resource_commune_bounds",
  "pictures": "Foto's",
  "firstName": "Voornaam",
  "lastName": "Achternaam",
  "addIssueInternalNoteByTransfer": "Bericht afkomstig van een doorgestuurde melding",
  "add_email": "Voeg een e-mail toe",
  "send_me_a_copy": "Stuur me een kopie van de verzonden e-mail",
  "stats_access": "Toegang tot statistiekenmodule",
  "user_pro_stats_access_explanation": "Volg de statistieken",
  "are_you_government": "Bent u een gemeente",
  "commune_invite_1": "Wilt u deze informatie ontvangen op een ander e-mailadres?",
  "commune_invite_2": "Wilt u meer weten over de Premium versie van BetterStreet en een vrijblijvende demonstratie ontvangen?",
  "contact_us": "Neem contact met ons op",
  "error_district_attribution": "Oeps. Er is een fout opgetreden.",
  "error_district_attribution_explanation": "Om onbekende redenen konden we deze locatie niet toewijzen aan een bestaand district. Meld dit probleem alstublieft bij support@betterstreet.org",
  "admin_resource_entity_identifier": "admin_resource_entity_identifier",
  "admin_resource_entity_identifier_explanation": "admin_resource_entity_identifier_explanation",
  "upload_csv": "upload_csv_",
  "admin_title_buildings_upload_csv": "admin_title_buildings_upload_csv",
  "admin_input_upload_csv": "admin_input_upload_csv",
  "admin_upload_csv_info": "admin_upload_csv_info",
  "create_buildings_from_csv": "Maak de gebouwen",
  "building_csv_google_map_no_results": "building_csv_google_map_no_results",
  "building_csv_existing_building_name": "building_csv_existing_building_name",
  "attribution_engine_spw-districts": "attribution_engine_spw-districts",
  "admin_entity_is_matser": "Is deze entiteit een bovenliggende entiteit?",
  "login_error_user_not_found": "Het ingevoerde e-mailadres of wachtwoord is niet geldig",
  "updateIssueEtag": "Categorie gewijzigd",
  "login_error_incorrect_password": "Het ingevoerde e-mailadres of wachtwoord is niet geldig",
  "issue_details_title_history": "Geschiedenis",
  "issue_details_title_near": "In de buurt",
  "issue_details_title_requestor": "Aanvrager & volgers",
  "issue_details_title_planification": "Planning",
  "issue_details_add_note": "Schrijf een interne notitie",
  "issue_details_add_user_to_note": "Voeg ontvanger toe",
  "please_wait": "Geduld is inderdaad een deugd :)",
  "recurrence_no_limit": "Geen tijdslimiet",
  "first_occurence_created_at": "Eerste keer aangemaakt op",
  "recurrence_issues_already_created": "Deze melding(en) is/zijn al aangemaakt",
  "recurrence_next_issues": "Volgende melding(en)",
  "recurrence_last_three": "Laatste drie",
  "update_requestor": "Bewerk",
  "press_esc_to_exit": "Gebruik de ESC-toets om te sluiten",
  "duplicate": "Dupliceren",
  "issue_duplicate_explanation": "De locatie en eventuele foto's worden gedupliceerd",
  "issue_duplicate_link_new_issue": "Koppel de twee meldingen",
  "issue_duplicated_links": "Gekoppelde meldingen",
  "issue_duplicate_from": "Gedupliceerd vanaf",
  "issue_group_name_label": "Groepsnaam van meldingen",
  "issue_will_be_added_to_group": "De nieuwe melding wordt toegevoegd aan de groep",
  "unlink": "Loskoppelen",
  "link": "Koppelen",
  "of_the_group": "van de groep",
  "unlink_confirmation_1": "Weet u zeker dat u de melding wilt loskoppelen?",
  "this_issue_is_linked_to": "Deze melding is gekoppeld aan",
  "issueUnlinked": "Melding losgekoppeld",
  "issueDuplicated": "Melding gedupliceerd",
  "issueGrouped": "Nieuwe koppeling",
  "with": "met",
  "issueUngrouped": "Koppeling verwijderd tussen",
  "merge": "Samenvoegen",
  "merge_issue_explanation_1": "U gaat de melding samenvoegen",
  "will_be_added_to": "worden toegevoegd aan",
  "merge_issue_pictures": "De foto's en bestanden van",
  "will_be_added_as_follower_to": "zal worden toegevoegd als volger aan",
  "the_requestor": "De aanvrager",
  "issueMerged": "Melding samengevoegd met",
  "reopen": "Heropenen",
  "issue_detail_merged_close": "Sluiten",
  "issueReopened": "Melding heropend",
  "working_hours": "Werkuren",
  "form_add_new_man_to_team": "Voeg een medewerker toe aan het team",
  "morning": "Ochtend",
  "afternoon": "Middag",
  "external": "Extern",
  "admin_array_title_teams": "Teams",
  "add_man": "Voeg een medewerker toe",
  "add_team": "Voeg een team toe",
  "form_title_team_edit": "Bewerk team",
  "remove_user_from_team_confirmation_1": "Weet u zeker dat u wilt uitschakelen",
  "remove_user_from_team_confirmation_2": "uit het team",
  "remove_from_team": "Verwijderen uit team",
  "issuePlanned": "Toewijzing gewijzigd",
  "teams_and_agents": "Teams & Medewerkers",
  "add_leave": "Voeg afwezigheid toe",
  "add_leaves_to": "Voeg afwezigheid toe aan",
  "reason": "Reden",
  "all_the_day": "De hele dag",
  "leaves_period": "Periode",
  "teams": "Teams",
  "work_timetable": "Afwezig",
  "does_not_work_this_day": "werkt niet deze dag",
  "three_days": "3 dagen",
  "admin_nav_teams": "Teams & Medewerkers",
  "users": "Medewerkers",
  "assign_removed": "Toewijzing verwijderd",
  "assign_added": "Toewijzing toegevoegd",
  "first_name_and_last_name_already_used": "Er bestaat al een medewerker met dezelfde naam. U kunt geen medewerker toevoegen met dezelfde naam als een bestaande gebruiker.",
  "existing_patrimoiny_name": "Er bestaat al een apparatuur met deze naam.",
  "admin_array_title_patrimonies": "Apparatuur",
  "admin_nav_patrimonies": "Voertuigen & Apparatuur",
  "repair": "Reparatie",
  "maintenance": "Onderhoud",
  "nears_patrimony": "Voor dit apparaat",
  "issuePatrimoniesUpdated": "Apparatuur gewijzigd",
  "added": "Toegevoegd",
  "removed": "Verwijderd",
  "patrimony": "Apparatuur",
  "add_patrimony": "Voeg een uitrusting toe",
  "from_patrimony": "Kies een uitrusting",
  "patrimonies": "Uitrustingen",
  "forgot_password_error_unknow_email": "Onbekend e-mailadres of gebruikersnaam. Neem contact op met uw beheerder.",
  "update_issue_patrimony": "Kies een andere uitrusting",
  "updateIssuePatrimony": "Uitrusting gewijzigd",
  "session_error_expired": "Uw gebruikerssessie is verlopen. Log alstublieft opnieuw in.",
  "form_patrimony": "Kies een uitrusting",
  "form_title_patrimony_creation": "Creëer een nieuwe uitrusting",
  "select_transfer": "Voeg een overdracht toe",
  "select_team_or_agents": "Voeg een toewijzing toe",
  "edit_leave": "Bewerk verlof",
  "edit_leave_to": "Bewerk verlof van",
  "admin_resource_public_spaces_management": "Openbare ruimtes?",
  "admin_resource_patrimonies_management": "Uitrustingen?",
  "existing_work_timetable_leaves": "De agent werkt niet in deze periode.",
  "existing_work_timetable_leaves_validation_explanation": "Er kan geen verlof worden toegekend voor deze dagen.",
  "existing_leaves": "Er zijn al verlofdagen toegewezen voor deze periode.",
  "existing_leaves_validation_explanation": "Bewerk of verwijder de bestaande verlofdagen.",
  "remove_hashtag_from_issue_confirmation": "Weet u zeker dat u het label wilt verwijderen?",
  "remove_hashtag_from_issue_confirmation_2": "van het rapport",
  "removeIssueHashtag": "Label verwijderd",
  "remove_hashtag_from_issue": "Label verwijderen",
  "admin_resource_commune_starter_explanation": "De link van nieuwe rapporten in de e-mails die naar de gemeente worden gestuurd, verwijst naar de starterweergave (mogelijkheid om de status te wijzigen)",
  "admin_resource_commune_starter": "BetterStreet Starter",
  "issue_stater_access_not_authorized": "Toegang tot dit rapport is niet toegestaan",
  "starter_states_update": "Klik op een status om het rapport bij te werken en de aanvrager te informeren",
  "starter_states_update_explanation": "Informeer de aanvrager over de voortgang",
  "add_hashtag": "Label",
  "add_hashtag_to_issue": "Label",
  "add_a_hashtag_placeholder": "Voeg een label toe",
  "most_used_hashtags": "Meest gebruikte labels",
  "please_wait_csv_in_preparation": "CSV-bestand wordt voorbereid, wacht alstublieft",
  "it_can_take_up_to_a_few_minutes": "Dit kan enkele minuten duren",
  "learn_more": "Leer meer",
  "starter_discover_betterstreet": "Ontdek BetterStreet Expert, de oplossing voor het beheren van technische interventies",
  "copy_url_link": "Kopieer de link",
  "no_patrimonies_message": "Een uitrusting is een voertuig, een groot gereedschap of een ander apparaat dat nodig is voor het uitvoeren van een taak. Het beheer van uitrusting gebeurt via de beheerdersconsole.",
  "no_patrimonies_link_to_console": "Maak uw eerste uitrusting via de beheerdersconsole",
  "no_teams_link_to_console": "Maak uw eerste team via de beheerdersconsole",
  "no_teams_message": "Teams & Agents is handig om te bepalen aan wie een taak kan worden toegewezen. Een agent maakt altijd deel uit van een team.",
  "issue_starter_confirmation_state_updated": "Uw e-mail is succesvol verzonden",
  "addIssueHashtags": "Label(s) toegevoegd",
  "removeIssueHashtags": "Label(s) verwijderd",
  "man": "agent",
  "mans": "agenten",
  "please_wait_1": "Geduld is een deugd...",
  "please_wait_2": "De beste dingen kosten tijd...",
  "please_wait_3": "Geduld is een boom waarvan de wortels bitter zijn, maar waarvan de vruchten heel zoet zijn...",
  "please_wait_4": "Geduld is de sleutel tot welzijn.",
  "please_wait_5": "Dit moment van wachten wordt u aangeboden door het BetterStreet-team.",
  "add_existing_user": "Voeg een bestaand gebruiker toe",
  "invite": "Uitnodigen",
  "rgpd_your_issues_and_actions": "Uw rapporten en opmerkingen",
  "rgpd_your_personal_data": "Uw persoonlijke gegevens",
  "rgpd_registration_device": "Geregistreerd apparaat",
  "rgpd_registration_provider": "Oorsprong",
  "rgpd_issue_created_from": "Oorsprong",
  "my_issues_lists": "Mijn lijsten",
  "remove_issues_list_confirmation": "Weet u zeker dat u deze lijst wilt verwijderen?",
  "selected_filters": "Geselecteerde filters",
  "new_issues_lists": "Nieuwe lijst",
  "issues_lists_set_default": "Maak deze lijst mijn standaardlijst",
  "issues_list_existing_preset": "Deze filterconfiguratie bestaat al",
  "issues_lists_impossible_to_change_values_of_this_preset": "Het is niet mogelijk om de filterconfiguratie van deze lijst te wijzigen",
  "issues_list_missing_label": "Voeg alstublieft een naam toe",
  "issues_list_existing_label": "Deze naam wordt al gebruikt",
  "issues_list_no_filters_setted": "U moet eerst een lijst filteren voordat u een nieuwe lijst kunt opslaan.",
  "notifications_mark_all_as_read": "Markeer alles als gelezen",
  "notifications_settings": "Meldingsinstellingen",
  "notifications": "Meldingen",
  "settings": "Instellingen",
  "issue_details_title_internal_followers": "Interne volgers",
  "follow_this_issue": "Volg dit rapport",
  "issueInternalUnFollow": "Interne opvolging verwijderd",
  "issueInternalFollow": "Nieuwe interne opvolging",
  "unfollow_this_issue": "Stop met volgen",
  "CSV": "CSV",
  "issue_actions": "Acties",
  "notifications_settings_explanation": "U ontvangt een melding per e-mail of via een waarschuwing in BetterStreet wanneer een verandering plaatsvindt die voor u interessant kan zijn.",
  "notifications_settings_as_owner_title": "Voor interventies waarvoor ik verantwoordelijk ben",
  "notifications_settings_new_issue": "Wanneer een nieuw rapport wordt ingediend",
  "notifications_settings_citizen_message": "Nieuw bericht van een burger over een rapport",
  "notifications_settings_new_internal_comment": "Nieuw intern commentaar ingevoerd",
  "notifications_settings_update_issue_state": "Bij elke statuswijziging",
  "notifications_settings_update_issue": "Voor elke andere wijziging",
  "notifications_settings_as_follower_title": "Voor interventies die ik volg",
  "notifications_settings_global_title": "Voor elke interventie",
  "notifications_settings_internal_message": "Nieuw bericht gericht aan mij",
  "notification": "Melding",
  "notifications_settings_validation_select_one": "Selecteer ten minste één kanaal voor deze melding",
  "user_issue_settings_default_follow_as_creator": "Volg standaard de rapporten die ik maak",
  "notification_mark_as_read": "Markeer als gelezen",
  "followed": "Gevolgd",
  "not_followed": "Niet gevolgd",
  "notifications_empty_list": "Geen meldingen",
  "selected_agents": "Geselecteerde agenten",
  "work_orders": "Werkorders",
  "selected_issues": "Geselecteerde rapporten",
  "valorizations_options": "Waardeeropties",
  "default_agents_hourly_cost": "Standaard uurtarief inclusief btw per agent",
  "default_vat": "BTW-percentage",
  "hourly_cost": "Uurtarief in euro's incl. btw",
  "admin_resource_valorization": "Waardeermodule voor prestaties",
  "valorization_validation_no_selected_quantities": "Geen hoeveelheden toegewezen",
  "addIssueValorizaiton": "Waardeering toegevoegd",
  "updateIssueValorizaiton": "Waardeering gewijzigd",
  "deleteIssueValorizaiton": "Waardeering verwijderd",
  "new_valorization": "Nieuwe waardeering",
  "valorization_modal_subtitle": "(Alleen elementen waarvan de kosten zijn geregistreerd)",
  "add_valorization": "Waardeering",
  "delete_valorization_confirmation": "Weet u zeker dat u deze waardeering wilt verwijderen?",
  "quantity": "Hoeveelheid",
  "cost_by_unit": "Kosten per eenheid inclusief btw in euro's",
  "total_cost": "Totale kosten",
  "valorization": "Waardeering",
  "valorization_date": "Waardeeringsdatum",
  "supplies": "Voorzieningen",
  "addIssueSupply": "Voorziening toegevoegd",
  "supply": "Voorziening",
  "removeIssueSupply": "Voorziening verwijderd",
  "total": "Totaal",
  "add_supply_to_issue": "Voeg een voorziening toe",
  "external_reference": "Externe referentie",
  "valorization_unit": "Eenheid",
  "add_external_service_to_issue": "Voeg een externe dienst toe",
  "patrimonies_already_used_by": "Let op: dit uitrusting is al in gebruik voor deze datum voor de interventie",
  "click_here_to_select_files": "Klik hier om bestanden te kiezen",
  "transfer_auto_change_state": "Verander de status naar overgedragen",
  "services": "Externe diensten",
  "cost_excl_tax": "Kosten exclusief btw in euro's",
  "vat": "BTW-percentage",
  "new_supply": "Maak een voorziening",
  "no_supplies_match_filters": "Geen voorzieningen gevonden die aan de filters voldoen",
  "patrimony_valorization_type": "Meet eenheden",
  "patrimony_valorization_cost": "Kosten per eenheid inclusief btw in euro's",
  "valorization_option_hourly": "Uren",
  "valorization_option_km": "Kilometers",
  "issues_map_adapt_list": "Pas de lijst aan voor de zichtbare zone",
  "search_by_bs_id": "Zoeken op fiche nummer",
  "merge_search_issue_explanation": "Zoek de interventie om samen te voegen door het nummer in te voeren",
  "selector_error_select_at_least_one": "Geen interventies geselecteerd!",
  "teller_profile": "Balie",
  "link_issue": "Koppel twee interventies",
  "link_search_issue_explanation": "Zoek de interventie om te koppelen door het nummer in te voeren",
  "issues_link_already_in_same_group": "Deze interventies zijn al aan elkaar gekoppeld",
  "issues_link_already_in_other_group": "Al gekoppeld aan andere interventies",
  "update_issues_state_set_public": "Publiceer de rapporten (alleen voor openbare ruimte)",
  "building_external_link": "Externe kaartlink",
  "external_link": "Externe kaartlink",
  "update_issues_state_contact_requestors": "Waarschuw de aanvragers per e-mail/ melding",
  "link_issue_explanation_1": "U gaat de fiche koppelen",
  "new": "Nieuw",
  "planification": "Planning",
  "back_to_console": "Terug naar de beheerdersconsole",
  "selector_error_too_many_selected_issues": "U heeft te veel fiches geselecteerd. Acties kunnen worden gegroepeerd voor maximaal 50 fiches tegelijk.",
  "grouped_planification": "Groepsplanning",
  "csv_valorization_agents": "Kosten agenten",
  "csv_valorization_patrimonies": "Kosten uitrustingen",
  "csv_valorization_supplies": "Kosten voorzieningen",
  "csv_valorization_external_service": "Kosten externe dienst",
  "csv_valorization_total": "Totale kosten",
  "cost_tax": "Kosten inclusief btw in euro's",
  "stats_patrimony_tags_bar": "Voertuigen & Uitrustingen - Meest gebruikte categorieën",
  "hashtags": "Labels",
  "notifications_settings_reports_title": "Statistische rapporten",
  "optional": "Optioneel",
  "cost_not_defined": "Niet gedefinieerd",
  "valorization_validation_item_witout_cost": "Een waarderingsitem heeft geen kosten! Verwijder dit item of ken het kosten toe via de beheerdersconsole",
  "stats_valorizations_by_date": "Waardering per datum",
  "stats_valorizations_kinds_pie": "Waardering per type",
  "stats_valorizations_by_tags": "Waardering per categorie",
  "cost": "Kosten",
  "valorizations_options_apply_to_teams": "Toepassen overal",
  "valorizations_options_apply_to_teams_explanation": "U gaat de kosten bijwerken voor al uw teams en agenten",
  "valorizations_options_apply_to_teams_erase_existant": "Overschrijf ook bestaande waarden",
  "error_404_issue_not_found": "Oops. Rapport niet gevonden of toegang geweigerd.",
  "update_picture": "Wijzig de foto",
  "reset": "Reset",
  "cancel_changes": "Annuleer wijzigingen",
  "add_first_name": "Voeg voornaam toe",
  "add_last_name": "Voeg achternaam toe",
  "user_profile_settings": "Mijn profiel",
  "user_identifier_type": "E-mail of Gebruikersnaam",
  "access_to_bs": "Toegang tot BetterStreet",
  "nickname": "Gebruikersnaam",
  "received_the_invitation": "ontving de uitnodiging per e-mail",
  "disabled_user_pro": "Deactiveerde gebruiker",
  "email_or_nickname": "E-mail of gebruikersnaam",
  "form_validation_nickname": "Ongeldige gebruikersnaam (minimaal 5 alfanumerieke tekens)",
  "agent_profile": "Agent",
  "drag_one_picture": "Sleep hier een foto of pdf",
  "activate_your_userpro_account": "Activeer uw BetterStreet-account",
  "finished": "Voltooid",
  "ongoing": "Bezig",
  "to_do": "Te doen",
  "workorder_update_state_title": "Wijzig de status van het werkorder",
  "updateIssueWorkorderState": "Status van het werkorder gewijzigd",
  "work_orders_outdated_but_not_closed": "Te laat / Niet voltooid",
  "confirmation_issue_created_by_agent_explanation": "",
  "confirmation_issue_created_by_agent": "Nieuw rapport succesvol opgeslagen",
  "workOrder_update_state_title": "Bevestiging van statuswijziging werkorder",
  "agent_displayed_days": "#dagen planning zichtbaar",
  "no_access": "Geen toegang",
  "disbaled": "Deactiveerd",
  "password_modified_for": "Wachtwoord gewijzigd voor",
  "password_saved": "Wachtwoord opgeslagen. Laat het de gebruiker weten.",
  "display_password": "Toon het wachtwoord",
  "default_agents_displayed_days": "Aantal dagen zichtbaar op het planning voor een agent",
  "agents_resolved_issues_on_finish": "Automatisch een werkorder als opgelost markeren wanneer een agent het afsluit",
  "agents_display_issues_assigned_to_team": "Werkorders die niet individueel toegewezen zijn, zijn zichtbaar voor alle agents van het team",
  "agents_options": "Werkorderinstellingen",
  "disabled": "Uitgeschakeld",
  "reset_form_modal_title": "Bevestiging",
  "reset_form_modal_message": "Weet u het zeker?",
  "confirm": "Bevestigen",
  "stats_patrimonies_bar": "Meest actieve apparatuur",
  "form_validation_invalid_link": "",
  "period_allday": "Van %start% tot %end%",
  "period_sameday_allday": "Op %date%",
  "period_sameday_withhour": "Op %date% van %start% tot %end%",
  "period_withhour": "Van %start_date% om %start_hour% tot %end_date% om %end_hour%",
  "warningAbsenceModal_plannedOnIndisponibility": "Planning tijdens onbeschikbaarheid",
  "warningAbsenceModal_questionIndisponibilityOnPlanned": "De agent %username% is niet beschikbaar voor deze periode. Wilt u de interventie toch plannen?",
  "copy_calendar_link": "Kopieer de Ical-link om mijn agenda in te stellen",
  "equipments": "Apparatuur",
  "select_supply_create": " + Creëren",
  "select_supply_search": "Zoeken...",
  "calendar_link_copied_successful": "De link is succesvol gekopieerd. Voor meer informatie over de implementatie, raadpleeg de",
  "calendar_link_documentation": "documentatie",
  "planning_calendar_link_copied_successful": "De link is succesvol gekopieerd. Voor meer informatie over de implementatie, raadpleeg de documentatie.",
  "permanent_delete": "Definitieve verwijdering" 
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-form-mapLayer-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  color: rgba(34, 34, 34, 1);
}

.bs-form-mapLayer-container .bs-mapLayer-form {
  flex-grow: 1;
  padding: 20px;
}

.bs-form-mapLayer-container .bs-mapLayer-button {
  background-color: #f5f5f5;
  padding:10px 10px;
  border-top: 1px solid #dddddd;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/entities/mapLayers/components/form.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,6BAA6B;EAC7B,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".bs-form-mapLayer-container{\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  color: rgba(34, 34, 34, 1);\n}\n\n.bs-form-mapLayer-container .bs-mapLayer-form {\n  flex-grow: 1;\n  padding: 20px;\n}\n\n.bs-form-mapLayer-container .bs-mapLayer-button {\n  background-color: #f5f5f5;\n  padding:10px 10px;\n  border-top: 1px solid #dddddd;\n  display: flex;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

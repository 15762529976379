import React, { ComponentType, FunctionComponent, PropsWithChildren } from 'react';
import State, { SelectPropsType } from '@cFeatures/state';
import StatesConfiguration, { type States } from '@universal/types/business/assignment/State';
import StateType from '@uTypes/business/State';
import Display from '@common/components/displayIf';
import Modal from '@common/components/modal';
import { Assignment } from '@universal/types/business/Issue';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import ObjectId from '@universal/types/technic/ObjectId';
import Form from '@universal/behaviour/form';
import Input from '@common/components/input';
import { FormValorizationBody, assignmentToStatements } from '@entities/valorizations';
import Valorization from '@universal/types/business/Valorization';
import useService from '@universal/behaviour/hooks/useService';
import RepositoryService from '@universal/services/repository';
import ScrollBar from '@common/components/scrollBar/scrollBar';
import Section from '@cComponents/section';
import T from '@universal/behaviour/i18n';
import Button from '@common/components/button';
import ApiService from '@universal/services/api';
import FormErrors from '@uTypes/technic/FormErrors';
import CError from '@common/components/error';

import './state.css';

interface StateProps {
  state: States;
}

const Tiny: FunctionComponent<StateProps> = ({ state }) => {
  const stateConfiguration = React.useMemo(() => StatesConfiguration[state], [state]);
  return (
    <State.Tiny state={ stateConfiguration } />
  );
}

const Short: FunctionComponent<StateProps> = ({ state }) => {
  const stateConfiguration = React.useMemo(() => StatesConfiguration[state], [state]);
  return (
    <State.Short state={ stateConfiguration } />
  );
}

const Standart: FunctionComponent<PropsWithChildren<StateProps>> = ({ state, children }) => {
  const stateConfiguration = React.useMemo(() => StatesConfiguration[state], [state]);
  return (
    <State.Standart state={ stateConfiguration }>
    { children }
    </State.Standart>
  );
}

interface SelectProps {
  value: States;
  onChange: (value: States) => void;
}

const createSelect: (SelectComponent: ComponentType<PropsWithChildren<SelectPropsType>>) => FunctionComponent<SelectProps> = (SelectComponent) => {
  const Select: FunctionComponent<SelectProps> = ({ value, onChange }) => {
    const states = React.useMemo(() => Object.values(StatesConfiguration), []);
    const state = React.useMemo(() => StatesConfiguration[value], [value]);
    const onChangeHandler = React.useCallback((state: StateType) => {
      onChange(state.label as States);
    }, [onChange]);
  
    return (
      <SelectComponent value={ state } onChange={ onChangeHandler } states={ states } />
    );
  }
  return Select;
}

const Compact = createSelect(State.Compact);
const Select = createSelect(State.Select);

interface FormValorizeProps{
  tenant: ObjectId;
  value: Valorization;
  errors: FormErrors,
  onChange: (value: Valorization) => void;
}

const FormValorize : FunctionComponent<FormValorizeProps> = ({ errors, tenant, value, onChange }) => {
  const sendChange = React.useCallback((form, object: Valorization) => {
    onChange(object);
    return object;
  }, [onChange]);
  return (
    <Form.Simple onChange={ sendChange } value={ value } hasDependency>
      <FormValorizationBody tenant={ tenant } value={ value } errors={ errors } withoutScrollbar />
    </Form.Simple>
  );
};

type ValorizationMinimified = Pick<Valorization, 'issue' | 'assignment' | 'statementOf' | 'statements'>;

interface StateCommentValorization {
  progress: States;
  assignment: ObjectId;
  issue: ObjectId;
  tenant: ObjectId;
  comment: string;
  files: ObjectId[];
  valorization: ValorizationMinimified | null;
}

interface CommentAndValorizeProps {
  value: StateCommentValorization;
  close?: () => void;
}

const formValidator = {
  rules: [
    (value) => value.valorization && (!value.valorization.statements.length || !value.valorization.statements.some(s => s.quantity > 0 || s.duration > 0)) ? { error:"valorization_form_no_statement_error" } : null
  ]
}

const ButtonNegative = Button.withStyle(Button.Stylized.negative);

const CommentAndValorize: FunctionComponent<CommentAndValorizeProps> = ({ value, close = () => {} }) => {
  const api = useService<ApiService>('api');
  const submitAndClose = React.useCallback((form, value: StateCommentValorization) => {
    api.service('issues', 'setAssignmentProgress').execute(value.issue, value.assignment, value.progress, value.comment, value.files, value.valorization)
      .then(() => {
        close();
      });
  }, [api, close]);
  return (
    <Input.File.DropArea>
      <Input.File.DropArea.DisplayOnDrag>
        <Form.Simple value={ value } submit={ submitAndClose } validator={formValidator}>
        {(ctx, value, errors, form, submit) => (
          <div  className='bs-assignment-state-commentAndValorize'>
            <Section.Header>
              <Section.Header.Text><T>assignments_states_commentAndValorize_title</T></Section.Header.Text>
            </Section.Header>
            <div className='bs-assignment-state-commentAndValorize-body'>
              <ScrollBar>
                <Section.Title>
                  <Display.If condition={ !!value.valorization }>
                    <Section.Title.Text.SubTitle>&nbsp;</Section.Title.Text.SubTitle>
                  </Display.If>
                  <Section.Title.Text><T>assignments_states_commentAndValorize_file_title</T></Section.Title.Text>
                </Section.Title>
                <Form.Simple.InputAdapter name="files" multiple>
                {(values, add, drop) =>  (
                  <Input.File.Gallery value={ values } onChange={(values, value, isAdded) => isAdded ? add(value) : drop(value) } column={ 3 } isAllowedToDelete />
                )}
                </Form.Simple.InputAdapter>
              </ScrollBar>
              <div>
                <ScrollBar viewPortClassName='bs-assignment-state-commentAndValorize-body-content'>
                  <Display.If condition={ !!value.valorization }>
                    <Section.Title>
                      <Section.Title.Text><T>assignments_states_commentAndValorize_valorization_title</T></Section.Title.Text>
                      <Section.Title.Text.SubTitle><T>assignments_states_commentAndValorize_valorization_subtitle</T></Section.Title.Text.SubTitle>
                    </Section.Title>
                    <Form.Simple.InputAdapter name='valorization'>
                    {(value, set, clear) => (
                      <FormValorize tenant={ value.tenant } value={ value } onChange={ set } errors={ errors }/>
                    )}
                    </Form.Simple.InputAdapter>
                  </Display.If>
                  <Section.Title>
                    <Section.Title.Text><T>assignments_states_commentAndValorize_comment</T></Section.Title.Text>
                  </Section.Title>
                  <Form.Simple.InputAdapter name='comment'>
                  {(value, set, clear) => (
                    <Input.TextArea value={ value } onChange={ set } />
                  )}
                  </Form.Simple.InputAdapter>
                </ScrollBar>
              </div>
            </div>
            <CError errors={ errors.global } />
            <Section.Footer className='bs-assignment-state-commentAndValorize-footer'>
              <ButtonNegative onClick={ close }><T>assignments_states_commentAndValorize_cancel</T></ButtonNegative>
              <Button.Text onClick={ () => submit(true) }><T>assignments_states_commentAndValorize_save</T></Button.Text>
            </Section.Footer>
          </div>
        )}
        </Form.Simple>
      </Input.File.DropArea.DisplayOnDrag>
    </Input.File.DropArea>
  );
};

interface SelectCommentAndValorizeProps {
  assignment: BusinessEntity<Assignment>;
  mustBeValorized: boolean;
  issue: ObjectId;
  tenant: ObjectId;
}


const createSelectCommentAndValorize: (SelectComponent: ComponentType<SelectProps>) => FunctionComponent<SelectCommentAndValorizeProps> = (SelectComponent) => {
  const Select: FunctionComponent<SelectCommentAndValorizeProps> = ({ assignment, mustBeValorized, issue, tenant }) => {
    const [value, setValue] = React.useState<StateCommentValorization | null>(null);

    const repositoryService = useService<RepositoryService>("repository");

    const storeProgressAndOpenCommentValorize = React.useCallback((state: States) => {
      let p: Promise<ValorizationMinimified | null> | null  = null;
      if(state === 'finished' && mustBeValorized){
        p = assignmentToStatements(assignment.toPlainText ? assignment.toPlainText() : assignment, repositoryService).then(statements => {
          const valorization: ValorizationMinimified = {
            assignment: assignment._id,
            issue,
            statementOf: new Date(),
            statements
          };
          return valorization;
        });
      }else {
        p = Promise.resolve(null);
      }
      p.then(valorization => {
        setValue({
          assignment: assignment._id,
          issue,
          tenant: tenant,
          comment: "",
          progress: state,
          files: [],
          valorization: valorization
        });
      });
    }, [setValue, issue, assignment, repositoryService, mustBeValorized]);

    const resetValue = React.useCallback(() => setValue(null), [setValue]);

    return (
      <>
        <SelectComponent value={ assignment.progress } onChange={ storeProgressAndOpenCommentValorize } />
        <Display.If condition={ value }>
          <Modal.Show close={ resetValue } style={{ height: '90vh', width: '80vw '}}>
            <CommentAndValorize value={ value } />
          </Modal.Show>
        </Display.If>
      </>
    )
  };
  return Select;
}

const StateComponent = {
  SelectCommentAndValorize: createSelectCommentAndValorize(Select),
  SelectCompactCommentAndValorize: createSelectCommentAndValorize(Compact),
  Compact,
  Select,
  Standart,
  Short,
  Tiny
};

export default StateComponent;
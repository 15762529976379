import Item     from './components/item';
import Form     from './components/form';
import Filters  from './components/filters';
import Detail  from './components/detail';

export default {
    Item,
    Form,
    Filters,
    Detail
}
import { TestBuilder } from "../library/test";
import Application from "@universal/lib/application";
import LoginAsManagerService from "@universal/services/loginAsSessionManager";
import ObjectId from "@universal/types/technic/ObjectId";

type StepCreation = { appTest: Application, userCommercialId: ObjectId };

export default (createApplication: () => Promise<StepCreation>) => {
  return TestBuilder.createTest("JVSCommercial", "Connexion à l'utilisateur technique")
    .addStep("Préparation de l'application", createApplication)
    .addStep("Connexion à l'utilisateur", async ({ appTest, userCommercialId }: StepCreation) => {
      await appTest.getService<LoginAsManagerService>("loginAsSessionManager").login(userCommercialId);
      await appTest.getService<LoginAsManagerService>("loginAsSessionManager").backToSuperAdmin();
      return {};
    })
    .build();
};
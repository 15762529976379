import React, { PureComponent } from 'react';
import T from 'i18n-react';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import classNames from 'classnames';
import { Link }  from '@cFeatures/router';

import { isAuthorized } from 'client/features/issues/lib';
import DropDown from 'client/features/common/components/DropDown.jsx';
import AuthorizationsWrapper from 'client/features/issues/components/common/AuthorizationsWrapper.jsx';
import IssueUpdatesWrapper from 'client/features/issues/containers/UpdatesWrapper.jsx';

import Recurrence from "@root/entities/recurrences";
import Modal from "@cComponents/modal";
import _ from "lodash";
import Application  from '@uBehaviour/application';
import LinkModal from 'client/features/issues/components/modals/Link.jsx'
import MergeModal from 'client/features/issues/components/modals/Merge.jsx'

const FormModal = ({ recurrence, close }) => {
  return (
    <Modal.Show style={{ width: "80vw", height: "80vh"}} close={close}>
    { close => (
      <Recurrence.Form recurrence={ recurrence } close={ close }/>
    )}
    </Modal.Show>
  )
}

class Actions extends PureComponent {
    state = {
      showRecurrenceModal: false,
      showLinkModal: false,
      showMergeModal: false
    }


    displayLinkModal = () => {
      this.setState({ showLinkModal: true });
    }
    hideLinkModal = () => {
      this.setState({ showLinkModal: false });
    }


    displayMergeModal = () => {
      this.setState({ showMergeModal: true });
    }
    hideMergeModal = () => {
      this.setState({ showMergeModal: false });
    }
    
    getRecurrence = (issue) => {
      const defaultRecurrence = this.props.repository.get("Recurrence").default;
      const issueTemplate = _.pick(issue, ["location", "equipment", "description", "files", "category", "requestor"]);

      defaultRecurrence.template = {
        ...defaultRecurrence.template,
        ...issueTemplate,
      };

      return defaultRecurrence;
    };

    render() {
        const {
            issue,
            follow,
            unFollow,
            displayDuplicateModal,
            printWorkOrderOne,
            printWorkOrderMulti,
            displayHashtagModal,
            archive
        } = this.props;

        return (
            <div className='o-flex-layout o-flex-layout--flex-end u-padding-right-small u-padding-top-small u-hide-for-print'>
                {
                    isAuthorized(issue, 'follow') &&
                    <div className='o-flex-layout__item u-margin-right-tiny'>
                        <a className='c-button u-small c-button--ghost-action' onClick={ follow } title={ T.translate('follow_this_issue') }>
                            <FontAwesome name='star-o' />&nbsp;
                            { T.translate('follow_this_issue') }
                        </a>
                    </div> 
                }
                {
                    isAuthorized(issue, 'unFollow') &&
                    <div className='o-flex-layout__item u-margin-right-tiny'>
                        <a className='c-button u-small c-button--ghost-action' onClick={ unFollow } title={ T.translate('unfollow_this_issue') }>
                            <FontAwesome name='star' />&nbsp;
                            { T.translate('followed') }
                        </a>
                    </div> 
                }
                { 
                    isAuthorized(issue, 'duplicate') &&
                    <div className='o-flex-layout__item u-margin-right-tiny'>
                        <a className='c-button u-small c-button--ghost-action' onClick={ displayDuplicateModal }>
                            <FontAwesome name='clone' />&nbsp;
                            { T.translate('duplicate') }
                        </a>
                    </div> 
                }
                <AuthorizationsWrapper
                    fields={ issue.fields }
                    authorizations={ {
                        archive: 'update',
                        hashtags: 'update',
                        print: 'read'
                    } }
                >
                    <div className='o-flex-layout__item'>
                        <DropDown
                            large
                            renderToggle={ (opened) => (
                                <a className={ classNames('c-button u-small c-button--ghost-action', { 'c-button--active': opened }) }>
                                    { /*<FontAwesome name='print' />&nbsp;*/ }
                                    <FontAwesome name='ellipsis-h' className='u-padding-horizontal-small u-font-size-large'/>
                                </a>
                            ) }
                            renderBody={ (close) => (
                                <div className='o-dropdown__menu'>
                                    <AuthorizationsWrapper
                                        fields={ issue.fields }
                                        authorizations={ {
                                            archive: 'update'
                                        } }
                                    >
                                        <a className='o-dropdown__item' onClick={ archive }>
                                            <FontAwesome name='trash-o' />&nbsp;
                                            { T.translate('permanent_delete') }
                                        </a>
                                    </AuthorizationsWrapper>
                                    <AuthorizationsWrapper
                                        fields={ issue.fields }
                                        authorizations={ {
                                            hashtags: 'update'
                                        } }
                                    >
                                        <a className='o-dropdown__item' onClick={ displayHashtagModal }>
                                            <FontAwesome name='hashtag' />&nbsp;
                                            <T.span text='add_hashtag' className='action-sort' />
                                        </a>
                                    </AuthorizationsWrapper>
                                    <AuthorizationsWrapper
                                        fields={ issue.fields }
                                        authorizations={ {
                                            merge: 'update'
                                        } }
                                    >
                                        <a className='o-dropdown__item' onClick={ this.displayMergeModal }>
                                            <FontAwesome name='compress' />&nbsp;
                                            <T.span text='merge' className='action-sort' />
                                        </a>
                                    </AuthorizationsWrapper>
                                    <AuthorizationsWrapper
                                        fields={ issue.fields }
                                        authorizations={ {
                                            duplicate: 'update'
                                        } }
                                    >
                                        <a className='o-dropdown__item' onClick={ this.displayLinkModal }>
                                            <FontAwesome name='link' />&nbsp;
                                            <T.span text='link' className='action-sort' />
                                        </a>
                                        {
                                          !issue.recurrence
                                          ? (
                                            <a className='o-dropdown__item' onClick={ () => this.setState({ showRecurrenceModal: true }) }>
                                                <FontAwesome name='clock-o' />&nbsp;
                                                <T.span text='create_recurrence' className='action-sort' />
                                            </a>
                                          )
                                          : (
                                            <Link to={`/recurrences/${ issue.recurrence._id}`} fromRoot={ true }>
                                              <a className='o-dropdown__item'>
                                                  <FontAwesome name='clock-o' />&nbsp;
                                                  <T.span text='goTo_recurrence' className='action-sort' />
                                              </a>
                                            </Link>
                                          )
                                        }
                                    </AuthorizationsWrapper>
                                    <AuthorizationsWrapper
                                        fields={ issue.fields }
                                        authorizations={ {
                                            print: 'read'
                                        } }
                                    >
                                        <div>
                                            <a className='o-dropdown__item' onClick={ printWorkOrderMulti }>
                                                <FontAwesome name='print' />&nbsp;
                                                <T.span text='print_work_order_multi' className='action-sort' />
                                            </a>
                                            <a className='o-dropdown__item' onClick={ printWorkOrderOne }>
                                                <FontAwesome name='print' />&nbsp;
                                                <T.span text='print_work_order_one' className='action-sort' />
                                            </a>
                                            <a className='o-dropdown__item' onClick={ () => {
                                                document.title = `BS_${moment().format("YYYYMMDD")}_${this.props.i18n.translate("print_issue_title")}_${issue.bsId}`
                                                window.print();
                                                document.title = "BetterStreet Admin";
                                            }}>
                                                <FontAwesome name='print' />&nbsp;
                                                <T.span text='print_all' className='action-sort'/>
                                            </a>
                                        </div>
                                    </AuthorizationsWrapper>
                                </div>
                            ) }
                        />
                    </div>
                </AuthorizationsWrapper>
                {
                  this.state.showRecurrenceModal
                  ? <FormModal recurrence={ this.getRecurrence(issue.original) } close={() => this.setState({ showRecurrenceModal: false })} />
                  : null
                }

                {
                  this.state.showLinkModal &&
                  <LinkModal
                    show={ this.state.showLinkModal }
                    hide={ this.hideLinkModal }
                    link={ isAuthorized(issue, 'duplicate') ? this.link : null }
                  />
                }

                {
                  this.state.showMergeModal &&
                  <MergeModal
                    show={ this.state.showMergeModal }
                    hide={ this.hideMergeModal }
                    merge={ isAuthorized(issue, 'merge') ? this.merge : null }
                  />
                }
            </div>
        )
    }
}

export default Application.Service.forward(["i18n", "repository"],IssueUpdatesWrapper(Actions));
import React        from "react";
import _            from "lodash";
import Data         from "@uBehaviour/data";
import Button       from '@cComponents/button';
import Team         from "@root/entities/teams";
import T            from "@cBehaviour/i18n";
import Filter       from "@cComponents/filter";
import FilteredList from "@cFeatures/filter/list";
import Input        from "@cComponents/input";
import CurrentTenantService from "@uServices/currentTenant";
import useService from "@uBehaviour/hooks/useService";
import { withLink, withModalLinked } from "@cHoc/withLinkAndModalLinked";
import TeamForm from './form';
import { Link } from "@cFeatures/router";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar from "@cComponents/searchbar";
import { escapeRegExp } from "@uLib/tool";

const query = (currentTenant: CurrentTenantService) => {
  let query = currentTenant.currentId ? { tenant: currentTenant.currentId }: undefined;
  return query;
}

const textSearchBuildQuery = (value: string) => {
  return { "name": { '$regex': escapeRegExp(value), '$options': 'i' } };
}

const defaultQuery = () => {
  return [{ name: "disabled", value: false }];
}

const LinkedTeamItem = withLink(Team.Item);
const ModalizedTeamForm = withModalLinked(TeamForm);

const TeamList = ({}) => {
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  return (
    <>
      <FilteredList default={ defaultQuery() }>
        <FilteredList.Filter>
          <Team.Filters />
        </FilteredList.Filter>
        <FilteredList.Header>
          <ListHeaderLayout>
            <ListHeaderLayout.Center>
              <Filter.Generic name="text" buildQuery={value => textSearchBuildQuery(value) } stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                {(value, set, clear) => (
                  <Searchbar>
                    <Input.Text focusOnMount className="bs-filter-text" value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                      <T>freesearch</T>
                    </Input.Text>
                  </Searchbar>
                )}
              </Filter.Generic>
            </ListHeaderLayout.Center>
            <ListHeaderLayout.Right>
              <Link to="/new">
                <Button.Stylized.Text>
                  <T>add</T>
                </Button.Stylized.Text>
              </Link>
            </ListHeaderLayout.Right>
          </ListHeaderLayout>
        </FilteredList.Header>
        <FilteredList.List>
        {composeQuery => (
          <Data.List model={"Team"} query={composeQuery(query(currentTenant))} load={{members: true, avatar: true}} sort={{ name: 1}}>
            <LinkedTeamItem />
          </Data.List>
        )}
        </FilteredList.List>
      </FilteredList>
      <ModalizedTeamForm style={{ width: "80vw", height: "90vh" }} />
    </>
  );
}

export default TeamList;
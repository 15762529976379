// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-buildingForm {
  display: flex;
}

.bs-buildingForm .bs-buildingForm-picture {
  padding: 0 15px;
}

.bs-buildingForm .bs-buildingForm-content {
  flex-grow: 1;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.bs-buildingForm .bs-buildingForm-content .bs-linkBuildings-table {
  width: 100%;
}

.bs-buildingForm .bs-buildingForm-content .bs-linkBuildings-table th{
  text-align: left;
  font-size: 0.9em;
  color: #2c3e50; 
}`, "",{"version":3,"sources":["webpack://./src/entities/buildings/components/form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".bs-buildingForm {\n  display: flex;\n}\n\n.bs-buildingForm .bs-buildingForm-picture {\n  padding: 0 15px;\n}\n\n.bs-buildingForm .bs-buildingForm-content {\n  flex-grow: 1;\n  padding: 0 15px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n.bs-buildingForm .bs-buildingForm-content .bs-linkBuildings-table {\n  width: 100%;\n}\n\n.bs-buildingForm .bs-buildingForm-content .bs-linkBuildings-table th{\n  text-align: left;\n  font-size: 0.9em;\n  color: #2c3e50; \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

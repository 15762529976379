// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-containers-tag {
  border: 1px solid #cecbc8;
  color: #5e676c;
  border-radius: 3px;
  padding: 2px 6px;
}`, "",{"version":3,"sources":["webpack://./src/entities/issuesAndAssignments/components/tag.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".bs-containers-tag {\n  border: 1px solid #cecbc8;\n  color: #5e676c;\n  border-radius: 3px;\n  padding: 2px 6px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { FunctionComponent, PropsWithChildren } from 'react';

import './base.css';
import withMouseEventStopped from '@cHoc/withMouseEventStopped';
import classNames from '@universal/lib/classNames';

export interface ButtonProps {
  disabled?: boolean;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onClick?: () => void;
  onDoubleClick?: () => void;
  className?: string;
}

export const Button: FunctionComponent<PropsWithChildren<ButtonProps>> = ({ disabled = false, onClick, onDoubleClick, onMouseDown, onMouseUp, children, className }) => (
  <button className={ classNames("bs-button-base").addNotEmpty(className) }
    onMouseDown = { onMouseUp } 
    onMouseUp = { onMouseDown }
    onClick={ onClick }
    onDoubleClick={ onDoubleClick }
  >
    { children }
  </button>
);

export default withMouseEventStopped(Button);
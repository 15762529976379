// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tenant-form-holidayDays-generic-dayAndMonth {
  display: flex;
}
.bs-tenant-form-holidayDays-generic-dayAndMonth>* {
  flex-grow: 1;
}
.bs-tenant-form-holidayDays-generic-dayAndMonth>*:nth-child(n + 2){
  margin-left: 5px;
}
.bs-tenant-form-holidayDays-generic-dayAndMonth>*:nth-last-child(n + 2){
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/entities/tenants/components/form/holidayDays/generics.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-tenant-form-holidayDays-generic-dayAndMonth {\n  display: flex;\n}\n.bs-tenant-form-holidayDays-generic-dayAndMonth>* {\n  flex-grow: 1;\n}\n.bs-tenant-form-holidayDays-generic-dayAndMonth>*:nth-child(n + 2){\n  margin-left: 5px;\n}\n.bs-tenant-form-holidayDays-generic-dayAndMonth>*:nth-last-child(n + 2){\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

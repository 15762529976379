// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issue-mapMarker-tooltip{
  width: 300px;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/map/tooltip.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":[".bs-issue-mapMarker-tooltip{\n  width: 300px;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

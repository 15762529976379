

class BaseEvent<Listener extends { handleEvent: Function }> {
  protected _listeners: Array<Listener>;

  constructor() {
    this._listeners = [];
  }

  hasListener(listener: Listener){
    return this._listeners.indexOf(listener) !== -1;
  }

  addListener(listener: Listener) {
    if (!listener || !listener.handleEvent) {
      throw new Error("Listener must have a handleEvent method");
    }
    if (this.hasListener(listener)) 
      return listener;
    
    this._listeners.push(listener);
    return listener;
  }

  removeListener(listener: Listener) {
    const idx = this._listeners.indexOf(listener);
    if (idx === -1) 
      return listener;
    
    this._listeners.splice(idx, 1);
    return listener;
  }

  clear(){
    this._listeners = [];
  }
}

interface IListener<AgumentsType extends Array<any>> {
  handleEvent: (...args: AgumentsType) => void;
}

export class Listener <AgumentsType extends Array<any> = any[], Context = any> implements IListener<AgumentsType> {
  private _handler: (this: Context, ...args: AgumentsType) => void;

  private _context: Context | undefined;

  constructor(handler: ((this: Context, ...args: AgumentsType) => void) & ThisType<Context>, context?: Context) {
    this._handler = handler;
    this._context = context;
  }

  handleEvent(...args: AgumentsType): void {
    this._handler.apply(this._context as Context, args);
  }
}

export class Event<AgumentsType extends Array<any> = any[]> extends BaseEvent<IListener<AgumentsType>> {
  trigger(...args: AgumentsType): void {
    this._listeners.slice().forEach(listener => {
      listener.handleEvent(...args);
    });
  }
}

interface IAsyncListener<AgumentsType extends Array<any>> {
  handleEvent: (...args: AgumentsType) => Promise<void>;
}

export class AsyncListener<AgumentsType extends Array<any> = any[], Context = any> implements IAsyncListener<AgumentsType> {
  private _handler: (this: Context, ...args: AgumentsType) => Promise<void>;

  private _context: Context | undefined;

  constructor(handler: ((this: Context, ...args: AgumentsType) => Promise<void>) & ThisType<Context>, context?: Context) {
    this._handler = handler;
    this._context = context;
  }

  async handleEvent(...args: AgumentsType): Promise<void> {
    await this._handler.apply(this._context as Context, args);
  }
}

export class AsyncEvent<AgumentsType extends Array<any> = any[]> extends BaseEvent<IAsyncListener<AgumentsType>> {
  async trigger(...args: AgumentsType): Promise<void> {
    const listeners = this._listeners.slice();
    for(const listener of listeners) {
      await listener.handleEvent(...args);
    }
  }
}


export default Event;

import React          from "react";
import T              from '@cBehaviour/i18n';
import Layout         from '@cComponents/layout';
import Button         from '@cComponents/button';
import Tenant         from "@root/entities/tenants";
import Application    from "@uBehaviour/application";

import "./formContent.css";

class FormLayout extends React.Component {
  constructor(props) {
    super(props);
    this._form = React.createRef();
    this._submit = this._submit.bind(this);
  }
  _submit(){
    this._form.current.submit()
      .then(tenant => {
        if(tenant){
          this.props.message.send("success", this.props.i18n.translate("tenant_saved")); 
        }
      });
  }
  render() {
    return (
      <div className="bs-form-tenant">
        <Layout.Standart stretch={true} className="bs-form-main">
          <Layout.Standart.Content className="bs-form-content">
            { React.cloneElement(this.props.children, { ref: this._form, id: this.props.currentTenant.currentId, user: this.props.session.user }) }
          </Layout.Standart.Content>
          <Layout.Standart.Footer className="bs-form-footer">
            <Button.Text onClick={ this._submit }><T>save</T></Button.Text>
          </Layout.Standart.Footer>
        </Layout.Standart>
      </div>
    );
  }
}
const FormLayoutConnected = Application.Service.forward(["currentTenant", "session", "message", "i18n"], FormLayout);
export default class TenantFormContent extends React.Component {
  render() {
    return (
      <FormLayoutConnected>
        <Tenant.Form />
      </FormLayoutConnected>
    )
  }
}
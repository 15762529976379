import { Service }        from "@uLib/application";
import Geometry           from "@uLib/geometry";
import Location           from "@cLib/location";

const dicPrecision = {
  "ROOFTOP": 1,
  "RANGE_INTERPOLATED": 2,
  "GEOMETRIC_CENTER": 3,
  "APPROXIMATE": 4
};
const dicFromTo = {
  "street_number": "streetNumber",
  "route": "street",
  "locality": "locality",
  "country": "country",
  "postal_code": "zip"
};

window.initMap  = () => {};

export default class GoogleMapService extends Service {
  constructor() {
    super("google-map");
    this._google = null;
  }
  start(){
    return this.waitReady(["configuration"]).then(([configuration]) => {
      return new Promise((resolve, reject) => {
        const script    = document.createElement("script");
        script.type     = "text/javascript";
        script.src      = "https://maps.googleapis.com/maps/api/js?key=" + configuration.get("google_api_key") + "&language=fr&callback=initMap&libraries=places";
        script.loading  = "async";
        script.async    = true;
        script.defer    = true;
        document.head.appendChild(script);
        script.onload = () => {
          window.google.apiKey = configuration.get("google_api_key");
          resolve(window.google);
        };
      }).then(google => {
        this._google              = google;
        this._geocoder            = new google.maps.Geocoder();
        this._autoCompleteService = new google.maps.places.AutocompleteService();
      });
    });
  }
  get lib(){
    return this._google;
  }
  queryAutoComplete(value){
    return new Promise((resolve, reject) => {
      // const id = window.setTimeout(() => reject(new Error("timeout")), 100000);
      this._autoCompleteService.getQueryPredictions({ input: value }, (results, status) => {
        // window.clearTimeout(id);
        if (status === this._google.maps.places.PlacesServiceStatus.OK && results) {
          resolve(results);
        }else if(status === this._google.maps.places.PlacesServiceStatus.ZERO_RESULTS){
          resolve([]);
        }else{
          reject(results);
        }
      });
    });
  }
  getPositionFromAddress(address){
    return new Promise((resolve, reject) => {
      return this._geocoder.geocode( { address: address === "" + address ? address : Location.getLabelFromAddress(address)}, (results, status) => resolve(results))
    })
    .then(results => {
      if(!results || !results.length) return null;
      const location = results
          .sort((r1, r2) => dicPrecision[r1.geometry.location_type] - dicPrecision[r2.geometry.location_type])
          .shift().geometry.location;
      return new Geometry.Point(location.lng(), location.lat()).toGeoJSON();
    });
  }
  getAddressObjectFromGMPlace(place){
    let address = {};
    place.address_components.forEach((c) => {
      if (c.types.includes("street_number")) {
        address.streetNumber = c.long_name;
      } else if (c.types.includes("route")) {
        address.street = c.long_name;
      } else if (c.types.includes("locality")) {
        address.locality = c.long_name;
      } else if (c.types.includes("postal_code")) {
        address.zip = c.long_name;
      } else if (c.types.includes("country")) {
        address.country = c.short_name.toLowerCase();
      }
    });

    return address;
  }
  getAddressFromLatLng(latLng){
    return new Promise((resolve, reject) => this._geocoder.geocode({ location:  latLng }, function(results, status) {
        if (status === 'OK') {
          return resolve(this.getAddressObjectFromGMPlace(results[0]));
        } else {
          return reject("no places found")
        }
    }));
  }
  getAddressFromPosition(position){
    const point = Geometry.buildFromGeoJSON(position).barycentre;
    return new Promise((resolve, reject) => {
      return this._geocoder.geocode( { location:  { lat: point.latitude, lng: point.longitude }}, (results, status) => resolve(results))
    }).then(results => {
      if(!results || !results.length) return null;
      const result = results[0];
        // .sort((r1, r2) => dicPrecision[r1.geometry.location_type] - dicPrecision[r2.geometry.location_type])
        // .filter((result, idx, results) => result.geometry.location_type === results[0].geometry.location_type)
        // .sort((r1, r2) => point.distance(new Geometry.Point(r1.geometry.location.lng(), r1.geometry.location.lat())) - point.distance(new Geometry.Point(r2.geometry.location.lng(), r2.geometry.location.lat())))
        // .shift();
      return result.address_components.reduce((address, component) => {
        const property = component.types.reduce((property, type) => property ? property : dicFromTo[type], undefined);
        if(property){
          address[property] = component.short_name;
        }
        return address;
      }, {});
    });
  }
}
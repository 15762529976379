// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-authentication-login-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--std-large-size-inter-element)
}

.bs-authentication-login-actions > span{
  margin-bottom: 30px;
  color: #d94c0b;
}
.bs-authentication-login-actions > span:hover{
  cursor: pointer;
}

.bs-authentication-login-input {
  margin: 15px 0;
}

.bs-authentication-login-input-checkbox {
  cursor: pointer;
  display: flex;
  gap: var(--std-size-inter-element);
  margin-bottom: 15px;
}
.bs-authentication-login-input-checkbox-label {
  font-size: small;
  color: #aaaaaa;
  display: flex;
  gap: 5px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/applications/unauthenticated/views/login/login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB;AACF;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,kCAAkC;EAClC,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB","sourcesContent":[".bs-authentication-login-actions {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: var(--std-large-size-inter-element)\n}\n\n.bs-authentication-login-actions > span{\n  margin-bottom: 30px;\n  color: #d94c0b;\n}\n.bs-authentication-login-actions > span:hover{\n  cursor: pointer;\n}\n\n.bs-authentication-login-input {\n  margin: 15px 0;\n}\n\n.bs-authentication-login-input-checkbox {\n  cursor: pointer;\n  display: flex;\n  gap: var(--std-size-inter-element);\n  margin-bottom: 15px;\n}\n.bs-authentication-login-input-checkbox-label {\n  font-size: small;\n  color: #aaaaaa;\n  display: flex;\n  gap: 5px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-assignmentTooltip-container {
  border-top: 1px solid;
  padding: 5px 0px;
}

.bs-assignmentTooltip-subject {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.bs-assignmentTooltip-container>.bs-assignmentTooltip-subject:nth-child(n + 2) {
  margin-top: 5px;
}

.bs-assignmentTooltip-subject-title {
  width: 100px;
  flex-shrink: 0;
  color: #444;
}

.bs-assignmentTooltip-subject-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}

.bs-assignmentTooltip-item {
  color: white;
  background-color: #AAA;
  border-radius: 2px;
  padding: 0px 2px;
  font-size: 12px;
  margin: 1px;
  height: fit-content;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.bs-assignmentTooltip-item > .fa {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/entities/assignments/components/tooltip.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-assignmentTooltip-container {\n  border-top: 1px solid;\n  padding: 5px 0px;\n}\n\n.bs-assignmentTooltip-subject {\n  display: flex;\n  flex-direction: row;\n  font-size: 14px;\n}\n\n.bs-assignmentTooltip-container>.bs-assignmentTooltip-subject:nth-child(n + 2) {\n  margin-top: 5px;\n}\n\n.bs-assignmentTooltip-subject-title {\n  width: 100px;\n  flex-shrink: 0;\n  color: #444;\n}\n\n.bs-assignmentTooltip-subject-content {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  overflow: hidden;\n}\n\n.bs-assignmentTooltip-item {\n  color: white;\n  background-color: #AAA;\n  border-radius: 2px;\n  padding: 0px 2px;\n  font-size: 12px;\n  margin: 1px;\n  height: fit-content;\n  text-wrap: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; \n}\n\n.bs-assignmentTooltip-item > .fa {\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import Event, { Listener } from '@uLib/event';
import useForceUpdate from '@universal/hooks/useForceUpdate';
import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';

import moulinnagededonnees          from "@root/old/lib/moulinnagededonnees";
import initStore       from '@root/old/constants/common/store.js';
import { Provider }                 from 'react-redux'
import { setApplication, fetchApi } from "@root/old/core/api";
import useApplication               from '@uHooks/useApplication';
import useService from '@universal/behaviour/hooks/useService';
import SessionService from '@universal/services/session';
import CurrentTenantService from '@universal/services/currentTenant';
import Issue from '@root/old/features/issues/containers/Issue';
import { IssuesRequest } from '@root/old/features/issues/actions';
import oldI18n from 'i18n-react';
import fr from "@root/old/i18n/fr";
import nl from "@root/old/i18n/nl";
import Modal from '@cComponents/modal';
import _ from 'lodash';

import 'client/assets/css/entry.scss';
import './detail.css';
import PushService from '@universal/services/push';
import ObjectId from '@universal/types/technic/ObjectId';
import IssueType from '@universal/types/business/Issue';
import LogType from '@universal/types/business/Log';
import CommentType from '@universal/types/business/Comment';
import I18nService from '@universal/services/i18n';

class OldContextLoader {
  private _datas: any;
  private _onLoad: Event;
  private _loaded: boolean;
  private _issuesToLoad: ObjectId[];

  constructor(){
    this._onLoad = new Event();
    this._loaded = false;
    this._datas = initStore({});
    this._issuesToLoad = [];
    this.loadIssues = _.debounce(this.loadIssues, 100);
  }

  get datas(): any{
    return this._datas;
  }

  get onLoad(): Event {
    return this._onLoad;
  }

  isLoaded(): boolean{
    return this._loaded;
  }

  private dispatch = (toDispatch: any) => {
    if(!this.isLoaded()){
      return;
    }
    this.datas.dispatch(toDispatch);
  }

  dispose(){
    this._loaded = false;
  }

  private hasIssue(id: ObjectId){
    if(!this.isLoaded()){
      return false;
    }
    return this._datas.getState().issues.all.some((issue: IssueType) => issue._id === id);
  }

  updateIssue(ids: ObjectId[]){
    ids = ids.filter((id: ObjectId) => this.hasIssue(id));
    if(ids.length === 0){
      return;
    }

    this._issuesToLoad = this._issuesToLoad.concat(ids);

    this.loadIssues();
  }

  private loadIssues(){
    const ids = _.uniq(this._issuesToLoad);
    this._issuesToLoad = [];
    this.dispatch(IssuesRequest.get.action({ params: {
      ids: ids.join(",")
    }}));
  }

  get id(){
    if(!this.isLoaded()){
      return "notloaded";
    }
    return this._datas.getState().session.currentClient._id;
  }

  initialize(){
    fetchApi("/config").then(({response: { json }}) => {
      const labasededonnees = json;
      window.__DEFAULT = labasededonnees;
      const datas = moulinnagededonnees(labasededonnees, "fr");  
      window.__DEFAULT_STATE = datas;
      this._loaded = true;
      this.dispatch({
        type: "changeState",
        datas
      });
      this._onLoad.trigger();
    });
  }


}

const DetailContext = React.createContext<OldContextLoader>(new OldContextLoader());

export const IssueDetailManager: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const application = useApplication();
  const session = useService<SessionService>('session', ['onServiceUpdated']);
  const currentTenant = useService<CurrentTenantService>('currentTenant', ['onServiceUpdated']);
  const i18n = useService<I18nService>("i18n");

  oldI18n.setTexts(i18n.currentLanguage.bs === "fr" ? fr : nl);

  const contextLoader = useContext(DetailContext);
  
  React.useEffect(() => {
    setApplication(application);
    contextLoader.initialize();
    return () => {
      contextLoader.dispose();
    }
  }, [session.userId, currentTenant.currentId, i18n.currentLanguage]);

  return children;
}

interface IssueDetailProps {
  params?: any,
  goToParentPath: () => void
}

export const IssueDetail: FunctionComponent<IssueDetailProps> = ({ params, goToParentPath }) => {
  const contextLoader = useContext(DetailContext);

  const forceUpdate = useForceUpdate();
  React.useEffect(() => {
    if(!contextLoader.isLoaded()){
      const listener = new Listener(() => {
        contextLoader.onLoad.removeListener(listener);
        _.defer(forceUpdate);
      });
      contextLoader.onLoad.addListener(listener);
      return () => {
        contextLoader.onLoad.removeListener(listener);
      }
    }
  }, [contextLoader, forceUpdate]);

  const pushService = useService<PushService>('push');
  React.useEffect(() => {
    if(contextLoader && pushService.client){
      const listener = new Listener((type, action, datas) => {
        if(type === "Issue"){
          contextLoader.updateIssue(datas.map((data: IssueType) => data._id));
        }
        if (type === "Log" || type === "Comment") {
          const issuesIds = datas.reduce((ids: Array<ObjectId>, data: LogType | CommentType) => {
            if(data.subject.resource === "Issue") {
              ids.push(data.subject.id);
            }
            return ids;
          }, []);
          contextLoader.updateIssue(issuesIds);
        }
      });
      pushService.client.onReceipt.addListener(listener);
      return () => {
        (pushService.client as unknown as PushService).onReceipt.removeListener(listener);
      };
    }
  }, [contextLoader, pushService.client]);
  
  
  return (
    <Modal.Show close={ goToParentPath } style={{ width: "90vw", height: "90vh" }}>
    {() => (
      <div className='bs-issue-detail'>
        <Modal.Manager>
        {
          <Provider store={ contextLoader.datas }>
          {
            contextLoader.isLoaded()
              ? <Issue match={{ params }}/>
              : null
          }
          </Provider>
        }
        </Modal.Manager>
      </div>
    )}
    </Modal.Show>
  );
}
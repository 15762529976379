import { TestBuilder } from "../library/test";
import Application from "@universal/lib/application";
import RepositoryService from "@universal/services/repository";
import SessionService from "@universal/services/session";
import Category from "@universal/types/business/Category";


type StepCreation = { appAc: Application, appAdmin: Application };
type StepValidation = { adminCategories: Category[], acCategories: Category[] };

const getAllCategories = async (app: Application, query?: object): Promise<Category[]> => {
  if(!query){
    query = {};
  }
  const repository = app.getService<RepositoryService>("repository").get("Category").repository;
  let categories: Category[] = [];
  let finished = false;
  let index = 0;
  const toLoad = 100;

  while(!finished) {
    const tmpCategories = await repository.find(query, {}, index, toLoad) as Category[];
    categories = categories.concat(tmpCategories);
    finished = tmpCategories.length !== toLoad;
    index += toLoad;
  }

  return categories;
};

export default (createApplication: () => Promise<StepCreation>) => {
  return TestBuilder.createTest("AttractiveCity", "Récupération des catégories")
    .addStep("Préparation de l'application", createApplication)
    .addStep("Récupération des catégories qui devrait être visibles par l'utilisateur AC avec un utilisateur SuperAdmin", async ({ appAc, appAdmin }: StepCreation) => {
      const adminQuery = { 
        type: "publicSpace",
        visibleByCitizen: true,
        tenant: appAc.getService<SessionService>("session").userBusiness.tenant 
      };

      const adminCategories = await getAllCategories(appAdmin, adminQuery);
      if(adminCategories.length === 0){
        throw "Aucune catégorie trouvée pour l'utilisateur SuperAdmin";
      }
      return { adminCategories, appAc };
    })
    .addStep("Récupération des catégories visibles par l'utilisateur AC", async ({ appAc, adminCategories }: { appAc: Application, adminCategories: Category[] }) => {
      const acCategories = await getAllCategories(appAc);
      return { adminCategories, acCategories };
    })
    .addStep("Comparaison des deux listes de catégories", async ({ adminCategories, acCategories }: StepValidation) => {
      if(adminCategories.length !== acCategories.length){
        throw "L'utilisateur AC n'a pas accès à toutes les catégories";
      }
      return;
    })
    .build();
};
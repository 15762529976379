// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tenant-item {
  height: 100px;
}

.bs-tenant-item .bs-item2-content{
  padding: 5px 10px;
}

.bs-tenant-item .bs-tenant-description{
  display: flex;
  justify-content: space-between;
}

.bs-tenant-item-invite-modal-container {
  padding: var(--std-padding);
}

.bs-tenant-item-invite-modal-submit-btn {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/entities/tenants/components/item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".bs-tenant-item {\n  height: 100px;\n}\n\n.bs-tenant-item .bs-item2-content{\n  padding: 5px 10px;\n}\n\n.bs-tenant-item .bs-tenant-description{\n  display: flex;\n  justify-content: space-between;\n}\n\n.bs-tenant-item-invite-modal-container {\n  padding: var(--std-padding);\n}\n\n.bs-tenant-item-invite-modal-submit-btn {\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

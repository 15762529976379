// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-item-subject {
  color: #27ae60;
  word-wrap: break-word;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/entities/issuesAndAssignments/components/subject.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".bs-item-subject {\n  color: #27ae60;\n  word-wrap: break-word;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

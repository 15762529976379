import Detail from './detail';
import Form from './form';
import History from './history';
import Map from './map';

export default {
  Detail,
  Form,
  History,
  Map
}
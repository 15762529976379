// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-mapLayer-action-tooltip-container {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/entities/mapLayers/components/actions.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB","sourcesContent":[".bs-mapLayer-action-tooltip-container {\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { FunctionComponent } from 'react';

import DropDown from '@cComponents/dropDown';

import Notification from '@entities/notifications';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import Issue from '@universal/types/business/Issue';

import './mobile.css';
import ConnectedLogo from '@root/components/connectedLogo';

type MobileProps = {
  navigationsItems: React.ReactNode[];
  helpItems: React.ReactNode[];
  connexionItems: React.ReactNode[];
  onIssueSelected: (issue: BusinessEntity<Issue>) => void;
};
const Mobile: FunctionComponent<MobileProps> = ({ navigationsItems, helpItems, connexionItems, onIssueSelected }) => {
  return (
    <div className="bs-starter-header-mobile">
      <div className="bs-starter-header-mobile-logo">
        <ConnectedLogo withText/>
      </div>
      <div className="bs-starter-header-mobile-menu">
        <Notification onIssueSelected={ onIssueSelected }/>
        <DropDown open={ "click" } alignRight>
          <DropDown.Menu>
            <span className='fa fa-bars fa-lg' />
          </DropDown.Menu>
          <DropDown.Content>
            <div className="bs-starter-header-mobile-menu-content">
              <div className="bs-starter-header-mobile-menu-content-navigation">
              { navigationsItems.map((child, index) => (<div key={ index }>{ child }</div>)) }
              </div>
              <div className="bs-starter-header-mobile-menu-content-helpAndConnexion">
              { helpItems.concat(connexionItems).map((child, index) => (<div key={ index }>{ child }</div>)) }
              </div>
            </div>
          </DropDown.Content>
        </DropDown>
      </div>
    </div>
  );
}

export default Mobile;
import React, { FunctionComponent, PropsWithChildren }      from "react";
import Menu       from "@cComponents/menu";
import FocusBlur  from "@cComponents/focusBlur";

import "./body.css";
import useService from "@universal/behaviour/hooks/useService";
import CurrentTenantService from "@universal/services/currentTenant";
import Tenant from "@root/entities/tenants";
import Notification from "@entities/notifications";
import UserConnected from "@features/session/userConnected";
import Help from "@features/help";
import { useLocation } from "@universal/features/router";
import Issue from "@universal/types/business/Issue";
import ObjectId from "@universal/types/technic/ObjectId";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import CacheButton from "@root/components/cacheButton";
import LanguageSelector from '@cComponents/languageSelector';

const SelectCurrentTenant = () => {
  const currentTenant = useService<CurrentTenantService>('currentTenant');
  switch(currentTenant.changeMode){
    case "unlimited": return (<Tenant.SelectCurrentTenant.Unlimited />);
    case "limited": return (<Tenant.SelectCurrentTenant.Limited />);
    default: return null;
  }
}

const RightHeader = ({}) => {
  const [, setLocation ] = useLocation();
  const currentTenant = useService<CurrentTenantService>('currentTenant');

  const onIssueSelected = React.useCallback(async (issue: BusinessEntity<Issue>) => {
    if(currentTenant.currentId !== issue.tenant){
      await currentTenant.setCurrentId(issue.toPlainText().tenant as ObjectId);
    }
    setLocation(`/issues/${issue._id}`);
  }, [setLocation, currentTenant]);
  
  return (
    <div className="bs-navigation-bar-static">
      <CacheButton />
      <LanguageSelector />
      <SelectCurrentTenant />
      <Notification onIssueSelected={ onIssueSelected }/>
      <Help />
      <UserConnected />
    </div>
  );
}

const Body: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Menu.Context>
    <FocusBlur.Context className="bs-body">
      <div className="bs-content">
      { children }
      </div>
      <div className="bs-navigation-bar">
        <Menu.Display />
        <RightHeader />
      </div>
    </FocusBlur.Context>
  </Menu.Context>
);
export default Body;
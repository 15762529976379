import React, { FunctionComponent, PropsWithChildren } from "react";
import { Paginated } from "@cComponents/wizard";
import T             from "@uBehaviour/i18n";
import CTA           from "../../features/cta";
import Template      from "./template";
import PromotionalSubtitle from "./promotionalSubtitle";
import Text, { Style } from "@cComponents/text";
import Slot from "@universal/components/slot2";
import useResponsiveObserver from "@universal/behaviour/hooks/useResponsiveObserver";
import Modal from "@common/components/modal";
import ResponsiveView from "./responsiveView";

import "./index.css";

const Image = Slot();

const StatisticsBase: FunctionComponent<PropsWithChildren> & { Image: typeof Image } = ({ children }) => {
  const image = Image.get(children);
  const restChildren = Image.not(children);

  return (
    <Template>
      <Template.Image>{ image }</Template.Image>
      <Template.Icon><span className="fa fa-line-chart bs-featuresDiscovery-stats-icon" /></Template.Icon>
      <Template.Title><Text style={ Style.huge.bold }><T>starter_featuresDiscovery_stats_title</T></Text></Template.Title>
      { restChildren }
    </Template>
  );
};
StatisticsBase.Image = Image;

const StatisticsAnalyze: FunctionComponent = () => {
  const focusedText = <Text style={ Style.bold }> <T>starter_featuresDiscovery_stats_analyzeData_focus</T> </Text>;

  return (
    <StatisticsBase>
      <Image><img src="/images/starter/featuresDiscovery/stats_1.png" alt="Statistiques"/></Image>
      <Text.Paragraph><T bind={{ focusedText }}>starter_featuresDiscovery_stats_analyzeData</T></Text.Paragraph>
    </StatisticsBase>
  );
};


const StatisticsExport: FunctionComponent = () => {
  const focusedText = <Text style={ Style.bold }><T>starter_featuresDiscovery_stats_exportData_focus</T></Text>;

  return (
    <StatisticsBase>
      <Image><img src="/images/starter/featuresDiscovery/stats_2.png" alt="Statistiques"/></Image>
      <div className="bs-featuresDiscovery-content">
        <Text.Paragraph>
          <T bind={{ focusedText }}>starter_featuresDiscovery_stats_exportData</T>
        </Text.Paragraph>
      </div>
    </StatisticsBase>
  );
};

type StatisticsUpsaleProps = {
  redirectToWebsite?: boolean
}

const StatisticsUpsale: FunctionComponent<StatisticsUpsaleProps> = ({ redirectToWebsite }) => (
  <StatisticsBase>
    <Image><img src="/images/starter/featuresDiscovery/stats_3.png" alt="Statistiques"/></Image>
    <PromotionalSubtitle>
      <div className="bs-featuresDiscovery-content">      
        <Text>
          <T>starter_featuresDiscovery_stats_upsale</T>
        </Text>
        <span className="bs-featuresDiscovery-content-CTA">
          <CTA.DiscoverOffers.Orange redirectToWebsite={ redirectToWebsite } />
        </span>
      </div>     
    </PromotionalSubtitle>
  </StatisticsBase>
);

type StatisticsProps = {
  close: () => void,
};
const Statistics: FunctionComponent<StatisticsProps> = ({ close }) => {
  const isLargeScreen = useResponsiveObserver();
  if (isLargeScreen) {
    return (
      <Paginated close={ close } style={{ width: "920px", height: "540px" }}>
        <Paginated.Step>
          <StatisticsAnalyze />
        </Paginated.Step>
        <Paginated.Step>
          <StatisticsExport />
        </Paginated.Step>
        <Paginated.Step>
          <StatisticsUpsale />
        </Paginated.Step>
      </Paginated>
    );
  }
  return (
    <Modal.Show close={ close } style={{ width: "90vw", height: "90vh" }}>
      <ResponsiveView>
        <StatisticsAnalyze />
        <StatisticsExport />
        <StatisticsUpsale redirectToWebsite={ !isLargeScreen } />
      </ResponsiveView>
    </Modal.Show>
  );
}

export default Statistics;
import React, { FunctionComponent }         from "react";
import Form          from "@uBehaviour/form";
import T             from "@cBehaviour/i18n"
import cButton       from "@cComponents/button";
import FormError     from "@cComponents/formError";
import Input         from "@cComponents/input";
import AuthentificationLayout from "../layout";

import useService from "@universal/behaviour/hooks/useService";
import SessionService from "@universal/services/session";
import { Checkbox } from "semantic-ui-react";
import DefaultStorageService from "@universal/services/defaultStorageService";

import useForceUpdate from "@universal/hooks/useForceUpdate";

import "./login.css";
import AclService from "@universal/services/acl";

interface LoginProps {
  displayForgotPassword: () => void;
}


const Button = cButton.withStyle(cButton.Stylized.orange.round);

const Login: FunctionComponent<LoginProps> = ({ displayForgotPassword }) => {
  const session = useService<SessionService>('session');
  const acl = useService<AclService>("acl");
  const manageDefaultStorage = useService<DefaultStorageService>('storage');
  const forceUpdate = useForceUpdate();

  const login = React.useCallback((form, user: { username: string, password: string }) => {
    return session.login(user.username, user.password).then(() => {
      if (acl.connectedUserIsAllow("sessions", "tabletLogin")) {
        session.logout();
      }
    });
  }, [session]);

  const switchStayLogged = React.useCallback(async () => {
    await manageDefaultStorage.switchStorage();
    forceUpdate();
  }, [manageDefaultStorage]);

  React.useEffect(() => {
    if(manageDefaultStorage.isPersistent()){
      switchStayLogged();
    }
  }, []);

  return (
    <AuthentificationLayout>
      <Form.Simple name="login" submit={ login }>
      {(ctx, value, errors, form, submit) => (
        <div className="bs-authentication-login">
          <FormError errors={errors.global}>
            <Form.Simple.InputAdapter name="username">
            {(value, set) => (
              <div className="bs-authentication-login-input">
                <Input.Text value={ value } onChange={ set }>
                  <T>authentication_login_username</T>
                </Input.Text>
              </div>
            )}
            </Form.Simple.InputAdapter>
            <Form.Simple.InputAdapter name="password">
            {(value, set) => (
              <div className="bs-authentication-login-input">
                <Input.Password value={ value } onChange={ set }>
                  <T>authentication_login_password</T>
                </Input.Password>
              </div>
            )}
            </Form.Simple.InputAdapter>
          </FormError>
          <div className="bs-authentication-login-input-checkbox" onClick={ switchStayLogged } >
            <Checkbox checked={  manageDefaultStorage.isPersistent() } />
            <span className="bs-authentication-login-input-checkbox-label">
              <T>authentication_stay_logged_in</T>
            </span>
          </div>
          <div className="bs-authentication-login-actions">
            <Button onClick={ submit }>
              <T>authentication_login_login</T>
            </Button>
            <span onClick={ displayForgotPassword }>
              <T>authentication_login_forgot_password</T>
            </span>
          </div>
        </div>
      )}
      </Form.Simple>
    </AuthentificationLayout>
  );
}

export default Login;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-form-contact {
  padding:  0 15px;
}

.bs-form-contact .bs-contactEmails-table {
  width: 100%;
}

.bs-form-contact .bs-contactEmails-table th{
  text-align: left;
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/entities/contacts/components/form.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".bs-form-contact {\n  padding:  0 15px;\n}\n\n.bs-form-contact .bs-contactEmails-table {\n  width: 100%;\n}\n\n.bs-form-contact .bs-contactEmails-table th{\n  text-align: left;\n  font-size: 0.9em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

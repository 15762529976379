// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-valorization-externalServiceForm {
  display: flex;
  flex-direction: column;
  height: 100%;

}
.bs-valorization-externalServiceForm-header{

}
.bs-valorization-externalServiceForm-body{
  flex-grow: 1;
  overflow: hidden;
}
.bs-valorization-externalServiceForm-body,
.bs-valorization-externalServiceForm-header{
  border-bottom: 1px solid #ccc;
}

.bs-valorization-externalServiceForm-header,
.bs-valorization-externalServiceForm-footer{
  flex: auto 0 0;
}

.bs-valorization-externalServiceForm-header,
.bs-valorization-externalServiceForm-body,
.bs-valorization-externalServiceForm-footer{
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/entities/valorizations/components/externalServiceForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;;AAEd;AACA;;AAEA;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;;EAEE,6BAA6B;AAC/B;;AAEA;;EAEE,cAAc;AAChB;;AAEA;;;EAGE,aAAa;AACf","sourcesContent":[".bs-valorization-externalServiceForm {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n\n}\n.bs-valorization-externalServiceForm-header{\n\n}\n.bs-valorization-externalServiceForm-body{\n  flex-grow: 1;\n  overflow: hidden;\n}\n.bs-valorization-externalServiceForm-body,\n.bs-valorization-externalServiceForm-header{\n  border-bottom: 1px solid #ccc;\n}\n\n.bs-valorization-externalServiceForm-header,\n.bs-valorization-externalServiceForm-footer{\n  flex: auto 0 0;\n}\n\n.bs-valorization-externalServiceForm-header,\n.bs-valorization-externalServiceForm-body,\n.bs-valorization-externalServiceForm-footer{\n  padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

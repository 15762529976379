import React      from "react";
import DateInput  from "./date";
import Hour       from "./hour";
import Select     from "./select";
import moment     from "moment";
import Display    from "@uComponents/displayIf";
import Slot       from '@uComponents/slot2';
import Text, { Style }       from "../text";

import './period.css'
import T from "@universal/behaviour/i18n";

const styleDurantion = Style.black.right;

const DATE_FORMAT = "YYYY-MM-DD";

const DurationType = ({ allDay, onChange }) => (
  <span onClick={ () => onChange(!allDay) } className={`fa ${allDay ? "fa-clock-o" : "fa-calendar-plus-o" }`} />
);


class Period extends React.Component{
  static StartLabel = Slot();
  static EndLabel = Slot();
  static ModeSwitchButtonDecorator = Slot();

  constructor(props){
    super(props);
    const { start, end }             = this.props;
    this._allDay                     = !start || !end ? true : moment(start).format("HH:mm") === "00:00" && moment(end).format("HH:mm") === "00:00";
    this._startDate                  = React.createRef();
    this._startHour                  = React.createRef();
    this._endDate                    = React.createRef();
    this._endHour                    = React.createRef();
    this._selectDuration             = React.createRef();

    this._allDayChange               = this._allDayChange.bind(this);
    this._selectDurationChangeAllDay = this._selectDurationChangeAllDay.bind(this);
    this._startDateChange            = this._startDateChange.bind(this);
    this._startHourChange            = this._startHourChange.bind(this);
    this._endDateChange              = this._endDateChange.bind(this);
    this._endHourChange              = this._endHourChange.bind(this);
    this._delete                     = this._delete.bind(this);
  }
  _allDayChange(value){
    this._allDay = value;
    let { start, end }  = this.props;
    if(!start || !end){
      this.forceUpdate();
      return;
    }
    start = moment(start).startOf("day").toDate();
    if(!this._allDay){
      end   = moment(end).startOf("day").subtract(1, "day").add(1, "h").toDate();
    }else{
      end   = moment(end).startOf("day").add(1, "day").toDate();
    }
    this._onChange(start, end, true);
  }
  get start(){
    const { valueAsObject, value, start }  = this.props;
    if(valueAsObject){
      return value.start;
    }
    return start;
  }
  get end(){
    const { valueAsObject, value, end }  = this.props;
    if(valueAsObject){
      return value.end;
    }
    return end;
  }
  _selectDurationChangeAllDay(value){
    let start  = this.start;
    if(!start) start = new Date();
    const newStart   = moment(start).startOf("day").toDate();
    const newEnd     = moment(newStart).clone().add(value, "day").toDate();
    this._onChange(newStart, newEnd);
  }
  _startDateChange(value){
    let { start, end }  = this;
    if (!value) {
      this._onChange(null, end);
    } else {
      if(!start) start = new Date();
      if(!end)   end   = new Date();
      const allDay          = this._allDay; 
      let newStart          = moment(value);
      let newEnd            = moment(end);
      const sameDay         = moment(newStart).isSame(moment(newEnd).subtract(1, "day"), "day");
      if(allDay && sameDay || !this.end){
        newEnd = newStart.clone().add(1, "day");
      }else{
       if(!allDay){
         newStart = moment(newStart.format(DATE_FORMAT) + "T" + this._startHour.current.value + ":00.000");
       }
       if(newStart.isSameOrAfter(newEnd)){
        let diff = parseInt((end.getTime() - start.getTime()) / 60000);
        if(diff <= 0){
          if(allDay){
            diff = 1440;
          } else {
            diff = 60;
          }
        }
        newEnd = newStart.clone().add(diff, "minutes");
       }
      }
      if(allDay){
        newStart.startOf("day");
        newEnd.startOf("day");
      }
      newStart = newStart.toDate();
      newEnd   = newEnd.toDate();
      this._onChange(newStart, newEnd);
    }
    
  }
  _startHourChange(value){
    let { start, end }  = this;
    if(!start) start = new Date();
    if(!end)   end   = new Date();
    const newStart = moment(`${moment(start).format(DATE_FORMAT)}T${value}:00.000`);
    let newEnd     = end;
    if(newStart.isSameOrAfter(newEnd)){
      let diff = parseInt((end.getTime() - start.getTime()) / 60000);
      if(diff <= 0){
        diff = 60;
      }
      newEnd = newStart.clone().add(diff, "minutes").toDate();
    }
    this._onChange(newStart.toDate(), newEnd);
  }
  _endDateChange(value){
    let { start, end }  = this;
    if (!value) {
      this._onChange(start, null);
    } else {
      if(!start) start = new Date();
      if(!end)   end   = new Date();
      const allDay  = this._allDay;
      let newEnd    = moment(value).startOf("day");
      let newStart  = moment(start);
      if(allDay){
        newEnd.add(1, "day");
      }else{
        newEnd  = moment(`${moment(value).format(DATE_FORMAT)}T${this._endHour.current.value}:00.000`);
      }
      if(newEnd.isSameOrBefore(newStart)){
        let diff = parseInt((end.getTime() - start.getTime()) / 60000);
        if(diff <= 0){
          diff = 1440;
        }
        newStart = newEnd.clone().subtract(diff, "minutes")
      }

      if(allDay){
        newStart.startOf("day");
        newEnd.startOf("day");
      }
      this._onChange(newStart.toDate(), newEnd.toDate());
    }
  }
  _endHourChange(value){
    let { start, end }  = this;
    if(!start) start = new Date();
    if(!end)   end   = new Date();
    const newEnd  = moment(`${moment(end).format(DATE_FORMAT)}T${value}:00.000`);
    let newStart  = start;
    if(newEnd.isSameOrBefore(newStart)){
      let diff = parseInt((end.getTime() - start.getTime()) / 60000);
      if(diff <= 0){
        diff = 60;
      }
      newStart = newEnd.clone().subtract(diff, "minutes").toDate();
    }
    this._onChange(newStart, newEnd.toDate());
  }
  _onChange(start, end, swtichAllDay = false){
    if(this.props.constrain){
      const constrainedHours = this.props.constrain(start, end, this._allDay, this.props.start, this.props.end, swtichAllDay ? !this._allDay : this._allDay);
      start                  = constrainedHours.start;
      end                    = constrainedHours.end;
      this._allDay           = constrainedHours.allDay;
    }
    if(this.props.onChange){
      if(this.props.valueAsObject){
        this.props.onChange({ start, end });
      } else {
        this.props.onChange(start, end);
      }
    }
  }
  _delete(){
    this._onChange(null, null);
  }
  render(){
    const { start, end }  = this;
    const allDay          = this._allDay;
    const startLabel = Period.StartLabel.get(this.props.children);
    const endLabel = Period.EndLabel.get(this.props.children);
    const modeSwitchButtonDecorator = Period.ModeSwitchButtonDecorator.get(this.props.children, false, (item) => item);

    return (
      <div className="bs-input-period">
        <Display.If condition={ allDay }>
          <Display.Then>
            <div className="bs-input-period-row bs-input-period-first">
              <div>{startLabel}</div>
              <div className="bs-input-period-tableSpace" />
              <div><DateInput ref={ this._startDate } value={ start } onChange={ this._startDateChange }/></div>
              <div className="bs-input-period-tableSpace" />
              <Select ref={ this._selectDuration } onChange={ this._selectDurationChangeAllDay } value={ moment(end).diff(start, "day")}>
                <Select.Value value={ 1 }><Text style={ styleDurantion }><T>bs_input_period_oneDay</T></Text></Select.Value>
                <Select.Value value={ 2 }><Text style={ styleDurantion }><T bind={{ nbrDay: 2 }}>bs_input_period_multiDay</T></Text></Select.Value>
                <Select.Value value={ 3 }><Text style={ styleDurantion }><T bind={{ nbrDay: 3 }}>bs_input_period_multiDay</T></Text></Select.Value>
                <Select.Value value={ 4 }><Text style={ styleDurantion }><T bind={{ nbrDay: 4 }}>bs_input_period_multiDay</T></Text></Select.Value>
                <Select.Value value={ 5 }><Text style={ styleDurantion }><T bind={{ nbrDay: 5 }}>bs_input_period_multiDay</T></Text></Select.Value>
                {
                  end && moment(end).diff(start, "day") > 5
                    ? (
                      <Select.Value value={ moment(end).diff(start, "day") }><Text style={ styleDurantion }><T bind={{ nbrDay: moment(end).diff(start, "day")}}>bs_input_period_multiDay</T></Text></Select.Value>
                    )
                    : null
                }
              </Select>
              <Display.If condition={ !this.props.allDay }>
                <div className="bs-input-period-tableSpace" />
                {modeSwitchButtonDecorator(<DurationType onChange={ this._allDayChange } allDay={ allDay } />, allDay)}
              </Display.If>
              <Display.If condition={ this.props.nullable }>
                <div className="bs-input-period-tableSpace" />
                <span className="fa fa-times" onClick={ this._delete } />
              </Display.If>
            </div>
            <div className="bs-input-period-row">
              <div>{endLabel}</div>
              <div className="bs-input-period-tableSpace" />
              <div><DateInput ref={ this._endDate } value={ end ? moment(end).subtract(1, "day").toDate() : null} onChange={ this._endDateChange }/></div>
            </div>
          </Display.Then>
          <Display.Else>
            <div className="bs-input-period-row">
              <div>{startLabel}</div>
              <div className="bs-input-period-tableSpace" />
              <div><DateInput ref={ this._startDate } value={ start } onChange={ this._startDateChange }/></div>
              <div className="bs-input-period-tableSpace" />
              <Hour ref={ this._startHour } value={ start } onChange={ this._startHourChange }/>
              <div className="bs-input-period-tableSpace" />
              {modeSwitchButtonDecorator(<DurationType onChange={ this._allDayChange } allDay={ allDay } />, allDay)}
              <div className="bs-input-period-tableSpace" />
              <Display.If condition={ this.props.nullable }>
                <div className="bs-input-period-tableSpace" />
                <span className="fa fa-times" onClick={ this._delete } />
              </Display.If>
            </div>
            <div className="bs-input-period-row">
              <div>{endLabel}</div>
              <div className="bs-input-period-tableSpace" />
              <div><DateInput ref={ this._endDate } value={ end } onChange={ this._endDateChange }/></div>
              <div className="bs-input-period-tableSpace" />
              <Hour ref={ this._endHour } value={ end } onChange={ this._endHourChange }/>
            </div>
          </Display.Else>
        </Display.If>
      </div>
    )
  }
}
const dateIsSame = (date1, date2) => (date1 && date2 && date1.getTime() === date2.getTime()) || (!date1 && !date2);
class StatedPeriod extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      start: this.props.start,
      end: this.props.end
    };
    this._lastProps = new Date();
    this._lastState = new Date();
    this._update    = this._update.bind(this);
  }
  _update(start, end){
    this.setState({ start, end });
    this._lastState = new Date();
    if(this.props.onChange){
      this.props.onChange(start, end);
    }
  }
  shouldComponentUpdate(props, state){
    if(!dateIsSame(props.start, this.props.start) || !dateIsSame(props.end, this.props.end)){
      this._lastProps = new Date();
    }
    return true;
  }
  render(){
    const { start, end } = this._lastState > this._lastProps
      ? this.state
      : this.props;
    return (<Period start={ start } end={ end } onChange={ this._update } nullable={ this.props.nullable } allDay={ this.props.allDay } constrain={ this.props.constrain } />)
  }
}
Period.Stated = StatedPeriod;

Period.StartToHour = (hour) => {
  return (newStart, newEnd, newAllDay, oldStart, oldEnd, oldAllDay) => {
    let start = newStart;
    let end   = newEnd;
    if(newAllDay !== oldAllDay && !newAllDay){
      start = moment(newStart).add(hour, "h").toDate();
      end   = moment(newEnd).add(hour, "h").toDate();
    }
    return { start, end, allDay: newAllDay };
  };
}
Period.MergeConstaint = (handlers) => {
  return (newStart, newEnd, newAllDay, oldStart, oldEnd, oldAllDay) => {
    return handlers.reduce((obj, handler) => handler(obj.start, obj.end, obj.allDay, oldStart, oldEnd, oldAllDay), { start: newStart, end: newEnd, allDay: newAllDay});
  };
}
Period.LimitDate = (limitDate) => {
  return (newStart, newEnd, newAllDay, oldStart, oldEnd, oldAllDay) => {
    let start = newStart.clone();
    let end   = newEnd.clone();
    if(start < limitDate){
      start = limitDate.clone();
    }
    if(end < start){
      end = moment(start).add(1, "day").toDate();
    }
    return { start, end, newAllDay };
  };
}

export default Period;
import Application, { Service } from "@universal/lib/application";
import SessionService from "./session";
import CurrentTenantService from "./currentTenant";
import INvavigatorService from "./types/navigator";
import { AsyncListener } from "@universal/lib/event";
import ApiService from "./api";
import ObjectId from "@universal/types/technic/ObjectId";


const firstLevelRegex = /(?<resource>issues|assignments|recurrences)\/(?<id>.*)/;
const secondLevelRegex =  /(settings|planning)\/(?<resource>issue|users|teams|categories|buildings|equipments|contacts|supplies)\/(?<id>.*)/;

type ResourceAndId = { resource: string, id: string };
const getResourceAndId = (navigator: INvavigatorService): ResourceAndId | null => {
  let result = navigator.navigator.current.match(firstLevelRegex);
  if(!result){
    result = navigator.navigator.current.match(secondLevelRegex);
  }
  if(result){
    return result.groups as ResourceAndId;
  }
  return null;
}
type WithTenants = { tenants: { tenant: ObjectId }[] };
type WithTenant = { tenant: ObjectId };
type With = WithTenants | WithTenant;

const isWithTenants = (resource: string, _with: With): _with is WithTenants  => {
  return resource === "users" && !!(_with as WithTenants).tenants;
}
const isWithTenant = (resource: string, _with: With): _with is WithTenant => {
  return !!(_with as WithTenant).tenant;
}
const getTenants = (resource: string, data: With): ObjectId[] => {
  if(isWithTenants(resource, data)) {
    return data.tenants.map(t => t.tenant);
  }
  if(isWithTenant(resource, data)){
    return [data.tenant];
  }
  return [];
}

class PositionRightTenantService extends Service {
  constructor(){
    super("positionRigheTenantService", ["session", "currentTenant", "navigator", "api"]);
  }

  async start(application: Application): Promise<void> {
    const [session, currentTenant, navigator, api] = await this.waitReady<[SessionService, CurrentTenantService, INvavigatorService, ApiService]>(["session", "currentTenant", "navigator", "api"]);
    await this.positionOnRightTenant(session, currentTenant, navigator, api);
    let userId = session.userId;
    currentTenant.onServiceUpdated.addListener(new AsyncListener(async () => {
      if(userId !== session.userId){
        await this.positionOnRightTenant(session, currentTenant, navigator, api);
        userId = session.userId;
      }
    }));
  }
  
  private async positionOnRightTenant(session: SessionService, currentTenant: CurrentTenantService, navigator: INvavigatorService, api: ApiService){
    const ask = getResourceAndId(navigator);
    if(!ask){
      return;
    }
    if(!session.isLogged()) {
      return;
    }
    const data = await api.service(ask.resource, "getOne").execute(ask.id);
    if(!data){
      return;
    }
    const tenants = getTenants(ask.resource, data);
    if(!tenants.length){
      return;
    }
    const tenantId = tenants.find(tenantId => currentTenant.isAllowToAccess(tenantId));
    if(!tenantId){
      navigator.back();
      return;
    }
    if(tenantId === currentTenant.currentId){
      return;
    }
    await currentTenant.setCurrentId(tenantId);
  }
}

export default PositionRightTenantService;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-assignment-itemShort{
  background-color: white;
  height: 96px;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  position: relative;
  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);
}
.bs-assignment-itemShort-illustration{
  flex-shrink: 0;
}
.bs-assignment-itemShort-content{
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.bs-assignment-itemShort-content *{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bs-assignment-itemShort-description{
  overflow: hidden;
  display: flex;
}
.bs-assignment-itemShort-description>div{
  text-overflow: ellipsis;
  color: black
}

.bs-assignment-itemShort-header-state {
  position: absolute;
  top: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/entities/assignments/components/item/short.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,4EAA4E;AAC9E;AACA;EACE,cAAc;AAChB;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,uBAAuB;EACvB;AACF;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;AACV","sourcesContent":[".bs-assignment-itemShort{\n  background-color: white;\n  height: 96px;\n  display: flex;\n  flex-direction: row;\n  margin-top: 15px;\n  position: relative;\n  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);\n}\n.bs-assignment-itemShort-illustration{\n  flex-shrink: 0;\n}\n.bs-assignment-itemShort-content{\n  padding: 5px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  overflow: hidden;\n}\n.bs-assignment-itemShort-content *{\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n.bs-assignment-itemShort-description{\n  overflow: hidden;\n  display: flex;\n}\n.bs-assignment-itemShort-description>div{\n  text-overflow: ellipsis;\n  color: black\n}\n\n.bs-assignment-itemShort-header-state {\n  position: absolute;\n  top: 0;\n  right: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import Event from "../event";
import Timeline from "../timeline";

class PeriodCache {
  constructor(remoteHandler, identityHandler, startHandler, endHandler){
    this._remoteHandler   = remoteHandler;
    this._identityHandler = identityHandler;
    this._startHandler    = startHandler;
    this._endHandler      = endHandler;
    this._loadedPeriod    = new Timeline();
    this._datas           = [];
    this._onUpdate        = new Event();
  }

  get onUpdate() {
    return this._onUpdate;
  }

  _extract(data){
    return {
      id: this._identityHandler(data),
      start: this._startHandler(data),
      end: this._endHandler(data),
      data
    };
  }

  store(data){
    this._store(data);
    this._sort();
    return this;
  }

  _store(data){
    const extracted = this._extract(data);
    const index = this._datas.findIndex(data => data.id === extracted.id);
    if(index === -1){
      this._datas.push(extracted);
    }else{
      this._datas[index] = extracted;
    }
    this._onUpdate.trigger();
  }

  _sort(){
    // this._datas.sort((d1, d2) => {
    //   const diff = d1.start.getTime() - d2.start.getTime();
    //   if(diff !== 0){
    //     return diff;
    //   }
    //   return d1.end.getTime() - d2.end.getTime();
    // });
  }

  delete(dataId){
    const index = this._datas.findIndex(data => data.id === dataId);
    if(index !== -1){
      this._datas.splice(index, 1);
    }
    return this;
  }

  _remoteGet(periods){
    return this._remoteHandler(periods);
  }

  has(dataId){
    return this._datas.findIndex(data => data.id === dataId) !== -1;
  }

  hasPeriod(start, end){
    const periods = this._loadedPeriod.intersect(new Timeline([{ start, end }])).toPeriods();
    if(periods.length !== 1){
      return false;
    }
    return periods[0].start.getTime() === start.getTime() && periods[0].end.getTime() === end.getTime();
  }

  _collect(start, end){
    return this._datas.filter(data => data.start < end && data.end > start);
  }

  async _get(start, end) {
    const getTimeline = new Timeline([{ start, end }]);
    const p = this._remoteGet(getTimeline.sub(this._loadedPeriod).toPeriods());
    this._loadedPeriod = this._loadedPeriod.add(getTimeline);
    const datas = await p;
    datas.forEach(data => this._store(data));
    this._sort();
  }

  get(start, end){
    if(!this.hasPeriod(start, end)){
      this._get(start, end);
    }
    return this._collect(start, end).map(o => {
      return o.data;
    });
  }
}

export default PeriodCache;
import React, { FunctionComponent } from 'react';
import createLogView, { LogDisplay } from '@root/entities/logs';
import type { Element } from '@root/entities/logs';
import State from '@cFeatures/state';
import T from '@universal/behaviour/i18n';
import { Icon as UserIcon } from '@entities/users';

import { BusinessEntity } from '@uTypes/technic/Entityable';
import Issue from '@uTypes/business/Issue';
import StatesConfiguration from '@uTypes/business/issue/State';
import { LogV3Creation } from '@uTypes/business/Log';
import User, { isUserCitizen } from '@uTypes/business/User';

import './history.css';
import Text, { Style } from '@common/components/text';

const LogView = createLogView('Issue');

const getLogCreation = (issue: Issue): LogV3Creation => {
  return {
    _id: issue._id,
    subject: {
      id: issue._id,
      resource: "Issue",
    },
    type: 'creation',
    createdAt: issue.createdAt,
    createdBy: issue.createdBy,
    version: 3
  };
};
type ContentContainerProps = {
  elements: Element[];
  before: Issue;
  after: Issue;
  createdBy: User;
}
const ContentContainer: FunctionComponent<ContentContainerProps> = ({ elements, after, createdBy }) => (
  <div className="bs-issues-starter-history-item-container">
    <div className="bs-issues-starter-history-item-container-state">
      <State.Short state={ StatesConfiguration[after.state] } withText />
    </div>
    {
      elements.map((element, index) => (
        <LogDisplay key={ index }>
          <LogDisplay.Title>
            <Text style={ isUserCitizen(createdBy) ? Style.black : Style.orange }>
              <UserIcon user={ createdBy } />
            </Text>
            &nbsp;&nbsp;
            { element.title }
          </LogDisplay.Title>
          <LogDisplay.Content>{ element.content }</LogDisplay.Content>
        </LogDisplay>
      ))
    }
  </div>
); 

type HistoryProps = {
  issue: BusinessEntity<Issue>;
};
const History: FunctionComponent<HistoryProps> = ({ issue }) => (
  <LogView data={ issue } withComments getLogCreation={ getLogCreation } ContentContainer={ ContentContainer } creationIsComment>
    <LogView.Rule
      title={() => <T>issues_starter_history_stateUpdated</T>}
      version={2}
      type="state"
    />
    <LogView.Rule
      title={() => <T>issues_starter_history_creation</T>}
      textify={(issue: Issue) => <>{ issue.description }</> }
      version={3}
      type="creation"
    />
  </LogView>
);

export default History;
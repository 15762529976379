// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-starter-header-item {
  display: flex;
  align-items: center;
  gap: var(--std-size-inter-element);
  padding: var(--std-padding-light);
}

.bs-starter-header-entryItem-container {
  position: relative;
  border-radius: var(--std-radius);
}
.bs-starter-header-entryItem-locked {
  background-color: var(--std-color-gray-light);
}
.bs-starter-header-entryItem-locked-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(20%, -20%);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);
  font-size: 12px;
  height: 12px;
  width: 12px;
  color: var(--std-color-black);
}

.bs-starter-header-communicationKit {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/applications/starter/header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kCAAkC;EAClC,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,gCAAgC;AAClC;AACA;EACE,6CAA6C;AAC/C;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,+BAA+B;EAC/B,kBAAkB;EAClB,qCAAqC;EACrC,eAAe;EACf,YAAY;EACZ,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".bs-starter-header-item {\n  display: flex;\n  align-items: center;\n  gap: var(--std-size-inter-element);\n  padding: var(--std-padding-light);\n}\n\n.bs-starter-header-entryItem-container {\n  position: relative;\n  border-radius: var(--std-radius);\n}\n.bs-starter-header-entryItem-locked {\n  background-color: var(--std-color-gray-light);\n}\n.bs-starter-header-entryItem-locked-icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  right: 0px;\n  top: 0px;\n  transform: translate(20%, -20%);\n  border-radius: 50%;\n  background-color: rgba(0, 0, 0, 0.15);\n  font-size: 12px;\n  height: 12px;\n  width: 12px;\n  color: var(--std-color-black);\n}\n\n.bs-starter-header-communicationKit {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode
} from 'react';
import Columns from "../../components/layout/columns";
import Slot from '@uComponents/slot2';

import "./listHeader.css";
import classNames from '@universal/lib/classNames';

type ColumnProps = { className?: string };

const Left   = Slot<ReactNode, PropsWithChildren<ColumnProps>>();
const Center = Slot<ReactNode, PropsWithChildren<ColumnProps>>();
const Right  = Slot<ReactNode, PropsWithChildren<ColumnProps>>();

const ListHeader: FunctionComponent<PropsWithChildren> & { Left: typeof Left, Center: typeof Center, Right: typeof Right }  = ({ children }) => {
  const left  = Left.get(children);
  const center = Center.get(children);
  const right = Right.get(children);
  
  return (
  <Columns className="bs-features-layout-listHeader-container">
    <Columns.Column className={classNames("bs-features-layout-listHeader-column").addNotEmpty(ListHeader.Left.props(children).className)}>
      { left }
    </Columns.Column>
    <Columns.Column className={classNames("bs-features-layout-listHeader-column").addNotEmpty(ListHeader.Center.props(children).className)}>
      { center }
    </Columns.Column>
    <Columns.Column className={classNames("bs-features-layout-listHeader-right bs-features-layout-listHeader-column ").addNotEmpty(ListHeader.Right.props(children).className)}>
      { right }
    </Columns.Column>
  </Columns>
)};

ListHeader.Left   = Left;
ListHeader.Center = Center;
ListHeader.Right  = Right;

export default ListHeader;
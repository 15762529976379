import React        from "react";
import Layout       from "@components/formLayout";
import Form         from '@cBehaviour/form';
import T            from '@cBehaviour/i18n';
import Building     from "@root/entities/buildings";
import Comment      from "@root/entities/comments";
import Observer     from "@common/lib/observer";
import Application  from "@uBehaviour/application";

class BuildingForm extends React.Component{
  constructor(props){
    super(props);
    this._observer = new Observer();
  }
  render(){
    const props = this.props;
    const comment = props.id === "new" ? null : (
      <Layout.Comment>
        <Comment.View subject={ props.id } query={{ "tenants": { $elemMatch: { "tenant" : props.currentTenant.currentId, roles: "admin" }}, _id: { $ne: props.session.userId }}} id={ props.id } resource="Building" />
      </Layout.Comment>
    );
    const logs = props.id === "new" ? null : (
      <Layout.Log>
        <Building.Log id={ props.id } />
      </Layout.Log>
    );

    const id = props.id === "new" ? null : props.id;
    
    return (
      <Layout close={ props.close } type="fullscreen">
        <Layout.Title>
          <Form.Observer observer={ this._observer }>
          {building => (
            <div>
              <T>building</T>
              <span> --- </span>
              <span>{ building.name }</span>
            </div>
          )}
          </Form.Observer>
        </Layout.Title>
        <Layout.Form>
          <Building.Form id={ id } observer={ this._observer }/>
        </Layout.Form>
        {/* comment */}
        {/* logs */}
      </Layout>
    )
  }
};

export default Application.forward(["currentTenant", "session"], [], BuildingForm);
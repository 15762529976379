// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-button-base {
  border: none;
  background: none;
  display: block;
}

.bs-button-base:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../common/components/button/base.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".bs-button-base {\n  border: none;\n  background: none;\n  display: block;\n}\n\n.bs-button-base:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

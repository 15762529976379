import React, { FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from 'react';
import Mobile from './mobile';
import Web from './web';
import Slot from '@universal/components/slot2';
import Responsive from '@components/responsive';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import Issue from '@universal/types/business/Issue';

type ItemProps = {
  displayOnly?: "web" | "mobile";
  children: ReactNode;
};
const Item = Slot<ReactNode, ItemProps>();
type ItemElement = ReactElement<ItemProps, typeof Item>;
type Items = Iterable<ItemElement> | ItemElement;
const Navigation = Slot<Items>();
const Connexion = Slot<Items>();
const Help = Slot<Items>();

type HeaderSlot = {
  Navigation: typeof Navigation;
  Connexion: typeof Connexion;
  Help: typeof Help;
  Item: typeof Item;
}
type HeaderProps = {
  onIssueSelected: (issue: BusinessEntity<Issue>) => void;
};

const filterAndMapChildren = (items: ItemProps[], displayOnly: "web" | "mobile") => {
  return items.filter(item => item.displayOnly === displayOnly || !item.displayOnly).map(item => item.children);
}

const Header: FunctionComponent<PropsWithChildren<HeaderProps>> & HeaderSlot = ({ onIssueSelected, children }) => {
  const navigation = Navigation.get(children);
  const connexion = Connexion.get(children);
  const help = Help.get(children);

  const navigationsItems = Item.props(navigation, true);
  const connexionItems = Item.props(connexion, true);
  const helpItems = Item.props(help, true);

  return (
    <>
      <Responsive.Mobile>
        <Mobile onIssueSelected={ onIssueSelected }
          navigationsItems={ filterAndMapChildren(navigationsItems, "mobile") }
          helpItems={ filterAndMapChildren(helpItems, "mobile") }
          connexionItems={ filterAndMapChildren(connexionItems, "mobile") }
        />
      </Responsive.Mobile>
      <Responsive.Web>
        <Web onIssueSelected={ onIssueSelected }
          navigationsItems={ filterAndMapChildren(navigationsItems, "web") }
          helpItems={ filterAndMapChildren(helpItems, "web") }
          connexionItems={ filterAndMapChildren(connexionItems, "web") }
        />
      </Responsive.Web>
    </>
  );
};
Header.Navigation = Navigation;
Header.Connexion = Connexion;
Header.Help = Help;
Header.Item = Item;

export default Header;
import React from 'react';
import { BusinessEntity } from '@uTypes/technic/Entityable';
import { Model } from '@uLib/model';
import useModel from './useModel';
import Loader from '@universal/types/technic/Loader';

function useGet<T>(modelName: string | Model, ids: Partial<T>[], foreignLoad: Loader<T>): BusinessEntity<T>[] {
  const model: Model = useModel(modelName);
  const [entities, setEntities] = React.useState<BusinessEntity<T>[]>([]);
  
  React.useEffect(() => {
    if(!ids.length){
      if (entities.length > 0) {
        setEntities([]);
      }
      return ;
    }
    model.repository.get(ids, foreignLoad).then(results => {
      setEntities(results);
    });
    return () => {
      entities.forEach(entity => {
        entity.dispose();
      });
    }
  }, [model, setEntities, JSON.stringify(ids)]);

  return entities;
};

export default useGet;


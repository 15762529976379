import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React, { FunctionComponent }                        from 'react'
import { render }                   from 'react-dom'
import Applications                 from './applications'
import reportWebVitals              from './reportWebVitals';
import application                  from "./app.services";
import Application                  from "@uBehaviour/application";
import Modal                        from "@cComponents/modal";
import MessageViewer                from "@cComponents/message";
import Tooltip                      from "@cComponents/tooltip";
import TooltipServiceListener       from "@cFeatures/tooltip";
import { Router }                   from "@cFeatures/router";
import ApplicationLib               from "@uLib/application";
import useServiceListenener         from '@uBehaviour/hooks/useServiceListener';
import SessionService               from '@uServices/session';
import CurrentTenantService         from './services/currentTenant'
import ReleaseService               from '@universal/services/release'
import LoginAsSessionManagerService from "@uServices/loginAsSessionManager";
import CampaignService              from '@universal/services/campaign'
import './app.css';
import './common/var.css';
import NavigatorService from '@common/services/navigator'
import I18nService from '@universal/services/i18n'

type AppContentProps = {
  application: ApplicationLib
}

const AppContent: FunctionComponent<AppContentProps> = ({ application }) => {
  const navigator = React.useMemo(() => application.getService<NavigatorService>('navigator').navigator, [application]);

  useServiceListenener<SessionService>('session', ['onServiceUpdated']);
  useServiceListenener<CurrentTenantService>('currentTenant', ['onServiceUpdated']);
  useServiceListenener<ReleaseService>('release', ['onServiceUpdated']);
  useServiceListenener<LoginAsSessionManagerService>('loginAsSessionManager', ['onServiceUpdated']);
  useServiceListenener<CampaignService>('campaign', ['onServiceUpdated']);
  useServiceListenener<I18nService>('i18n', ['onServiceUpdated']);

  const currentTenant           = application.getService("currentTenant");
  const release                 = application.getService("release");
  const loginAsSessionManager   = application.getService("loginAsSessionManager");
  const campaign                = application.getService("campaign");
  
  React.useEffect(() => {
    document.getElementsByClassName("bs-public-start")[0].remove();
  }, []);
  
  return ( 
    <div className="c-app-root bs-app">
      <Router navigator={ navigator }>
        <Modal.Manager>
          <Tooltip.Manager>        
            <Applications />
          </Tooltip.Manager>
        </Modal.Manager>
        <MessageViewer />
        <TooltipServiceListener />
      </Router>
    </div>
  );
};

//Render app
const app = document.getElementById("root");

const App = () => {
  return (
    <Application application={application}>
    {() => (<AppContent application={application} />)}
    </Application>
  );
}

render((<App />), app);

//Remove default data
const defaultDataScript = document.querySelector('[default-data]')
if (defaultDataScript)
  defaultDataScript.outerHTML = ''


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



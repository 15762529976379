const states = ["open", "in_progress", "planned", "transferred", "resolved", "merged", "rejected", "deleted"];

const starterStates = ["open", "in_progress", "resolved", "rejected", "deleted"];

const workOrderStates = ["to_do", "ongoing", "finished"];

const commonHolidayDays = [
  "newYearDay",
  "easterMonday",
  "laborDay",
  "europeDay",
  "ascent",
  "pentecost",
  "easterDay",
  "pentecostMonday",
  "assumption",
  "allSaintsDay",
  "christmasDay"
];

const luxembourgHolidayDays = commonHolidayDays.concat([
  "luxemburgNationalDay",
  "saintStephenDay"
]);

const belgiumHolidayDays = commonHolidayDays.concat([
  "belgiumNationalDay",
  "armistice1918"
]);

const frenchHolidayDays = commonHolidayDays.concat([
  "frenchVictory1945",
  "frenchNationalDay",
  "armistice1918",
  "saintStephenDay",
  "goodFriday"
]);

export default {
  states,
  starterStates,
  workOrderStates,
  luxembourgHolidayDays,
  belgiumHolidayDays,
  frenchHolidayDays
};
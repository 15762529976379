import { ServiceStatement } from "@uTypes/business/Valorization";
import { FunctionComponent } from "react";
import { Grid, Divider } from "semantic-ui-react";
import { ServiceSubject } from "../subjects";
import Money from "@cComponents/money";
import ServiceStatementFiles from '../serviceStatementFiles';

interface ServiceStatementProps {
  statement: ServiceStatement;
};
const ServiceStatementComponent: FunctionComponent<ServiceStatementProps> = ({ statement }) => (
  <>
    <Grid.Row>
      <Grid.Column width="13">
        <ServiceSubject name={ statement.name } />
      </Grid.Column>
      <Grid.Column width="3" textAlign='center'><Money value={statement.cost} /></Grid.Column>
    </Grid.Row>
    { !!statement.files.length && (
      <ServiceStatementFiles files={statement.files.map(f => f._id)} />
    )}
    <Divider />
  </>
);

export default ServiceStatementComponent;

import ApiService from "@universal/services/api";
import { TestBuilder } from "../library/test";
import Application from "@universal/lib/application";
import RepositoryService from "@universal/services/repository";
import Category from "@universal/types/business/Category";
import Tenant from "@universal/types/business/Tenant";
import extractToken from "../library/extractToken";
import MemoryStorageService from "@universal/services/memoryStorage";
import SessionService from "@universal/services/session";
import { StartHandler } from "../library/createApplication";
import generateRandomelyString from "../library/generateRandomelyString";

type StepCreation = { appJvsCommercial: Application, appAdmin: Application };

const idKnote = "0000"

export default (title: string, createApplication: () => Promise<StepCreation>, createValidationApp: (beforeStart: StartHandler) => Promise<Application>) => {
  return TestBuilder.createTest("JVSCommercial", `Envoi du mail d'activation starter ${title} à une commune non cliente`)
    .addStep("Préparation de l'application", createApplication)
    .addStep("Récupération d'une structure non cliente", async ({ appJvsCommercial, appAdmin }: StepCreation) => {     
      const repository = appAdmin.getService<RepositoryService>("repository").get("Tenant").repository;
      let tenants = await repository.find({ 
        commercialOffer: null,
        "informations.siren": { $exists: true },
        clientIdJvs: { $ne: idKnote }
      }, {}) as Tenant[];

      if(tenants.length === 0){
        throw "Aucune structure trouvée";
      }

      return { 
        appJvsCommercial,
        appAdmin,
        tenant: tenants[0]
      };
    })
    .addStep("Envoi du mail HORIZON INFINITY", async ({ appJvsCommercial, appAdmin, tenant }: StepCreation & { tenant: Tenant } ) => {     
      await appJvsCommercial.getService<ApiService>("api").service("tenants", "sendStarterInvitation").execute({
        siren: tenant.informations.siren,
        idKnote: idKnote,
        commercialOffer: "HORIZON INFINITY",
        emailContact: generateRandomelyString(32) + "@test.com"
      });
      const repository = appAdmin.getService<RepositoryService>("repository").get("Tenant").repository;
      const tenantUpdated = await repository.findOne(tenant._id);

      if (tenantUpdated.clientIdJvs !== idKnote) {
        throw new Error ("L'idKnote n'a pas été mis à jour")
      }

      return { 
        appJvsCommercial,
        appAdmin
      };
    })
    .addStep("Validation de l'email HORIZON INFINITY", async ({ appJvsCommercial, appAdmin }: StepCreation) => {
      const token = await extractToken(appAdmin);     
      const appValidation = await createValidationApp(async (app) => {
        await app.getService<MemoryStorageService>("temporaryStorage").set("bsAdminToken", token);
      });

      return { 
        appJvsCommercial,
        appAdmin
      };
    })
    .build();
};
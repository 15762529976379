import React, { ComponentType, FunctionComponent, MouseEvent, PropsWithChildren } from "react";
import { useLocation } from "@universal/features/router";
import Issue from "@universal/types/business/Issue";
import Header from "./components/header";
import classNames from "@universal/lib/classNames";
import Display from "@uComponents/displayIf";
import useOpenCloseToggle from "@universal/behaviour/hooks/useOpenCloseToggle";
import T from "@uBehaviour/i18n";
import FeaturesDiscovery from "./views/featuresDiscovery";
import StarterGuide from "./components/starterGuide";
import Release from "@cEntities/release";
import withApplication from "@uHoc/withApplication";
import { UnsubscribeWizardModal } from "./components/unsubscribe";
import useService from "@universal/behaviour/hooks/useService";
import AclService from "@universal/services/acl";
import useLoginAs from "@hooks/useLoginAs";
import Button from "@common/components/button";
import AssistanceHelper from "@universal/helpers/assistance";
import useHelper from "@universal/behaviour/hooks/useHelper";
import ConfigurationService from "@universal/services/configuration";
import SessionService from "@universal/services/session";
import Modal from "@common/components/modal";

import './header.css';

const ReleaseList = withApplication(Release.List);

type ItemProps = {
  icon?: string,
  className?: string,
  onClick?: () => void,
}
const Item: FunctionComponent<PropsWithChildren<ItemProps>> = ({ icon, className, onClick, children }) => (
  <Button className={ classNames("bs-starter-header-item").addNotEmpty(className) } onClick={ onClick }>
    <span className={ icon && `fa fa-${icon}` } />
    <div>
      { children }
    </div>
  </Button>
);

type EntryItemProps = { 
  icon: string,
  lock?: boolean,
  DisplayedComponent?: ComponentType<{ close: () => void }>,
};
const EntryItem: FunctionComponent<PropsWithChildren<EntryItemProps>> = ({ lock, icon, DisplayedComponent, children }) => {
  const [showed, open, close] = useOpenCloseToggle();
  return(
    <>
      <div className={ classNames("bs-starter-header-entryItem-container").addIf("bs-starter-header-entryItem-locked", !!lock) }>
        { lock && <span className="bs-starter-header-entryItem-locked-icon fa fa-lock" /> }
        <Item icon={ icon } className={ "bs-starter-header-entryItem" } onClick={ open }>
        { children }
        </Item>
      </div>
      { 
        DisplayedComponent &&
          <Display.If condition={ showed }>
            <DisplayedComponent close={ close } />
          </Display.If>
      }
    </>
  );
};

const ButtonAssistance: FunctionComponent = () => {
  const assistance = useHelper<AssistanceHelper>("assistance");
  const openAssistance = React.useCallback(async () => await assistance.open(), [assistance]);  
  return (
    <Item icon="envelope" onClick={ openAssistance }>
      <T>send_message</T>
    </Item>
  )
};

const Logout: FunctionComponent = () => {
  const session = useService<SessionService>("session");
  const logout = React.useCallback(() => {
    session.logout();
  }, [session]);
  return (
    <Item icon="power-off" onClick={ logout }>
      <T>starter_f_session_userConnected_logout</T>
    </Item>
  );
}


const CommunicationKit: FunctionComponent = () => {
  const configurationService = useService<ConfigurationService>("configuration");
  const [ alreadyDownloaded, setAlreadyDownloaded ] = React.useState(false);
  const ref = React.useRef<HTMLAnchorElement>(null);

  const download = React.useCallback(() => {
    if (alreadyDownloaded || !ref.current) {
      return;
    }
    ref.current.click();
    setAlreadyDownloaded(true);
    setTimeout(() => setAlreadyDownloaded(false), 5000);
  }, [alreadyDownloaded, ref]);

  const stopProgragation = React.useCallback((event: MouseEvent) => { event.stopPropagation(); }, []);

  return (
    <Item icon="download" onClick={ download}>
        <T>communication_kit_label</T>
        <a ref={ ref } onClick={ stopProgragation } className="bs-starter-header-communicationKit" href={configurationService.get("communicationKitLink")} download />
    </Item>
  );
}



const NotificationSettings: FunctionComponent<any> = Modal.withUrlRequestButton(({ onClick }: { onClick: () => void }) => {
  return (
    <Item icon="bell" onClick={ onClick }>
      <T>starter_f_session_userConnected_notificationSettings</T>
    </Item>
  )
});

const HeaderImplementation: FunctionComponent = () => {
  const acl = useService<AclService>("acl");
  const loginAs = useLoginAs();

  const [current, goTo] = useLocation();

  const goToIssue = React.useCallback((issue: Issue) => {
    goTo(`${current}/${issue._id}`);
  }, [current, goTo]);

  const openDocumentation = React.useCallback(() => {
    window.open("https://betterstreet.gitbook.io/betterstreet-starter/");
  }, []);

  return (
    <Header onIssueSelected={ goToIssue }>
      <Header.Navigation>
        <Header.Item>
          <EntryItem icon="list-ul">
            <T>starter_header_item_issuesList</T>
          </EntryItem>
        </Header.Item>
        <Header.Item>
          <EntryItem icon="calendar" lock DisplayedComponent={ FeaturesDiscovery.Planning }>
            <T>starter_header_item_planning</T>
          </EntryItem>
        </Header.Item>
        <Header.Item>
          <EntryItem icon="line-chart" lock DisplayedComponent={ FeaturesDiscovery.Statistics }>
            <T>starter_header_item_stats</T>
          </EntryItem>
        </Header.Item>
        <Header.Item>
          <EntryItem icon="plus-circle" lock DisplayedComponent={ FeaturesDiscovery.NewIssue }>
            <T>starter_header_item_newIssue</T>
          </EntryItem>
        </Header.Item>
      </Header.Navigation>
      <Header.Help>
          <Header.Item>
            <ButtonAssistance />
          </Header.Item>
          <Header.Item displayOnly="web">
            <Item icon="book" onClick={ openDocumentation }>
              <T>documentation</T>
            </Item>
          </Header.Item>
          <Header.Item displayOnly="web">
            <EntryItem icon="users" DisplayedComponent={ StarterGuide }>
              <T>help_starterDemo</T>
            </EntryItem>
          </Header.Item>
          <Header.Item displayOnly="web">
            <EntryItem icon="gitlab" DisplayedComponent={ ReleaseList }>
              <T>release_note</T>
            </EntryItem>
          </Header.Item>
      </Header.Help>
      <Header.Connexion>
        <Header.Item>
          <NotificationSettings displayModalValue="notificationSettings" />
        </Header.Item>
        <Header.Item displayOnly="web">
          <CommunicationKit />
        </Header.Item>
        { acl.connectedUserIsAllow("tenants", "viewUnsubscribeStarter") && (
          <Header.Item displayOnly="web">
            <EntryItem icon="window-close" DisplayedComponent={ UnsubscribeWizardModal }>
              <T>starter_header_item_unsubscribe</T>
            </EntryItem>
          </Header.Item>
        )}
        <Header.Item>
          <Logout />
        </Header.Item>
        { loginAs.map((login, index) => (
          <Header.Item key={ index } displayOnly="web">
          { login }
          </Header.Item>
        ))}
      </Header.Connexion>
    </Header>
  )
}


export default HeaderImplementation;
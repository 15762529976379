import React    from "react";
import Layout   from "@components/formLayout";
import Form     from '@cBehaviour/form'
import T        from '@cBehaviour/i18n';
import Tenant   from "@root/entities/tenants";
import Observer from "@cLib/observer";
import useService from "@uBehaviour/hooks/useService";
import ScrollBar from "@common/components/scrollBar/scrollBar";


const TenantForm = ({ id, close }) => {
  const observer = React.useMemo(() => new Observer(), []);
  const aclService = useService("acl");
  const tenantId = id === "new" ? null : id;
  const displayLogs = aclService.connectedUserIsAllow("tenants", "manage") && tenantId;
  const repository = useService("repository");
  const [tenant, setTenant] = React.useState(null);
  React.useEffect(() => {
    if(tenantId){
      repository.get("Tenant").repository.get([{ _id: tenantId }])
        .then(tenants => {
          if(tenants.length){
            setTenant(tenants[0]);
          }
        });
    }
  }, [tenantId, repository]);
  return (
    <Layout close={ close } type="fullscreen">
      <Layout.Title>
        <Form.Observer observer={ observer }>
        {tenant => {
          return (
          <div>
            <T>tenant</T>
            <span> --- </span>
            <span><T>{ tenant.name }</T></span>
          </div>
        )}}
        </Form.Observer>
      </Layout.Title>
      <Layout.Form>
        <Tenant.Form id={ tenantId } observer={ observer }/>
      </Layout.Form>
      { displayLogs && tenant && (
        <Layout.Log>
          <ScrollBar>
            <Tenant.Log tenant={ tenant } />
          </ScrollBar>
        </Layout.Log>
      )}
    </Layout>
  )
}

export default TenantForm;
import React        from "react";
import _            from "lodash";
import Data         from "@uBehaviour/data";
import Button       from '@cComponents/button';
import Release      from "@root/entities/releases";
import ReleaseForm  from './form';
import T            from "@cBehaviour/i18n";
import Filter       from "@cComponents/filter";
import FilteredList from "@cFeatures/filter/list";
import Input        from "@cComponents/input";
import { Link }     from "@common/features/router";
import { withLink, withModalLinked } from "@cHoc/withLinkAndModalLinked";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar from "@cComponents/searchbar";
import { escapeRegExp } from "@uLib/tool";

const LinkedReleaseItem = withLink(Release.Item);
const ModalizedReleaseForm = withModalLinked(ReleaseForm);

const query = () => {
  return null;
}

const textSearchBuildQuery = (value) => {
  return { $or: [
    { version:   { '$regex': escapeRegExp(value), '$options': 'i' } },
    { description: { '$regex': escapeRegExp(value), '$options': 'i' } }
  ]};
}

const defaultQuery = () => {
  return [];
}

const ReleaseList = ({}) => (
  <>
    <FilteredList default={defaultQuery()}>
      <FilteredList.Filter>
        <Release.Filters />
      </FilteredList.Filter>
      <FilteredList.Header>
        <ListHeaderLayout>
          <ListHeaderLayout.Center>
            <Filter.Generic name="text" buildQuery={value => textSearchBuildQuery(value)} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
            {(value, set, clear) => (
              <Searchbar>
                <Input.Text focusOnMount className="bs-filter-text" value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                  <T>freesearch</T>
                </Input.Text>
              </Searchbar>
            )}
            </Filter.Generic>
          </ListHeaderLayout.Center>
          <ListHeaderLayout.Right>
            <Link to="/new">
              <Button.Stylized.Text><T>add</T></Button.Stylized.Text>
            </Link>
          </ListHeaderLayout.Right>
        </ListHeaderLayout>
      </FilteredList.Header>
      <FilteredList.List>
      {composeQuery => (
        <Data.List model={"Release"} query={composeQuery(query())} sort={{ 'createdAt': -1 }}>
          <LinkedReleaseItem />
        </Data.List>
      )}
      </FilteredList.List>
    </FilteredList>
    <ModalizedReleaseForm  style={{ width: "90vw", height: "90vh" }}/>
  </>
);

  export default ReleaseList;
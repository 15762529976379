// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-starter-header {
  background-color: var(--std-color-white);
  box-shadow: 0px 1px 8px 0px #c4c4c4;
  height: 48px;
  color: var(--std-color-gray);
}
.bs-starter-header-left {
  flex-basis: 20%;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--std-color-black);
}

.bs-starter-header-right {
  height: 100%;
}

.bs-starter-header-web {
  height: 100%;
  display: flex;
  align-items: center;
}

.bs-starter-header-navigation {
  flex-grow: 2;
  display: flex;
  align-items: center;
  gap: var(--std-size-inter-element);
}

.bs-starter-header-other {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/applications/starter/components/header/web.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,mCAAmC;EACnC,YAAY;EACZ,4BAA4B;AAC9B;AACA;EACE,eAAe;EACf,YAAY;EACZ,cAAc;EACd,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8CAA8C;AAChD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".bs-starter-header {\n  background-color: var(--std-color-white);\n  box-shadow: 0px 1px 8px 0px #c4c4c4;\n  height: 48px;\n  color: var(--std-color-gray);\n}\n.bs-starter-header-left {\n  flex-basis: 20%;\n  flex-grow: 0;\n  flex-shrink: 0;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  border-right: 1px solid var(--std-color-black);\n}\n\n.bs-starter-header-right {\n  height: 100%;\n}\n\n.bs-starter-header-web {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.bs-starter-header-navigation {\n  flex-grow: 2;\n  display: flex;\n  align-items: center;\n  gap: var(--std-size-inter-element);\n}\n\n.bs-starter-header-other {\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { FunctionComponent, PropsWithChildren } from "react";
import { Paginated } from "@cComponents/wizard";
import T             from "@uBehaviour/i18n";
import CTA           from "../../features/cta";
import Template      from "./template";
import Text, { Style } from "@cComponents/text";
import Slot from "@universal/components/slot2";
import PromotionalSubtitle from "./promotionalSubtitle";

import "./index.css";
import useResponsiveObserver from "@universal/behaviour/hooks/useResponsiveObserver";
import Modal from "@common/components/modal";
import ResponsiveView from "./responsiveView";

const Image = Slot();

const BuildingBase: FunctionComponent<PropsWithChildren> & { Image: typeof Image } = ({ children }) => {
  const image = Image.get(children);
  const restChildren = Image.not(children);

  return (
    <Template>
      <Template.Image>{ image }</Template.Image>
      <Template.Icon><span className="fa fa-building-o bs-featuresDiscovery-building-icon" /></Template.Icon>
      <Template.Title><Text style={ Style.huge.bold }><T>starter_featuresDiscovery_building_title</T></Text></Template.Title>
      { restChildren }
    </Template>
  );
};
BuildingBase.Image = Image;

const BuildingCentralize: FunctionComponent = () => {
  const focusedText = <Text style={ Style.bold }> <T>starter_featuresDiscovery_building_centralize_focus</T> </Text>;

  return (
    <BuildingBase>
      <Image><img src="/images/starter/featuresDiscovery/building_1.png" alt="Gestion de bâtiment"/></Image>
      <Text><T bind={{ focusedText }}>starter_featuresDiscovery_building_centralize</T></Text>
    </BuildingBase>
  );
};


const BuildingManage: FunctionComponent = () => {
  const focusedText = <Text style={ Style.bold }><T>starter_featuresDiscovery_building_manage_focus</T></Text>;

  return (
    <BuildingBase>
      <Image><img src="/images/starter/featuresDiscovery/building_2.png" alt="Gestion de bâtiment"/></Image>
      <div className="bs-featuresDiscovery-content">
        <Text.Paragraph style={ Style.bold }>
          <T>starter_featuresDiscovery_building_manage_subtitle</T>
        </Text.Paragraph>
        <Text>
          <T bind={{ focusedText }}>starter_featuresDiscovery_building_manage</T>
        </Text>
      </div>
    </BuildingBase>
  );
};

type BuildingUpsaleProps = {
  redirectToWebsite?: boolean
}

const BuildingUpsale: FunctionComponent<BuildingUpsaleProps> = ({ redirectToWebsite }) => (
  <BuildingBase>
    <Image><img src="/images/starter/featuresDiscovery/building_3.png" alt="Gestion de bâtiment"/></Image>
    
    <PromotionalSubtitle>
      <div className="bs-featuresDiscovery-content">
        <Text>
          <T>starter_featuresDiscovery_building_upsale</T>
        </Text>
        <span className="bs-featuresDiscovery-content-CTA">
          <CTA.DiscoverOffers.Orange redirectToWebsite={ redirectToWebsite } />
        </span>
      </div>
    </PromotionalSubtitle>
  </BuildingBase>
);

type BuildingProps = {
  close: () => void,
};
const Building: FunctionComponent<BuildingProps> = ({ close }) => {
  const isLargeScreen = useResponsiveObserver();
  if (isLargeScreen) {
    return (
      <Paginated close={ close } style={{ width: "920px", height: "540px" }}>
        <Paginated.Step>
          <BuildingCentralize />
        </Paginated.Step>
        <Paginated.Step>
          <BuildingManage />
        </Paginated.Step>
        <Paginated.Step>
          <BuildingUpsale />
        </Paginated.Step>
      </Paginated>
    );
  }
  return (
      <Modal.Show close={ close } style={{ width: "90vw", height: "90vh" }}>
        <ResponsiveView>
          <BuildingCentralize />
          <BuildingManage />
          <BuildingUpsale redirectToWebsite={ !isLargeScreen } />
        </ResponsiveView>
      </Modal.Show>
    );
}

export default Building;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen {
  .bs-app {
    width: 100vw;
    height: calc(100vh - env(safe-area-inset-bottom));
    overflow: hidden;
  } 
}

`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;IACZ,iDAAiD;IACjD,gBAAgB;EAClB;AACF","sourcesContent":["@media screen {\n  .bs-app {\n    width: 100vw;\n    height: calc(100vh - env(safe-area-inset-bottom));\n    overflow: hidden;\n  } \n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

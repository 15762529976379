import ApiService from "@universal/services/api";
import { TestBuilder } from "../library/test";
import Application from "@universal/lib/application";
import RepositoryService from "@universal/services/repository";
import Category from "@universal/types/business/Category";
import Tenant from "@universal/types/business/Tenant";
import extractToken from "../library/extractToken";
import MemoryStorageService from "@universal/services/memoryStorage";
import SessionService from "@universal/services/session";
import { StartHandler } from "../library/createApplication";
import generateRandomelyString from "../library/generateRandomelyString";

type StepCreation = { appJvsCommercial: Application, appAdmin: Application };

const idKnote = "0000"

export default (createApplication: () => Promise<StepCreation>, createValidationApp: (beforeStart: StartHandler) => Promise<Application>) => {
  return TestBuilder.createTest("JVSCommercial", "Envoi d'un mail d'activation starter à une commune cliente")
    .addStep("Préparation de l'application", createApplication)
    .addStep("Récupération d'une structure cliente", async ({ appJvsCommercial, appAdmin }: StepCreation) => {     
      const repository = appAdmin.getService<RepositoryService>("repository").get("Tenant").repository;
      let tenants = await repository.find({ 
        "settings.commercialOffer": { $ne: null },
        "informations.siren": { $exists: true },
        clientIdJvs: { $ne: idKnote }
      }, {}) as Tenant[];

      if(tenants.length === 0){
        throw "Aucune structure trouvée";
      }

      return { 
        appJvsCommercial,
        appAdmin,
        tenant: tenants[0]
      };
    })
    .addStep("Envoi de mail pour cette commune", async ({ appJvsCommercial, appAdmin, tenant }: StepCreation & { tenant: Tenant } ) => {     
      await appJvsCommercial.getService<ApiService>("api").service("tenants", "sendStarterInvitation").execute({
        siren: tenant.informations.siren,
        idKnote: idKnote,
        commercialOffer: "HORIZON INFINITY",
        emailContact: generateRandomelyString(32) + "@test.com"
      });
      
      return {
        appAdmin
      };
    })
    .addStep("Validation que l'email n'a pas été envoyé ", async ({ appAdmin }: StepCreation) => {
      const token = await extractToken(appAdmin);     
      if (token !== null) {
        throw new Error("Le mail HORIZON INFINITY a été envoyé à la commune alors qu'elle est déjà cliente");
      }
    })
    .build();
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issue-list-bulkUpdate-form-header{
  padding: 10px 15px;
  border-bottom: 1px solid #cccccc;
  color: #5e676c;
  font-size: 26px;
  font-weight: 600;
}


.bs-issue-list-bulkUpdate-form-footer{
  border-top: 1px solid #cccccc;
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-end;
}

.bs-issue-list-bulkUpdate-form-footer>.bs-button{
  margin: 10px;
}

.bs-issue-list-bulkUpdate-form-content {
  padding: 10px 15px;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/defaultBulkUpdateForm.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gCAAgC;EAChC,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;;AAGA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".bs-issue-list-bulkUpdate-form-header{\n  padding: 10px 15px;\n  border-bottom: 1px solid #cccccc;\n  color: #5e676c;\n  font-size: 26px;\n  font-weight: 600;\n}\n\n\n.bs-issue-list-bulkUpdate-form-footer{\n  border-top: 1px solid #cccccc;\n  background-color: #f5f5f5;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.bs-issue-list-bulkUpdate-form-footer>.bs-button{\n  margin: 10px;\n}\n\n.bs-issue-list-bulkUpdate-form-content {\n  padding: 10px 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import useApplication from "@universal/hooks/useApplication";
import { ComponentType, FunctionComponent } from "react";

function withApplication<T>(Component: ComponentType<T>, propertyName: string = "application"): FunctionComponent<T> {
  return (props: T) => {
    const application = useApplication();
    return <Component {...{...props, [propertyName]: application}} />;
  }
};

export default withApplication;
import React, { FunctionComponent, PropsWithChildren } from 'react';
import ButtonBase from "./base";

import './display.css';
import classNames from '@universal/lib/classNames';

type DisplayProps = {
  onClick: () => void;
  className?: string;
}

const Display: FunctionComponent<PropsWithChildren<DisplayProps>> = ({ onClick, className, children }) => (
  <ButtonBase className={classNames("bs-button").addNotEmpty(className)} onClick={ onClick }>
    { children }
  </ButtonBase>
);

export default Display;
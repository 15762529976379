import React, { PureComponent } from 'react'
import T from 'i18n-react'
import classNames from 'classnames'
import FontAwesome from 'react-fontawesome'
import Confirmation from 'client/features/common/components/Confirmation.jsx'
import ButtonGroupSelector from '../../../common/components/ButtonGroupSelector'

import './Priority.css';

const priorities = ['a_low', 'j_middle', 'z_high']

class IssueDetailsPriority extends PureComponent {

    constructor(props) {
        super(props)
        this._renderPrioritiesButtons = this._renderPrioritiesButtons.bind(this)
    }
    
    _renderPrioritiesButtons(priority, edit) {
        if (!edit) {
            return (
                <span>
                    <T.span text={ priority }/>
                    <FontAwesome
                        name='warning'
                        className={ `icon-priority-${priority}` }
                        style={ { marginTop: 1, marginLeft: 2 } }
                    />
                </span>
            )
        }
        const options = priorities.map((option) => ({
            isSelected: option === priority,
            label: T.translate(option),
            value: option,
            className: classNames({
                'c-button-group-selector-priority__middle': (option === 'j_middle'),
                'c-button-group-selector-priority__high': (option === 'z_high'),
            })
        }))
        return <ButtonGroupSelector onSelect={ this.props.onClick } className='c-button-group-selector-priority' options={ options }/>
    }

    render() {
        const { priority, edit, confirmation } = this.props
        return <div className='issue-detail-field'>
            <div className='o-layout u-margin-top-small o-layout--middle'>
                <div className='o-layout__item u-1of3-tablet'>
                    <span className='c-issue-detail-field__label'>{ T.translate('priority') }:</span>
                </div>
                <div className='o-layout__item u-2of3'>
                    <div className='c-confirmation-container'>
                        <div className='c-confirmation-container__component'>
                            { this._renderPrioritiesButtons(priority, edit) }
                        </div>

                        <Confirmation data={ confirmation }/>

                    </div>
                </div>
            </div>
        </div>
    }
}

export default IssueDetailsPriority
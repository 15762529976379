// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-equipment-item {
  height: 100px;
}
.bs-equipment-item .bs-action {
  display: flex;
  justify-content: space-between;
  color: #27ae60; 
}
.bs-equipment-description{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bs-equipment-item .bs-item2-content{
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/entities/equipments/components/item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;AAChB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".bs-equipment-item {\n  height: 100px;\n}\n.bs-equipment-item .bs-action {\n  display: flex;\n  justify-content: space-between;\n  color: #27ae60; \n}\n.bs-equipment-description{\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.bs-equipment-item .bs-item2-content{\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-form-category {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bs-form-category .bs-fieldGroup .bs-subTitle {
  color: #E84C24;;
}
.bs-form-category-type-readonly {
  margin-top: var(--std-size-inter-element);
  color: rgb(90, 98, 102);
}
.bs-form_category-deadlineReminder-container {
  display: flex;
  flex-direction: "row";
}
.bs-form_category-deadlineReminder-container>.bs-input-numeric {
  width: 60px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/entities/categories/components/form.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,cAAc;AAChB;AACA;EACE,yCAAyC;EACzC,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".bs-form-category {\n  width: 100%;\n  padding: 0 15px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.bs-form-category .bs-fieldGroup .bs-subTitle {\n  color: #E84C24;;\n}\n.bs-form-category-type-readonly {\n  margin-top: var(--std-size-inter-element);\n  color: rgb(90, 98, 102);\n}\n.bs-form_category-deadlineReminder-container {\n  display: flex;\n  flex-direction: \"row\";\n}\n.bs-form_category-deadlineReminder-container>.bs-input-numeric {\n  width: 60px;\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

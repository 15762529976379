import React, { FunctionComponent, PropsWithChildren } from "react";
import "./searchbar.css";
import classNames from "@universal/lib/classNames";

interface SearchbarProps extends PropsWithChildren {
  className?: string;
}

const Searchbar: FunctionComponent<SearchbarProps> = ({ children, className }) => (
  <div className={classNames("bs-searchbar").addNotEmpty(className)}>
    {children}
  </div>
);

export default Searchbar;

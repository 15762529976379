// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-release-item{
  height: 100px;
}
.bs-release-item .bs-item2-container{
  position: relative;
}
.bs-release-item .bs-item2-illustration{
  color: #999;
  font-size: 90px;
  line-height: 90px;
  padding: 5px;
}
.bs-release-item .bs-item2-content{
  padding: 10px;
}
.bs-release-item .bs-item2-content-body{
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}
.bs-release-item .bs-item2-content-body>p{
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}
.bs-release-item .bs-release-state {
  position: absolute;
  top: 10px;
  right: 10px;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
  padding: 2px;
  border-radius: 2px;
}
.bs-release-published{
  border-color: rgba(39, 174, 96, 1);
  background-color: rgba(39, 174, 96, 0.25);
}
.bs-release-not-published{
  border-color: rgba(230, 75, 34, 1);
  background-color: rgba(230, 75, 34, 0.25);
}
.bs-release-item .bs-item2-content-footer{
  display: flex;
  flex-direction: row;
}
.bs-release-item .bs-item2-content-footer>*{

}`, "",{"version":3,"sources":["webpack://./src/entities/releases/components/item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,cAAc;EACd,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,0BAA0B;EAC1B,wBAAwB;AAC1B;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,kCAAkC;EAClC,yCAAyC;AAC3C;AACA;EACE,kCAAkC;EAClC,yCAAyC;AAC3C;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;;AAEA","sourcesContent":[".bs-release-item{\n  height: 100px;\n}\n.bs-release-item .bs-item2-container{\n  position: relative;\n}\n.bs-release-item .bs-item2-illustration{\n  color: #999;\n  font-size: 90px;\n  line-height: 90px;\n  padding: 5px;\n}\n.bs-release-item .bs-item2-content{\n  padding: 10px;\n}\n.bs-release-item .bs-item2-content-body{\n  flex-shrink: 1;\n  flex-grow: 1;\n  overflow: hidden;\n}\n.bs-release-item .bs-item2-content-body>p{\n  margin-block-start: 0.25em;\n  margin-block-end: 0.25em;\n}\n.bs-release-item .bs-release-state {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  border-width: 1px;\n  border-style: solid;\n  font-size: 12px;\n  padding: 2px;\n  border-radius: 2px;\n}\n.bs-release-published{\n  border-color: rgba(39, 174, 96, 1);\n  background-color: rgba(39, 174, 96, 0.25);\n}\n.bs-release-not-published{\n  border-color: rgba(230, 75, 34, 1);\n  background-color: rgba(230, 75, 34, 0.25);\n}\n.bs-release-item .bs-item2-content-footer{\n  display: flex;\n  flex-direction: row;\n}\n.bs-release-item .bs-item2-content-footer>*{\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-assignment-state-commentAndValorize {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bs-assignment-state-commentAndValorize>.bs-section-header,
.bs-assignment-state-commentAndValorize>.bs-section-footer {
  flex: 0 0 auto;
}

.bs-assignment-state-commentAndValorize-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
}

.bs-assignment-state-commentAndValorize-body > div:first-child {
  width: 25%;
  flex: 0 0 auto;
  padding: 0 15px;
}

.bs-assignment-state-commentAndValorize-body > div:last-child {
  flex-grow: 1;
}

.bs-assignment-state-commentAndValorize-body-content {
  padding: 0 15px 15px 15px;
}

.bs-assignment-state-commentAndValorize-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bs-assignment-state-commentAndValorize-footer>* {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/entities/assignments/components/state.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-assignment-state-commentAndValorize {\n  overflow: hidden;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.bs-assignment-state-commentAndValorize>.bs-section-header,\n.bs-assignment-state-commentAndValorize>.bs-section-footer {\n  flex: 0 0 auto;\n}\n\n.bs-assignment-state-commentAndValorize-body {\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  overflow: hidden;\n}\n\n.bs-assignment-state-commentAndValorize-body > div:first-child {\n  width: 25%;\n  flex: 0 0 auto;\n  padding: 0 15px;\n}\n\n.bs-assignment-state-commentAndValorize-body > div:last-child {\n  flex-grow: 1;\n}\n\n.bs-assignment-state-commentAndValorize-body-content {\n  padding: 0 15px 15px 15px;\n}\n\n.bs-assignment-state-commentAndValorize-footer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.bs-assignment-state-commentAndValorize-footer>* {\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React        from "react";
import Release      from "@root/entities/releases";
import Data         from "@uBehaviour/data";
import useService from "@universal/behaviour/hooks/useService";


 const ReleaseForm = ({ id }) => {
  const repository = useService('repository');
  if(id === "new"){
    return  (
      <Release.Form data={ repository.get("Release").default }/>
    );
  } else {
    return (
      <Data.One id={id} load={{ "newFeatures.files": 1, "bugsFixed.files": 1}} model="Release">
        <Release.Form />
      </Data.One> 
    )
  }
}

export default ReleaseForm;
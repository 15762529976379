// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-valorizations-set {
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #e3e3e3;
  margin-bottom: 20px;
}

.bs-valorizations-set-title {
  padding-bottom: 10px; 
}

.bs-valorizations-set-item {
  border-top: 1px solid #eee;
  padding-top: 20px;
  padding-bottom: 20px;
}

.bs-valorizations-set-controlOpen:hover {
  cursor: pointer;
}
.bs-valorizations-set-controlOpen{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}
.bs-valorizations-set-controlOpen>span:first-child {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/entities/valorizations/components/setByType.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-valorizations-set {\n  background-color: #f5f5f5;\n  padding: 10px;\n  border: 1px solid #e3e3e3;\n  margin-bottom: 20px;\n}\n\n.bs-valorizations-set-title {\n  padding-bottom: 10px; \n}\n\n.bs-valorizations-set-item {\n  border-top: 1px solid #eee;\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n.bs-valorizations-set-controlOpen:hover {\n  cursor: pointer;\n}\n.bs-valorizations-set-controlOpen{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: right;\n}\n.bs-valorizations-set-controlOpen>span:first-child {\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import md5 from 'md5';
import moment from 'moment';
import LifeCycleManager from "./lifeCycleManager";
import Store from "./store";
import Geometry from "./geometry";
import Criterion from './filter';

export default class Key {
  static create(properties) {
    if (properties instanceof Key) {
      return properties;
    }
    if (!Array.isArray(properties)) {
      properties = [properties];
    }
    return new Key(properties)
  }
  constructor(properties) {
    this._properties = properties
  }
  get properties(){
    return this._properties.slice();
  }
  extract(object) {
    const result = {}
    this._properties.forEach(prop => {
      result[prop] = object[prop]
    })
    return result
  }
  hash(object) {
    return md5(JSON.stringify(this.extract(object)))
  }
  equals(a, b) {
    return this.hash(a) === this.hash(b);
  }
  toQuery(datas){
    if(!datas.length){
      throw new Error('datas must have minimum one occurence');
    }
    if(this.properties.length === 1){
      return { [this.properties[0]]: { $in: datas.map(data => data[this.properties[0]]) }};
    }else{
      return {
        $or: datas.map(data => {
          return { 
            $elemMatch: this.properties.reduce((acc, property) => {
              acc[property] = data[property];
              return acc;
            }, {})
          };
        })
      };
    }
  }
}

class DataBrowser {
  constructor(entity, type, path) {
    this._entity = entity;
    this._type   = type;
    this._path   = path ? path : [];
  }
  get(path) {
    if (this[path] instanceof Function) {
      const oThis = this;
      return function () {
        return oThis[path].apply(oThis, Array.prototype.slice.apply(arguments));
      }
    }
    path = this._path.concat([path]);
    return this._type.browserGet(this._entity, path);
  }
  set(path, value) {
    return this._type.browserSet(this._entity, this._path.concat([path]), value);
  }
  toPlainText() {
    return JSON.parse(JSON.stringify(this._entity.extract(this._path)));
  }
  eval(path) {
    const realPath = this._path.concat(path);
    return this._entity.extractAll(realPath);
  }
}
class ObjectBrowser extends DataBrowser {
}
class ArrayBrowser extends DataBrowser {
  get(path) {
    if (path === "length") {
      return this._entity.extract(this._path).length;
    }
    return super.get(path);
  }
  map(preGet, handler) {
    const array = this._entity.extract(this._path);
    return array.map((val, index) => {
      return handler(preGet(this._type.browserGet(this._entity, this._path.concat([index]))), index, preGet(this));
    });
  }
  reduce(preGet, handler, initialValue) {
    const array = this._entity.extract(this._path);
    return array.reduce((acc, val, index) => {
      return handler(acc, preGet(this._type.browserGet(this._entity, this._path.concat([index]))), index);
    }, initialValue);
  }
  filter(preGet, handler) {
    const array = this._entity.extract(this._path);
    const ids   = array.filter((val, index) => {
      return handler(preGet(this._type.browserGet(this._entity, this._path.concat([index]))), index);
    });
    return ids.map(id => {
      const index = array.indexOf(id);
      return preGet(this._type.browserGet(this._entity, this._path.concat([index])));
    });
  }
  forEach(preGet, handler) {
    const array = this._entity.extract(this._path);
    return array.forEach((val, index) => {
      return handler(preGet(this._type.browserGet(this._entity, this._path.concat([index]))), index);
    });
  }
  some(preGet, handler) {
    const array = this._entity.extract(this._path);
    return array.some((val, index) => {
      return handler(preGet(this._type.browserGet(this._entity, this._path.concat([index]))), index);
    });
  }
  find(preGet, handler) {
    const array = this._entity.extract(this._path);
    const id    = array.find((val, index) => {
      return handler(preGet(this._type.browserGet(this._entity, this._path.concat([index]))), index);
    });
    if (! id) 
      return;
    
    const index = array.indexOf(id);
    return preGet(this._type.browserGet(this._entity, this._path.concat([index])));
  }
  includes(preGet, value) {
    const array = this._entity.extract(this._path);
    return array.includes(value);
  }
  join(preGet, separator) {
    const array = this._entity.extract(this._path);
    return array.join(separator);
  }
  splice(preGet, index, nbr) {
    throw new Error('TO DO');
  }
}
class EntityManager {
  constructor() {
    this._store = new LifeCycleManager("EntityManager");
  }
  create(model, data, reload = false) {
    const id = Entity.getEntityId(model, data);
    let entity;
    if (!this._store.has(id)) {
      this._store.create(id, new Entity(model, data));
      entity = this._store.register(id);
    } else {
      entity = this._store.register(id);
      if (reload) {
        entity._update(data);
      }
    }
    return entity;
  }
  update(model, entity, data) {
    const newId = Entity.getEntityId(model, data);
    if (newId !== entity.id) {
      this._store.mutateId(entity.id, newId);
      entity._id = newId;
    }
    entity._update(data);
  }
  has(model, data) {
    return this._store.has(Entity.getEntityId(model, data));
  }
  get(model, data) {
    const id   = Entity.getEntityId(model, data);
    let entity = null;
    if (this._store.has(id)) {
      entity = this._store.register(id);
    }
    return entity;
  }
  register(entity) {
    this._store.register(entity.id);
  }
  unregister(entity) {
    this._store.unregister(entity.id);
  }

  get entities(){
    return Object.values(this._store.datas);
  }
}

class Model {
  constructor(name, key, type, repository, defaultValue) {
    this._name          = name;
    this._key           = key;
    this._type          = type;
    this._entityManager = entityManager;
    this._repository    = new Repository(this._entityManager, this, repository);
    this._default       = defaultValue;
  }
  get entityManager() {
    return this._entityManager;
  }
  get default() {
    if (this._default) {
      if(this._default instanceof Function) {
        return this._default();
      }
      return JSON.parse(JSON.stringify(this._default));
    }
    return {};
  }
  get type() {
    return this._type;
  }
  updateSet(action, data) {
    let entity;
    if (action === "create") {
      entity = this._entityManager.create(this, data);
    } else if (action === "update") {
      entity = this._entityManager.create(this, data, true);
    } else if (action === "remove" && this._entityManager.has(this, data)) {
      entity = this._entityManager.get(this, data);
    }
    return entity ? proxyFactory(entity) : null;
  }
  extractAll(path, values) {
    if (!Array.isArray(values)) {
      values = [values];
    }
    return this._type.extractAll(path, values);
  }
  eval(path, entity) {
    const type = this._type.getType(path);
    return type.eval(path, entity);
  }
  get name() {
    return this._name;
  }
  get key() {
    return this._key;
  }
  get repository() {
    return this._repository;
  }
  create(data) {
    return proxyFactory(this.entityManager.create(this, data, true));
  }
  update(entity, data) {
    this.entityManager.update(this, entity, data);
  }
  validate(object, errors) {
    if (!errors) {
      errors = () => {};
    }
    const errs     = [];
    const fnErrors = (error => {
      errs.push(error);
      errors(error);
    });
    this._type.validate(object, fnErrors, "")
    return errs;
  }
  getType(path, byPassArray) {
    return this._type.getSubType(path, byPassArray);
  }
}
class Entity extends Store {
  static getEntityId(model, data) {
    return `Entity::${
      model.name
    }#${
      model.key.hash(data)
    }`;
  }
  constructor(model, data) {
    super(Entity.getEntityId(model, data), data, {
      update: function (old, payload) {
        for (let key in old) {
          delete old[key];
        }
        for (let key in payload.new) {
          old[key] = payload.new[key];
        }
      }
    }, state => JSON.parse(JSON.stringify(state)));
    this._model = model;
  }
  get key() {
    return this.datas._id;
  }
  get model(){
    return this._model;
  }
  addListener(listener) {
    this.onStoreUpdated.addListener(listener);
  }

  removeListener(listener) {
    this.onStoreUpdated.removeListener(listener);
  }
  update(data) {
    this._model.update(this, data);
  }
  _update(data) {
    this._store.dispatch({type: "update", new: data});
  }
  get(propertyName) {
    return this._model.type.browserGet(this, [propertyName]);
  }
  extractAll(path) {
    return this._model.extractAll(path, this.datas);
  }
  extract(path) {
    return path.reduce((val, path) => val ? val[path] : val, this.datas);
  }
  has(path) {
    const data = this.extract(path);
    return data !== undefined && data !== null;
  }
  hasMethod(methodName) {
    console.log(`Entity try to access to ${methodName} that are not set`);
    return false;
  }
}


const proxyFactory = (val) => {
  if (val instanceof DataBrowser) {
    return new Proxy(val, browserProxyManager);
  }
  if (val instanceof Entity) {
    return new Proxy({
      main        : val,
      dependencies: []
    }, entityProxyManager);
  }
  if(Array.isArray(val) && val.length > 0 && val[0] instanceof Entity){
    return val.map(v => proxyFactory(v));
  }
  return val;
}

const browserProxyManager = {
  get(browser, propertyName) {
    if (propertyName === "eval") {
      return (path) => {
        return proxyFactory(browser.eval(path));
      }
    }
    const ret = browser.get(propertyName);
    if (ret instanceof Function) {
      return function () {
        return ret(proxyFactory, ...arguments);
      };
    }
    return proxyFactory(ret);
  },
  set(browser, propertyName, value) {
    browser.set(propertyName, value);
    return true;
  }
}
const entityProxyManager = {
  get(entities, propertyName) {
    switch (propertyName) {
      case "window":
        return undefined;
      case "id":
        return entities.main.id;
      case "toJSON":
        return() => (entities.main.datas);
      case "register":
      case "addListener":
        return(listener) => {
          entities.dependencies.forEach(entity => {
            entityManager.register(entity);
          });
          entityManager.register(entities.main);
          if (propertyName === "addListener") {
            entities.main.addListener(listener);
          }
        };
      case "dispose":
      case "removeListener":
        return (listener) => {
          if (propertyName === "removeListener") {
            entities.main.removeListener(listener);
          }
          entityManager.unregister(entities.main);
          entities.dependencies.forEach(entity => {
            entityManager.unregister(entity);
          });
        };
      case "update":
        return(value) => {
          return entities.main.update(value);
        };
      case "toPlainText":
        return() => {
          return JSON.parse(JSON.stringify(entities.main.datas));
        };
      case "equals":
        return (entity) => {
          return entities.main._model.key.equals(entities.main.datas, entity);
        }
      case "eval":
        return(path) => {
          return proxyFactory(entities.main._model.eval(path, entities.main));
        };
      case "_updateDependencies":
        return (dependencies) => {
          entities.dependencies = dependencies;
        }
      case "toString": 
        return () => {
          return entities.main.id;
        };
      case "valueOf":
        return () => {
          return entities.main.valueOf();
        };
      default:
    }

    try {
      return proxyFactory(entities.main.get(propertyName));
    } catch (err) {
      if (!entities.main.hasMethod(propertyName)) {
        return undefined;
      }

      return function () {
        entities.main.execute.apply(entities.main, Array.prototype.slice.call(arguments));
      };
    }
  },
  set(entity, propertyName, value) {
    entity.set(propertyName, value);
    return true;
  }
};

class JointCollector {
  constructor(entityManager, link) {
    this._localPath     = link.local.split(".");
    this._foreignPath   = link.foreign.split(".");
    this._foreign       = link.foreign;
    this._entityManager = entityManager;
    this._entities      = {};
  }
  collect(entity) {
    entity.main.extractAll(this._localPath).forEach(key => {
      if (!this._entities[key]) {
        this._entities[key] = [];
      }
      this._entities[key].push(entity);
    });
  }
  assign(results) {
    results.forEach(result => {
      const dependencies = [result.main].concat(result.dependencies);
      dependencies.forEach(e => this._entityManager.unregister(e));
      result.main.extractAll(this._foreignPath).forEach(key => {
        this._entities[key].forEach(entity => {
          if (!entity.props[this._foreign]) {
            entity.props[this._foreign] = [];
          }
          entity.props[this._foreign].push(result.main.key);
          entity.dependencies = entity.dependencies.concat(dependencies);
          dependencies.forEach(e => this._entityManager.register(e));
        });
      });
    });
  }
  getKeys() {
    return Object.keys(this._entities);
  }
}

const join = function join(entityManager, entities, link, model, options) {
  const collector = new JointCollector(entityManager, link);
  entities.forEach(entity => {
    collector.collect(entity);
  });
  const keys      = collector.getKeys();
  if(!keys.length){
    return Promise.resolve();
  }
  return model.repository.find(link.query(keys), {}, 0, link.many ? 100000 : keys.length, options, false).then(results => {
    collector.assign(results);
  });
}

class Repository {
  constructor(entityManager, model, accessor) {
    this._entityManager = entityManager;
    this._model         = model;
    this._accessor      = accessor;
  }
  get entityManager() {
    return this._entityManager;
  }
  set entityManager(entityManager) {
    this._entityManager = entityManager;
  }
  _getEntity(id) {
    const entity = this._entityManager.create(this._model, {
      _id: id
    }, false);
    this._entityManager.unregister(entity);
    return entity;
  }
  _createEntity(object) {
    return this._entityManager.create(this._model, object, true);
  }

  updateDependancies(entities, load){
    return this.join(Promise.resolve(entities.map(entity => entity.toPlainText())), load, false)
      .then(updatedEntities => {
        entities.forEach((entity, index) => {
          entity.dispose();
          entity._updateDependencies(updatedEntities[index].dependencies);
          entity.register();
        });
      });
  }

  join(datasSource, load = {}, buildProxy = true) {
    return datasSource.then(results => {
      const entities = results.map(r => ({ main: this._createEntity(r), dependencies: [], props: {} }));
      let p          = Promise.resolve();
      if (Object.keys(load).length && entities.length) {
        p = this._load(load, entities);
      }
      return p.then(() => {
        return !buildProxy ? entities : entities.map(entity => new Proxy(entity, entityProxyManager))
      });
    });
  }
  
  find(query, sort, start, nbr, load = {}, buildProxy = true) {
    return this.join(this._accessor.find(query, sort, start, nbr), load, buildProxy);
    
  }
  _load(load, entities) {
    return Promise.all(Object.keys(load).map(property => {
      let options = {};
      if (load[property] !== true) {
        options = load[property];
      }
      const path  = property.split(".");
      return join(this._entityManager, entities, this._model.getType(path, true).link, this._model.getType(path, true).model, options);
    }));
  }

  hasInCache(data){
    return this._entityManager.has(this._model, data);
  }

  getFromCache(data){
   return new Proxy({
      main        : this._entityManager.create(this._model, data),
      dependencies: [],
      props       : {}
    }, entityProxyManager);
  }

  async get(ids, foreignLoad) {
    const toLoad = ids.filter(id => !this._entityManager.has(this._model, id));
    let entities = [];
    ids.forEach(id => {
      if(this.hasInCache(id)){
        entities.push({
          main        : this._entityManager.create(this._model, id),
          dependencies: [],
          props       : {}
        });
      }
    });
    if(toLoad.length){
      const loaded = await this.find(this._model.key.toQuery(toLoad), {}, 0, toLoad.length, {}, false);
      entities =  entities.concat(loaded);
    }

    if (foreignLoad && entities.length) {
      await this._load(foreignLoad, entities);
    }

    return entities.map(entity => new Proxy(entity, entityProxyManager));
  }

  findOne(id, load) {
    return (this.hasInCache(id) ? this.get([id]).then(results => results[0]) : this._accessor.findOne(id))
      .then(object => {
        if (!object) {
          return null;
        }
        let p        = Promise.resolve();
        const entity = {
          main        : this._createEntity(object),
          dependencies: [],
          props       : {}
        };
        if (load && entity) {
          p = this._load(load, [entity]);
        }

        return p.then(() => new Proxy(entity, entityProxyManager));
      });
  }
  count(query) {
    return this._accessor.count(query);
  }
  create(object) {
    return this._accessor.create(object);
  }
  update(object) {
    return this._accessor.update(object);
  }
  partial(object) {
    return this._accessor.partial(object);
  }
  remove(object) {
    return this._accessor.remove(object);
  }
  applyFilterLocaly(filter) {
    return this._entityManager.entities.filter(entity => entity.model === this._model && filter(entity));
  }
}

class Type {
  constructor() {
    this.required  = false;
    this._parent   = null;
    this._property = null;
  }
  isVirtual(){
    return false;
  }
  get parent() {
    return this._parent;
  }
  get property() {
    return this._property;
  }
  get path() {
    let path = "";
    if (this._parent) {
      path = this._parent.path;
    }
    if (this._property) {
      if (path !== "") {
        path += ".";
      }
      path += this._property;
    }
    return path;
  }
  setParentAndProperty(parent, property) {
    this._parent   = parent;
    this._property = property;
  }
  extractAll(path, values) {
    if (path.length) {
      throw new Error(`Final value : can't access to "${
        path.join(".")
      }"`)
    }
    return values;
  }
  eval(path, entity) {
    return this.get(entity, path);
  }
}
class FinalType extends Type {
  constructor(type) {
    super();
    this._type = type;
  }
  get(entity, path) {
    let val = entity.extract(path);
    switch (this._type) {
      case "date": val     = val ? new Date(val) : val;
        break;
      case "geometry": val = Geometry.buildFromGeoJSON(val);
        break;
      default:
        break;;
    }
    return val;
  }
  getType(path) {
    if (path.length) {
      throw new Error(`No type : "${
        path.join(".")
      }"`);
    }
    return this;
  }
  getSubType(path) {
    if (path.length) {
      throw new Error(`No subtype : "${
        path.join(".")
      }"`);
    }
    return this._type;
  }
  validate(value, errors, path) {
    if (this.required && (value === null || value === undefined)) {
      errors({path: path, error: 'required'});
    }
    if (value === null || value === undefined) {
      return;
    }
    switch (this._type) {
      case "string":
        if (value + "" !== value) {
          errors({path: path, error: 'bad_type_string'});
        }
        break;
      case "int":
        if (parseInt(value) !== value) {
          errors({path: path, error: 'bad_type_integer'});
        }
        break;
      case "float":
        if (parseFloat(value) !== value) {
          errors({path: path, error: 'bad_type_float'});
        }
        break;
      case "date":
        if (!(value instanceof Date)) {
          if (!moment(value, moment.ISO_8601, true).isValid()) {
            errors({path: path, error: 'bad_type_date'});
          }
        }
        break;
      case "bool":
        if (!!value !== value) {
          errors({path: path, error: 'bad_type_boolean'});
        }
        break;
      case "mixed":break;
      default:
        throw new Error(`Unknow type => ${
          this._type
        }`);
    }
  }
}
class ArrayType extends Type {
  constructor(type) {
    super();
    this._type = type;
    this._type.setParentAndProperty(this, null);
  }
  extractAll(path, values) {
    return this._type.extractAll(path, values.reduce((acc, value) => acc.concat(value), []));
  }
  browserGet(entity, path) {
    if (!entity.has(path)) {
      return entity.extract(path);
    }
    return this._type.get(entity, path);
  }
  get(entity, path) {
    return new ArrayBrowser(entity, this, path);
  }
  getType(path) {
    if (!path.length) {
      return this;
    }
    return this._type.getType(path);
  }
  getSubType(path, byPassArray) {
    if (!path.length && !byPassArray) {
      return this;
    }
    return this._type.getSubType(path, byPassArray);
  }
  validate(value, errors, path) {
    if (this.required && (value === null || value === undefined)) {
      errors({path: path, error: 'required'});
    }
    if (value === null || value === undefined) {
      return;
    }
    if (!Array.isArray(value)) {
      errors({path: path, error: 'bad_type_array'});
      return;
    }
    value.forEach((value, idx) => {
      this._type.validate(
        value,
        errors,
        `${path}${
          path !== "" ? "." : ""
        }${idx}`
      );
    });
  }
  eval(path, entity){
    //Ajout bogue user sort de la liste si filtre sur rôle
    return entity.extractAll(path);
  }
}
class ObjectType extends Type {
  constructor() {
    super();
    this._properties = {};
  }
  addProperty(name, type) {
    this._properties[name] = type;
    this._properties[name].setParentAndProperty(this, name);
  }
  extractAll(path, values) {
    if (!path.length) {
      return values;
    }
    if (!this._properties[path[0]]) {
      throw new Error(`Unknow property : "${
        path[0]
      }"`);
    }
    return this._properties[path[0]].extractAll(path.slice(1), values.filter(value => value[path[0]]).map(value => value[path[0]]));
  }
  browserGet(entity, path) {
    const propertyName = path[path.length - 1];
    if(["$$typeof", "@@iterator"].includes(propertyName) || typeof propertyName !== "string"){
      return null;
    }
    if (!this._properties[propertyName]) {
      return undefined;
    }

    if(this._properties[propertyName].isVirtual()){
      return this._properties[propertyName].get(entity, path);
    }

    if (!entity.has(path)) {
      return entity.extract(path);
    }
    return this._properties[propertyName].get(entity, path);
  }
  get(entity, path) {
    return new ObjectBrowser(entity, this, path);
  }
  getType(path) {
    if (!path.length) {
      return this;
    }
    if (!this._properties[path[0]]) {
      throw new Error(`Unknow path: ${
        path[0]
      }`);
    }
    return this._properties[path[0]].getType(path.slice(1));
  }
  getSubType(path, byPassArray) {
    if (!path.length) {
      return this;
    }
    if (!this._properties[path[0]]) {
      throw new Error(`Unknow path: ${
        path[0]
      }`);
    }
    return this._properties[path[0]].getSubType(path.slice(1), byPassArray);
  }
  validate(value, errors, path) {
    if (this.required && (value === null || value === undefined)) {
      errors({path: path, error: 'required'});
    }
    if (value === null || value === undefined) {
      return;
    }
    if (!(value instanceof Object)) {
      errors({path: path, error: 'bad_type_object'});
      return;
    }
    Object.keys(this._properties).forEach(key => {
      this._properties[key].validate(
        value[key],
        errors,
        `${path}${
          path !== "" ? "." : ""
        }${key}`
      );
    });
  }
}
class EntityType extends Type {
  static regex = /[0123456789abcdef]{24}/i;
  constructor(models, name, link) {
    super();
    this._models = models;
    this._name   = name;
    this._link   = link;
  }
  isVirtual(){
    return false;
  }

  get model() {
    return this._models[this._name];
  }

  get link() {
    let link = this._link;
    if (!this._link) {
      link = {
        local  : this.path,
        foreign: "_id",
        many: false,
        query  : (keys) => (
          {
            _id: {
              $in: keys
            }
          }
        )
      };
    }
    return link;
  }

  extractAll(path, values) {
    return this.model.extractAll(path, values);
  }

  get(entity, path) {
    const id = entity.extract(path);
    if (! id) 
      return null;
    
    const retEntity = this.model.repository._getEntity(id);
    return retEntity;
  }
  getType(path) {
    if (!path.length) {
      return this;
    }
    return this.model.getType(path);
  }
  getSubType(path, byPassArray) {
    if (!path.length) {
      return this;
    }
    return this.model.getType(path, byPassArray);
  }
  validate(value, errors, path) {
    if (this.required && (value === null || value === undefined)) {
      errors({path: path, error: 'required'});
    }
    if (value === null || value === undefined) {
      return;
    }
    if (! EntityType.regex.test(value)) {
      errors({path: path, error: 'bad_type_entity'});
    }
  }
  eval(path, entity) {
    return entity.extractAll(path);
  }
}
class VirtualEntityType extends EntityType {
  constructor(models, name, link) {
    super(models, name, link);
  }
  isVirtual(){
    return true;
  }
  get(entity, path) {
    const values = entity.extractAll(this._link.local.split("."));
    const filter = Criterion.factory({ [this._link.foreign]: { $in: values }});
    return this.model.repository.applyFilterLocaly(entity => filter.match(entity.datas));
  }
}



class PropertyBuilder {
  static create(type) {
    if (type === "object") {
      return new ObjectPropertyBuilder(type);
    }
    if (type === "array") {
      return new ArrayPropertyBuilder(type);
    }
    if (type === "entity") {
      return new EntityPropertyBuilder(type);
    }
    return new PropertyBuilder(type);
  }
  constructor(type, writable = true) {
    this._description = {
      type    : type,
      required: false
    };
  }
  required() {
    this._description.required = true;
    return this;
  }
  build(type) {
    if (!type) {
      type = new FinalType(this._description.type);
    }
    if (this._description.required) {
      type.required = true;
    }
    return type;
  }
}
class EntityPropertyBuilder extends PropertyBuilder {
  constructor(type, writable = true) {
    super(type, writable);
    this._entityType = null;
    this._link       = null;
    this._virtual    = false;
  }
  type(type) {
    this._entityType = type;
    return this;
  }
  virtual(virtual = true){
    this._virtual = virtual;
    return this;
  }
  link(local, foreign, many = false, query = null) {
    if (!query) {
      query = (keys) => {
        const query    = {};
        query[foreign] = {
          $in: keys
        };
        return query;
      }
    }
    this._link = {
      local,
      foreign,
      many,
      query
    };
    return this;
  }
  build() {
    return super.build(new (this._virtual ? VirtualEntityType : EntityType)(models, this._entityType, this._link));
  }
}
class ArrayPropertyBuilder extends PropertyBuilder {
  constructor(type, writable = true) {
    super(type, writable);
    this._ofHandlerBuilder = null;
  }
  of(handlerBuilder) {
    if (!(handlerBuilder instanceof Function)) {
      const args     = Array.prototype.slice.call(arguments);
      handlerBuilder = p => p.apply(null, args);
    }
    this._ofHandlerBuilder = handlerBuilder;
    return this;
  }
  build() {
    return super.build(new ArrayType(this._ofHandlerBuilder((type) => PropertyBuilder.create(type)).build()));
  }
}

class ObjectPropertyBuilder extends PropertyBuilder {
  constructor(type, writable = true) {
    super(type, writable);
    this._properties = [];
  }
  add(handlerBuilder) {
    if (!(handlerBuilder instanceof Function)) {
      const args     = Array.prototype.slice.call(arguments);
      handlerBuilder = p => p.apply(null, args);
    }
    this._properties.push(handlerBuilder);
    return this;
  }
  build() {
    const objType = super.build(new ObjectType());
    this._properties.forEach(handlerBuilder => {
      let _name  = null;
      const type = handlerBuilder((name, type) => {
        _name = name;
        return PropertyBuilder.create(type)
      }).build();
      if (! _name) 
        throw new Error("Property must have a name");
      
      objType.addProperty(_name, type);
    });
    return objType;
  }
}
class ModelBuilder {
  static create(name, key, repository, defaultValue) {
    return new ModelBuilder(name, key, repository, defaultValue);
  }
  constructor(name, key, repository, defaultValue) {
    this._name       = name;
    this._key        = key;
    this._repository = repository;
    this._default    = defaultValue;
    this._type       = new ObjectPropertyBuilder();
  }
  add() {
    const args = Array.prototype.slice.apply(arguments);
    this._type.add.apply(this._type, args);
    return this;
  }
  build() {
    models[this._name] = new Model(this._name, this._key, this._type.build(), this._repository, this._default);
    return models[this._name];
  }
}
const entityManager = new EntityManager();
const models = {};

export {
  ModelBuilder,
  Key,
  Model
};

import Application from "@universal/lib/application";
import generatePassword from "@universal/lib/password";
import ApiService from "@universal/services/api";
import MemoryStorageService from "@universal/services/memoryStorage";
import SessionService from "@universal/services/session";
import Tenant from "@universal/types/business/Tenant";
import { StartHandler } from "./createApplication";
import extractToken from "./extractToken";
import { sleep } from "@universal/lib/promise";

type CreateValidationAppHandler = (beforeStart: StartHandler) => Promise<Application>;

const validatePPManagerAccount = (createValidationApp: CreateValidationAppHandler) => async ({ appMainPP, appAdmin, tenant }: { appMainPP: Application, appAdmin: Application, tenant: Tenant }) => {
  await sleep(2000);
  const token = await extractToken(appAdmin);
  const appValidation = await createValidationApp(async (app) => {
    await app.getService<MemoryStorageService>("temporaryStorage").set("bsAdminToken", token);
  });
  await appValidation.getService<SessionService>("session").updateMyPassword(generatePassword(24));

  const result = await appMainPP.getService<ApiService>("api").service("tenants", "panneaupocketInfo").execute(tenant.informations.siren);
  if(result.managerState !== "validated"){
    throw "La fonctionalité de validation du compte manager n'a pas fonctionné";
  }
}

export default validatePPManagerAccount;
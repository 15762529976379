import React from 'react';
import Button from "@cComponents/button";
import T      from "@uBehaviour/i18n";

import "./discoverOffers.css"
import { ComponentType, FunctionComponent, PropsWithChildren } from "react";
import useOpenCloseToggle from "@universal/behaviour/hooks/useOpenCloseToggle";
import SubscriptionOffers from "../../views/subscriptionsOffer";
import ScrollBar from "@common/components/scrollBar/scrollBar";
import Modal from "@common/components/modal";
import Text from "@common/components/text";
import useService from "@universal/behaviour/hooks/useService";
import ConfigurationService from "@universal/services/configuration";

type ButtonProps = {
  onClick: () => void;
}

type HocProps = {
  redirectToWebsite?: boolean
};

const withCTA = (Button: ComponentType<PropsWithChildren<ButtonProps>>): FunctionComponent<HocProps> => {
  return ({ redirectToWebsite }) => {
    const [displaySubscriptionsModal, openSubscriptionsModal, closeSubscriptionsModal] = useOpenCloseToggle();
    const configuration = useService<ConfigurationService>("configuration");

    const onButtonClick = React.useCallback(() => {
      return redirectToWebsite
        ? window.open(configuration.get("discoverLink"))
        : openSubscriptionsModal()
    }, [ redirectToWebsite, configuration ]);

    return (
      <>
        <Button onClick={ onButtonClick }>
          <div className="bs-starter-CTA-discoverOffers">
            <Text><T>starter_cta_discoverOffer</T></Text>
            <span className="fa fa-arrow-circle-up" />
          </div>
        </Button>
        {displaySubscriptionsModal && (
          <Modal.Show close={ closeSubscriptionsModal } style={{ width:"90vw", height: "90vh" }}>
            <ScrollBar>
              <SubscriptionOffers />
            </ScrollBar>
          </Modal.Show>
        )}
      </>
    );
  };
};

const White  = withCTA(Button.withStyle(Button.Stylized.BigWhite) as ComponentType<PropsWithChildren<ButtonProps>>)
const Orange = withCTA(Button.withStyle(Button.Stylized.BigOrange) as ComponentType<PropsWithChildren<ButtonProps>>)

const CTA = {
  Orange,
  White 
}

export default CTA;

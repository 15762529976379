// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-floatingBox {
  position: relative;
  border: 1px solid #ded4cc;
  border-top: none;
  box-shadow: 0 0 1px rgba(44,62,80,.6), 0 10px 25px -4px rgba(44,62,80,.4);
}

.bs-floatingBox-arrow {
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  right: 40px;
  top: -8px;
}

`, "",{"version":3,"sources":["webpack://./../common/components/floatingBox.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,yEAAyE;AAC3E;;AAEA;EACE,8BAA8B;EAC9B,mCAAmC;EACnC,oCAAoC;EACpC,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX","sourcesContent":[".bs-floatingBox {\n  position: relative;\n  border: 1px solid #ded4cc;\n  border-top: none;\n  box-shadow: 0 0 1px rgba(44,62,80,.6), 0 10px 25px -4px rgba(44,62,80,.4);\n}\n\n.bs-floatingBox-arrow {\n  border-bottom: 10px solid #fff;\n  border-left: 10px solid transparent;\n  border-right: 10px solid transparent;\n  position: absolute;\n  right: 40px;\n  top: -8px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

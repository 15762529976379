import Item       from "./components/item";
import Filters    from "./components/filters";
import Form       from "./components/form";
import Actions    from "./components/actions";

export default {
  Filters,
  Item,
  Form,
  Actions
} 
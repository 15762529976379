import React from 'react'
import FontAwesome from 'react-fontawesome'
import IssueId from '../common/IssueId'
import classNames from 'classnames'
import _ from 'lodash'
import { isAuthorized } from 'client/features/issues/lib'
import AuthorizationsWrapper from 'client/features/issues/components/common/AuthorizationsWrapper.jsx'
import { Deadline } from '@root/entities/issuesAndAssignments';
import Recurrence from 'client/features/issues/components/Recurrence';


const renderCountry = (issue, user) => {
  if (user && (!user.admin || !issue.country))
    return
  return (
    <span className='c-issue-country'>
      <img src={ `/img/flags/${issue.country.toLowerCase()}.png` } />
    </span>
  )
}

const RightTitle = ({ issue }) => (
  <div className='c-issue-title__deadline'>
    <div className={ 'c-issue-deadline' }>
      { issue.deadline && (<Deadline value={ issue.deadline }/>)}
      { issue.recurrence && !issue.recurrence.deleted && issue.deadline && (<br />) }
      { issue.recurrence && !issue.recurrence.deleted
        ?
        (<Recurrence issue={ issue } />)
        : null
      }
      {
        issue.valorizationsTotal && isAuthorized(issue, 'valorizationCost', 'read') 
          && ((issue.recurrence && !issue.recurrence.deleted) || issue.deadline) && (<br />) }
      {
        issue.valorizationsTotal && isAuthorized(issue, 'valorizationCost', 'read') &&
        <span className='c-issue__valorization-total'>{ Math.round(issue.valorizationsTotal*100)/100 }€</span>
      }
    </div>
  </div>
);

const IssueListTitle = ({ issue, user, noDeadline, highlight, short, freeSearch, showLinks }) => (
  <h3 className={ classNames('c-issue-title', { 'c-issue-title--short': short }) }>
    <span className='c-issue-title__id'>
      <IssueId
        bsId={ issue.bsId }
        bsIdNumber={ issue.bsIdNumber }
        full={ (!user || user.admin || (user.entity && user.entity.slaves && user.entity.slaves[0])) }
        short={ short }
        highlight={ highlight }
        freeSearch={ freeSearch }
      />
    </span>

    <span className='c-issue-title__tag c-issue-tag'>
      { issue.tag.label }
    </span>
    {
      issue.currentUser.isInternalFollower && <FontAwesome name='star u-color-grey' />
    }

    {
      issue.priority.value !== 'a_low' &&
      <AuthorizationsWrapper
        fields={ issue.fields }
        authorizations={ {
          priority: 'read'
        } }
      >
        <span className={ classNames('c-issue__priority') } title={ issue.priority.label }>
          <FontAwesome name='warning'/>
        </span>
      </AuthorizationsWrapper>
    }
    {
      issue.group && issue.group.issues && issue.group.issues[0] && showLinks && user.authorizations.issue.showGroup &&
                _.sortBy(issue.group.issues, 'bsIdNumber').filter((link) => link._id !== issue._id).map((link, index) =>
                  <span className='u-margin-left-small' key={ `${index}-${link._id}` }>
                    <FontAwesome name='link' className={ `u-color-${link.state}` }/>&nbsp;
                    { link.bsIdNumber }
                  </span>
                )
    }

    <span className='c-issue-title__country'>
      { user && renderCountry(issue, user) }
    </span>
    { !noDeadline && <RightTitle issue={ issue } /> }
  </h3>
)

export default IssueListTitle
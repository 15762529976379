import React      from 'react';
import Numeric    from '../input/numeric';
import _          from 'lodash';
import FocusBlur  from "@cComponents/focusBlur.jsx";
import i18n       from "i18n-react";
import Display    from "@cComponents/displayIf";

import './selectSupply.css';

const getUnit= (supply) => {
  if(!supply.valorization || supply.valorization.type === 'other'){
    return '';
  }
  return supply.valorization.type;
}

class InputSupplyQuantity extends React.Component {
  constructor(props) {
    super(props);
    this._onQuantityChangeHandler = this._onQuantityChangeHandler.bind(this);
    this._stringifyHandler        = this._stringifyHandler.bind(this);
    this._onEdit                  = this._onEdit.bind(this);
    this._onRemove                = this._onRemove.bind(this);
  }

  _onEdit () {
    this.props.onEdit(this.props.supply);
  }

  _onRemove () {
    this.props.onRemove(this.props.supply);
  }

  _onQuantityChangeHandler(quantity) {
    this.props.onQuantityChange(this.props.supply, quantity);
  }

  _stringifyHandler(value) {  
    return `${value} ${getUnit(this.props.supply)}`;
  }

  render() {
    const { quantity } = this.props;
    return(
      <div className="bs-inputsupplyquantity">
        <div className="bs-inputsupplyquantity-container">
          <div className="bs-inputsupplyquantity-label">{ this.props.supply.name }</div>
          <Display.If condition={ !this.props.readOnly}>
            <Display.If condition={ this.props.onEdit }>
              <div className="fa fa-pencil" onClick={ this._onEdit }/>
            </Display.If>
            <div className="fa fa-remove" onClick={ this._onRemove }/>
          </Display.If>
        </div>
        <Display.If condition={ this.props.readOnly}>
          <Display.Then>
            <span>{ this._stringifyHandler(quantity) }</span>
          </Display.Then>
          <Display.Else>
            <Numeric value={ quantity } min={0} onChange={ this._onQuantityChangeHandler } stringify={ this._stringifyHandler }/>
          </Display.Else>
        </Display.If>
      </div>
    );
  };
};

class InputSuppliesQuantity extends React.Component {
  constructor(props) {
    super(props);
    this.onQuantityChangeHandler = this.onQuantityChangeHandler.bind(this);
  }

  onQuantityChangeHandler(supply, quantity) {
    this.props.onUpdate({ supply, quantity });
  }

  render() {
    return (
      <div className="bs-inputsuppliesquantity">
        {this.props.supplies.map((supply) => { 
          return (
            <div className="bs-inputsuppliesquantity-item">
              <InputSupplyQuantity
                onEdit={ this.props.onSupplyEdit }
                onRemove={ this.props.onDrop }
                supply={supply.supply}
                quantity={supply.quantity}
                onQuantityChange={this.onQuantityChangeHandler}
                readOnly={ this.props.readOnly }
              />
            </div>
          )
        })}

      </div>
    );
  };
};

class InputSelectSupply extends React.Component {
  constructor(props) {
    super(props);
    this.state = { values: this.props.supplies };
  }

  _filter(val) {
    if(val !== ""){
      const regex = new RegExp(val, "i")
      this.setState({ values:  this.props.supplies.filter(v => v.name.match(regex)) });
    }
    else {
      this.setState({ values: this.props.supplies });
    }
  }

  render() {
    return (
      <div className="bs-inputSelectSupply">
        <div className="bs-selectsupply-inputContainer">
          <input className="bs-selectsupply-input" type='text' placeholder={i18n.translate('select_supply_search')} onChange={(val) => this._filter(val.target.value)} />
          <span className="fa fa-search" />
        </div>
        <Display.If condition={ this.props.onCreate instanceof Function }>
          <div className="bs-inputSelectSupply-create" onClick={ this.props.onCreate }>{ i18n.translate("select_supply_create") }</div>
        </Display.If>
        {this.state.values.map((supply) => {
          return (
            <div className="bs-inputSelectSupply-item" onClick={ this.props.onSelect.bind(null, supply) }>
              {supply.name}
            </div>
          )
        })}
      </div>
    );
  };
};

class SelectSupply extends React.Component {
  constructor(props) {
    super(props);
    this.state        = { isOpen: false };
    this._openSelect  = this._openSelect.bind(this);
    this._closeSelect = this._closeSelect.bind(this);
    this._onAdd       = this._onAdd.bind(this);
    this._onDrop      = this._onDrop.bind(this);
    this._onUpdate    = this._onUpdate.bind(this);
  }

  _closeSelect(){
    this.setState({ isOpen: false });
  }

  _openSelect(e){
    e.preventDefault();
    if(!this.props.readOnly){
      this.setState({ isOpen: true });
    }
  }

  _onCreate = () => {
    if (this.props.onCreate) {
      this.props.onCreate();
    }
    this._closeSelect();
  }

  _onAdd(supply, e){
    e.preventDefault();
    const values = JSON.parse(JSON.stringify(this.props.values));
    const idx = values.findIndex((s) => s.supply === supply._id);
    if(idx == -1){
      values.push({ supply:supply._id, quantity:1 });
    }
    this.props.onChange(values);
    this._closeSelect();
  }

  _onDrop(supply){
    const values = JSON.parse(JSON.stringify(this.props.values));
    const idx = values.findIndex((s) => s.supply === supply._id);
    values.splice(idx, 1);
    this.props.onChange(values);
  }

  _onUpdate(supply){
    const values = JSON.parse(JSON.stringify(this.props.values));
    const idx = values.findIndex((s) => s.supply === supply.supply._id);
    values[idx].quantity = supply.quantity;
    this.props.onChange(values);
  }

  render() {
    this._formatedValues = this.props.values.reduce((acc, value) => {
      const supply = this.props.supplies.find((supply) => supply._id.toString() == value.supply);
      if(supply){
        acc.push({supply: supply, quantity: value.quantity});
      } 
      return acc;
    }, []);
    
    const valuesID = this.props.values.map(v => v.supply);
    this._filteredSupplies = this.props.supplies.filter((supply) => !valuesID.includes(supply._id));

    return (
      <FocusBlur onBlur={ this._closeSelect } name="selectSupply">
        <div className="bs-selectsupply">
          <InputSuppliesQuantity
            onSupplyEdit={ this.props.onEdit }
            onDrop={ this._onDrop }
            onUpdate={ this._onUpdate }
            supplies={this._formatedValues}
            readOnly={ this.props.readOnly }
          />
          <Display.If condition={!this.props.readOnly}>
            {this.state.isOpen 
              ? <InputSelectSupply
                  supplies={ this._filteredSupplies }
                  onSelect={ this._onAdd }
                  onCreate={ this._onCreate }
                /> 
              : <div className="bs-selectsupply-label" onClick={ this._openSelect }>{i18n.translate("add_supply_to_issue") }</div>
            }
          </Display.If>
        </div>
      </FocusBlur>
      
    );
  };
};

export default SelectSupply;
import React from 'react'
import { compose } from 'redux'

import T from 'i18n-react'

import { IssueRequest, IssuesRequest } from 'client/features/issues/actions'
import { isAuthorized, setMentionsHastags } from 'client/features/issues/lib'

import State from '@root/entities/issues/components/state'
import IssueWrapper from 'client/features/issues/containers/IssueWrapper.jsx'
import DuplicateModal from 'client/features/issues/components/modals/Duplicate.jsx'
import Hashtags from 'client/features/issues/components/common/Hashtags.jsx'
import WorkorderStateModal from 'client/features/issues/components/workOrders/StateModal.jsx'
import _ from 'lodash';
import Application from '@universal/behaviour/application'
import Modal from '@common/components/modal'

const __LANG    = window.__LANG;

const upperCaseFirst = (input) => input.charAt(0).toUpperCase() + input.substr(1);

const IssueUpdatesWrapper = (WrappedComponent) => {
  class IssueUpdatesHOC extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        showStateModal: false,
        showDuplicateModal: false,
        showHashtagModal: false,
        showWorkorderStateModal: false,
        currentState: null,
        currentWorkorderState: null
      }
    }

    displayStateModal = (currentState) => {
      this.setState({
        showStateModal: !this.state.showStateModal,
        currentState
      })
    }

    displayWorkorderStateModal = (currentWorkorderState, issue, assignmentId) => {
      this.setState({
        showWorkorderStateModal: !this.state.showWorkorderStateModal,
        currentWorkorderState,
        showWorkorderStateModalAssignmentId: assignmentId,
        issueId: issue
      })
    }

    displayDuplicateModal = () => {
      this.setState({
        showDuplicateModal: !this.state.showDuplicateModal
      })
    }

    displayHashtagModal = () => {
      this.setState({ showHashtagModal: !this.state.showHashtagModal })
    }

    updateIssue = (type, data = {}) => {
      if (!type)
        throw new Error('Update issue: missing type')
      const req = {
        ...data,
        params: {
          id: this.props.issueId || this.state.issueId,
          type
        },
        actionData: {
          type
        }
      }
      this.props.dispatch(IssueRequest.put.action(req))
    }

    changeWorkorderState = (payload) => {
      payload.idAssignment = this.state.showWorkorderStateModalAssignmentId;
      this.setState({
        showWorkorderStateModal: false,
        showWorkorderStateModalAssignmentId: null
      })
      const files = payload.files
      delete payload.files
      this.updateIssue('workOrderState', { payload, files })
    }

    follow = () => {
      this.updateIssue('follow')
    }

    unFollow = () => {
      this.updateIssue('unFollow')
    }

    archive = () => {
      this.updateIssue('archive', {})
    }




    duplicate = (payload) => {
      const { issue } = this.props
      this.setState({
        showDuplicateModal: false
      })
      payload = {
        ...payload,
        duplicate: issue._id
      }
      this.props.dispatch(IssuesRequest.post.action({ payload }))
    }

    addHashtag = (hashtags) => {
      this.updateIssue('addHashtag', { payload: { hashtags } })
    }

    render() {
      const { issue, session, user } = this.props

      return (
        <>
          <WrappedComponent
            updateIssue={ this.updateIssue }
            displayStateModal={ this.displayStateModal }
            displayDuplicateModal={ this.displayDuplicateModal }
            displayHashtagModal={ this.displayHashtagModal }
            displayWorkorderStateModal={ this.displayWorkorderStateModal }
            follow={ this.follow }
            unFollow={ this.unFollow }
            archive={ this.archive }
            { ...this.props }
          />
          {
            this.state.showStateModal && (
              <Modal.Show close={ this.displayStateModal } style={{ height: this.props.issue.original.public ? '44vh' : '29vh', width: "60vw" }}>
                <State.SelectAndCommentModal state="transferred" issue={ this.props.issue.original } close={this.displayStateModal}/>
              </Modal.Show>
            )
          }

          {
            this.state.showWorkorderStateModal &&
            <WorkorderStateModal
              issue={ this.props.issue }
              initialValues={ { state: this.state.currentWorkorderState } }
              onHide={ this.displayWorkorderStateModal }
              show={ this.state.showWorkorderStateModal }
              newState={ this.state.currentState }
              onSubmit={ this.changeWorkorderState }
              user={ this.props.session.user }
            />
          }

          {
            isAuthorized(issue, 'duplicate') && this.state.showDuplicateModal &&
            <DuplicateModal
              issue={ issue }
              onHide={ this.displayDuplicateModal }
              tags={ session.tags[issue.type].map((tag) => ({ ...tag, label: tag.labels[__LANG], value: tag._id })) }
              show={ this.state.showDuplicateModal }
              initialValues={ {
                overwrite: {
                  description: issue.description,
                  tag: issue.tag
                },
                grouped: true,
                groupName: `${upperCaseFirst(T.translate('issue_duplicate_from'))} ${issue.bsId}`
              } }
              group={ issue.group }
              onSubmit={ this.duplicate }
              mentions= {
                [
                  {
                    type: 'tag',
                    trigger: '#',
                    data: setMentionsHastags(issue, session.user.resource && session.user.resource.hashtags),
                    renderSuggestion: (tag, search, highlightedDisplay) => <span>{ highlightedDisplay }</span>
                  }
              ]
              }
            />
          }
          {
            this.state.showHashtagModal &&
            <Hashtags.CreateHashtagModal
              show={ this.state.showHashtagModal }
              onHide={ this.displayHashtagModal }
              onSubmit={ this.addHashtag }
              hashtags={ issue.hashtags }
              mentions={ setMentionsHastags(issue, session.user.resource && session.user.resource.hashtags, true) }
              mostUsed={ _.sortBy(session.user.resource && session.user.resource.hashtags, 'used').reverse().splice(0, 12) }
            />
          }
        </>
      )
    }
  }

  return Application.Service.forward(['api', ['message', 'messageService'], 'i18n'], IssueUpdatesHOC);
}

export default compose(IssueWrapper, IssueUpdatesWrapper);
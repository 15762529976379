// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-notification {
  position: relative;
}

.bs-notification-dropdown {
  position: absolute;
  max-width: calc(100vw - 20px);
  width: 500px;
  top: calc(100% + 20px);
  right: calc(100% - 75px);
}`, "",{"version":3,"sources":["webpack://./src/entities/notifications/components/notification.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,YAAY;EACZ,sBAAsB;EACtB,wBAAwB;AAC1B","sourcesContent":[".bs-notification {\n  position: relative;\n}\n\n.bs-notification-dropdown {\n  position: absolute;\n  max-width: calc(100vw - 20px);\n  width: 500px;\n  top: calc(100% + 20px);\n  right: calc(100% - 75px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-button-group-selector-priority > div {
  padding: 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/old/features/issues/components/details/Priority.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":[".c-button-group-selector-priority > div {\n  padding: 0 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-valorization-summary>div {
  margin-bottom: 5px;
}
.bs-valorization-summary-bar{
  display: flex;
  align-items: center;
  font-size: 13px;
}
.bs-valorization-summary-bar>*{
  margin-right: 5px;
}
.bs-valorization-summary-bar-indicator {
  height: 12px;
  width: var(--bs-valorization-summary-bar-width);
}
.bs-valorization-summary-agentTeam{
  background-color: #ff9800;
}
.bs-valorization-summary-equipment{
  background-color: #2980b9;
}
.bs-valorization-summary-supply{
  background-color: #27ae60;
}
.bs-valorization-summary-service{
  background-color: #9c27b0;
}`, "",{"version":3,"sources":["webpack://./src/entities/valorizations/components/summary.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,+CAA+C;AACjD;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B","sourcesContent":["\n.bs-valorization-summary>div {\n  margin-bottom: 5px;\n}\n.bs-valorization-summary-bar{\n  display: flex;\n  align-items: center;\n  font-size: 13px;\n}\n.bs-valorization-summary-bar>*{\n  margin-right: 5px;\n}\n.bs-valorization-summary-bar-indicator {\n  height: 12px;\n  width: var(--bs-valorization-summary-bar-width);\n}\n.bs-valorization-summary-agentTeam{\n  background-color: #ff9800;\n}\n.bs-valorization-summary-equipment{\n  background-color: #2980b9;\n}\n.bs-valorization-summary-supply{\n  background-color: #27ae60;\n}\n.bs-valorization-summary-service{\n  background-color: #9c27b0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import Item from './components/item';
import Filters from './components/filters';
import Form from './components/form';
import State from './components/state';
import SelectEquipment from './components/selectEquipments';
import SelectSupply from './components/selectSupplies';

export default {
  Item,
  Filters,
  Form,
  State,
  SelectEquipment,
  SelectSupply
}
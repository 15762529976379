import React         from "react";
import _             from "lodash";
import Data          from "@uBehaviour/data";
import User          from "@entities/users";
import T             from "@cBehaviour/i18n";
import Filter        from "@cComponents/filter";
import FilteredList  from "@cFeatures/filter/list";
import Input         from "@cComponents/input";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar     from "@cComponents/searchbar";
import { escapeRegExp } from "@uLib/tool";

export default class CitizenList extends React.Component {
  query() {
    return { discriminator: "citizen" };
  }
  _textSearchBuildQuery(value){
    return { $or: [
      { fullname: { '$regex': escapeRegExp(value), '$options' : 'i' }},
      { email:    { '$regex': escapeRegExp(value), '$options' : 'i' }}
    ]};
  }
  render() {
    return (
      <FilteredList>
        <FilteredList.Header>
          <ListHeaderLayout>
            <ListHeaderLayout.Center>
              <Filter.Generic name="text" buildQuery={ this._textSearchBuildQuery } stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
              {(value, set, clear) => (
                <Searchbar>
                  <Input.Text focusOnMount className="bs-filter-text" value={ value } onChange={_.debounce(value => { value ? set(value) : clear()}, 1000)}>
                    <T>freesearch</T>
                  </Input.Text>
                </Searchbar>
              )}
              </Filter.Generic>
            </ListHeaderLayout.Center>
          </ListHeaderLayout>
        </FilteredList.Header>
        <FilteredList.List>
        {composeQuery => (
          <Data.List model={"User"} query={composeQuery(this.query())} sort={{ firstname: 1, lastname: 1 }} >
          {(data) => (
            <User.CitizenItem data={data} />
          )}
          </Data.List>
        )}
        </FilteredList.List>
      </FilteredList>
    )
  }
}

import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode
}         from 'react';
import T             from '@cBehaviour/i18n';
import Format        from '@cBusiness/format';
import File          from '@cComponents/file';
import Item          from '@cComponents/item';
import Title         from '@cComponents/title';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import UserType, {
  isRealPersonUser,
  isUserCollaborator,
  isUserPro,
  isUserTechnical 
} from '@uTypes/business/User';
import Money from '@cComponents/money';
import Slot from '@universal/components/slot2';

import './item.css';

type UserItemProps = {
  data: BusinessEntity<UserType>
};

const Details = Slot<ReactNode>();
const Actions = Slot<ReactNode>();

const UserItem: FunctionComponent<PropsWithChildren<UserItemProps>> & { Details: typeof Details, Actions: typeof Actions } = ({ data: user, children }) => {
  const details = Details.get(children)
  const actions = Actions.get(children)

  const illustration = React.useMemo(() => {
    if(isUserTechnical(user)){
      let source = null;
      switch(user.application) {
        case "jvs-ac":
          source = "/images/logos/attractivecity_color_text_vertical.png";
          break;
        case "jvs-pp":
          source = "/images/logos/panneaupocket.png";
          break;
        case "jvs-grc":
          source = "/images/logos/grc_color_text.svg";
          break;
        default:
          return null;
      }
      return {
        type: "static",
        src: source,
        fit: "contain"
      } 
    }
    if (isUserCollaborator(user) || isUserPro(user)) {
      return user.avatar;
    }
    return null;
  }, [ user ]);

  return (
    <>
      <Item className="bs-user-item">
        <Item.Illustration>
          <File file={ illustration } width={160} height={160} fit="cover" />
        </Item.Illustration>
        <Item.Content.Header>
          <Title>{Format.User.longFullname(user)}</Title>
        </Item.Content.Header>
        <Item.Content.Body>
          {(isRealPersonUser(user) && user.email) && (
            <div className="bs-user-item-email">{ user.email }</div>
          )}
          { details }
        </Item.Content.Body>
        <Item.Content.Footer>
          {(isUserPro(user) && user.valorization) && (
            <div>
              <span><T>hourly_cost</T> : </span>
              <span><Money value={ user.valorization } /></span>
            </div>
          )}
        </Item.Content.Footer>
        {actions && (
          <Item.Actions>
            {actions}
          </Item.Actions>
        )}
      </Item>
    </>
  );
} 

UserItem.load = { 
  "tenants.tenant": true,
  "tenants.buildings.ids": true,
  avatar: true
};

UserItem.Details = Details;
UserItem.Actions = Actions;

export default UserItem;
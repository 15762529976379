// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-assignmentsAndIssues-search-fakeAutoComplete {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 27px;
  margin: 0;
  padding: 0 9px;
  border: 1px solid #e3dfdc;
  background: #f2eeeb;
  color: #666;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  transition: none;
  background-color: white;
  line-height: 1.5;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px 0px inset;
}

.bs-assignmentsAndIssues-search-fakeAutoComplete-content {
  flex-grow: 1;
}
.bs-input-autocomplete-display input {
  background-color: white;
}

.bs-assignmentsAndIssues-search-fakeAutoComplete .fa:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/entities/issuesAndAssignments/components/search.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;EAChB,sDAAsD;AACxD;;AAEA;EACE,YAAY;AACd;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".bs-assignmentsAndIssues-search-fakeAutoComplete {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  position: relative;\n  height: 27px;\n  margin: 0;\n  padding: 0 9px;\n  border: 1px solid #e3dfdc;\n  background: #f2eeeb;\n  color: #666;\n  font-size: 12px;\n  font-weight: 600;\n  border-radius: 3px;\n  transition: none;\n  background-color: white;\n  line-height: 1.5;\n  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px 0px inset;\n}\n\n.bs-assignmentsAndIssues-search-fakeAutoComplete-content {\n  flex-grow: 1;\n}\n.bs-input-autocomplete-display input {\n  background-color: white;\n}\n\n.bs-assignmentsAndIssues-search-fakeAutoComplete .fa:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-assignment-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bs-assignment-form>div:first-child {
  flex-grow: 1;
  overflow: hidden;
}
.bs-assignment-form .bs-areaTitle {
  margin-bottom: 20px;
}
.bs-assignment-form .bs-areaTitle:nth-child(n + 2){
  margin-top: 20px;
}
.bs-assignment-form .bs-scrollbar-viewport{
  padding: 10px;
}

.bs-assignment-form-footer {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-top: 1px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/entities/assignments/components/form.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,0BAA0B;AAC5B","sourcesContent":[".bs-assignment-form {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.bs-assignment-form>div:first-child {\n  flex-grow: 1;\n  overflow: hidden;\n}\n.bs-assignment-form .bs-areaTitle {\n  margin-bottom: 20px;\n}\n.bs-assignment-form .bs-areaTitle:nth-child(n + 2){\n  margin-top: 20px;\n}\n.bs-assignment-form .bs-scrollbar-viewport{\n  padding: 10px;\n}\n\n.bs-assignment-form-footer {\n  padding: 10px 15px;\n  display: flex;\n  justify-content: space-between;\n  background-color: #f5f5f5;\n  border-top: 1px solid #ccc;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

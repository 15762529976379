import React        from "react";
import _            from "lodash";
import Data         from "@uBehaviour/data";
import T            from "@cBehaviour/i18n";
import Filter       from "@cComponents/filter";
import FilteredList from "@cFeatures/filter/list";
import Input        from "@cComponents/input";
import Modal        from "@cComponents/modal";
import { useLocation, Switch, Route }  from '@cFeatures/router';
import { Link } from '@cFeatures/router';
import Button     from "@cComponents/button";
import Display    from "@cComponents/displayIf";
import "./list.css";
import useService from "@universal/behaviour/hooks/useService";
import CurrentTenantService from "@universal/services/currentTenant";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar from "@common/components/searchbar";
import "./list.css";
import RepositoryService from "@universal/services/repository";
import { escapeRegExp } from "@uLib/tool";
import Recurrence from "@root/entities/recurrences";

const FormModal = ({ recurrence }) => {
  const [, setLocation] = useLocation();
  return (
    <Modal.Show style={{ width: "80vw", height: "80vh"}} close={() => setLocation("/recurrences") }>
    { close => (
      <Recurrence.Form recurrence={ recurrence } close={ close }/>
    )}
    </Modal.Show>
  )
}

const FormRouting = ({}) => {
  const repositoryService = useService<RepositoryService>("repository");
  return (
    <Switch>
      <Route path="/:id/copy">
      {(params) => (
        <Data.One model="Recurrence" id={ params.id }>
        { recurrence => {
          const clone = recurrence.toPlainText();
          return (
            <FormModal recurrence={{ 
              type: clone.type,
              endRule: clone.endRule,
              schedulerRule: clone.schedulerRule,
              template: clone.template,
              eventHorizonInDay: clone.eventHorizonInDay,
              history: repositoryService.get("Recurrence").default.history
            }} />
          );
        }}
        </Data.One>
      )}
      </Route>
      <Route path="/:id">
      {(params) => (
        <Data.One model="Recurrence" id={ params.id }>
        { recurrence => (
          <FormModal recurrence={ recurrence.toPlainText() } />
        )}
        </Data.One>
      )}
      </Route>
      <Route path="/" component={ FormModal }/>
    </Switch>
  );
};
const query = (currentTenant: CurrentTenantService) => {
  let query = currentTenant.currentId ? { tenant: currentTenant.currentId }: undefined;
  return query;
}

const textSearchBuildQuery= (value: string) => {
  return { "template.description": { '$regex': escapeRegExp(value), '$options': 'i' } };
}

const defaultFilterState = () => {
  return [{ name: "state", value: "inProgress" }];
}

const RecurrenceList = ({ }) => {
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const [,setLocation] = useLocation();
  return (
    <>
      <FilteredList default={ defaultFilterState() }>
        <FilteredList.Filter>
          <Recurrence.Filters />
        </FilteredList.Filter>
        <FilteredList.Header>
          <ListHeaderLayout>
            <ListHeaderLayout.Center>
              <Filter.Generic name="text" buildQuery={ value => textSearchBuildQuery(value)} stringify={ value => (<><b><T>contains</T></b>: {value}</>)}>
                {(value, set, clear) => (
                  <Searchbar>
                    <Input.Text focusOnMount className="bs-filter-text" value={value} onChange={_.debounce( value => { value ? set(value) : clear() }, 1000)}>
                      <T>freesearch</T>
                    </Input.Text>
                  </Searchbar>
                )}
              </Filter.Generic>
            </ListHeaderLayout.Center>
            <ListHeaderLayout.Right className="bs-recurrences-list-header-button">
              <Link to={ `/recurrences/`} fromRoot>
                <Button.Stylized.Text><T>recurrence_add</T></Button.Stylized.Text>
              </Link>
            </ListHeaderLayout.Right>
          </ListHeaderLayout>
        </FilteredList.Header>
        <FilteredList.List>
          { composeQuery => (
            <Data.List model="Recurrence" query={composeQuery(query(currentTenant))} load={Recurrence.Item.load} sort={{ 'createdAt': -1 }}>
            {(recurrence) => (
              <Display.If condition={!recurrence.ended && !recurrence.deleted}>
                <Display.Then>
                  <Link to={ `/recurrences/${ recurrence._id }` } fromRoot>
                    <Recurrence.Item recurrence={ recurrence } copy={() => {
                      setLocation(`/recurrences/${ recurrence._id }/copy`);
                    }} />
                  </Link>
                </Display.Then>
                <Display.Else>
                  <Recurrence.Item recurrence={ recurrence } copy={() => {
                    setLocation(`/recurrences/${ recurrence._id }/copy`);
                  }} />
                </Display.Else>
              </Display.If>
            )}
            </Data.List>
          )}
        </FilteredList.List>
      </FilteredList>
      <FormRouting />
    </>
  );
}

export default RecurrenceList;
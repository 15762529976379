// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-featuresDiscovery-responsiveView-container {
  padding-bottom: 15px;
}
.bs-featuresDiscovery-responsiveView-container .bs-featuresDiscovery-template {
  flex-direction: column-reverse;
  align-items: center;
}

.bs-featuresDiscovery-responsiveView-container .bs-featuresDiscovery-template-title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.bs-featuresDiscovery-responsiveView-container .bs-featuresDiscovery-template:not(:first-child) .bs-featuresDiscovery-template-title {
  display: none;
}

.bs-featuresDiscovery-responsiveView-container .bs-featuresDiscovery-template:last-child .bs-featuresDiscovery-template-image {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/applications/starter/views/featuresDiscovery/responsiveView.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;AACA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".bs-featuresDiscovery-responsiveView-container {\n  padding-bottom: 15px;\n}\n.bs-featuresDiscovery-responsiveView-container .bs-featuresDiscovery-template {\n  flex-direction: column-reverse;\n  align-items: center;\n}\n\n.bs-featuresDiscovery-responsiveView-container .bs-featuresDiscovery-template-title {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  align-items: center;\n}\n\n.bs-featuresDiscovery-responsiveView-container .bs-featuresDiscovery-template:not(:first-child) .bs-featuresDiscovery-template-title {\n  display: none;\n}\n\n.bs-featuresDiscovery-responsiveView-container .bs-featuresDiscovery-template:last-child .bs-featuresDiscovery-template-image {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

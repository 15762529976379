import React, { FunctionComponent, PropsWithChildren } from "react";
import { Paginated } from "@cComponents/wizard";
import T             from "@uBehaviour/i18n";
import CTA           from "../../features/cta";
import Template      from "./template";
import Text, { Style } from "@cComponents/text";
import Slot from "@universal/components/slot2";
import PromotionalSubtitle from "./promotionalSubtitle";
import useResponsiveObserver from "@universal/behaviour/hooks/useResponsiveObserver";
import Modal from "@common/components/modal";
import ResponsiveView from "./responsiveView";

import "./index.css";

const Image = Slot();

const CategoryBase: FunctionComponent<PropsWithChildren> & { Image: typeof Image } = ({ children }) => {
  const image = Image.get(children);
  const restChildren = Image.not(children);

  return (
    <Template>
      <Template.Image>{ image }</Template.Image>
      <Template.Icon><span className="fa fa-archive bs-featuresDiscovery-category-icon" /></Template.Icon>
      <Template.Title><Text style={ Style.huge.bold }><T>starter_featuresDiscovery_category_title</T></Text></Template.Title>
      { restChildren }
    </Template>
  );
};
CategoryBase.Image = Image;

const CategoryManage: FunctionComponent = () => {
  const focusedText = <Text style={ Style.bold }> <T>starter_featuresDiscovery_category_manage_focus</T> </Text>;

  return (
    <CategoryBase>
      <Image><img src="/images/starter/featuresDiscovery/category_1.png" alt="Gestion de catégories"/></Image>
      <Text><T bind={{ focusedText }}>starter_featuresDiscovery_category_manage</T></Text>
    </CategoryBase>
  );
};


const CategoryRedirect: FunctionComponent = () => {
  const focusedText1 = <Text style={ Style.bold }><T>starter_featuresDiscovery_category_redirect_focus_1</T></Text>;
  const focusedText2 = <Text style={ Style.bold }><T>starter_featuresDiscovery_category_redirect_focus_2</T></Text>;

  return (
    <CategoryBase>
      <Image><img src="/images/starter/featuresDiscovery/category_2.png" alt="Gestion de catégories"/></Image>
      <div className="bs-featuresDiscovery-content">
        <Text.Paragraph>
          <T bind={{ focusedText1 }}>starter_featuresDiscovery_category_redirect_1</T>
        </Text.Paragraph>
        <Text.Paragraph>
          <T bind={{ focusedText2 }}>starter_featuresDiscovery_category_redirect_2</T>
        </Text.Paragraph>
      </div>
    </CategoryBase>
  );
};

type CategoryUpsaleProps = {
  redirectToWebsite?: boolean
}

const CategoryUpsale: FunctionComponent<CategoryUpsaleProps> = ({ redirectToWebsite }) => (
  <CategoryBase>
    <Image><img src="/images/starter/featuresDiscovery/category_3.png" alt="Gestion de catégories"/></Image>
    <PromotionalSubtitle>
      <div className="bs-featuresDiscovery-content">      
        <Text>
          <T>starter_featuresDiscovery_category_upsale</T>
        </Text>
        <span className="bs-featuresDiscovery-content-CTA">
          <CTA.DiscoverOffers.Orange redirectToWebsite={ redirectToWebsite } />
        </span>
      </div>     
    </PromotionalSubtitle>
  </CategoryBase>
);

type CategoryProps = {
  close: () => void,
};
const Category: FunctionComponent<CategoryProps> = ({ close }) => {
  const isLargeScreen = useResponsiveObserver();
  if (isLargeScreen) {
    return (
      <Paginated close={ close } style={{ width: "920px", height: "540px" }}>
        <Paginated.Step>
          <CategoryManage />
        </Paginated.Step>
        <Paginated.Step>
          <CategoryRedirect />
        </Paginated.Step>
        <Paginated.Step>
          <CategoryUpsale />
        </Paginated.Step>
      </Paginated>
    );
  }

  return (
    <Modal.Show close={ close } style={{ width: "90vw", height: "90vh" }}>
      <ResponsiveView>
        <CategoryManage />
        <CategoryRedirect />
        <CategoryUpsale redirectToWebsite={ !isLargeScreen } />
      </ResponsiveView>
    </Modal.Show>
  );
}

export default Category;
import MessageService from '@common/services/message';
import useResponsiveObserver from '@universal/behaviour/hooks/useResponsiveObserver';
import useService from '@universal/behaviour/hooks/useService';
import React, { ReactElement } from 'react';

let closeMessage: () => void;
function useScreenSizeMessage(minWidth: number, message: ReactElement): null {
  const messageService = useService<MessageService>("message");
  const isScreenSizeReach = useResponsiveObserver(minWidth);

  React.useEffect(() => {
    if (closeMessage) {
      closeMessage();
    }
    if (!isScreenSizeReach) {
      closeMessage = messageService.send("info", message, 0);
    }
  }, [ isScreenSizeReach, closeMessage ]);

  return null;
};

export default useScreenSizeMessage;
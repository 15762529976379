// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1281px) {
  .bs-responsive-mobile {
    display: none;
  }
}

@media (max-width: 1280px) {
  .bs-responsive-web {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/responsive.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["@media (min-width: 1281px) {\n  .bs-responsive-mobile {\n    display: none;\n  }\n}\n\n@media (max-width: 1280px) {\n  .bs-responsive-web {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-users-technical-user-info {
  width: 400px;
  border-radius: var(--std-radius);
  border: solid var(--std-color-gray-dark) 1px;
  padding: var(--std-padding-input);
  margin-bottom: var(--std-size-inter-element);
}

.bs-user-technicalUser-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/entities/users/components/technicalUser.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gCAAgC;EAChC,4CAA4C;EAC5C,iCAAiC;EACjC,4CAA4C;AAC9C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".bs-users-technical-user-info {\n  width: 400px;\n  border-radius: var(--std-radius);\n  border: solid var(--std-color-gray-dark) 1px;\n  padding: var(--std-padding-input);\n  margin-bottom: var(--std-size-inter-element);\n}\n\n.bs-user-technicalUser-footer {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

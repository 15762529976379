import Display from '@uComponents/displayIf';
import React, { FunctionComponent } from 'react';
import { Button } from '@common/components/button/base';
import './button.css';

interface ButtonProps {
  onClick: () => void;
  notificationNotSeen: number;
}

const NotificationButton: FunctionComponent<ButtonProps> = ({ notificationNotSeen, onClick }) => (
  <Button onClick={ onClick } className="bs-notifications-button">
    <span className='fa fa-bell-o fa-lg' />
    <Display.If condition={ notificationNotSeen }>
      <div className='bs-notifications-button-quantity'>
        <span>{ notificationNotSeen }</span>
      </div>
    </Display.If>
  </Button>
);

export default NotificationButton;

import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import toDic        from "@cLib/toDic";
import Building     from "@root/entities/buildings";
import useService from '@universal/behaviour/hooks/useService';
import useHelper from '@universal/behaviour/hooks/useHelper';

const BuildingFilter = ({}) => {
  const api = useService("api");
  const currentTenant = useService("currentTenant");
  const repository = useService("repository");
  const queryHelper = useHelper("query");

  return (
    <Filter.Generic 
      deshydrate={value => value._id}
      hydrate={values => api.service("buildings", "get").execute({_id: {$in: values }}, null, null, values.length).then(buildings => toDic(values, v => v, (v) => buildings.find(b => b._id === v)))}
      multiple
      name="buildings"
      buildQuery={values => ({ "location.building": { $in: values.map(v => v._id) } })}
      stringify={value => { return (<><T>issue_filter_buildings</T> : {value.name}</>)}}
    >
      {(values, add, drop, clear) => {
        let query = { tenant: currentTenant.currentId, disabled:false };
        if(values.length){
          query = { $and: [query, { _id: { $nin: values.map(v => v._id) }}]};
        }

        return (
          <Collapsable>
            <Collapsable.Title><T>issue_filter_buildings</T></Collapsable.Title>
            <Collapsable.Content>
              <Input.Selectable 
                value={ values.map(v => repository.get("Building").key.extract(v))}
                onChange={(vIds, values, isAdded) => { isAdded ? add(values, ["subs_buildings"]) : drop(values) }}
                model={ repository.get("Building") }
                load={{ files: 1 }}
                query={ queryHelper.completeQueryWithBuildings(query, (query, buildings) => ({ ...query, _id: { $in: buildings }})) }
                sort={{ name: 1 }}
                textify={ building => building.name }
                filterQuery={ value => ({ name: { '$regex': value, '$options': 'i' } }) }
              >
                <Building.Item />
              </Input.Selectable>
              </Collapsable.Content>
          </Collapsable>
        )
      }}
    </Filter.Generic>
  );
};

export default BuildingFilter;
import React, { FunctionComponent, useState }  from "react";
import T                    from '@cBehaviour/i18n'; 
import DropDown             from '@cComponents/dropDown';
import Release              from "@cEntities/release";
import Display              from "@cComponents/displayIf";
import CurrentTenantService from "@uServices/currentTenant";
import useService           from "@uBehaviour/hooks/useService";
import useOpenCloseToggle   from "@uBehaviour/hooks/useOpenCloseToggle";
import { useMatcher }       from "@uFeatures/router";
import Assistance           from "@components/assistance";

import './help.css';

const Help: FunctionComponent = () => {
  
  const [displayRelease, setDisplayRelease] = useState<Boolean>(false);
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const { match: pathToSettings } = useMatcher("/settings");
  
  const openDocumentation = React.useCallback(() => {
    if(!!pathToSettings) {
      window.open("https://betterstreet.gitbook.io/betterstreet-console-d-administration/")
    }
    else {
      window.open("https://betterstreet.gitbook.io/workspace/")
    }
  }, [currentTenant, pathToSettings]);

  
 
  const application = currentTenant.current?.settings.commercialOffer === "starter" ? "starter" : "admin";

  return (
    <div className="bs-help">
      <DropDown.List alignRight={ true }>
        <DropDown.List.Main>
        <img 
          alt="BetterStreet" 
          src={'/images/ico-help.png'} style={{ 
          width: "25px",
          height: "25px"
        }}/>
        </DropDown.List.Main>
        <DropDown.List.List>
          <div>
            <Assistance.Button />
          </div>
          <div onClick={ openDocumentation }>
            <span className="fa fa-book"/>
            <span className="bs-help-btn-text"><T>documentation</T></span>
          </div>
          <div onClick={() => setDisplayRelease(true) }>
            <span className="fa fa-gitlab"/>
            <span className="bs-help-btn-text"><T>release_note</T></span>
          </div>
        </DropDown.List.List>
      </DropDown.List>
      <Display.If  condition={displayRelease}>
      {() => (
        <Release.List application={application} close={() => { setDisplayRelease(false); }}/>
      )}
      </Display.If>
    </div>
  );
};

export default Help;
import React        from "react";
import InputBase    from "./inputBase";
import Display      from "@cComponents/displayIf";
import Slot         from "@cComponents/slot";
import ScrollBar    from '@cComponents/scrollBar';
import md5          from "md5";

import "./select.css";
import Tooltip from "@common/components/tooltip";

const tooltipValueStyle = { 
  whiteSpace: "nowrap",
  color:"white",
  padding: "10px",
  backgroundColor: "rgba(250, 114, 79)",
  borderRadius: "2px",
  fontSize: "12px",
  fontWeight: "bold"
}

export default class Select extends InputBase {
  static Display  = Slot();
  static Value    = Slot();
  constructor(props){
      super(props);
      this._display               = React.createRef();
      this.switch                 = this.switch.bind(this);
      this.open                   = this.open.bind(this);
      this.close                  = this.close.bind(this);
      this._emulateOnBlurBody     = this._emulateOnBlurBody.bind(this);
      this._emulateOnBlurElement  = this._emulateOnBlurElement.bind(this);
      this.state                  = {
          open: !!this.props.open
      };
  }
  
  get label(){
    const values    = Select.Value.props(this, true);
    let selectedValue = null;
    if(this._value !== undefined){
      const comparableValue = this.props.comparableValue ? this.props.comparableValue : v => v ;
      const value = comparableValue(this._value);
      selectedValue = values.find(val => comparableValue(val.value) === value);
    }
    if(!selectedValue) return "";
    return selectedValue.label;
  }
  _selectionChange(value){
    if(this.props.postSelect){
      value = this.props.postSelect(value.value, value.label);
    }else{
      value = value.value;
    }
    this._value = value;
    this._triggerChange();
  }
  isOpen(){
    return this.state.open;
  }
  open(){
    if(!this.isOpen()){
      this.setState({ open: true });
    }
  }
  close(){
    if(this.isOpen()){
      this.setState({ open: false });
    }
  }
  switch(){
    if(this.isOpen()){
      this.close();
    }else{
      this.open();
    }
  }
  _emulateOnBlurElement(){
    this._clicked = true;
  }
  _emulateOnBlurBody(){
    if(this._clicked){
      this._clicked = false;
    }else{
      this.close();
    }
  }
  componentDidMount(){
    super.componentDidMount();
    if (this._value && this.props.initialize) {
      this.props.initialize(this.props.comparableValue ? this.props.comparableValue(this._value) : this._value);
    }
    this._el.addEventListener("click", this._emulateOnBlurElement, true);
    document.body.addEventListener("click", this._emulateOnBlurBody, false);
  }
  componentWillUnmount(){
    super.componentWillUnmount();
    document.body.removeEventListener("click", this._emulateOnBlurBody);
    this._el.removeEventListener("click", this._emulateOnBlurElement);
  }
  _render(){
    const display   = Select.Display.get(this);
    let fnDisplay   = null;

    if(display instanceof Function){
      fnDisplay = display;
    }else if(!display){
      fnDisplay = (value) => {
        return (
          <div className="bs-old-select-display-default">
            { value && value.label ? value.label : (value && value.children ? value.children : "") }
          </div>
        ) ;
      }
    } else {
      fnDisplay = (value, open) => {
        return React.cloneElement(display, { value: value ? value.label : "", open: open });
      }
    }
    const values    = Select.Value.props(this, true);
    let selectedValue = null;
    if(this._value !== undefined){
      const comparableValue = this.props.comparableValue ? this.props.comparableValue : v => v ;
      const value = comparableValue(this._value);
      selectedValue = values.find(val => comparableValue(val.value) === value);
    }

    const selectedValueLabel = selectedValue?.label || selectedValue?.children || null;

    return (
      <span className="bs-old-select"  ref={ r => this._el = r } onClick={ this.switch }>
        <Display.If condition={this.props.withTooltip}>
          <Display.Then>
            <Tooltip placement='left'>
              <Tooltip.Subject> 
                <div className="bs-old-select-display">
                  { fnDisplay(selectedValue, this.isOpen()) }
                </div>
              </Tooltip.Subject>
              <Tooltip.Info style={{ opacity: 0.9 }} >
                {selectedValueLabel && (<div style={ tooltipValueStyle }>{ selectedValueLabel }</div>)}
              </Tooltip.Info>
            </Tooltip>
          </Display.Then>
          <Display.Else>
            { fnDisplay(selectedValue, this.isOpen()) }
          </Display.Else>
        </Display.If>
        <Display.If condition={ this.state.open }>
          <div className="bs-old-select-values-container">
            <div className="bs-old-select-values-content">
              <ScrollBar scrollBlockClassname='bs-old-select-values-item'>
                {
                  values.filter(value => value.value !== this._value).map(value => {
                    const key = md5(JSON.stringify(value.value));
                    return React.createElement("div", {
                      key: key,
                      className:"bs-old-select-values-item",
                      onClick: () => this._selectionChange(value)
                    }, value.children ? value.children : value.label );
                  }, [])
                }
              </ScrollBar>
            </div>
          </div>
        </Display.If>
      </span>
    );
  }
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-filters-containers .DayPicker,
.bs-issues-filters-containers .bs-select-wa-values-item {
  color: #5e676c;
}
.bs-filter-creators-users-pro-label {
  padding-top: 10px;
}

.bs-issues-filters-containers .bs-button-radio {
  color: white;
}

.bs-issues-filters-item-line {
  font-size: 1.2em;
  padding:5px;
  font-weight: 450;
  color: rgb(94, 103, 108);
  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);
  margin-bottom: 12px;
  opacity: 0.8;
  transition: all 0.3;
  border-radius: 3px;
}

.bs-filter-icon {
  margin-right: 4px;
}

.bs-priority-1{
  color: green;
}
.bs-priority-3{
  color: orange;
}
.bs-priority-5{
  color: red;
}

.bs-filter-period .bs-select-display-default .bs-text,
.bs-filter-period input {
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/filters.css"],"names":[],"mappings":"AAAA;;EAEE,cAAc;AAChB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,wBAAwB;EACxB,4EAA4E;EAC5E,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,UAAU;AACZ;;AAEA;;EAEE,uBAAuB;AACzB","sourcesContent":[".bs-issues-filters-containers .DayPicker,\n.bs-issues-filters-containers .bs-select-wa-values-item {\n  color: #5e676c;\n}\n.bs-filter-creators-users-pro-label {\n  padding-top: 10px;\n}\n\n.bs-issues-filters-containers .bs-button-radio {\n  color: white;\n}\n\n.bs-issues-filters-item-line {\n  font-size: 1.2em;\n  padding:5px;\n  font-weight: 450;\n  color: rgb(94, 103, 108);\n  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);\n  margin-bottom: 12px;\n  opacity: 0.8;\n  transition: all 0.3;\n  border-radius: 3px;\n}\n\n.bs-filter-icon {\n  margin-right: 4px;\n}\n\n.bs-priority-1{\n  color: green;\n}\n.bs-priority-3{\n  color: orange;\n}\n.bs-priority-5{\n  color: red;\n}\n\n.bs-filter-period .bs-select-display-default .bs-text,\n.bs-filter-period input {\n  color: white !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

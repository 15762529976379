import T from '@universal/behaviour/i18n';
import React, { FunctionComponent } from 'react'
import _ from "lodash";
import useService from '@universal/behaviour/hooks/useService';
import ConfigurationService from '@universal/services/configuration';
import Button from '@common/components/button';

const CommunicationKit: FunctionComponent = () => {
  const configurationService = useService<ConfigurationService>("configuration");
  const [ alreadyDownloaded, setAlreadyDownloaded ] = React.useState(false);

  const possiblyPreventDownload = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
    if (alreadyDownloaded) {
      ev.preventDefault();
      return;
    }
    setAlreadyDownloaded(true);
    setTimeout(() => setAlreadyDownloaded(false), 5000);
  }, [ alreadyDownloaded ]);

  return (
    <Button>
      <a onClick={ possiblyPreventDownload } href={configurationService.get("communicationKitLink")} download>
        <T>communication_kit_label</T>
      </a>
    </Button>
  );
}

export default CommunicationKit;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-starter-header-mobile {
  position: relative;
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 8px 0px #c4c4c4;
  height: 48px;
  background-color: var(--std-color-white);
}
.bs-starter-header-mobile-logo {
  display: flex;
  align-items: center;
  height: 100%;
}
.bs-starter-header-mobile-menu {
  height: 100%;
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.bs-starter-header-mobile-menu-content {
  display: flex;
  flex-direction: column;
  background-color: var(--std-color-gray);
  padding: 3px;
  gap: 3px;
}

.bs-starter-header-mobile-menu-content-navigation {
  background-color: var(--std-color-white);
  border-radius: var(--std-radius);
}

.bs-starter-header-mobile-menu-content-helpAndConnexion {
  background-color: var(--std-color-white);
  border-radius: var(--std-radius);
}
.bs-starter-header-mobile-menu-content-navigation>*:nth-child(n + 2)::before,
.bs-starter-header-mobile-menu-content-helpAndConnexion>*:nth-child(n + 2)::before {
  content: "";
  display: block;
  border-top: var(--std-color-gray) solid 1px;
  width: 100%;
  height: 1px;
}`, "",{"version":3,"sources":["webpack://./src/applications/starter/components/header/mobile.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mCAAmC;EACnC,YAAY;EACZ,wCAAwC;AAC1C;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uCAAuC;EACvC,YAAY;EACZ,QAAQ;AACV;;AAEA;EACE,wCAAwC;EACxC,gCAAgC;AAClC;;AAEA;EACE,wCAAwC;EACxC,gCAAgC;AAClC;AACA;;EAEE,WAAW;EACX,cAAc;EACd,2CAA2C;EAC3C,WAAW;EACX,WAAW;AACb","sourcesContent":[".bs-starter-header-mobile {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  box-shadow: 0px 1px 8px 0px #c4c4c4;\n  height: 48px;\n  background-color: var(--std-color-white);\n}\n.bs-starter-header-mobile-logo {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n.bs-starter-header-mobile-menu {\n  height: 100%;\n  position: absolute;\n  right: 0px;\n  display: flex;\n  align-items: center;\n  margin-right: 20px;\n}\n\n.bs-starter-header-mobile-menu-content {\n  display: flex;\n  flex-direction: column;\n  background-color: var(--std-color-gray);\n  padding: 3px;\n  gap: 3px;\n}\n\n.bs-starter-header-mobile-menu-content-navigation {\n  background-color: var(--std-color-white);\n  border-radius: var(--std-radius);\n}\n\n.bs-starter-header-mobile-menu-content-helpAndConnexion {\n  background-color: var(--std-color-white);\n  border-radius: var(--std-radius);\n}\n.bs-starter-header-mobile-menu-content-navigation>*:nth-child(n + 2)::before,\n.bs-starter-header-mobile-menu-content-helpAndConnexion>*:nth-child(n + 2)::before {\n  content: \"\";\n  display: block;\n  border-top: var(--std-color-gray) solid 1px;\n  width: 100%;\n  height: 1px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { PureComponent } from 'react'
import classNames from 'classnames'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import Popover from 'react-popover'
import T from 'i18n-react'
import _ from 'lodash'

import Address from 'client/features/overlay-view/components/Address.jsx'
import Cut from 'client/features/common/components/Cut.jsx'
import IssueStateBannerSVG from './common/IssueStateBannerSVG'
import Hashtags from 'client/features/issues/components/common/Hashtags.jsx'
import IssuePlanificationWrapper from 'client/features/issues/containers/IssuePlanificationWrapper.jsx'

import { isAuthorized } from 'client/features/issues/lib'

import AuthorizationsWrapper from 'client/features/issues/components/common/AuthorizationsWrapper.jsx'
import IssueListItemSelector from 'client/features/issues/components/lists/Selector.jsx'

import File     from 'client/components/file.jsx';

import Recurrence from 'client/features/issues/components/Recurrence';

import Display      from "@uComponents/displayIf";
import Assignment   from "@root/entities/assignments/components/assignment";


class IssuePopover extends PureComponent {

    constructor(props) {
        super()
        this._renderIssue = this._renderIssue.bind(this)
        this.state = {
            issue: props.issue
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.issue, this.props.issue)) {
            this.setState({
                issue: this.props.issue
            })
            this.forceUpdate()
        }
    }

    _renderIssue() {
        const { 
            showDistance,
            onClick = (e) => e.stopPropagation(),
            teams,
            patrimonies,
            editable,
            user,
            className,
            expend,
            selectActive,
            selected,
            select,
            supplies
        } = this.props
        const { issue } = this.state
        if (!issue || !issue.planification)
            return <div></div>
        return (
            <div className={ classNames('c-popover-issue', className) } onClick={ onClick }>
                <div className={ classNames('c-issue', 'c-issue--popover', {
                    'c-issue--low-priority': issue.priority.value === 'a_low',
                    'c-issue--middle-priority': issue.priority.value === 'j_middle',
                    'c-issue--high-priority': issue.priority.value === 'z_high',
                }) }>
                    <div className='c-issue__primary'>
                        <IssueListItemSelector
                            selectActive={ selectActive }
                            selected={ selected }
                            select={ select }
                            issueId={ issue._id }
                        />
                        <div className='c-issue__title u-margin-bottom-tiny'>
                            <div className='c-issue-title'>
                                <span className='c-issue-title__id'>
                                    <span className='c-issue-id'>#{issue.bsIdNumber}</span>
                                </span>
                                <span className='c-issue-title__tag'>
                                    <span className='c-issue-tag'>{issue.tag.label}</span>
                                </span>
                                {
                                    (issue.priority.value !== 'a_low') &&
                                    <span className='c-issue__priority'><FontAwesome name='warning'/></span>
                                }
                                { 
                                  issue.recurrence && !issue.recurrence.deleted && <Recurrence issue={ issue } onlyIcon/>
                                }
                            </div>
                            <Hashtags
                                hashtags={ issue.hashtags } 
                                disabled
                            />
                        </div>
                        <div className='c-issue__location u-margin-top-small'>
                            <div className='c-issue__address'>
                                <Address 
                                    place={ issue.place }
                                    locationInfo={ issue.locationInfo }
                                    roadInfo={ issue.roadInfo }
                                    address={ issue.subject }
                                    cut='200'
                                    inline
                                />
                            </div>

                            {
                                showDistance && issue.distance >= 0 &&
                                <div className='c-issue__distance'><span className={ 'c-issue-distance c-badge c-badge--ghost c-badge--rounded c-badge--success' }>{ issue.distance }m</span></div>
                            }
                        </div>

                        {
                            issue.description &&
                                <div className='c-issue__description'>
                                    <div className='c-issue-description c-issue-description--full'>
                                        <Cut string={ issue.description } cut='180' more='...' />
                                    </div>
                                </div>
                        }
                        {
                            issue.owner &&
                                <div className='u-margin-top-small'>
                                    <div className='o-layout'>
                                        <span className='o-layout__item u-3of12 c-text-small'>{T.translate('owner')}: </span>
                                        <span className='o-layout__item u-9of12'>
                                            <span className='c-issue-meta__value'>
                                                {issue.owner.firstName[0].toUpperCase()}. {issue.owner.lastName}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                        }
                        {
                            issue.deadline &&
                            <AuthorizationsWrapper
                                fields={ issue.fields }
                                authorizations={ {
                                    deadline: 'read'
                                } }
                            >
                                <div className='u-margin-top-small'>
                                    <div className='o-layout'>
                                        <span className='o-layout__item u-3of12 c-text-small'>{T.translate('deadline')}: </span>
                                        <span className='o-layout__item u-9of12'>
                                            <span className='c-issue-meta__value'>
                                                {moment(issue.deadline).format('DD/MM/YYYY')}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </AuthorizationsWrapper>
                        }

                        <div className='c-issue__state'>
                            <IssueStateBannerSVG state={ issue.state } workOrderState={ issue.workOrder.state } label />
                        </div>
                    </div>
                    {
                        !issue.noCover && issue.cover ? 
                            <div className='c-issue__image u-border-vertical'>
                                <File file={ issue.cover } width={320} height={220} fit="cover" />
                            </div> 
                            : 
                            <hr className='u-margin-vertical-small'/>
                    }
                    <Display.If condition={ issue.assignment }>
                      <Display.Then>
                      {() => (
                        <Assignment 
                          hideAction={true}
                          isAssignment={ true }
                          assignment={ issue.assignment }
                          issue={ issue.issue }
                          deadline={ issue.deadline }
                          tenant={ issue.tenant }
                          readOnly={!isAuthorized(issue, 'scheduledFrom')}
                        />
                      )}
                      </Display.Then>
                      <Display.Else>
                      {() => (
                        <>
                        {
                          issue.assignments.map((assignment, index) => (
                            <Assignment 
                              hideAction={true}
                              isAssignment={ false }
                              assignment={ assignment }
                              issue={ issue._id }
                              deadline={ issue.deadline }
                              tenant={ issue.tenant }
                              readOnly={ true }
                            />
                          ))
                        }
                        </>
                      )}
                      </Display.Else>
                    </Display.If>
                    {
                        expend &&
                        <div
                            className='u-text-align-right u-padding-right-tiny u-padding-bottom-tiny'
                            onClick={ (e) => e.stopPropagation() }
                        >
                            <span className='u-color-grey' onClick={ expend.bind(null, null) }>
                                <FontAwesome name='caret-up' style={ { marginTop: 4 } }/>
                            </span>
                        </div>
                    }
                </div>
            </div>
        )
    }

    render() {
        const { preferPlace = 'right', transition = 0, onlyContent, isOpen } = this.props
        if (onlyContent)
            return this._renderIssue()
        
        return (
            <Popover
                isOpen={ isOpen }
                body={ this._renderIssue() }
                preferPlace={ preferPlace }
                enterExitTransitionDurationMs={ transition }
            >
                { this.props.children }
            </Popover>
        )
    }
}

export default IssuePlanificationWrapper(IssuePopover)
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-mapLayer-item{
  height: 100px;
}

.bs-mapLayer-item .bs-item2-content{
  padding: 10px;
}

.bs-mapLayer-item .bs-item2-content-body {
  display: flex;
  align-items: center;
}

`, "",{"version":3,"sources":["webpack://./src/entities/mapLayers/components/item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".bs-mapLayer-item{\n  height: 100px;\n}\n\n.bs-mapLayer-item .bs-item2-content{\n  padding: 10px;\n}\n\n.bs-mapLayer-item .bs-item2-content-body {\n  display: flex;\n  align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from "react";
import Event, { AsyncEvent } from "@uLib/event";
import { Service as Serv} from "@uLib/application";
import _ from "lodash";

export const ApplicationContext = React.createContext(null);
class Application extends Component{
  constructor(props){
    super(props);

    this.state = { application: props.application, ready: false };
    this.state.application.onReady.addListener(this);
  }
  handleEvent(){
    console.log("APPLICATION READY");
    window.application = this.state.application;
    this.setState({ ready: true });
  }
  render(){
    return React.createElement(
      ApplicationContext.Provider,
      { value: this.state.application },
      this.state.application.ready ? this.props.children() : null
    );
  }
}

const ServiceContext  = {};
const getContext      = (name) => {
  if(!ServiceContext[name]){
    ServiceContext[name] = React.createContext([]);
  }
  return ServiceContext[name];
}
class ServiceListenerRender extends React.Component {
  get service(){
    return this._service(this.props);
  }
  get eventName(){
    return this._eventName(this.props);
  }
  get event(){
    return this._event(this.props);
  }

  _service(props) {
    const service = Array.isArray(props.service) ? props.service[0] : props.service;
    if(service instanceof Serv) return service;
    throw new Error("You must specify Service");
  }

  _eventName(props){
    return Array.isArray(props.service) && props.service.length === 2 
      ? (props.service[1] + "" === props.service[1] ? props.service[1] : null)
      : "onServiceUpdated";
  }
  _event(props){
    const eventName =  this._eventName(props);
    const service = this._service(props);
    if(eventName && service[eventName] && (service[eventName] instanceof Event || service[eventName] instanceof AsyncEvent)){
      return service[eventName];
    }else{
      throw new Error("Unknow event")
    }
  }

  componentDidMount(){
    this.event.addListener(this);
  }
  componentDidUpdate(oldProps) {
    if (oldProps.service !== this.props.service) {
      this._event(oldProps).removeListener(this);
      this.event.addListener(this);
    }
  }
  componentWillUnmount(){
    this.event.removeListener(this);
  }
  handleEvent(){
    _.defer(() => {
      if(this._root){
        this.forceUpdate();
      }
    })
  }
  render(){
    const Context = getContext(this.service.name);
    return React.createElement(Context.Consumer, {}, ctxService => {
      const eventName = this.eventName;
      if(!ctxService.includes(eventName)){
        this._root = true;
        return React.createElement(Context.Provider, { value: ctxService.slice().concat([eventName]) }, this.props.children());
      }
      this._root = false;
      return this.props.children();
    });
  }
}
class ServicesListenerRender extends React.Component {
  render(){
    return this.props.services.reduce((children, service) => {
      return () => React.createElement(ServiceListenerRender, { service: service }, children)
    }, this.props.children)();
  }
}
class Service extends React.Component{
  constructor(props){
    super(props);
    if(!this.props.children instanceof Function){
      throw new Error("Children must be a fonction");
    }
    if(!this.props.name || (Array.isArray(this.props.name) && !this.props.name.length)){
      throw new Error("Specify service name");
    }
  }
  get servicesName(){
    return Array.isArray(this.props.name) ? this.props.name : [this.props.name];
  }
  get application(){
    return this.props.application;
  }
  get services(){
    return Array.isArray(this.props.name)
      ? this.props.name.map(name => this.application.getService(name))
      : [this.application.getService(this.props.name)]
  }
  render(){
    return this.props.children(Array.isArray(this.props.name) ? this.services : this.services[0]);
  }
}

class Helper extends React.Component{
  constructor(props){
    super(props);
    if(!this.props.children instanceof Function){
      throw new Error("Children must be a fonction");
    }
    if(!this.props.name || (Array.isArray(this.props.name) && !this.props.name.length)){
      throw new Error("Specify helper name");
    }
  }
  get helpersName(){
    return Array.isArray(this.props.name) ? this.props.name : [this.props.name];
  }
  get application(){
    return this.props.application;
  }
  get helpers(){
    return Array.isArray(this.props.name)
      ? this.props.name.map(name => this.application.getHelper(name))
      : [this.application.getHelper(this.props.name)]
  }
  render(){
    return this.props.children(Array.isArray(this.props.name) ? this.helpers : this.helpers[0]);
  }
}
Application.Service = (props) => React.createElement(ApplicationContext.Consumer, {}, application => 
  React.createElement(Service, Object.assign({}, props, { application: application }), props.children)
);
Application.Helper = (props) => React.createElement(ApplicationContext.Consumer, {}, application => 
  React.createElement(Helper, Object.assign({}, props, { application: application }), props.children)
);
Application.Getter  = (props) => {
  if(props.helpers && props.helpers.length && props.services && props.services.length){
    return React.createElement(Application.Helper, { name: props.helpers }, helpers =>
      React.createElement(Application.Service, { name: props.services }, services =>
        props.children(services, helpers)
      )
    );
  } else if(props.helpers && props.helpers.length){
    return React.createElement(Application.Helper, { name: props.helpers }, helpers =>
      props.children([], helpers)
    );
  } else if(props.services && props.services.length){
    return  React.createElement(Application.Service, { name: props.services }, services =>
      props.children(services, [])
    );
  } else {
    return props.children([], []);
  }
};
const prepareName = (names, type) => names.map(name => {
  if(Array.isArray(name) && name.length === 2 && name[0] + "" === name[0] && name[1] + "" === name[1]){
    return name;
  }
  if(name + "" === name) return [name, name];    
  throw new Error(type + " name must be a string or an array of 2 strings")
});
const prepareProps = (names, objects) => names.reduce((props, name, idx) => {
  props[name[1]] = objects[idx];
  return props;
}, {});
Application.forward = (servicesName, helpersName, Component, forwardedRefName = "ref") => {
  servicesName  = prepareName(servicesName, "Service");
  helpersName   = prepareName(helpersName, "Helper");
  return React.forwardRef((props, ref) =>
    React.createElement(Application.Getter, { services: servicesName.map(serviceName => serviceName[0]), helpers: helpersName.map(helper => helper[0]) }, (services, helpers) => 
      React.createElement(
        Component,
        Object.assign({}, props, prepareProps(servicesName, services), prepareProps(helpersName, helpers), { [forwardedRefName]: ref }), 
        props.children
      )    
    )
  );
};
Application.Service.forward = (services, component, forwardedRefName) => Application.forward(services, [], component, forwardedRefName);
Application.Helper.forward  = (helpers, component, forwardedRefName) => Application.forward([], helpers, component, forwardedRefName);
Application.Listen          = ServicesListenerRender;
export default Application;
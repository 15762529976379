import React, { FunctionComponent, PropsWithChildren, ComponentType,useEffect,useState } from 'react';
import State from '@cFeatures/state';
import StatesConfiguration, { type States } from '@universal/types/business/issue/State';
import StateType from '@uTypes/business/State';
import Issue from '@universal/types/business/Issue';
import Valorization from '@universal/types/business/Valorization';
import Display from '@universal/components/displayIf';
import Form from '@universal/behaviour/form';
import Input from '@common/components/input';
import Section from '@cComponents/section';
import Button from '@common/components/button';
import Modal from '@cComponents/modal';
import T from '@universal/behaviour/i18n';
import useService from '@universal/behaviour/hooks/useService';
import ApiService from '@universal/services/api';
import useOpenCloseToggle from '@universal/behaviour/hooks/useOpenCloseToggle';
import _ from "lodash";

import './state.css'
import useResponsiveObserver from '@universal/behaviour/hooks/useResponsiveObserver';

interface StateProps {
  state: States;
}

const Tiny: FunctionComponent<StateProps> = ({ state }) => {
  const stateConfiguration = React.useMemo(() => StatesConfiguration[state], [state]);
  return (
    <State.Tiny state={ stateConfiguration } />
  );
}


const Short: FunctionComponent<StateProps> = ({ state }) => {
  const stateConfiguration = React.useMemo(() => StatesConfiguration[state], [state]);
  return (
    <State.Short state={ stateConfiguration } />
  );
}

const Standart: FunctionComponent<PropsWithChildren<StateProps>> = ({ state, children }) => {
  const stateConfiguration = React.useMemo(() => StatesConfiguration[state], [state]);
  return (
    <State.Standart state={ stateConfiguration }>
    { children }
    </State.Standart>
  );
}


const Responsive: FunctionComponent<StateProps> = ({ state, children }) => {
  const isLargeScreen = useResponsiveObserver();

  return isLargeScreen ? (
    <Standart state={state}>{children}</Standart>
  ) : (
    <Short state={state} />
  );
}; 

interface SelectProps {
  value: States;
  onChange: (value: States) => void;
}

const Select: FunctionComponent<SelectProps> = ({ value, onChange }) => {
  const states = React.useMemo(() => Object.values(StatesConfiguration), []);
  const state = React.useMemo(() => StatesConfiguration[value], [value]);
  const onChangeHandler = React.useCallback((state: StateType) => {
    onChange(state.label as States);
  }, [onChange]);

  return (
    <State.Select value={ state } onChange={ onChangeHandler } states={ states } />
  );
}

interface ListProps {
  onSelect: (value: States) => void;
  close: () => void;
}

const List: FunctionComponent<ListProps> = ({ onSelect, close }) => {
  const states = React.useMemo(() => Object.values(StatesConfiguration), []);

  const transmitLabelSelection = React.useCallback((state: StateType) => {
    onSelect(state.label as States);
  }, [onSelect]);

  return (
    <State.List states={ states } onSelect={ transmitLabelSelection } close={ close } />
  );
}

interface CommentValueProps  { 
  internalComment?: string, 
  publicComment?: string, 
}

interface SelectAndCommentProps {
  issue: Issue;
  state: string;
  close?: () => void;
}
const CancelButton = Button.withStyle(Button.Stylized.negative)

const SelectAndCommentModal: FunctionComponent<SelectAndCommentProps> = ({ issue, state, close }) => {
  const api = useService<ApiService>('api');
  const submitAndClose = React.useCallback((form, value: CommentValueProps) => {
    api.service('issues', 'updateState')
      .execute(issue._id, state, value.internalComment, value.publicComment)
      .then(() => {
        close();
      });
  }, [api, close, issue._id, state]);

  return (
    <Form.Simple submit={submitAndClose}>
    {(ctx, value, errors, form, submit) => (
      <div className='bs-issue-state-comment'>
        <Section.Header>
            <T>issues_state_comment_title</T>
        </Section.Header>

        <div className='bs-issue-state-comment-body'>
          <div className='bs-issue-state-comment-section'>
            <Section.Title.Text> <T>issues_state_comment_internal</T> </Section.Title.Text>
            <Form.Simple.InputAdapter name='internalComment'>
              {(value, set, clear) => (
                <Input.TextArea value={ value } onChange={ set } />
              )}
            </Form.Simple.InputAdapter>
          </div>

          <Display.If condition={ issue.public } >
            <div className='bs-issue-state-comment-section'>
              <Section.Title.Text>
                <T>issues_state_comment_public</T>
              </Section.Title.Text>
              <Form.Simple.InputAdapter name='publicComment'>
                {(value, set, clear) => (
                  <Input.TextArea value={ value } onChange={ set } />
                )}
              </Form.Simple.InputAdapter>
            </div>
          </Display.If>
        </div>

        <Section.Footer className='bs-issue-state-comment-footer'>
          <CancelButton onClick={ close } >
            <T>issue_state_comment_cancel</T>
          </CancelButton>
          <Button.Text onClick={ () => submit(true) }>
            <T>issue_state_comment_confirm</T>
          </Button.Text>
        </Section.Footer>
      </div>
    )}
    </Form.Simple>
  );
}
const ModalAlert = ({ unvalorizedAssignments, close}) => (
  <div className="bs-modal-state-alert">
    <Section.Header>
      <Section.Title.Text> <T>issue_assignment_warning_mustBeValorized</T> </Section.Title.Text>
    </Section.Header>
    <div className="bs-modal-state-alert-assignments">
      <h6><T>issue_assignment_warning_mustBeValorized_instructions</T></h6>
      <span><T>issue_assignment_warning_unvalorized_assignments</T>: </span>
      {
        unvalorizedAssignments?.map((assignment) => (
          <ul> 
            <li>{assignment}</li> 
          </ul>
        ))
      }
    </div>
    <Section.Footer className='bs-modal-state-alert-footer'>
      <Button.Text onClick={close}>Ok</Button.Text>
    </Section.Footer>
  </div>
);

const createSelectAndComment: (SelectComponent: ComponentType<SelectProps>) => FunctionComponent<SelectAndCommentProps> = (SelectComponent) => {
  const Select: FunctionComponent<SelectAndCommentProps> = ({ issue }) => {
    const [unvalorizedAssignments, setUnvalorizedAssignments] = React.useState([]);
    const [showModalAlert, openModalAlert, closeModalAlert] = useOpenCloseToggle(false);
    const [value, setValue] = React.useState<States | null>(null);
    
    const api = useService<ApiService>('api');

    const resetValue = React.useCallback(() => setValue(null), [setValue]);
    
    const handleChange = React.useCallback((state: States) => {
      if(state === "resolved" && issue.mustBeValorized){
        api.service('valorizations', 'get')
        .execute({ issue: issue._id, assignment: { $ne: null } }, null, null, 100)
        .then((valorizations) => {
          const idAssignments = issue.assignments.map(assignment => assignment._id.toString());
          const valorizationsAssignmentsIds = _.uniq(valorizations.map((valorization: Valorization) => valorization.assignment));
          const unvalorizedAssignmentsIds = _.difference(idAssignments, valorizationsAssignmentsIds);

          if(unvalorizedAssignmentsIds.length) {
            const unvalorizedAssignments = unvalorizedAssignmentsIds.map((assignmentId: Valorization['assignment']) => {
              const assignment = issue.assignments.find((assignment) => assignmentId === assignment._id)
              return(`${issue.bsIdNumber}-${assignment.bsIdNumber}`);
            });

            setUnvalorizedAssignments(unvalorizedAssignments);
            openModalAlert();
            return;
          }
          else{
            setValue(state);
          }          
        });
      }
      else {
        setValue(state);         
      }
    }, [issue]);
    
    return (
      <>
        <SelectComponent value={ issue.state } onChange={ handleChange } />
        <Display.If condition={ value }>
          <Modal.Show close={ resetValue } style={{ height: issue.public ? '44vh' : '29vh', width: "60vw" }}>
            <SelectAndCommentModal issue={ issue } state={ value } close={ () => resetValue() } />
          </Modal.Show>
        </Display.If>

        { 
          showModalAlert ? 
          <Modal.Show style={{ height: '40%', width: "50vw" }} close={closeModalAlert} userCanClose>
            {(close) => 
              <ModalAlert unvalorizedAssignments={unvalorizedAssignments} close={close}/>
            }
          </Modal.Show>
          : null
        }
      </>
    )
  };
  return Select;
}

const StateComponent = {
  SelectAndComment: createSelectAndComment(Select),
  SelectAndCommentModal,
  Select,
  List,
  Standart,
  Short,
  Tiny,
  Responsive
}

export default StateComponent;
import { EquipmentStatement } from "@uTypes/business/Valorization";
import { ComponentType, FunctionComponent } from "react";
import { EquipmentSubject } from "../subjects";
import Equipment from "@uTypes/business/Equipment";
import { DurationProps, QuantityProps } from "../../types/StatementType";
import StatementLayout from "../statementLayout";


interface EquipmentStatementProps {
  statement: EquipmentStatement;
};
function EquipmentStatementComponent(Duration: ComponentType<DurationProps>, Quantity: ComponentType<QuantityProps>) : FunctionComponent<EquipmentStatementProps> {
  return ({ statement }) => (
    <StatementLayout statement={ statement } displayCostResource='equipments' displayCostAction='readCost'>
      <StatementLayout.Subject>
        <EquipmentSubject equipment={ statement.equipment as Equipment } />
      </StatementLayout.Subject>
      <StatementLayout.Occurence>
      {
        statement.unit === "h"
          ? <Duration duration={ statement.quantity } />
          : <Quantity quantity={ statement.quantity } unit={ statement.unit }/>
      }
      </StatementLayout.Occurence>
    </StatementLayout>
  );
};

export default EquipmentStatementComponent;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-recurrence-detail-content {
  padding: var(--std-padding);
}

.bs-recurrence-detail-already-created-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bs-recurrence-detail-already-created-item {
  width: 30%
}`, "",{"version":3,"sources":["webpack://./src/entities/recurrences/components/detail/content.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE;AACF","sourcesContent":[".bs-recurrence-detail-content {\n  padding: var(--std-padding);\n}\n\n.bs-recurrence-detail-already-created-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.bs-recurrence-detail-already-created-item {\n  width: 30%\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-supply-item {
  height: 100px;
}
.bs-supply-item .bs-action {
  display: flex;
  justify-content: space-between;
  color: #27ae60; 
}

.bs-supply-item .bs-supply-content {
  align-items: center;
}

.bs-supply-item .bs-supply-content .bs-supply-title {
  width: 15em;
  margin-right: 10px;
}

.bs-supply-item .bs-supply-content .bs-supply-description {
  width: 20em;
}

.bs-supply-item .bs-supply-content .bs-supply-title>div:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bs-supply-item .bs-supply-content .bs-supply-description-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bs-supply-item .bs-supply-content .bs-action {
  margin-left: 10px;
}

.bs-supply-item .bs-item2-content {
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/entities/supplies/components/item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".bs-supply-item {\n  height: 100px;\n}\n.bs-supply-item .bs-action {\n  display: flex;\n  justify-content: space-between;\n  color: #27ae60; \n}\n\n.bs-supply-item .bs-supply-content {\n  align-items: center;\n}\n\n.bs-supply-item .bs-supply-content .bs-supply-title {\n  width: 15em;\n  margin-right: 10px;\n}\n\n.bs-supply-item .bs-supply-content .bs-supply-description {\n  width: 20em;\n}\n\n.bs-supply-item .bs-supply-content .bs-supply-title>div:first-child {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.bs-supply-item .bs-supply-content .bs-supply-description-content {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.bs-supply-item .bs-supply-content .bs-action {\n  margin-left: 10px;\n}\n\n.bs-supply-item .bs-item2-content {\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { ComponentType, FunctionComponent } from "react";
import { TeamStatement } from "@uTypes/business/Valorization";
import { TeamSubject } from "../subjects";
import Team from "@uTypes/business/Team";
import { DurationProps } from "../../types/StatementType";
import StatementLayout from "../statementLayout";



interface TeamStatementProps {
  statement: TeamStatement;
};
function TeamStatementComponent(Duration: ComponentType<DurationProps>) : FunctionComponent<TeamStatementProps> {
  return ({ statement }) => (
    <StatementLayout statement={ statement } displayCostResource='teams' displayCostAction='readCost'>
      <StatementLayout.Subject><TeamSubject team={ statement.team as Team } /></StatementLayout.Subject>
      <StatementLayout.Occurence><Duration duration={ statement.duration } /></StatementLayout.Occurence>
    </StatementLayout>
  );
};

export default TeamStatementComponent;
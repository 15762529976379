// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-starter-subscription-alert-container {
  display: flex;
  flex-direction: column;
  padding: 25px;
  justify-content: space-between;
  text-align: center;
  height:100%;
  border-radius: var(--std-radius);
  gap: 15px;
}

.bs-starter-subscription-alert-title {
  font-weight: bold;
}

.bs-starter-subscription-alert-buttons-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.bs-starter-subscription-alert-container .fa-exclamation-triangle {
  color: var(--std-color-orange)
}`, "",{"version":3,"sources":["webpack://./src/entities/notifications/components/starterSubscriptions.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,WAAW;EACX,gCAAgC;EAChC,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE;AACF","sourcesContent":[".bs-starter-subscription-alert-container {\n  display: flex;\n  flex-direction: column;\n  padding: 25px;\n  justify-content: space-between;\n  text-align: center;\n  height:100%;\n  border-radius: var(--std-radius);\n  gap: 15px;\n}\n\n.bs-starter-subscription-alert-title {\n  font-weight: bold;\n}\n\n.bs-starter-subscription-alert-buttons-container {\n  display: flex;\n  gap: 10px;\n  justify-content: center;\n}\n\n.bs-starter-subscription-alert-container .fa-exclamation-triangle {\n  color: var(--std-color-orange)\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

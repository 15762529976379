import HttpService from "@common/services/http";
import useService from "@universal/behaviour/hooks/useService";
import React, { FunctionComponent } from "react";

const ConnectedLogoOnly: FunctionComponent<{}> = ({}) => {
  const http = useService<HttpService>("http", ['onServiceUpdated']);
  
  const logo = React.useMemo(() => {
    if(http.hasRequestInProgress()){
      return (
        <img alt="BetterStreet" src={`/images/logos/betterstreet_color_spinner.svg`} style={{
          width: "40px",
          height: "40px",
        }} />
      )
    } else {
      return (
        <img alt="BetterStreet" src={`/images/logos/betterstreet_color.svg`} style={{
          width: "40px",
          height: "40px",
        }} />
      );
    }
  }, [http.hasRequestInProgress()]);

  return logo;
};

const ConnectedLogoWithText: FunctionComponent<{}> = ({}) => {
  const http = useService<HttpService>("http", ['onServiceUpdated']);
  
  const logo = React.useMemo(() => {
    if(http.hasRequestInProgress()){
      return (
        <img alt="BetterStreet" src={`/images/logos/betterstreet_color_text_horizontal_spinner.svg`} style={{
          width: "148px",
          height: "40px",
        }} />
      )
    } else {
      return (
        <img alt="BetterStreet" src={`/images/logos/betterstreet_color_text_horizontal.svg`} style={{
          width: "148px",
          height: "40px",
        }} />
      );
    }
  }, [http.hasRequestInProgress()]);

  return logo;
};

type ConnectedLogoProps = {
  withText?: boolean
};

const ConnectedLogo: FunctionComponent<ConnectedLogoProps> = ({ withText = false}) => {
  return withText ? <ConnectedLogoWithText /> : <ConnectedLogoOnly />
};

export default ConnectedLogo;
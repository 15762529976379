import Application from "@universal/lib/application";
import RepositoryService from "@universal/services/repository";
import User, { isLoggableUser, isUserTechnical, LoggableUser } from "@universal/types/business/User";
import ApiService from "@universal/services/api";
import generatePassword, { AppName } from "@universal/lib/password";
import createApplication, { StartHandler } from "../library/createApplication";
import ConfigurationService from "@universal/services/configuration";
import SessionService from "@universal/services/session";
import LoginAsManagerService from "@universal/services/loginAsSessionManager";
import DefaultStorageService from "@universal/services/defaultStorageService";

export const getUsernamePasswordForUser = async (appTest: Application, confTest: ConfigurationService, user: LoggableUser, beforeStart: StartHandler = (async () => {}), afterStart: StartHandler = (async () => {})) => {
  const password = generatePassword(32);
  await appTest.getService<ApiService>("api").service("users", "resetPassword").execute(user._id, password);

  return {
    application: await createApplication(confTest, beforeStart, afterStart),
    username: user.authentificationProviders[0].username,
    password: password
  };
}

export const getUserPassword = async (appTest: Application, confTest: ConfigurationService, query: object, test: ((user: User) => void) = (() => {}), beforeStart: StartHandler = (async () => {}), afterStart: StartHandler = (async () => {})) => {
  const repository = appTest.getService<RepositoryService>("repository");
  const users = await repository.get("User").repository.find(query) as User[];
  if(!users.length){
    throw `Aucun utilisateur de trouvé pour la requête ${JSON.stringify(query)}`;
  }
  const user = users[0];
  if(!isLoggableUser(user)){
    throw new Error("Erreur : l'utilisateur n'a pas la possibilité de se connecter");
  }
  test(user);
  return getUsernamePasswordForUser(appTest, confTest, user, beforeStart, afterStart);
}

export const getGlobalTechnicalUserPassword = (appTest: Application, confTest: ConfigurationService, appTechnicalUser: string, beforeStart: StartHandler = (async () => {}), afterStart: StartHandler = (async () => {})) => async () => {
  return getUserPassword(appTest, confTest, { discriminator: "globalTechnical", application: appTechnicalUser }, () => {}, beforeStart, afterStart);
}

export const getTechnicalUserPassword = (appTest: Application, confTest: ConfigurationService, appTechnicalUser: AppName, beforeStart: StartHandler = (async () => {}), afterStart: StartHandler = (async () => {})) => async() => {
  return getUserPassword(appTest, confTest, { discriminator: "technical", application: appTechnicalUser }, (user) => {
    if(!isUserTechnical(user)){
      throw "Erreur lors de la récupération de l'utilisateur technique";
    }
  }, beforeStart, afterStart);
};

export const getApplicationLogAsTechnical = async (appTest: Application, confTest: ConfigurationService, appTechnicalUser: AppName, beforeStart: StartHandler = (async () => {}), afterStart: StartHandler = (async () => {})): Promise<Application> => {
  const {
    application,
    username,
    password
  } = await (getTechnicalUserPassword(appTest, confTest, appTechnicalUser, beforeStart, afterStart))();

  await application.getService<SessionService>("session").login(username, password);
  return application;
};

export const getApplicationLogAsGlobalTechnical = async (appTest: Application, confTest: ConfigurationService, appTechnicalUser: string, beforeStart: StartHandler = (async () => {}), afterStart: StartHandler = (async () => {})): Promise<Application> => {
  const {
    application,
    username,
    password
  } = await (getGlobalTechnicalUserPassword(appTest, confTest, appTechnicalUser, beforeStart, afterStart))();

  await application.getService<SessionService>("session").login(username, password);
  return application;
};

const getJvsCommercialTechnicalUser = async (appTest: Application, confTest: ConfigurationService, beforeStart: StartHandler = (async () => {}), afterStart: StartHandler = (async () => {})) => {
  const repository = appTest.getService<RepositoryService>("repository");
  const usersJvsCommercial = await repository.get("User").repository.find({
    discriminator: "globalTechnical",
    application: "jvs-commercial"
  });

  if (!usersJvsCommercial.length) {
    throw new Error("Utilisateur technique global jvs-commercial non retrouvé, lancez le script de création");
  }
  
  return {
    application: await createApplication(confTest, beforeStart, afterStart),
    appTest,
    userCommercialId: usersJvsCommercial[0]._id 
  };
}

export const getJvsCommercialTechnicalUserFn = (appTest: Application, confTest: ConfigurationService, beforeStart: StartHandler = (async () => {}), afterStart: StartHandler = (async () => {})) => async() => {
  return await getJvsCommercialTechnicalUser(appTest, confTest, beforeStart, afterStart);
};

export const getApplicationLogAsJvsCommercial = async (appTest: Application, confTest: ConfigurationService, beforeStart: StartHandler = (async () => {}), afterStart: StartHandler = (async () => {})): Promise<Application> => {
  const {
    application,
    userCommercialId
  } = await getJvsCommercialTechnicalUser(appTest, confTest, beforeStart, afterStart);
  const applicationSession = application.getService<SessionService>("session");
  const appTestStorage = appTest.getService<DefaultStorageService>("storage");
  const appTestSession = appTest.getService<SessionService>("session");
  const token = await appTestStorage.get(appTestSession.tokenName);
  if(!token){
    throw new Error("token de l'utilisateur super-admin non retrouvé");
  }
  applicationSession.token = token;
  await application.getService<LoginAsManagerService>("loginAsSessionManager").login(userCommercialId);
  return application;
};



export default getTechnicalUserPassword;
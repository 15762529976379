import React, { FunctionComponent } from "react";
import _             from "lodash";
import Data          from "@uBehaviour/data";
import Button        from '@cComponents/button';
import User          from "@entities/users";
import Modal         from '@cComponents/modal';
import T             from "@cBehaviour/i18n";
import Filter        from "@cComponents/filter";
import FilteredList  from "@cFeatures/filter/list";
import Input         from "@cComponents/input";
import Acl from "@universal/behaviour/acl";
import useService from "@universal/behaviour/hooks/useService";
import CurrentTenantService from "@universal/services/currentTenant";
import AclService from "@universal/services/acl";
import SessionService from "@universal/services/session";
import useOpenCloseToggle from "@universal/behaviour/hooks/useOpenCloseToggle";
import Display from "@common/components/displayIf";
import TechnicalUser, { DisplayTechnicalUser } from "@entities/users/components/technicalUser";
import withFactory from "@universal/hoc/withFactory";
import UserType, { isAgent, isRealPersonUser, isUserPro, isUserTablet, isUserTechnical, UserDiscriminators } from "@universal/types/business/User";
import UserForm from './form';
import withData from "@universal/hoc/withData";
import { withLink, withModalLinked } from "@cHoc/withLinkAndModalLinked";
import { Link } from "@cFeatures/router";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar from "@cComponents/searchbar";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import useHelper from "@universal/behaviour/hooks/useHelper";
import UserHelper from "@universal/helpers/users";
import { isEmail } from "@universal/lib/string";
import UpdatePassword from "@common/entities/user/updatePassword";
import { escapeRegExp } from "@uLib/tool";

function textSearchBuildQuery(value: string){
  return { $or: [
    { fullname: { '$regex': escapeRegExp(value), '$options' : 'i' }},
    { email:    { '$regex': escapeRegExp(value), '$options' : 'i' }}
  ]};
}

function textSearchStringify(value: string){
  return (<><b><T>contains</T></b>: {value}</>);
}

interface InputTextSearchProps { 
  value: string;
  set: (value: string) => void;
  clear: () => void;
}

function InputTextSearch({ value, set, clear }: InputTextSearchProps) {
  const setOrClearDebounced = React.useCallback(_.debounce((value) => {
    if(value){
      set(value);
      return;
    }
    clear();
  }, 1000), []);

  return (
    <Input.Text focusOnMount className="bs-filter-text" value={ value } onChange={ setOrClearDebounced }>
      <T>freesearch</T>
    </Input.Text>
  );
}

function defaultQuery(){
  return [{ name: "disabled", value: false }];
}

interface UserFormProps {
  close: () => void;
  data: UserType;
}

const isTechnicalOrRelated = (data: any) => isUserTechnical(data as UserType) || isUserTablet(data as UserType);

const Form = withFactory<UserFormProps>([
  { test: (props) => !props.data, component: UserForm },
  { test: (props) => props.data && isTechnicalOrRelated(props.data), component: DisplayTechnicalUser },
  { test: (props) => props.data && !isTechnicalOrRelated(props.data), component: UserForm }
]);

type UserItemProps = {
  data: BusinessEntity<UserType>;
}

const UserItem: FunctionComponent<UserItemProps> = ({ data: user }) => {
  const usersHelper = useHelper<UserHelper>("users");
  const currentTenantService = useService<CurrentTenantService>("currentTenant");
  const aclService = useService<AclService>("acl");

  const isAllowToInvite =  isRealPersonUser(user)
    && user.authentificationProviders.some(ap => ap.username && isEmail(ap.username))

  const isAllowToResetPassword = !isUserTechnical(user)
    && usersHelper.isAllowToChangePasswordOn(user);

  const isAllowToResetPin = isAgent(user) && aclService.isAllow("users", "loginFromTablet", user.toPlainText(), currentTenantService.currentId);

  const isAllowToCopy = user.discriminator !== "technical"
    && (aclService.connectedUserIsAllow("application", "configure") 
      || !aclService.isAllow("application", "administrate", user.toPlainText())
    );

  const isAllowToCopyCalendar =  isUserPro(user)
    && !!user.tenants.find(t => t.roles.includes("agent"));

  const [ displayPasswordForm, openPasswordFormModal, closePasswordFormModal ] = useOpenCloseToggle(false);
  const updatePassword = React.useCallback((password: string) => {
    usersHelper.updatePassword(user._id, password).then(() => {
      closePasswordFormModal();
    });

  }, [ user._id, usersHelper, closePasswordFormModal ]);

  const [ userForm, setUserForm ] = React.useState(null);
  const copy = React.useCallback(() => {
    const newUser = Object.assign({
      authentificationProviders: [{
        discriminator: "username",
        username: "",
        password: ""
      }],
      email: "",
    }, _.pick(user.toPlainText(), ["tenants", "settings", "skills", "valorization", "discriminator", "roles"]));
    setUserForm(newUser);
  }, [ user, setUserForm ]);

  return (
    <>
      <User.Item data={user}>
        <User.Item.Details>
          <User.Details user={user} />
        </User.Item.Details>
        <User.Item.Actions>
          { isAllowToInvite && (<User.Actions.Invite user={ user } />) }
          { isAllowToResetPassword && (<User.Actions.ResetPassword onClick={ openPasswordFormModal } />) }
          { isAllowToResetPin && (<User.Actions.ResetPin userId={ user._id } />) }
          { isAllowToCopy && (<User.Actions.Copy onClick={ copy } />) }
          { isAllowToCopyCalendar && (<User.Actions.CopyCalendarLink user={ user } />) }
        </User.Item.Actions>
      </User.Item>
      {!!displayPasswordForm && (
        <Modal.Show style={{ width: "40vw" }} close={closePasswordFormModal}>
          <UpdatePassword updatePassword={ updatePassword } />
        </Modal.Show>
      )}
      { !!userForm && (
        <Modal.Show
          style={{ width: "80vw", height: "90vh" }}
          close={ () => setUserForm(null) }
        >
          <UserForm data={ userForm } />
        </Modal.Show>
      )}
    </>
  )
}

const LinkedUserItem = withLink<UserItemProps, UserType>(UserItem);
const WithDataForm = withData(Form, "User", "id");
const ModalizedUserForm = withModalLinked(WithDataForm);


interface UserListProps {

}

const UserList: FunctionComponent<UserListProps> = () => {
  const currentTenant = useService<CurrentTenantService>("currentTenant", ["onServiceUpdated"]);
  const acl = useService<AclService>("acl");  
  const session = useService<SessionService>("session", ["onServiceUpdated"]);

  const [isTechnicalUserFormOpen, openTechnicalUserForm, closeTechnicalUserForm] = useOpenCloseToggle();

  const query = React.useMemo(() => {
    let baseQuery: any = { 
      "tenants.roles": { $ne: "starter" }
    };
    
    if(currentTenant.isSelected()){
      if(acl.connectedUserIsAllow("application", "configure")){ 
        return Object.assign(baseQuery, { $or: [
          currentTenant.useInQuery({ discriminator: "pro" }, false, "tenants.tenant"),
          currentTenant.useInQuery({ discriminator: "technical" }, false, "tenant"),
          currentTenant.useInQuery({ discriminator: "tablet" }, false, "tenant")
        ]});
      } else {
        return Object.assign(baseQuery, currentTenant.useInQuery({ discriminator: "pro" }, false, "tenants.tenant"));
      }
      
    }
    
    if(!acl.connectedUserIsAllow("application", "configure")){
      return Object.assign({}, baseQuery, {
        discriminator: { $nin: ["citizen", "collaborator", "betterstreet"] }
      });
    }
    return Object.assign(Object.assign({}, baseQuery, {
      discriminator:  { $nin: ["citizen", "betterstreet"] }
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenant.currentId, session.userId]);

  return (
    <>
      <FilteredList default={ defaultQuery() }>
        <FilteredList.Filter>
          <User.Filters />
        </FilteredList.Filter>
        <FilteredList.Header>
          <ListHeaderLayout>
            <ListHeaderLayout.Center>
              <Filter.Generic name="text" buildQuery={ textSearchBuildQuery } stringify={ textSearchStringify }>
                {(value, set, clear) => ( 
                  <Searchbar>
                    <InputTextSearch value={ value } set={ set } clear={ clear } />
                  </Searchbar>
                )}
              </Filter.Generic>
            </ListHeaderLayout.Center>
            <ListHeaderLayout.Right>
              <Acl.If resource="users" action="manageTechnical">
                <Button.Text onClick={ openTechnicalUserForm }>
                  <T>user_list_createTechnicalUser</T>
                </Button.Text>
                <Display.If condition={ isTechnicalUserFormOpen }>
                  <Modal.Show close={ closeTechnicalUserForm } style={{ width: "80vw"}}>
                  {(close) => (<TechnicalUser close={ close } />)}
                  </Modal.Show>
                </Display.If>
              </Acl.If>
              <Link to="/new">
                <Button.Stylized.Text><T>add</T></Button.Stylized.Text>
              </Link>
            </ListHeaderLayout.Right>
          </ListHeaderLayout>
        </FilteredList.Header>
        <FilteredList.List>
        {composeQuery => (
          <Data.List model={"User"} query={composeQuery(query)} sort={{ fullname: 1 }}  load={ User.Item.load }>
          {(user) => {
            return (
              <LinkedUserItem data={user} />
            )
          }}
          </Data.List>
        )}
        </FilteredList.List>
      </FilteredList>
      <ModalizedUserForm style={{ width: "80vw", height: "90vh" }}/>
    </>
  )
};

export default UserList;

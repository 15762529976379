import React from "react";
import T from "@cBehaviour/i18n";
import "./error.css";
import CErrorNew from '@cComponents/error_new'; 

const CError = ({ errors = [], children = null }) => (
  <CErrorNew errors={errors} children={children} />
);

export default CError;


import dayjs from 'dayjs';
import _ from 'lodash';
import md5 from 'md5';

import { Service } from "@uLib/application";
import Event, { Listener } from "@uLib/event";
import NotificationBuilder from "@uLib/notificationBuilder";
import Pager from "@universal/lib/pager";
import { NotificationUsed } from "@universal/types/business/Notification";
import Criterion, { Filter } from '@uLib/filter'; 
import notificationConfigurationToFilter from '@uBusiness/notificationConfigurationToFilter';
import User, { NotificationSubscription } from "@universal/types/business/User";
import SessionService from './session';
import CurrentTenant from '@root/services/currentTenant';
import NetworkingType from "@uServices/types/networking";
import PagerService from './pager';


const getNotificationSubscriptionsHash = (subscriptions: NotificationSubscription[], user: User, load: object) => {
  return md5(user._id + "::" + JSON.stringify(subscriptions
      .filter(sub => sub.channels.includes("push"))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(sub => sub.name)
    )
    + "::" +
    JSON.stringify(load)
  );
}

const notificationSubscriptionToNotification = {
  onIssueCreatedAsManager: "IssueCreate",
  onCitizenCommentAsManager: "IssueComment",
  onIssueStateUpdatedAsManager: "IssueUpdate",
  onIssueUpdatedAsManager: "IssueUpdate",
  onIssueCreatedAsFollower: "IssueCreate",
  onIssueStateUpdatedAsFollower: "IssueUpdate",
  onCommentAsReceiver: "IssueComment",
  onAssignedToAnIssue: "IssueAssignment"
}


class BusinessNotificationService extends Service {
  private _onNotificationLoadedListener: Listener;

  private _onReloadListener: Listener;

  private _onNotificationChange: Event;
  
  private _pager: Pager | null;

  private _limit: number;

  private _notificationsBuilder: NotificationBuilder;

  private _allowedNotificationFilter: Filter;

  private _notificationsSubscriptionsHash: string | null;

  constructor(limit: number){
    super("businessNotification", ["currentTenant", "pager", "session", "acl", "networking"]);
    this._limit = limit;
    this._onNotificationChange = new Event();
    this._onNotificationLoadedListener = new Listener(this._onNotificationLoaded, this);
    this._onReloadListener = new Listener(this._onReload, this);
    this._notificationsBuilder = new NotificationBuilder();
    this._allowedNotificationFilter = Criterion.factory({});
    this._notificationsSubscriptionsHash = null;
    this._pager = null;
  }

  get onNotificationChange(): Event{
    return this._onNotificationChange;
  }

  get withTenant(){
    return this.application.getService<CurrentTenant>('currentTenant').changeMode !== 'none';
  }

  private isNotificationAllowed(){
    const session = this.application.getService<SessionService>('session');
    const networking = this.application.getService<NetworkingType>('networking');
    return networking.isConnected()
      && session.isLogged()
      && (session.user.discriminator === "pro" || session.user.discriminator === "collaborator")
      && !session.isUserProWithoutTenant();
  }

  async start(){
    const [session, networking] = await this.waitReady<[SessionService, NetworkingType]>(["session", "networking", "acl", "currentTenant", "pager"]);
    session.onServiceUpdated.addListener(this._onReloadListener);
    networking.onStateChange.addListener(this._onReloadListener);
    if(!this.isNotificationAllowed()){
      return;
    }
    this._initialize(session);
  }

  _onReload(){
    if(!this.isNotificationAllowed()){
      this.destroyPager();
    } else {
      this._initialize(this.application.getService('session'));
    }
  }
  
  _initialize(session: SessionService){ 
    const load: any = { 
      issue: {
        files: true,
      },
      createdBy: true
    };
    if(this.withTenant){
      load.issue.tenant = true;
    }

    let filter = notificationConfigurationToFilter(session.user.personalSettings.subscriptions, session.user._id);
    this._allowedNotificationFilter = filter;

    const currentHash = getNotificationSubscriptionsHash(session.user.personalSettings.subscriptions, session.user, load);
    if(this._notificationsSubscriptionsHash === currentHash || !this.hasNotificationSubscriptions()){
      return Promise.resolve();
    }
    this._notificationsBuilder = new NotificationBuilder();
    if(this._pager){
      this.destroyPager();
    }
    this._notificationsSubscriptionsHash = currentHash;
    this.createPager(load);
    return this._firstLoad();
  }

  createPager(load: object){
    const pagerService = this.application.getService<PagerService>("pager");
    //Hack: Ajout du tri createdAt même si pas utilisé au niveau du service API afin de correspondre à
    //ce qui est fait coté serveur (pour que les éléments venant du push soient filtrés)
    this._pager = pagerService.create("Notification", this.queryFromNotificationSubscriptions, { createdAt: -1 }, load) as Pager;
    this._pager.stateChange.addListener(this._onNotificationLoadedListener);
  }

  destroyPager(){
    if(!this._pager){
      return;
    }
    this._pager.stateChange.removeListener(this._onNotificationLoadedListener);
    const pagerService = this.application.getService<PagerService>("pager");
    pagerService.unregister(this._pager.id);
    this._pager = null;
    this._notificationsSubscriptionsHash = null;
    this._onNotificationChange.trigger();
  }

  _firstLoad(){
    return new Promise((resolve, reject) => {
      if(!this._pager){
        reject(new Error("Pager not initialized"));
        return;
      }
      if(this._pager.isFinishedLoading()){
        resolve({});
        return;
      }
      this._pager.next(this._limit).then(() => {
        if(this.get().length < 10){
          this._firstLoad().then(() => resolve({}));
          return;
        }
        resolve({});
      }, err => {
        reject(err);
      });
    });
  }
  private hasNotificationSubscriptions(){
    return this.application.getService<SessionService>('session')
      .user.personalSettings.subscriptions
      .filter((s: NotificationSubscription) => s.channels.includes("push")).length > 0;
  }
  private get queryFromNotificationSubscriptions(){
    if(!this.hasNotificationSubscriptions()){
      throw new Error("No notification subscriptions");
    }
    const notifications = this.application.getService<SessionService>('session')
      .user.personalSettings.subscriptions
      .filter((s: NotificationSubscription) => s.channels.includes("push"))
      .map((s: NotificationSubscription) => s.name);

    return {
      $or: [{
        type: { $in: 
          _.uniq(notifications.map((n: keyof typeof notificationSubscriptionToNotification) => notificationSubscriptionToNotification[n]))
        }
      }, {
        discriminator: { $exists: true }
      }]
    };
    
  }
  _loadNotification(element: any){
    if(element.type === "IssueCreate"){
      this._notificationsBuilder.addCreated(element.issue, dayjs(element.createdAt).toDate(), element.createdBy);
    }else if(element.type === "IssueUpdate"){
      if(element.diff.some(d => d.path.includes("state"))) {
        this._notificationsBuilder.addStateUpdated(element.issue, dayjs(element.createdAt).toDate(), element.createdBy, element);
      } else {
        this._notificationsBuilder.addUpdated(element.issue, dayjs(element.createdAt).toDate(), element.createdBy, element);
      } 
    }else if(element.type === "IssueAssignment"){
      this._notificationsBuilder.addAssigned(element.issue, dayjs(element.createdAt).toDate(), element.createdBy, element);
    }else if(element.type === "IssueComment"){
      this._notificationsBuilder.addComment(element.issue, dayjs(element.createdAt).toDate(), element.createdBy, element.message);
    }else if(element.discriminator === "seen"){
      this._notificationsBuilder.markSeen(dayjs(element.createdAt).toDate());
    }else {
      if(element.all){
        this._notificationsBuilder.markAllRead(dayjs(element.createdAt).toDate());
      }else{
        this._notificationsBuilder.markIssueRead(element.subject.id, dayjs(element.createdAt).toDate());
      }
    }
  }

  loadNext(){
    if(!this._pager){
      return Promise.resolve();
    }
    if(this._pager.isFinishedLoading()){
      return Promise.resolve();
    }
    return this._pager.next(this._limit);
  }

  get(): NotificationUsed[] {
    if(!this._pager){
      return [];
    }
    const notifications = this._notificationsBuilder.get();
    return notifications.filter(notification => this._allowedNotificationFilter.match(notification));
  }

  _onNotificationLoaded(event: string, datas: any[]){
    if(event === 'loaded'){
      datas.forEach(element => {
        this._loadNotification(element);
      });
      this._onNotificationChange.trigger();
    }else if(event === 'entities-sorted'){
      this._notificationsBuilder = new NotificationBuilder();
      if(!this._pager){
        return;
      }
      this._pager.forEachLoaded((element: any) => {
        this._loadNotification(element);
      });
      this._onNotificationChange.trigger();
    }
  }
}

export default BusinessNotificationService;
import React, { FunctionComponent } from "react";
import DropDown from '@cComponents/dropDown';
import useService from "@universal/behaviour/hooks/useService";
import I18nService from "@universal/services/i18n";

const LanguageSelector: FunctionComponent = () => {
  const i18n = useService<I18nService>("i18n");
  return (
    <DropDown.List alignRight={ true }>
      <DropDown.List.Main>
        <div>{ i18n.currentLanguage.label }</div>
      </DropDown.List.Main>
      <DropDown.List.List>
        {i18n.acceptedLanguages.filter(l => l.iso !== i18n.currentLanguage.iso).map(language => (
          <div onClick={() => i18n.currentLanguage = language }>
            <span>{language.label}</span>
          </div>
        ))}
      </DropDown.List.List>
    </DropDown.List>
  )
}

export default LanguageSelector;